import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getStatus = async () => {
    try {
        const token = await getToken();
        const response = await axios.get(`${BaseUrl}/students/getallstatuses`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const info = response.data || [];
        const status = info.map((item, index) => {
            return {
              idstudent_status: item.idstudent_status || '',
              status_name: item.status_name || '',
              status_description: item.status_description || '',
              //index: index
            };
        });
        return status;
    } catch (error) {
        return [];
    }
};

export default getStatus ;

