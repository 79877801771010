import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getTodayIntersactions= async () => {
  try {  
    const token = await getToken();      
    const response = await axios.get(`${BaseUrl}//students/gettodaytransactions`,{
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const info = response.data || [];
    const ContactStateList = info.map((item, index) => {
      return {
        studentid: item.studentid || '',
        nextcalldatetime: item.nextcalldatetime || '',
        follower_namesurname: item.follower_namesurname || '',
        email: item.email || '',
        std_namesurname: item.std_namesurname || '',
      };
    });
    return ContactStateList;
  } catch (error) {
        console.log("getTodayContacts Error: ", error);
  }
};

export { getTodayIntersactions };