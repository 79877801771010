const PDFViewer = ({ fileData, onClose }) => {
    return (
        <>
            <button onClick={onClose} style={{ position: 'absolute', top: -40, right: 5, width: 85, height: 35 }}>Close</button>
            <iframe
            src={fileData}
            title="PDF Viewer"
            width="100%"
            height="100%"
            style={{ border: 'none' }}
            />
        </>
    );
  };
  
  export default PDFViewer;
