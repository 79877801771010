import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, 
InputLabel, FormControl, Alert, Snackbar, FormControlLabel, RadioGroup, Radio, Box,Typography,
FormGroup, Checkbox,Accordion, AccordionSummary, AccordionDetails, Paper, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; 
import Iconify from '../iconify';
import InputAdornment from '@mui/material/InputAdornment';
import creatstudent from '../../_data/student/createstudent';
import { useTranslation } from 'react-i18next'; //Multi Language
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneInput from '../student/PhoneInput';
import getSession from '../../utils/getSession';
import style from '../../theme/blink.css';
import { getActiveLmsBatches } from '../../_data/batchs/getActiveLmsBatches';
import config from '../../config';

const CreateStudentDialog = forwardRef(({ initialData, onStudentAdded }, ref) => {
//const CreateStudentDialog = forwardRef(({ onStudentAdded }, ref) => { 
  //TODO: cagrildigi yerdeki tooltip ref degeri almayan bir yapida oldugu icin fonksiyon asagidaki seklinden bu yapiya alip ref degeri alacak sekilde revize ettim. 
  const { PUBLIC_URL } = config;
  const TransactionIcon = `${PUBLIC_URL}/assets/icons/navbar/ic_transaction.svg`;
  const account = getSession();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [teachableId, setTeachableId] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',    
    batchname: '',
    idbatches: '' ,
    contact: {city: "", mail: "", phone: "", address: "", country: ""},
    memberships: {Teachable: true, Quizizz: false, TryHackMe: false, DigitalCampus: false, DiscordUserName: ""},
    resourcecode: '',
    interninfo: '',
    status: '',
    description: '',
    bycreated: account.user[0].iduser
  }); 
  const [newResource, setNewResource] = useState("");
  //const CourseNames = ['BATCH-7', 'BATCH-8', 'BATCH-9']; //TODO: Batch tablosundan dynamic almalı tablodaki isactive=1 olanlar gösterilmeli  
  const Status = [
    // {key:4, value:'Prospective Student'}, 
    // {key:1, value:'Registered Student'}, 
    // {key:2, value:'Intern Student'}, 
    // {key:3, value:'Timed Access Granted'}, 
    // {key:5, value:'Relationship Ended'},
    {key:6, value:'LMS-Hybrid Registered'}, 
    {key:7, value:'LMS Registered'}
  ]; //TODO: Batch tablosundan dynamic almalı tablodaki isactive=1 olanlar gösterilmeli     

  const handleComingStudentData = (data) => {
    //console.log('data: ', data);
    setTeachableId(data.id);
    const nameParts = data.name.split(' ');
    let _name = '';
    let _surname = '';
    if (nameParts.length === 2) {
      _name = nameParts[0];
      _surname = nameParts[1];
    }else if (nameParts.length >= 3) {
      _name = nameParts.slice(0, -1).join(' ');
      _surname = nameParts[nameParts.length - 1];
    }else if (nameParts.length === 1) {
      _name = nameParts[0];
    };
    setFormData({
      ...formData,
      id: data.id,
      name: _name,
      surname: _surname,
      email: data.email,
      contact: {
        mail:{
          mailaddress1: data.email
        },
        phone: {
          cellphone1: "",
          whatsapp: "",
        },
      }
    });
  };

  useEffect(() => {
    if(initialData) {
      handleComingStudentData(initialData);
    }  
  }, [initialData]);

  useImperativeHandle(ref, () => ({
    handleClickOpen: () => {
      setOpen(true);
    }
  }));

  const handleMembershipCheckboxChange = (event) => {
    setFormData({
      ...formData,
      memberships: {
        ...formData.memberships,
        [event.target.name]: event.target.checked
      }
    });
  };

  const [courseNames, setCourseNames] = useState([])
  useEffect(() => {
    const fetchActiveBatches = async () => {
      const data = await getActiveLmsBatches();
      const courses = Array.isArray(data) ? data : [];
      setCourseNames(courses);
    };
    fetchActiveBatches();
  }, []);

  const handleMembershipTextChange = (event) => {
    setFormData({
      ...formData,
      memberships: {
        ...formData.memberships,
        [event.target.name]: event.target.value
      }
    });
  };

  // const copyToClipboard = async (text) => {
  //   try {
  //     if (navigator.clipboard && window.isSecureContext) {
  //       await navigator.clipboard.writeText(text);
  //       showMessage('Create e-Mail User & Password copied.');
  //     } else {
  //       // Eski tarayıcılar için alternatif yöntem
  //       let textArea = document.createElement("textarea");
  //       textArea.value = text;
  //       document.body.appendChild(textArea);
  //       textArea.focus();
  //       textArea.select();
  //       document.execCommand('copy');
  //       document.body.removeChild(textArea);
  //       showMessage('Create e-Mail User & Password copied.');
  //     }
  //   } catch (err) {
  //     console.error('Could not copy password to clipboard!', err);
  //   }
  // };

  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success'); // or 'error'
  const [alertMessage, setAlertMessage] = useState('');
  const showMessage = (message, severityType = 'success') => {
    setAlertMessage(message);
    setSeverity(severityType);
    setMsgOpen(true);
  };
  const handleCloseStudentMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeStatus = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, status: value });
  };

  const handleChangeCourseName = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, idbatches: value, batchname: name });
  };

  const handleChange = (event) => {
    try {
      const { name, value } = event.target;
      if(name === 'contact.mail.mailaddress1'){
        setFormData({ ...formData, email: value });
      }     

      const nameParts = name.split('.'); // Nokta ile ayrılmış yolu parçalara ayır
      setFormData((prevFormData) => {
        let updatedValue = { ...prevFormData }; // İlk kopyayı oluştur
        nameParts.reduce((acc, key, index) => {
          if (index === nameParts.length - 1) {
            // Eğer son parçadaysak değeri güncelle
            acc[key] = value;
          } else {
            // Eğer son parça değilse, bir sonraki parçaya geç
            acc[key] = acc[key] || {}; // Eğer bir sonraki anahtar yoksa, yeni bir nesne oluştur
          }
          return acc[key];
        }, updatedValue);
        return updatedValue; // Güncellenmiş değeri döndür
      });
    // }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleChangeResource = (event) => {
    setFormData({ ...formData, resourcecode: event.target.value });
  };

  const nameRef = useRef(null);
  const surnameRef = useRef(null);
  const resourcecodeRef = useRef(null);
  const statusRef = useRef(null);
  const batcheRef = useRef(null);
  const mailRef = useRef(null);
  const cellphone1Ref = useRef(null);
  const [formErrors, setFormErrors] = useState({});
  const [firstErrorField, setFirstErrorField] = useState(null);

  const validateForm = () => {
   // console.log('formData: ', formData);
    
    let errors = {};
    let firstError = null;
    if (!formData.name) {
      errors.name = true;
      firstError = firstError || 'name';
      showMessage("Fill in (name) the required fields! ", 'warning');
    }
    if (!formData.surname) {
      errors.surname = true;
      firstError = firstError || 'surname';
      showMessage("Fill in (surname) the required fields! ", 'warning');
    }
    if (!formData.resourcecode && formData.status !== 4 ) {
      errors.resourcecode = true;
      firstError = firstError || 'resourcecode';
      showMessage("Fill in (recourse) the required fields! ", 'warning');
    }
    if (!formData.status) {
      errors.status = true;
      firstError = firstError || 'status';
      showMessage("Fill in (status) the required fields! ", 'warning');
    }
    if (!formData.contact.phone.cellphone1) {
      //console.log('formData.contact.phone.cellphone1: ', formData.contact.phone);
      errors.cellphone1 = true;
      firstError = firstError || 'cellphone1';
      showMessage("Fill in (contact>phone>cellphone1) the required fields! ", 'warning');
    }
    if (!formData.idbatches && formData.status !== 4) {
      errors.idbatches = true;
      firstError = firstError || 'coursename';
      showMessage("Fill in (course name) the required fields! ", 'warning');
    }
    if (!formData.email) {
      errors.email = true;
      firstError = firstError || 'email';
      showMessage("Fill in (contac->mail) the required fields! ", 'warning');
    }
    setTimeout(() => setFormErrors(false), 3000);
    setFormErrors(errors);
    setFirstErrorField(firstError);
    
    if(firstError) {
      const refs = {name: nameRef, surname: surnameRef, resourcecode: resourcecodeRef, status: statusRef, coursename: batcheRef, email: mailRef, cellphone1: cellphone1Ref};
      if (refs[firstError] && refs[firstError].current && typeof refs[firstError].current.focus === 'function') {
        refs[firstError].current.focus();
      } else {
        console.error(`Focus function not found for field: ${firstError}`);
      }

      // if (refs[firstError] && refs[firstError].current) {
      //   refs[firstError].current.focus();
      // }


     // refs[firstError].current?.focus();
    }
    return Object.keys(errors).length === 0;
  };

  const updatedFormData = {
    ...formData,
  };

  const handleSubmit = async() => {    
    setFormData(updatedFormData);   
    const isValid = validateForm();
    if (!isValid) return;
    const result = await creatstudent(formData);
    
    if(result.affectedRows === 1 && result.message === "New Student created successfully."){
      showMessage(t('NewStudentCreateSuccess'), 'success');
      onStudentAdded();
      handleClose();
    } else {
      showMessage(result.message, 'error');
    }    
  };

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getNestedValue = (obj, path) => {
    //console.log('Path: ', path.split('.').reduce((acc, part) => acc && acc[part], obj) || '');
    return path.split('.').reduce((acc, part) => acc && acc[part], obj) || '';
  };

  const [contentEmailError, setContentEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');
  const [contentFullnameError, setContentFullnameError] = useState(false); 
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const handleBlur = (e) => {
    const isValid = emailRegex.test(e.target.value);
    if (!isValid) {
      setEmailHelperText("Please enter a valid email address.");
      setContentEmailError(true);
    } else {
      setEmailHelperText("");
      setContentEmailError(false);
    }
  };

  return (
    <div>
      <IconButton 
        size="small" 
        onClick={() => handleClickOpen()}
        style={{ color: 'white', marginLeft: '8px' }}
      >
        <img src={TransactionIcon} alt="Interview_Transactions" style={{ width: 24, height: 24 }} />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('Title.CNS')} / {teachableId}</DialogTitle>
        <DialogContent>
          <TextField
            inputRef={nameRef}
            margin="dense"
            name="name"
            label={t('Name')}
            type="text"
            fullWidth            
            variant="outlined"
            value={formData.name}
            onChange={handleChange}            
            className={formErrors.name ? 'blinking' : ''}
          />
          <TextField
            inputRef={surnameRef}
            margin="dense"
            name="surname"
            label={t('Surname')}
            type="text"
            fullWidth            
            variant="outlined"
            value={formData.surname}
            onChange={handleChange}
            className={formErrors.surname ? "blinking" : ""}
          />
          <Paper 
              sx={{
                mt:1,
                pl:2,
                border: 1,
                borderColor: 'divider'
              }}
          >
            <FormControl sx={{ ml: 0 }} fullWidth margin="dense">
              <InputLabel sx={{ pl: 0 }} id="resource-select-label" shrink>{t('ResourceCode')}</InputLabel>
              <RadioGroup 
                row aria-label="resourcecode" 
                name="row-radio-buttons-group" 
                inputRef={resourcecodeRef}
                //defaultValue="L"
                value={newResource.resourcecode}  
                onChange={handleChangeResource} 
                sx={{ mt: 1 }}
                className={formErrors.resourcecode ? "blinking" : ""}
              >
              {/*  <FormControlLabel value="P" control={<Radio />} label={t('Private')} sx={{ mr: 8 }}/> 
                <FormControlLabel value="J" control={<Radio />} label={t('JobCenter')} />*/}
                <FormControlLabel value="L" control={<Radio />}  label={t('LMS-Hybrid')} />
              </RadioGroup>
            </FormControl>
          </Paper>
          <FormControl fullWidth margin="dense">
            <InputLabel id="coursename-select-label">{t('CourseName')}</InputLabel>
            <Select
              inputRef={batcheRef}
              labelId="coursename-select-label"
              name="batchename"
              label={t('CourseName')}
              value={formData.idbatches}
              onChange={handleChangeCourseName}
              className={formErrors.idbatches ? "blinking" : ""}
            >
              {courseNames.length > 0 && courseNames.map((courseName) => (                                
                <MenuItem key={courseName.idbatches} value={courseName.idbatches}>
                  {courseName.batchname}                 
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
              <InputLabel id="status-select-label">Status</InputLabel>
              <Select
                inputRef={statusRef}
                labelId="status-select-label"
                name="status"
                label="Status"
                value={formData.status}
                onChange={handleChangeStatus}                
                className={formErrors.status ? "blinking" : ""}
              >
                {Status.map((status, index) => (
                  <MenuItem key={index} value={status.key}>
                    {status.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          <Paper 
              sx={{
                mt:1,
                border: 1,
                borderColor: 'divider'
              }}
          >
            <Accordion expanded={expanded === 'contact'} onChange={handleAccordionChange('contact')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="contactbh-content"
                id="contactbh-header"
              >
                <Typography variant="h6">{t('Contact')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField            
                  margin="dense"
                  name="contact.address"
                  label="Address"
                  type="text"
                  fullWidth            
                  variant="outlined"
                  value={getNestedValue(formData, "contact.address")}
                  onChange={handleChange}
                />
                <TextField            
                  margin="dense"
                  name="contact.city"
                  label="City"
                  type="text"
                  fullWidth            
                  variant="outlined"
                  value={getNestedValue(formData, "contact.city")}
                  onChange={handleChange}
                />
                <TextField            
                  margin="dense"
                  name="contact.country"
                  label="Country"
                  type="text"
                  fullWidth            
                  variant="outlined"
                  value={getNestedValue(formData, "contact.country")}
                  onChange={handleChange}
                />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                  <TextField
                    autoFocus={contentEmailError}
                    inputRef={mailRef}
                    error={contentEmailError}
                    helperText={contentEmailError ? emailHelperText : ""}
                    type="email"
                    margin="dense"
                    name="contact.mail.mailaddress1"
                    label="Mail Address 1"
                    fullWidth
                    variant="outlined"
                    value={getNestedValue(formData, "contact.mail.mailaddress1")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={formErrors.email ? "blinking" : ""}
                  />                  
                </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <PhoneInput
                      inputRef={cellphone1Ref}                    
                      margin="dense"
                      name="contact.phone.cellphone1"
                      label="Phone CellPhone 1"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={getNestedValue(formData, "contact.phone.cellphone1")}
                      onChange={handleChange}                      
                      className={formErrors.cellphone1 ? "blinking" : ""}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneInput 
                        margin="dense"
                        name="contact.phone.whatsapp"
                        label="Phone WhatsApp Number"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={getNestedValue(formData, "contact.phone.whatsapp")}
                        onChange={handleChange} 
                      />
                  </Grid>                  
                </Grid>                   
              </AccordionDetails>
            </Accordion>
          </Paper>
          <Paper 
            sx={{
              mt:1.5,
              border: 1,
              borderColor: 'divider'
            }}
          >    
            <Accordion expanded={expanded === 'memberShips'} sx={{ order: 1, borderColor: 'divider'}} onChange={handleAccordionChange('memberShips')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="memberShipsbh-content"
                id="memberShipsbh-header"
              >
                <Typography variant="h6" gutterBottom>{t('MemberShips')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ p: 2 }}>              
                  <FormGroup>
                  <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.memberships.Teachable}
                          onChange={handleMembershipCheckboxChange}
                          name="Teachable"
                        />
                      }
                      label="LMS"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.memberships.Quizizz}
                          onChange={handleMembershipCheckboxChange}
                          name="Quizizz"
                        />
                      }
                      label="Quizizz"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.memberships.TryHackMe}
                          onChange={handleMembershipCheckboxChange}
                          name="TryHackMe"
                        />
                      }
                      label="TryHackMe"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.memberships.DigitalCampus}
                          onChange={handleMembershipCheckboxChange}
                          name="DigitalCampus"
                        />
                      }
                      label="DigitalCampus"
                    />
                    <TextField
                      label="Discord Username"
                      name="DiscordUserName"
                      value={formData.memberships.DiscordUserName}
                      onChange={handleMembershipTextChange}
                      margin="normal"
                      fullWidth
                    />
                  </FormGroup>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Paper>
          <TextField
            margin="dense"
            name="description"
            label={t('Description')}
            type="text"
            fullWidth
            variant="outlined"
            value={formData.description}
            onChange={handleChange}
          />                    
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button onClick={handleSubmit} autoFocus
            style={{
              backgroundColor:'blue',
              color: '#FFFFFF',
            }}>{t('Create')}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={5000} 
        onClose={handleCloseStudentMessage}
      >
        <Alert severity={severity} onClose={handleCloseStudentMessage} sx={{ width: 450, minWidth: 288, height: 75, fontSize: 14, fontStyle: 'normal'}} >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
});
export default CreateStudentDialog;
