import { Helmet } from 'react-helmet-async';
// @mui
import { Stack, Container, Typography } from '@mui/material';

export default function LecturesPlanning() {
  
  return (
    <>    
      <Helmet>
        <title> Recorded Lectures| Oak Academy </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
              Oak Lectures Planning
            </Typography>                     
          </div>
        </Stack>
      </Container>

    </>
  );
}
