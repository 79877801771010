import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, 
  Select, InputLabel, FormControl, Alert, Snackbar, Typography, FormHelperText } from '@mui/material'; 
import Iconify from '../iconify';
import createApprovelUser from '../../_data/approvals/createApprovalUsers';
import PhoneInput from '../../utils/phoneInput';
import getSession from '../../utils/getSession';

const CreateApprovalDialog = (props) => {
  
  const [open, setOpen] = useState(false);
  const account = getSession();
  const [formData, setFormData] = useState({
    createdby: account.user[0].iduser,
    email: '' ,    
    fullname: '',
    contact: {
      Address: '',
      CellPhone: ''
    },
  });

  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const showMessage = (message, severityType = 'success') => {
    setAlertMessage(message);
    setSeverity(severityType);
    setMsgOpen(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [contentEmailError, setContentEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');
  const [contentFullnameError, setContentFullnameError] = useState(false); 
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const handleBlur = (e) => {
    const isValid = emailRegex.test(e.target.value);
    if (!isValid) {
      // Geçerli değilse hata mesajını set et
      setEmailHelperText("Please enter a valid email address.");
      setContentEmailError(true);
    } else {
      setEmailHelperText("");
      setContentEmailError(false);
    }
  };
  const handleSubmit = async () => {
    const contactJson = JSON.stringify(formData.contact);
    const dataToSend = {
      ...formData,
      contact: contactJson      
    };
    try {
      setContentEmailError(false);
      setContentFullnameError(false);
      if (!formData.email) {
        setContentEmailError(true);
        return;
      }
      const isEmailValid = emailRegex.test(formData.email);
      if (!isEmailValid) {
        setEmailHelperText("Please enter a valid email address.");
        setContentEmailError(true);
        return; // Eğer e-posta geçersizse, form gönderimini burada durdur.
      }
      if (!formData.fullname) {
        setEmailHelperText("This field is required.");
        setContentFullnameError(true);
        return;
      }
      const result = await createApprovelUser(dataToSend);
      if (result.affectedRows === 1) {
        props.onApprovalSuccess();
        showMessage('The record has been added successfully.', 'success');
        handleClose();
      } else {
        if (result.message == 'Error inserting database'){
          if(result.error.includes("Duplicate entry")){ 
            const alertMessage = `An error occurred while adding a record.\nThe email (${formData.email}) has been saved before.`;
            const splitMessage = alertMessage.split('\n').map((str, index) => <div key={index}>{str}</div>);
            showMessage(splitMessage, 'error');
          }
        }else{ 
          if(result.message == 'Request failed with status code 400'){
            const alertMessage = "email and fullname fields required.";
            const splitMessage = alertMessage.split('\n').map((str, index) => <div key={index}>{str}</div>);
            showMessage(splitMessage, 'error');
          }
        }
      }
    } catch (error) {
      console.error(error);
      showMessage('Error: ',error, 'error');
    }
    handleClose();
  };

  const handleContactChange = (event, field) => {
  //  console.log('Event Log: ', event); 
    //const value = event.target.value;
    const { value } = event.target;
    setFormData({
      ...formData,
      contact: { ...formData.contact, [field]: value }
    });
  };  
  // TODO: New Record esnasında Email, FullName, Address, Phone gibi kayıtlar tekrar istenmekte, bunu yerine 'Student --> List' bölümünde bir submenu item olarak işlem yaptıralım. Bu buton işlem yaparken kaydı yapsın sonra 'Permission Approval' sayfası açılsın.
  // TODO: kayıt yapıldıktan sonra başarılı olduğunu gösteren mesaj ekranda beliriyor ancak, sayfa refresh edilmiyor. Böylece kayıt olduğu anlaşılmıyor. 
  // TODO: İlk kayıt oluştuğunda arka plan rengi yeşil oluyor. Eğer o kullanıcı için(yeni oluşturulan izin kaydı) hiç istek yapılmamış ise arka plan beyaz olabilir kafa karıştırıyor gibi. 
  return (
    <div>
      <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen} >
        New Record
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>New Approval Record</DialogTitle>
        <DialogContent>          
          <TextField
            autoFocus={contentEmailError}
            error={contentEmailError}
            //helperText={contentEmailError ? "This field is required." : ""}
            helperText={contentEmailError ? emailHelperText : ""}
            type="email"
            margin="dense"
            name="email"
            label="Email"
            fullWidth
            variant="outlined"
            value={formData.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            autoFocus={contentFullnameError && !contentEmailError}
            error={contentFullnameError}
            helperText={contentFullnameError ? "This field is required." : ""}
            margin="dense"
            name="fullname"
            label="Full Name"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.fullname}
            onChange={handleChange}
          />
          <div style={{
            border: '1px solid #ced4da',
            borderRadius: '8px',
            padding: '20px',
            margin: '10px 0'
          }}>
            <Typography variant="h6" gutterBottom style={{
              marginBottom: '20px'
            }}>
              Contact Information
            </Typography>
            <TextField
              margin="dense"
              name="address"
              label="Address"
              type="text"
              fullWidth
              variant="outlined"
              value={formData.contact ? formData.contact.Address : ''}
              onChange={(event) => handleContactChange(event, 'Address')}
              style={{
                marginBottom: '10px'
              }}
            />
            <PhoneInput 
              margin="dense"
              name="cellPhone"
              label="Cell Phone"
              type="text"
              fullWidth
              variant="outlined"
              value={formData.contact ? formData.contact.CellPhone : ''}
              onChange={(event) => {handleContactChange(event, 'CellPhone')}} 
              //returnEvent={true} // Bu satırda returnEvent propunu true olarak belirliyoruz.
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} autoFocus
            style={{
              backgroundColor:'blue',
              color: '#FFFFFF',
            }}>Create</Button>
        </DialogActions>
      </Dialog>
      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={10000} 
        onClose={handleCloseMessage}
      >        
        <Alert severity={severity} onClose={handleCloseMessage} 
          sx={{ 
            width: 450, 
            minWidth: 288,
            fontSize: 15, 
            fontStyle: 'normal' 
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default CreateApprovalDialog;
