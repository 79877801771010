import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getLmsCourseLectures = async (id) => {
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/lms/course/lectures`, {
      params: {id},
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const info = response.data.course || [];
    const infoArray = Array.isArray(info) ? info : [info];
    const lmsCoursesInfo = infoArray.map((item, index) => {
      return {
        id: item.id || '',
        name: item.name || '',
        heading: item.heading || '',
        is_published: item.is_published || '',
        description: item.description || '',
        image_url: item.image_url || '',
        lecture_sections: item.lecture_sections || '',
        author_bio: item.author_bio || '',
      };
    });
    return {lmsCoursesInfo};
  } catch (error) {
    console.log('error: ', error )
    return [];
  }
};

export default getLmsCourseLectures;