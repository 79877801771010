import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const setLectures= async (_moduleid, _lectureTopic, _duration, _description, _byInsert) => {
    try {
        const token = await getToken();
        const response = await axios.post(`${BaseUrl}/syllabus/setLectures`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params:{
            moduleid: _moduleid,
            lectureTopic: _lectureTopic,
            duration: _duration,
            description: _description,
            byInsert: _byInsert
          }
        });
        return response.data;
    } catch (error) {
        console.error("Insert error: ", error);
        return [];
    }
};

export default setLectures;