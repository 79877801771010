import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
import formatDateTime from '../../utils/formatDateTime';
const { BaseUrl } = config;

const getApprovalDetailStatus= async (pk) => {
    try {
        const token = await getToken();
        //console.log('pk: ', pk);
        const response = await axios.get(`${BaseUrl}/permissionreq/getapprovaldetailstatus`, {
            params: {
                pk: pk,
            },
            headers: {
                'Authorization': `Bearer ${token}`
            }        
        });
        //console.log('getApprovalDetailStatus return: ', response.data);
        const info = response.data || [];
        // const approvalDetailsStatus = info.map((item, index) => {
        //     return {
        //       approved_items: item.approved_items || '',
        //     };
        // });
        // console.log('getApprovalDetailStatus-return: ', approvalDetailsStatus);
        return info;
    } catch (error) {
        console.error('Error fetching approval user detail status:', error);
        return [];
    }
};

export default getApprovalDetailStatus;