import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getLmsHybridStatus = async () => {
    try {
        const token = await getToken();
        const response = await axios.get(`${BaseUrl}/students/getlmshybridstatuses`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const info = response.data || [];
        const status = info.map((item, index) => {
            return {
              idstudent_status: item.idstudent_status || '',
              status_name: item.status_name || '',
              status_description: item.status_description || '',
            };
        });
        return status;
    } catch (error) {
        return [];
    }
};

export default getLmsHybridStatus;