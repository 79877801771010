import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
// const generateRandomPassword = () => {
//   const upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
//   const lowerChars = 'abcdefghijklmnopqrstuvwxyz';
//   const numbers = '0123456789';
//   const specialChars = '!@#$-%^=&*+';
//   const allChars = upperChars + lowerChars + numbers + specialChars;
//   let password = '';
//   for (let i = 0; i < 8; i++) {
//     password += allChars.charAt(Math.floor(Math.random() * allChars.length));
//   }
//   return password;
// };

const generateRandomPassword = () => {
  const upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerChars = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const specialChars = '!@#$%^&*';
  let password = '';

  // Her karakter türünden en az bir tane ekleyin
  password += upperChars.charAt(Math.floor(Math.random() * upperChars.length));
  password += lowerChars.charAt(Math.floor(Math.random() * lowerChars.length));
  password += numbers.charAt(Math.floor(Math.random() * numbers.length));
  password += specialChars.charAt(Math.floor(Math.random() * specialChars.length));

  // Geri kalan karakterleri rastgele tüm karakterlerden seçin
  const allChars = upperChars + lowerChars + numbers + specialChars;
  for (let i = password.length; i < 8; i++) {
    password += allChars.charAt(Math.floor(Math.random() * allChars.length));
  }

  // Şifreyi karıştırın (opsiyonel)
  password = password.split('').sort(() => 0.5 - Math.random()).join('');

  return password;
};

const PasswordField = ({ onPasswordChange }) => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleGeneratePassword = () => {
    const newPassword = generateRandomPassword();
    setPassword(newPassword);
    onPasswordChange(newPassword); 
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    onPasswordChange(newPassword); // Callback fonksiyonunu çağır
  };

  return (
    <TextField
      label="Password"
      type={showPassword ? 'text' : 'password'}
      value={password}
     // onChange={(e) => setPassword(e.target.value)}
      onChange={handlePasswordChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleGeneratePassword}>
              <RefreshIcon />
            </IconButton>
            <IconButton onClick={togglePasswordVisibility}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;