import axios from 'axios';
import config from '../../config';
const { BaseUrl } = config;

const tryhackmeStudentInfo = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/tryhackme/studentinfo`);
      const info = response.data || [];
      const studentInfo = info.map((item, index) => {
        return {
          type: item.type || '',
          difficulty: item.difficulty || '',
          code: item.code || '',
          title: item.title || '',
          description: item.description || '',
          image: item.image || '',
          banner: item.banner || '',
          index: index,
          tags: item.tags.join(', ')
        };
      });
      return studentInfo;
    } catch (error) {
      return [];
    }
};

export default tryhackmeStudentInfo;
