import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getAllReferences = async () => {
    try {     
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/students/getallreferences`, {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      });
      const info = response.data.result || []; 
      if (!Array.isArray(info)) {
        throw new Error('API response is not an array');
      }
      const references = info.map((item, index) => {
        return {
          idstudent_references: item.idstudent_references || '',
          referencename: item.referencename || '',
          adsname: item.adsname || '',
          descriptions: item.descriptions || '',
        };
      });       
      return references;
    } catch (error) {
      console.error('Error fetching references:', error);
      return [];
    }
};

export default getAllReferences;