import React, { createContext, useContext, useState } from 'react';

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

const alertTypes = {
  info: { message: 'This is an info message.', type: 'info' },
  warning: { message: 'This is a warning message.', type: 'warning' },
  error: { message: 'This is an error message.', type: 'error' },
  networkError: { message: 'Network connection failed.', type: 'error' },
  unexpectedError: { message: 'An unexpected error occurred.', type: 'error' }
};

export const AlertProvider = ({ children }) => {
  const [message, setMessage] = useState(null);

  // const [type, setType] = useState('info');
  // const setAlert = (msg) => {    
  //   setMessage(msg);
  //   setTimeout(() => setMessage(null), 5000);
  // };

   const [alert, setAlert] = useState({ message: null, type: 'info' });
  // const showAlert = (msg, alertType = 'info') => {    
  //   setAlert({ message: msg, type: alertType });
  //   setTimeout(() => {
  //     setAlert({ message: null, type: 'info' });
  //   }, 5000);
  // };
  
  const showAlert = (alertType) => {
    if (alertType === null) {
      setAlert({ message: null, type: 'info' });
    } else if (alertTypes[alertType]) {
      setAlert(alertTypes[alertType]);
      setTimeout(() => {
        setAlert({ message: null, type: 'info' });
      }, 5000);
    } else {
      console.error(`Alert type "${alertType}" is not defined.`);
    }
  };

  return (
    <AlertContext.Provider value={{ alert, showAlert }}>
      {children}
    </AlertContext.Provider>
  );
};