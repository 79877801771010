import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const createStudentPaymentContract= async (paymentContractData) => {
  try {
    console.log('createStudentPaymentContract-paymentContractData: ', paymentContractData);
    const token = await getToken();
    const response = await axios.post(`${BaseUrl}/coursecoordination/insertstudentpaymentcontract`, 
      {  paymentContractData },
      {  headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
          },     
      }
    );
 // console.log('createStudentPaymentContract: ', response.data);
    return response.data;
  } catch (error) {  
      console.error("Report error: ", error);
      return [];
  }
};

export default createStudentPaymentContract;