import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import meetingpaticipants from './MeetingPaticipantsPage';
import { Link, InputAdornment, TextField, Snackbar, Alert, Switch } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Divider from '@mui/material/Divider';
import dayjs from 'dayjs';
import { fDate, fDateX } from '../../utils/formatTime';
import LoadingButton from '@mui/lab/LoadingButton';
import FilePicker from '../../utils/filePicker';
import DirectoryPicker from '../../utils/directoryPicker';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  SvgIcon,
  useTheme
} from '@mui/material';
import { blueGrey, lightBlue, green, amber, deepPurple  } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
//import BasicDateTimePicker from '../../components/datetime';
// sections
import { ZoomListHead, ZoomListToolbar } from '../../sections/@dashboard/zoom';
// mock
import ZOOMLIST from '../../_data/zoom/getCloudRecordList';
import downloadRecord from '../../_data/zoom/downloadRecord';
import ReportParticipants from '../../_data/zoom/report_participants';
//import getAMeeting from '../../_data/zoom/getAMeeting';
import createFolder from './createFolder';
// import GETSTATUS from '../../_data/zoom/getStatusToUuids';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';

// -------------------------COLUMN TITLES--------------------------------

const TABLE_HEAD = [
   { id: 'uuid', label: 'Meeting UUID', alignRight: false },
   { id: 'id', label: 'Meeting ID', alignRight: false },
  { id: 'topic', label: 'Topic', alignRight: false },
  { id: 'start_time', label: 'Start Time', alignRight: false },
  { id: 'recording_count', label: 'Record Count', alignRight: false },
  { id: 'duration', label: 'Duration', alignRight: false },
  { id: 'total_size', label: 'Total Size (MB)', alignCenter: true },
  { id: 'status', label: 'Backup Status Local/GDrive', alignRight: false },
  { id: '' },
];
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (!Array.isArray(array)) {
    return [];
  }


  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_zoom) => _zoom.meetid.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ZoomPage() {

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('meetid');

  const [filterMeetId, setfilterMeetId] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [downResultMsg, setDownResultMsg] = useState('');

  const vertical = 'top', horizontal = 'right'; // Message Position
  const [msgOpen, setMsgOpen] = useState(false); //Notification Show/hide

  const [severity, setSeverity] = useState('success'); //Notification Show/hide
  const [alertMessage, setAlertMessage] = useState('');

  let formattedDate = new Date();

  let year = formattedDate.getFullYear();
  let month = String(formattedDate.getMonth() + 1).padStart(2, "0");
  let day = String(formattedDate.getDate()).padStart(2, "0");
  let today = `${year}-${month}-${day}`;

  let formattedOneMonthAgo = new Date();
  formattedOneMonthAgo.setMonth(formattedOneMonthAgo.getMonth() - 1);

  // Tarih değerlerini alın
  year = formattedOneMonthAgo.getFullYear();
  month = String(formattedOneMonthAgo.getMonth() + 1).padStart(2, "0");
  day = String(formattedOneMonthAgo.getDate()).padStart(2, "0");

  // 'year-month-day' formatında tarihi oluşturun
  let oneMonthAgo = `${year}-${month}-${day}`;


 // let oneMonthAgo = today.setMonth(today.getMonth() - 1);
  //console.log("today: ", today);
  //console.log("oneMonthAgo: ", oneMonthAgo);

  const [radio_value, setRadioValue] = useState('');

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };



  //console.log("oneMonthAgo: ", oneMonthAgo);

  const [from, setfrom] = useState(oneMonthAgo);
  // const [value, setValue] = React.useState(dayjs('2022-04-17'));
  //console.log("today: ", dayjs(today));
  //console.log("first from: ", from);
  const [to, setto] = useState(today);

  //console.log("first to: ", to);
  const params = {
    //userId : 'info@hackeracademy.uk', //TODO: Bu değer otomatik ve databaseden gelmeli
    //userid = 'me'
    userId : radio_value,
    from : from,
    to : to,
    page_size : 299
  };

  //TODO: from < to olma durumunu kontrol etmeliyiz.
  //userId = 'aydin@hackeracademy.uk'
  // const [zoomDatas, setZoomDatas] = useState([ZOOMLIST]);
  //console.log("first params: ", params);

  const [zoomDatas, setZoomDatas] = useState([ZOOMLIST(params)]);

  const getZoomDatas = async () => {
    try {
      const data = await ZOOMLIST(params);
      //console.log("ZOOMLIST: ", data.length);
      setZoomDatas(data || []); // Eğer data boşsa veya hatalıysa boş bir array atayın
    } catch (error) {
      console.error(error);
      setZoomDatas([]);
    }
  };
  const [newRecords, setNewRecords] = useState(false);
  useEffect(() => {
   //   getZoomDatas();
    }, [newRecords]);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setMsgOpen(false); // Bildirimi kapat
  };
  const [visible, setVisible] = useState(true);
  const handleGetZoomRecords = (event) => {
    setVisible(false);
    params.from = fDateX(from);
    params.to = fDateX(to);
    getZoomDatas();
 //   if(newRecords){setNewRecords(false);}else{setNewRecords(true);}
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = zoomDatas.metting.map((n) => n.uuid
      );
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, uuid, id, topic) => {
    // selected dizisinde varsa çıkar, yoksa ekle
    const selectedIndex = selected.findIndex(e => e.uuid === uuid && e.id === id && e.topic === topic);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, {uuid, id, topic});
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setfilterMeetId(event.target.value);
  };
  const avatarUrl = `/assets/images/avatars/zoom-9.png`;
  
  const handleDownRecord = async (event) => {
    try {
      const label = event.currentTarget.textContent;
      //console.log("selected.length: ", selected.length);
      if(selected.length > 0){
       //console.log("handleDownRecord-->label: ", label);
        switch(label){
          case 'Download in GDrive':
            //console.log("This is (Download in GDrive)");
            // const folderPath = "try11257";
            // const result = createFolder(folderPath);
            //console.log("createFolder: ", result);
          break;
          case 'Download in Local':
            // `selected` dizisindeki tüm öğeleri işle
            const processItem = async (index) => {
              if (index < selected.length) {
                const item = selected[index];
                const alertMessage = (
                  <>
                    <div>Download is started!</div>
                    <div>uuid: {item.uuid}</div>
                    <div>meetid: {item.id}</div>
                  </>
                );
                const res = await downloadRecord(item.uuid, item.id);
                const backdata = JSON.parse(res.data);
                setDownResultMsg(backdata.event); // **** Bu Backend den gelen mesaj*******
                if(backdata.event === 'app.download.started'){
                  setVisible(true);
                  setSeverity('success');
                  setAlertMessage(alertMessage);
                  setMsgOpen(true); // Show Notifican
                }
                else {setMsgOpen(false);}

                // Bilgi mesajını ayarla ve bildirimi göster
                // setSeverity('success');
                // setAlertMessage(alertMessage);
                // setMsgOpen(true);
                // // 2 saniye bekleyelim
                // await delay(2000);
                // // Bildirimi kapat
                // setMsgOpen(false);
                // Sonraki öğe için işleme devam et
                await processItem(index + 1);
              }
            };
            // İlk öğeden başlayarak işlemi başlat
            await processItem(0);
          break;
          case 'Participants':
            const item = selected[0];  
            console.log("selected item: ", item);
            const reportParticipants = await ReportParticipants.getReportParticipants(item.id, item.uuid, item.topic);
            console.log("reportParticipants: ", reportParticipants);

            const MeetItem = async (index) => {
              if (index < selected.length) {
                //const item = selected[index];
                navigate(`/dashboard/meetingparticipants`, { state: { reportParticipants } });


                await MeetItem(index + 1);
              }
            };
            // İlk öğeden başlayarak işlemi başlat
            await MeetItem(0); 
            


            //console.log("index: ",index);
            // const MeetItem = async (index) => {
            //   if (index < selected.length) {
            //     const item = selected[index];
            //     console.log("selected.meetid: ", item.id);  
            //     //navigate('/meetingparticipants', { state: { meetingID: item.id } });
            //     navigate(`/dashboard/zoomregistrants/${item.id}`);

            //     await MeetItem(index + 1);
            //   }
            // };
            // // İlk öğeden başlayarak işlemi başlat
            // await MeetItem(0);              
          break;
          default:
            break;
        };
      }else{
        setSeverity('error');
        setAlertMessage('Not selected Meeting Record!');
        setMsgOpen(true); 
      }
    } catch (error) {
        console.log("handleDownRecord-Error: ", error);
    }
  };

  const MENU_OPTIONS = [
    {
      label1: 'Download>Gdrive',
      //icon: 'eva:home-fill',
    },
    {
      label2: 'Download>Local',
      //icon: 'eva:person-fill',
    },
    {
      label3: 'Participants',
      //icon: 'eva:settings-2-fill',
    },
  ];

  // function ColoredCircle({ value }) {
  //   return (
  //     <SvgIcon>
  //       <circle cx="12" cy="12" r="8" fill={value ? 'success' : 'amber'} />
  //     </SvgIcon>
  //   );
  // }
  function ColoredCircle({ value }) {
    const theme = useTheme();
    const color = value ? theme.palette.success.main : theme.palette.error.main;
    
    return (
      <SvgIcon>
        <circle cx="12" cy="12" r="8" fill={color} />
      </SvgIcon>
    );
  }

  
  const [folderPath, setFolderPath] = useState('');

  const handleDirectoryRead = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      // Sadece ilk dosyanın yolunu al ve klasör yolunu bul
      const commonPath = getCommonPath(files[0].webkitRelativePath);
      setFolderPath(commonPath);
    }
  };
  const getCommonPath = (relativePath) => {
    // 'file.webkitRelativePath' özelliği 'klasör/dosya' formatında bir yoldur.
    // Burada, dosya adını yoldan kaldırarak sadece klasör yolunu alıyoruz.
    //console.log("getCommonPath: ", relativePath);
    const pathSegments = relativePath.split('/'); // Yolu '/' karakterine göre ayırıyoruz.
    pathSegments.pop(); // Dizinin son elemanını (dosya adını) kaldırıyoruz.
    return pathSegments.join('/'); // Geri kalan kısmı birleştirerek klasör yolunu elde ediyoruz.
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ZOOMLIST.length) : 0;
  const filteredZooms = applySortFilter(zoomDatas, getComparator(order, orderBy), filterMeetId);
  const isNotFound = !filteredZooms.length && !!filterMeetId;

  return (
    <>
      <Helmet>
        <title> Zoom Downloader | Oak Academy </title>
      </Helmet>
      <Container maxWidth={false} sx={{ marginLeft: 0, maxWidth: 'none' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Oak Zoom Downloader
          </Typography>
          <Divider />
          <FormControl component="fieldset">
            <FormLabel component="legend">Accounts</FormLabel>
            <RadioGroup
              aria-label="accounts"
              name="customized-radios"
              value={radio_value}
              onChange={handleRadioChange}
              column
            >
              <FormControlLabel value="finance@oakacademy.de" control={<Radio />} label="finance@oakacademy.de" />
              <FormControlLabel value="oakzoom@oakacademy.de" control={<Radio />} label="oakzoom@oakacademy.de" />
              <FormControlLabel value="course@oakacademy.de" control={<Radio />} label="course@oakacademy.de" />
            </RadioGroup>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                label="From-Date"
                defaultValue={dayjs(oneMonthAgo)}
                onChange={(newValue) => setfrom(fDate(newValue))}/>
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker
                label="To-Date"
                defaultValue={dayjs(today)}
                onChange={(newValue) => setto(fDate(newValue))}/>
            </DemoContainer>
          </LocalizationProvider>
          <Button variant="contained" color="secondary" onClick={handleGetZoomRecords}>
            Get Zoom Records
          </Button>
        </Stack>     
        <Card sx={{ backgroundColor: deepPurple[100], p: 2 }}>
          <Typography
                variant="subtitle1"
                component="div"
                sx={{
                  fontWeight: 'bold',
                  color: 'primary.main',
                  display: 'flex',
                  justifyContent: 'space-between', // Metin ve yükleme simgesi arasında boşluk bırakır
                  alignItems: 'center', // İçerikleri dikey olarak ortalar
                  width: '100%' // Tam genişlik kullanımını sağlar
                }}
              >
                {`Total Records: ${zoomDatas.length}`}
                {visible && (
                  <div>
                    <CircularProgress
                      size={24} // Boyutu küçültmek için size prop'unu kullanabilirsiniz
                      thickness={5}
                      style={{ color: 'green' }}
                    />
                  </div>
                )}
          </Typography>
          </Card>
        <Card>
          <ZoomListToolbar numSelected={selected.length} filterMeetId={filterMeetId} onfilterMeetId={handleFilterByName}/>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ZoomListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={zoomDatas.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}                  
                />
                <TableBody>
                  {filteredZooms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { uuid, id, topic, duration, recording_count, start_time, total_size, local_download_status, google_upload_status } = row;
                    // const selectedZoom = selected.indexOf({uuid, id}) !== -1;
                    const selectedZoom = selected.some(item => item.uuid === uuid && item.id === id);
                    return (
                      <TableRow hover key={uuid} tabIndex={-1} role="checkbox" selected={selectedZoom}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedZoom} onChange={(event) => handleClick(event, uuid, id, topic)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={uuid}  src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {uuid}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{id}</TableCell>
                        <TableCell align="left">{topic}</TableCell>
                        <TableCell align="left">{start_time}</TableCell>
                        <TableCell align="center">{recording_count}</TableCell>
                        <TableCell align="center">{duration}</TableCell>                        
                        <TableCell align="center">{total_size === 0 ? 'Processing Recording...' : (total_size/1048576).toFixed(2)}</TableCell>    {/* 1024 * 1024 = 1048576 bu değer byte türünden size MB türüne çevirir.*/}
                        <TableCell style={{ verticalAlign: 'middle', align: "center"  }}>                          
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ColoredCircle value={local_download_status} />
                            <ColoredCircle value={google_upload_status} />
                          </div>
                        </TableCell>                       
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterMeetId}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 200, 300]}
            component="div"
            count={ZOOMLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 190,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem key={MENU_OPTIONS.label1} onClick={handleDownRecord} sx={{ color: 'success.dark' }}>
          <Iconify icon={'mdi:google-drive'} sx={{ mr: 2 }} />
          {/* <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} /> */}
          Download in GDrive
        </MenuItem>

        <MenuItem key={MENU_OPTIONS.label2} onClick={handleDownRecord} sx={{ color: 'secondary.main' }}>
          <Iconify icon={'mdi:harddisk'} sx={{ mr: 2 }}/>
          {/* <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }}/> bu çöp kutu iconu*/}
          Download in Local
        </MenuItem>

        <MenuItem key={MENU_OPTIONS.label3} onClick={handleDownRecord} sx={{ color: 'text.primary' }}>
          {/* <Iconify icon={'heroicons-outline:users'} sx={{ mr: 2 }}/> */}
          <Iconify icon={'mdi:account-group'} sx={{ mr: 2 }}/>
          {/* <Iconify icon={'eva:more-vertical-fill'} sx={{ mr: 2 }}/> üç nokta üst üste bir icondur */}
          {/* more-vertical-fill        eva:plus-fill*/}
          Participants
        </MenuItem>
      </Popover>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={msgOpen}  autoHideDuration={8000} onClose={handleClose}>
          <Alert severity={severity} onClose={handleClose} sx={{ width: 450, height: 100, fontSize: 14, fontWeight: 'normal', fontStyle: 'normal'}} > {alertMessage} </Alert>
          {/* <Alert severity="success">New record successful.</Alert> */}
      </Snackbar>
    </>
  );
}

