import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getContactChannels= async () => {
  try {
    const token = await getToken(); 
    const response = await axios.get(`${BaseUrl}/students/getcontactchannels`,{
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const info = response.data || [];
    const ContactChannalList = info.map((item, index) => {      
      return { 
        contactenvironment: item.contactenvironment || '',
        idcontactchannel: item.idstudent_contactchannels || '',
        description: item.description || '',
      };
    });
    return ContactChannalList;
  } catch (error) {
        console.log("getContactChannals Error: ", error);
  }
};

export { getContactChannels };
