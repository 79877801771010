import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getLmsCourseTrainees = async (id) => {
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/lms/course/enrolments`, {
      params: { id },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });    
    const info = response.data.enrollments || [];
    const infoArray = Array.isArray(info) ? info : [info];
    const lmsCoursesInfo = infoArray.map((item, index) => {
      return {
        id: item.user_id || '',
        enrolled_at: item.enrolled_at || '',
        percent_complete: item.percent_complete || '',
        expires_at: item.expires_at || '',
        completed_at: item.completed_at || '',
      };
    });
    return {lmsCoursesInfo};
  } catch (error) {
    console.log('error: ', error )
    return [];
  }
};

export default getLmsCourseTrainees;