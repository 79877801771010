import React, { useState } from 'react';
//import useInactivityTimer from './useInactivityTimer';
import useTokenRefresh from './useTokenRefresh';
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import Router from './routes';
import SessionExpiredDialog from './components/session/sessionExpried';
import { useNavigate } from 'react-router-dom';
//import SessionCountdown from './components/session/SessionCountdown';
import ConfirmDialog from './components/confirmdialog/ConfirmDialog';

const AppContent = () => {
  const [sessionExpiryTime, setSessionExpiryTime] = useState(Date.now() + 15 * 60 * 1000); // Örneğin, 15 dakika sonrası
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogout = () => {
    console.log("User is inactive. Logging out...");
    setIsModalOpen(true);
  };

  const refreshToken = () => {
    console.log("Refreshing token...");
    //TODO: burayi tamamlamaliyim  Token yenileme işlemi
  };

  //useInactivityTimer(handleLogout);
  useTokenRefresh(refreshToken);

  const handleClose = () => {
    setIsModalOpen(false);
    // Logout işlemini burada gerçekleştirebilirsiniz, örneğin:
    navigate('/login');
  };

  // const handleSessionExpired = () => {
  //   setIsModalOpen(true);
  // };

  return (
    <>
      {/* {sessionExpiryTime && (
        <SessionCountdown sessionExpiryTime={sessionExpiryTime} onSessionExpired={handleSessionExpired} />
      )} */}
      <ConfirmDialog />
      <ScrollToTop />
      <StyledChart />
      <Router />
      <SessionExpiredDialog open={isModalOpen} handleClose={handleClose} />
    </>




  );
};

export default AppContent;





// import React, { useState } from 'react';
// import useInactivityTimer from './useInactivityTimer';
// import useTokenRefresh from './useTokenRefresh';
// import ScrollToTop from './components/scroll-to-top';
// import { StyledChart } from './components/chart';
// import Router from './routes';
// import SessionExpiredDialog from './components/session/sessionExpried';

// const AppContent = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const handleLogout = () => {
//     console.log("User is inactive. Logging out...");
//     setIsModalOpen(true);
//   };

//   const refreshToken = () => {
//     console.log("Refreshing token...");
//     // Token yenileme işlemi
//   };

//   useInactivityTimer(handleLogout);
//   useTokenRefresh(refreshToken);

//   const handleClose = () => {
//     setIsModalOpen(false);
//     // Logout işlemini burada gerçekleştirebilirsiniz, örneğin:
//     // router.push('/logout');
//   };

//   return (
//     <>
//       <ScrollToTop />
//       <StyledChart />
//       <Router />
//       <SessionExpiredDialog open={isModalOpen} onClose={handleClose} />
//     </>
//   );
// };

// export default AppContent;
