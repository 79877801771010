import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const createApprovalUser= async (formData) => {
    try {
        const token = await getToken();
        const response = await axios.post(`${BaseUrl}/permissionreq/createuser`, {
                createdby: formData.createdby,
                email: formData.email,
                fullname: formData.fullname,
                contact: formData.contact,        
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
    );  
        // console.log("createapprovaluser-response: ", response);
        return response.data;
    } catch (error) {  
        return error;
    }
};

export default createApprovalUser;
