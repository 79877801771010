import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const setmodules= async (moduleName, duration, description, byuser) => {
    try {
        const token = await getToken();
        const response = await axios.post(`${BaseUrl}/syllabus/setmodules`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          params:{
            moduleName: moduleName,
            duration: duration,
            description: description,
            byInsert: byuser
          }
        });
        return response.data;
    } catch (error) {
        console.error("Insert error: ", error);
        return [];
    }
};

export default setmodules;