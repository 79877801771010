import React, { useState } from "react";
import { useLoading } from '../../utils/contexts/LoadingContext';
import { Box, Button, Typography, IconButton, Grid, Tooltip } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CancelIcon from '@mui/icons-material/Cancel';
import setOfferFile from '../../_data/coursecoordination/setOfferFile';
import setVoucherFile from '../../_data/coursecoordination/setVoucherFile';

const FileUploadPanel = ({ studentid, onUploadComplete, type, onCancel }) => {
  const { showLoading, hideLoading } = useLoading();
  console.log("type: ", type);
  const [selectedFile, setSelectedFile] = useState(null);
  // const [openAlertMsg, setOpenAlertMsg] = useState(false); //Notification Show/hide
  // const [severity, setSeverity] = useState('success'); //Notification Show/hide  
  // const [alertMessage, setAlertMessage] = useState('');
  // const [msgOpen, setMsgOpen] = useState(false);
  const [title, setTitle] = useState(type);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // const handleCloseStudentMessage = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setMsgOpen(false);
  // };

  const handleFileUpload = async () => {
    let uploadResult;
    try {
      showLoading();
      console.log('type: ', type);
      if (selectedFile) {
        // Dosya yükleme işlemini burada yapabilirsiniz.
        console.log("Uploading file:", selectedFile, title);
        if(type === "Upload Your Voucher File!"){
          console.log('uploadResult VoucherSubmithandleFileUpload-Error:: ');
            uploadResult = await setVoucherFile(studentid, selectedFile);
            console.log("uploadResult: ", uploadResult);
        }else if(type === "Upload Your Offer File!"){
          console.log('uploadResult Offer Submit: ');
          setTitle("Upload Your Offer File ");
            uploadResult = await setOfferFile(studentid, selectedFile);
        }
        console.log('uploadResult.message: ', uploadResult.message);
        if(uploadResult.message === "Upload process is successfull."){
          onUploadComplete('success', uploadResult.message);
        }else{
          onUploadComplete('error', 'File upload not complated!');
        }
        }        
    } catch (error) {
        console.log('handleFileUpload-Error:', error);
    }finally{
      hideLoading();
    }
  };

  return ( 
    <>
        <Box 
        sx={{ 
            border: "2px dashed #ccc", 
            padding: 3, 
            borderRadius: 2, 
            backgroundColor: "#fafafa",
            textAlign: "center"
        }}
        >
        <Typography variant="h6" gutterBottom>
          { title }
        </Typography>
        
        <Grid container spacing={2} justifyContent="center">
            <Grid item>
            <input
                accept="image/*,.pdf,.docx"
                style={{ display: 'none' }}
                id="file-upload"
                type="file"
                onChange={handleFileSelect}
            />
            <label htmlFor="file-upload">
              <Tooltip title="Upload your file">
                <IconButton color="primary" aria-label="upload file" component="span">
                  <UploadFileIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </label>            
            </Grid>
            <Grid item>
            <Button 
                variant="contained" 
                color="primary" 
                onClick={handleFileUpload}
                disabled={!selectedFile}
            >
                Upload File
            </Button>
            </Grid>
            <Grid item>
              <Tooltip title="Close The Upload Panel!">
                <IconButton
                  color="error"
                  aria-label="cancel upload"
                  onClick={onCancel} // İptal fonksiyonunu burada tetikleyin
                  component="span"
                >
                  <CancelIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </Grid>            
        </Grid>
        
        {selectedFile && (
            <Box mt={2}>
            <Typography variant="body2">
                Selected File: {selectedFile.name}
            </Typography>
            </Box>
        )}
        </Box>
        {/* <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={5000} 
        onClose={handleCloseStudentMessage}
        sx={{ zIndex: 1500 }}
      >
        <Alert severity={severity} onClose={handleCloseStudentMessage} 
        sx={{ 
          width: 480, minWidth: 350, minheight: 100, height: 130, fontSize: 14, 
          fontStyle: 'normal',
          whiteSpace: 'pre-line' //  Burada \n yerine satir atlamak icin whiteSpace stilini uyguluyoruz.
        }} >
          <Typography style={{ whiteSpace: 'pre-line' }}>
            {alertMessage}
          </Typography>
        </Alert>
      </Snackbar> */}
    
    </>
  );
};

export default FileUploadPanel;