import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState, useRef } from 'react';
// @mui
import { Container, Stack, Typography, Card, List, ListItem, Box, Grid, Paper, 
ListItemText, Button, TextField, Alert, Snackbar, Dialog, DialogTitle, 
DialogContent, DialogActions, Tabs, Tab, tabsClasses } from '@mui/material';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
// components
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../../sections/@dashboard/products';
import LanguageLevelDefinitions from '../../components/settings/coursecoordination/LangLevelDefForm';
import getSession from '../../utils/getSession';
import { useTranslation } from 'react-i18next'; //Multi Language
import { maxBy } from 'lodash';
// ----------------------------------------------------------------------

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} sx={{ display: 'flex', justifyContent: 'left', mt: 3  }}>         
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function CourseCoordinationSettings() {
  const [modulesInfo, setModulesInfo] = useState([]);
   const [selectedModuleId, setSelectedModuleId] = useState(0);    
  const [error, setError] = useState(false);
  const [expanded1, setExpanded1] = useState(true);
  const [expanded2, setExpanded2] = useState(true);
  const [expanded3, setExpanded3] = useState(true);

   const expandIconStyle1 = expanded1 ? { transform: 'rotate(180deg)' } : {};
   const expandIconStyle2 = expanded2 ? { transform: 'rotate(180deg)' } : {};
   const expandIconStyle3 = expanded3? { transform: 'rotate(180deg)' } : {};

  const handleExpand1Click = () => {
    setExpanded1(!expanded1);
  };
  const handleExpand2Click = () => {
    setExpanded2(!expanded2);
  };
  const handleExpand3Click = () => {
    setExpanded3(!expanded3);
  };

   const handleModulesClick = (ModuleId, ModuleName, Duration, Decription, LessonTimeOverflow) => {
     setSelectedModuleId(ModuleId);  
   };

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
   // console.log('newValue:', newValue);
    setValue(newValue);
  };
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title> Course Coordination Settings | Oak Academy </title>
      </Helmet>
      <Typography variant="h5" sx ={{mb: "15px"}}>
        {t('CCS')}
      </Typography>
      <Tabs value={value} 
        onChange={handleChange} 
        //centered 
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
      <Tab label= {t('LanguageLevelDefinitions')} {...a11yProps(0)} />
      <Tab label= {t('StudentStatusDefinitions')}  />
      <Tab label= {t('GraduationsDefinitions')} />
      <Tab label= {t('ContactStateDefinitions')} />
      <Tab label= {t('ContactChannelDefinitions')} />
    </Tabs>

    <TabPanel value={value} index={0}>
      <LanguageLevelDefinitions />
    </TabPanel>  

    <TabPanel value={value} index={1}>
      <Typography sx ={{fontSize:"11px", fontStyle:"italic"}}>
        {t('StudentStatusDefinitions_Description')}        
      </Typography>
      <br/>
      <TextField label="Input 1" variant="outlined" fullWidth />
    </TabPanel>      
    </>
  );
};
