import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getInstallmentDetails= async (id) => {
  try {    
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/coursecoordination/getpaymentdefinitiondetails`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },     
      params: {
        courseregistrationfeesid: id
      }
    });
    return response.data;
  } catch (error) {  
      console.error("Report error: ", error);
      return [];
  }
};

export default getInstallmentDetails;