import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const zoomUsers = async () => {
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/zoom/users`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });
    //  console.log("response.data.users :", response.data.users);
    const users = response.data.users || []; // users dizisi boş ise varsayılan olarak boş dizi ataması yapılır.
    //  console.log("users :", users);    
    const zoomUsersDatas = users.map((item) => ({      
      id: item.id || '',
      name_surname: item.first_name + ' ' + item.last_name || '',
      display_name: item.display_name || '',
      email: item.email || '',
      type: item.type || '',      
      pmi: item.pmi || '',      
      timezone: item.timezone || '',      
      verified: item.verified || '',      
      dept: item.dept || '',      
      created_at: item.created_at || '',      
      last_login_time: item.last_login_time || '',      
      language: item.language || '',      
      status: item.status || '',      
      role_id: item.role_id || '',      
      user_created_at: item.user_created_at || '',      
    }));
    //  console.log("zoomUsersDatas :", zoomUsersDatas);
    return zoomUsersDatas;
  } catch (error) {
    console.error(error);    
    return [];
  }
};

export default zoomUsers;
/*
{
  "page_count": 1,
  "page_number": 1,
  "page_size": 30,
  "total_records": 5,
  "next_page_token": "",
  "users": [
      {
          "id": "1Up4Fo3ASBKbtrj57IV6oQ",
          "first_name": "Bâkır",
          "last_name": "Emre",
          "display_name": "Bâkır Emre",
          "email": "emre@oakacademy.de",
          "type": 1,
          "pmi": 8277709390,
          "timezone": "Europe/London",
          "verified": 1,
          "dept": "Test",
          "created_at": "2022-12-30T15:07:29Z",
          "last_login_time": "2022-12-30T15:07:30Z",
          "language": "en-US",
          "status": "active",
          "role_id": "2",
          "user_created_at": "2022-12-30T15:06:54Z"
      },     
  ]
}
*/
