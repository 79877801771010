
const getToken = async () =>{
  try {
    const sessionString = localStorage.getItem('session');
    const session = JSON.parse(sessionString);
    const token = session.apitoken;    
    return token;
  } catch (error) {  
      console.error("getToken Error: ", error);
      return [];
  }
};

export default getToken;