import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const updateApprovalUser= async (formData) => {
    try {
      const token = await getToken();
      //console.log("updateapprovaluser-response: ", formData);
      const response = await axios.patch(`${BaseUrl}/permissionreq/updateuser`, {
          createdby: formData.createdby,
          pk: formData.pk,
          email: formData.email,
          fullname: formData.fullname,
          contact: formData.contact,
          notyourmail: formData.notyourmail
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
     );
      return response.data;
    } catch (error) {  
        return error;
    }
};

export default updateApprovalUser;
