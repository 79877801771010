import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getPages = async (moduleId) =>{
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/roles/getpages`, {         
      params: {
        idmodule: moduleId,
      },      
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {  
      console.error("getPages Error: ", error);
      return [];
  }
};

export default getPages;