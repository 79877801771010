import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const sendReqConfirm = async (createdby, email, contentLink, contentDescription, selectedLanguage, fullname) => {
    try {
        const token = await getToken();
        const response = await axios.post(`${BaseUrl}/sendmail/approvellMail`, {
            createdby: createdby,
            email: email,
            link: contentLink,
            description: contentDescription,
            mailType: selectedLanguage,
            name_surname: fullname
            },
            {
                headers: {
                'Authorization': `Bearer ${token}`}
            }
        );          
        //console.log('response.data: ', response.data);
        return response.data;
    } catch (error) {  
        return error;
    }
};

export default sendReqConfirm;