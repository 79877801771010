import React from 'react';
import PDFViewer from './PDFViewer';
import ImageViewer from './imageViewer';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function FileViewerPanel({ fileData, fileType, onClose }) {
  return (
    <Box
      position="fixed"
      top="5%"
      left="10%"
      transform="translate(-50%, -50%)"
      width="95%"
      height="100%"
      bgcolor="rgba(0,0,0,0.5)"
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex={1300}
    >
      <Paper elevation={3} style={{ position: 'relative', width: '80%', height: '80%' }}>
        {fileType === 'application/pdf' ? (
          <PDFViewer fileData={fileData} onClose={onClose} />
        ) : (
          <ImageViewer fileData={fileData} onClose={onClose} />
        )}
      </Paper>
    </Box>
  );
}
export default FileViewerPanel;

