import React, { useState } from 'react';

const PasswordGenerator = () => {
  const length = 8;
  const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  const specialCharacters = '!@#$%^&*()_+}{[]:;?><,.-=';

  const allCharacters = lowerCaseLetters + upperCaseLetters + numbers + specialCharacters;

  let password = '';
  let usedCharacters = new Set();

  // Şifrenin her zorunlu karakterini en az bir kez ekleyin
  const addUniqueCharacter = (characterSet) => {
    let char;
    do {
      char = characterSet[Math.floor(Math.random() * characterSet.length)];
    } while (usedCharacters.has(char));
    password += char;
    usedCharacters.add(char);
  };

  addUniqueCharacter(lowerCaseLetters);
  addUniqueCharacter(upperCaseLetters);
  addUniqueCharacter(numbers);
  addUniqueCharacter(specialCharacters);

  // Kalan karakterleri rastgele ekleyin
  for (let i = password.length; i < length; i++) {
    addUniqueCharacter(allCharacters);
  }

  // Şifreyi karıştırın
  return password.split('').sort(() => Math.random() - 0.5).join('');
};

export default PasswordGenerator;






// import React, { useState } from 'react';


// const PasswordGenerator = () => {
//   const length = 8;
//   const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
//   const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
//   const numbers = '0123456789';
//   const specialCharacters = '!@#$%^&*()_+}{[]:;?><,.-=';
  
//   const allCharacters = lowerCaseLetters + upperCaseLetters + numbers + specialCharacters;

//   let password = '';
  
//   // Şifrenin her zorunlu karakterini en az bir kez ekleyin
//   password += lowerCaseLetters[Math.floor(Math.random() * lowerCaseLetters.length)];
//   password += upperCaseLetters[Math.floor(Math.random() * upperCaseLetters.length)];
//   password += numbers[Math.floor(Math.random() * numbers.length)];
//   password += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

//   // Kalan karakterleri rastgele ekleyin
//   for (let i = password.length; i < length; i++) {
//     password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
//   }

//   // Şifreyi karıştırın
//   return password.split('').sort(() => Math.random() - 0.5).join('');
// };

// export default PasswordGenerator;
