import axios from 'axios';
import config from '../../config';
const { BaseUrl } = config;

const getRecordedLectures = async () => {
    try {
        const response = await axios.get(`${BaseUrl}/lectures/recordedlectures`);
        const info = response.data || [];
        const lecturesInfo = info.map((item, index) => {
            return {
                idzoom_records: item.idzoom_records || '',
                meeting_uuid: item.meeting_uuid || '',
                meeting_id: item.meeting_id || '',
                start_time: item.start_time || '',
                topic: item.topic || '',
                topic_changes: item.topic_changes || '',
                gprocess_result: item.gprocess_result || '',
                index: index
            };
        });
      //  console.log("lecturesInfo: ", lecturesInfo);
        return lecturesInfo;
    } catch (error) {
        return [];
    }
};

export { getRecordedLectures };
// For ZOOM Records