import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const setStudentFinancialDatas = async (studentId, paymentDetails, paymentdate, paymentOverdueDate, paymentDescription, 
  contractId, methodname, transactionId, accountNumber, providerEmail, paymentDetailDescription, receiving_account_number) => {
  try {
      const token = await getToken();
      console.log('studentId: ', studentId, 'paymentDetails: ', paymentDetails, 'paymentdate: ', paymentdate, 'paymentOverdueDate: ',
        paymentOverdueDate, 'paymentDescription: ', paymentDescription, 'paymentDetailDescription: ', paymentDetailDescription, 
        'contractId: ', contractId, 'methodname: ', methodname, 'transactionId: ', transactionId, 'accountNumber: ', accountNumber, 
        'providerEmail: ', providerEmail, 'receiving_account_number: ', receiving_account_number);
      const response = await axios.post(`${BaseUrl}/finance/setregiteredstudentfinancialdatas`,
        {
          studentId: studentId,
          paymentDetails: paymentDetails,
          paymentdate: paymentdate,
          paymentOverdueDate: paymentOverdueDate,
          description: paymentDescription,
          contractId: contractId,
          paymentDetailDescription: paymentDetailDescription,
          methodname: methodname,
          transactionId: transactionId,
          accountNumber: accountNumber,
          providerEmail: providerEmail,
          additionalInfo: paymentDetailDescription,
          receiving_account_number: receiving_account_number
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      const info = response.data || [];
      return info;
  } catch (error) {
      return [];
  }
};

export default setStudentFinancialDatas;