import React, { useState, useEffect } from 'react';
import { Grid, TextField, FormControlLabel, Radio, FormControl, RadioGroup, 
FormLabel, Button, Paper, Typography, List, ListItem, ListItemText, Dialog, 
DialogTitle, DialogActions, DialogContent, Snackbar, Alert } from '@mui/material';
import getModules from '../../_data/roles/getModules';
import getApplications from '../../_data/roles/getApps';
import getPages from '../../_data/roles/getPages';
import getFields from '../../_data/roles/getFields';
import { useConfirmDialog } from '../../utils/contexts/ConfirmDialogContext';
import addNewRoleDefinition from '../../_data/roles/addNewRoleDefination';


const UserRolesComponent = ({ selectedRoleId, selectedRoleDefinition, onDatabaseOperationComplete}) => {
  const [modules, setModules] = useState([]);
  const [apps, setApps] = useState([]);
  const [selectedAppName, setSelectedAppName] = useState(null);
  const [selectedAppsId, setSelectedAppsId] = useState(0);
  const [selectedModuleName, setSelectedModuleName] = useState(null);
  const [selectedModuleId, setSelectedModuleId] = useState(0);
  const [pages, setPages] = useState([]);
  const [selectedPageName, setSelectedPageName] = useState(null);
  const [fields, setFields] = useState([]);
  const [selectedPageId, setSelectedPageId] = useState(0);
  const [selectedFieldId, setSelectedFieldId] = useState(0);
  const [selectedFieldName, setSelectedFieldName] = useState(0);
  const [successMessage, setSuccessMessage] = useState('');
  const { openDialog } = useConfirmDialog();
  const [disabledFields, setDisabledFields] = useState([]);

  const checkIfFieldIsDisabled = (moduleName, pageName, fieldName) => {    
    return disabledFields.some(field => 
      field.moduleName === moduleName && 
      field.pageName === pageName && 
      field.fieldName === fieldName    
    );    
  };

  // Seçili rol değiştiğinde çağırılan fonksiyon
  const handleRoleChange = (selectedRoleDefinition) => {
    // Seçili role'e ait modül, sayfa ve alan isimlerini çıkart
    const fieldNamesForDisabled = selectedRoleDefinition.flatMap(module => 
      module.Pages.flatMap(page => 
        page.FieldNames.map(field => ({
          moduleName: module.ModuleName,
          pageName: page.PageName,
          fieldName: field.FieldName
        }))
      )
    );    
    setDisabledFields(fieldNamesForDisabled); // Update State
  }; 

  useEffect(() => {
    // handleRoleChange fonksiyonunuzu burada çağırabilirsiniz
    if (selectedRoleDefinition) {
      handleRoleChange(selectedRoleDefinition);
    }
  }, [selectedRoleDefinition]);

  useEffect(() => {
    loadApps();
    //loadModules();
  }, []);

  useEffect(() => {
    if (selectedAppsId) {
      loadModules(selectedAppsId);     
    }
  }, [selectedAppsId]);

  useEffect(() => {
    if (selectedModuleId) {
      loadPages(selectedModuleId);      
    }
  }, [selectedModuleId]);

  useEffect(() => {
    if (selectedPageId) {
      loadFields(selectedPageId);
    }
  }, [selectedPageId]);
  
  const loadApps = async () => {
    try {
        const response = await getApplications();        
        setApps(response);
    } catch (error) {
        console.error('Error loading Apps roles:', error);
    }
  };

  const loadModules = async (selectedAppsId) => {
    try {
      const response = await getModules(selectedAppsId);        
      setModules(response);
    } catch (error) {
        console.error('Error loading roles loadModules:', error);
    }
  };

  const loadPages = async (ModuleId) => {
    try {      
      const response = await getPages(ModuleId);
      setPages(response);
    } catch (error) {
        console.error('Error loading roles loadPages:', error);
    }
  };

  const loadFields = async (FieldId) => {
    try {      
      const response = await getFields(FieldId);     
      setFields(response);
    } catch (error) {
        console.error('Error loading roles loadFields:', error);
    }
  };

  const handleAppsClick = (appsid, appsname) => {
    setSelectedAppsId(appsid);
    setSelectedAppName(appsname);
    setPages([]);
    setSelectedPageId(0);
    setFields([]);
    setSelectedFieldId(0);
  }

  const handleModulesClick = (moduleid, modulename) => {
    setSelectedModuleId(moduleid);
    setSelectedModuleName(modulename);
    setPages([]);
    setSelectedPageId(0);
    setFields([]);
    setSelectedFieldId(0);
  }

  const handlePagesClick = (pageid, pagename) => {    
    setSelectedPageId(pageid);
    setSelectedPageName(pagename);
    setFields([]);
    setSelectedFieldId(0);
  }

  const handleFieldsClick = (fieldid, fieldname) => {    
    setSelectedFieldId(fieldid);
    setSelectedFieldName(fieldname);
  }

  const [permission, setPermission] = useState('readOnly');
  const handlePermissionChange = (event) => {
    setPermission(event.target.value);
  };

  const getOpenDialog = async (header, message) => {
    openDialog({
      title: 'Confirm Action',
      messageHeader: header,
      message: message,
      onConfirm: async () => {        
        const result = await addNewRoleDefinition(selectedRoleId, selectedRoleDefinition, selectedModuleName, selectedPageName, selectedFieldName, permission);
        if(result){          
          loadModules(selectedAppsId);
          onDatabaseOperationComplete(selectedRoleId, result);
        }else{
          //işlem yapma
        }
      },
      onCancel: () => {
      //  console.log('Cancelled!');
      },
    });
  };
  
  const [addPermissions, setAddPermissions] = useState();
  const handleAddPermission = () => {
    if(selectedRoleId === 0 || selectedRoleId === null){
      setSuccessMessage('A "Role Name" selection must be made.');       
    } else if(selectedModuleId === 0 || selectedModuleId === null){
      setSuccessMessage('A "Module Name" selection must be made.');      
    } else if(selectedPageId === 0 || selectedPageId === null){
      setSuccessMessage('A "Page Name" selection must be made.');      
    } else if(selectedFieldId === 0 || selectedFieldId === null){
      setSuccessMessage('A "Field Name" selection must be made.');      
    }else{
      let header = "Role Definition Operation";
      let message = 'Are you sure you want to do this?';
      getOpenDialog(header, message);
    }
    setTimeout(() => setSuccessMessage(''), 3000); //Deletes the message after 3 seconds.    
    // TODO: burada yapılacak işlem önce UserId değerini al sonra tüm Module, page ve field id değerlerini ve bu id değelerine edit mi readonly mi bu ifadeleri de alarak insert veya update işlemi yapalım.
    //setAddPermissions([isEditable]); // Switch'in yeni değeri ile state'i güncelle.
    // Burada ayrıca, değişikliği bir üst bileşene bildirmek veya API çağrısı yapmak için ekstra işlemler yapabilirsiniz.
  };

  const [isRequestDialogOpen, setIsRequestDialogOpen] = useState(false);
  const handleOpenRequestDialog = (email, fullname) => { 
    try {
      //setSelectedMail(email);
      setIsRequestDialogOpen(true);
      //Dialog Panel aç ve link değerini açıklamsını vs girmesi iste  
              
    } catch (error) {
      console.log("handleOpenRequestDialog error: ", error);
    }
  };
  const handleCloseRequestDialog = () => {
    setIsRequestDialogOpen(false);
  };

  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success'); // or 'error'
  const [alertMessage, setAlertMessage] = useState('');
  const showMessage = (message, severityType = 'success') => {
    setAlertMessage(message);
    setSeverity(severityType);
    setMsgOpen(true);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  return (
    <>
      {/* User Permissions */}
      <Grid container justifyContent="flex-end">
        <Grid item sx={{mb: 5, width: 295}} xs={3}>
          <Paper sx={{padding: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between'}}>
            <Typography sx = {{mb: '5px'}} variant="h6">User Fields Permissions</Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="permissions"
                name="permissions"
                value={permission}
                onChange={handlePermissionChange}
              >
              <FormControlLabel sx = {{mr: '50px'}} value="readOnly" control={<Radio />} label="Read Only" />
              <FormControlLabel value="editable" control={<Radio />} label="Editable" />
              </RadioGroup>
            </FormControl>
            <Button 
              variant="contained" 
              color="mycolor" 
              sx={{ marginTop: 2, mb: 3, height: 50, width: 275 }}
              onClick={handleAddPermission}
            >
              Add
            </Button>
            {successMessage && <div style={{color:'red', fontWeight: 'bold' }}>{successMessage}</div>}
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">Select a Applications</Typography>
              <List>
                {apps && apps.map((app, index) => (
                  <ListItem button key={app.idpermission_app} onClick={() => handleAppsClick(app.idpermission_app, app.apptypename)}>
                    {module.modulename !== 'SystemAdmin' && (                        
                      <ListItemText 
                      style={{ backgroundColor: selectedAppsId === app.idpermission_app ? '#D4E7C5' : 'transparent' }}                
                      primary={`${app.idpermission_app}. ${app.apptypename} (${app.userstatus})`} />
                    )}
                  </ListItem>
                ))}
              </List>
          </Paper>
        </Grid>

        {/* Modules */}
        <Grid item xs={3}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">Modules</Typography>
              <List>
                {modules && modules.map((module, index) => (
                  <ListItem button key={module.idpermission_modules} onClick={() => handleModulesClick(module.idpermission_modules, module.modulename)}>
                    {module.modulename !== 'SystemAdmin' && (                        
                      <ListItemText 
                      style={{ backgroundColor: selectedModuleId === module.idpermission_modules ? '#D4E7C5' : 'transparent' }}                
                      primary={`${module.idpermission_modules}. ${module.modulename}`} />
                    )}
                  </ListItem>
                ))}
              </List>
          </Paper>
        </Grid>

        {/* Pages */}
        <Grid item xs={3}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">Pages</Typography>
            <List>
                {pages && pages.map((page, index) => (
                  <ListItem button key={page.idpermission_pages} onClick={() => handlePagesClick(page.idpermission_pages, page.description)}> 
                  {/* // TODO: Burada description yerine database yeni create edeceğimiz pagecodename alanını kullanacağız */}
                    {page.pagename !== 'SystemAdmin' && (                        
                      <ListItemText 
                      style={{ backgroundColor: selectedPageId === page.idpermission_pages ? '#D4E7C5' : 'transparent' }}                
                      primary={`${page.idpermission_pages}. ${page.description}`} />
                    )}
                  </ListItem>
                ))}
              </List>
          </Paper>
        </Grid>

        {/* Fields */}
        <Grid item xs={3}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">Fields</Typography>
            <List>
                {fields.map((field, index) => (
                  <ListItem button key={field.idpermission_fields} onClick={() => handleFieldsClick(field.idpermission_fields, field.fieldname)}
                    disabled={checkIfFieldIsDisabled(selectedModuleName, selectedPageName, field.fieldname)} // Disable durumunu kontrol et
                    style={{
                      backgroundColor: selectedFieldId === field.idpermission_fields ? '#D4E7C5' : 'transparent',
                      opacity: checkIfFieldIsDisabled(selectedModuleName, selectedPageName, field.fieldname) ? 0.5 : 1 // Eğer disable ise opaklığı azalt
                    }}
                  >

                    {field.fieldname !== 'SystemAdmin' && (                        
                      <ListItemText 
                      style={{ backgroundColor: selectedFieldId === field.idpermission_fields ? '#D4E7C5' : 'transparent' }}                
                      primary={`${field.fieldname}`} />
                    )}
                  </ListItem>
                ))}
              </List>
          </Paper>
        </Grid>        
      </Grid>     
      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={10000} 
        onClose={handleCloseMessage}
      >        
        <Alert severity={severity} onClose={handleCloseMessage} 
          sx={{ 
            width: 450, 
            minWidth: 288,
            fontSize: 15, 
            fontStyle: 'normal' 
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>  
    </>
  );
};

export default UserRolesComponent;