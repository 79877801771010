import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const createStudent= async (formData) => {
    try {           
        const token = await getToken(); // TODO: axios post istegimde params yerine data kullanilir
        const response = await axios.post(`${BaseUrl}/student/createstudent`, 
            formData,
            {
                headers: {'Authorization': `Bearer ${token}`}
            }
        );  
        return response.data;
    } catch (error) {  
        console.error("Insert error: ", error);
        return [];
    }
};

export default createStudent;