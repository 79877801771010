import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getOnboardAnswerList = async () => {
    try {
        const token = await getToken();
        //console.log('getOnboardAnswerList. ');
        const response = await axios.get(`${BaseUrl}/student/onboard/exams/getonboardanswers`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
        });
     //  console.log('getOnboardAnswerList: ', response.data);
        const info = response.data || [];
        const questionInfo = info.map((item, index) => {
            return {
                idanswers: item.idanswers || '',
                answertext: item.answertext || '',
                answerimage: item.answerimage || '',
                imagepath: item.imagepath || '',
                relatedquestions: item.relatedquestions || '',
                index: index
            };
        });
       // console.log("getOnboardAnswerList: ", questionInfo);
        return questionInfo;
    } catch (error) {
        console.log('error: ', error);
        return [];
    }
};

export { getOnboardAnswerList };
