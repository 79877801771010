import { useState } from 'react';
import { MenuItem, InputLabel, FormControl, Select, Box, IconButton, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from 'react-i18next'; // Multi Language

const DynamicSelect = ({ data, titleLabel, onSelectionChange, width }) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onSelectionChange(event.target.value);
  };

  const clearSelection = () => {
    setSelectedValue('');
    onSelectionChange('');
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: width ? width : 'auto', position: 'relative' }}>
      <FormControl fullWidth sx={{ flexGrow: 1, marginRight: 1 }}>
        <InputLabel
          id="dynamic-select-label"
          shrink
          sx={{
            position: 'relative',
            backgroundColor: 'white',
            paddingRight: '0.5em',
            width: 'auto',
            transform: 'none',
            top: '0',
          }}
        >
          {titleLabel}
        </InputLabel>
        <Select
          labelId="dynamic-select-label"
          id="dynamic-select"
          value={selectedValue}
          displayEmpty
          onChange={handleChange}
          renderValue={
            selectedValue !== '' ? undefined : () => <span style={{ opacity: 0.7 }}>{t('SelectABatch')}</span>
          }
          sx={{ '.MuiOutlinedInput-notchedOutline': { borderColor: 'grey' }, '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'black' } }}
        >
          {Array.isArray(data) ? data.map((option, index) => (
            <MenuItem key={index} value={option.batchname}>
              {option.batchname}
            </MenuItem>
          )) : <MenuItem disabled>No options available</MenuItem>}
        </Select>
      </FormControl>
      {selectedValue && (
        <IconButton onClick={clearSelection} size="small" sx={{ position: 'absolute', right: 0 }}>
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default DynamicSelect;


// import { useState } from 'react';
// import { MenuItem, InputLabel, FormControl, Select, Box, IconButton } from '@mui/material';
// import ClearIcon from '@mui/icons-material/Clear';
// import { useTranslation } from 'react-i18next'; // Multi Language

// const DynamicSelect = ({ data, titleLabel, onSelectionChange, width }) => {
//   const { t } = useTranslation();
//   const [selectedValue, setSelectedValue] = useState('');

//   const handleChange = (event) => {
//     setSelectedValue(event.target.value);
//     onSelectionChange(event.target.value);
//   };

//   const clearSelection = () => {
//     setSelectedValue('');
//     onSelectionChange('');
//   };

//   return (
//     <Box sx={{ display: 'flex', alignItems: 'center', width: width ? width : 'auto' }}>
//       <FormControl fullWidth sx={{ flexGrow: 1 }}>
//         <InputLabel
//           id="dynamic-select-label"
//           shrink // Bu başlığın her zaman daraltılmış halde olmasını sağlar
//           sx={{
//             position: 'relative', // İçeriğin üzerine gelmesini engellemek için konumlandırma
//             backgroundColor: 'white', // Yazının üzerine gelen çizgiyi kapatmak için
//             paddingRight: '0.5em', // Metin ve çizgi arasında boşluk bırakır
//             width: 'auto', // Genişliği içeriğe göre ayarlar
//             transform: 'none', // Transform efektini kaldırır
//             top: '0', // Üst pozisyonu sıfırlar
//           }}
//         >
//           {titleLabel}
//         </InputLabel>
//         <Select
//           labelId="dynamic-select-label"
//           id="dynamic-select"
//           value={selectedValue}
//           label="Choose a Batch"
//           displayEmpty
//           onChange={handleChange}
//           renderValue={
//             selectedValue !== '' ? undefined : () => <span style={{ opacity: 0.7 }}>{t('SelectABatch')}</span>
//           }
//         >
//           {Array.isArray(data) ? data.map((option, index) => (
//             <MenuItem key={index} value={option.batchname}>
//               {option.batchname}
//             </MenuItem>
//           )) : <MenuItem disabled>No options available</MenuItem>}
//         </Select>
//       </FormControl>
//       {selectedValue && (
//         <IconButton onClick={clearSelection} size="small">
//           <ClearIcon />
//         </IconButton>
//       )}
//     </Box>
//   );
// };

// export default DynamicSelect;



// import { useState } from 'react';
// import {  MenuItem, InputLabel, FormControl, Select } from '@mui/material';
// import { useTranslation } from 'react-i18next'; //Multi Language

// const DynamicSelect = ({ data , titleLabel, onSelectionChange, width}) => {
//   const { t } = useTranslation();
//   const [selectedValue, setSelectedValue] = useState('');
//   const handleChange = (event) => {
//     setSelectedValue(event.target.value);
//     onSelectionChange(event.target.value); 
//   };
//   return (
//     <FormControl fullWidth sx={{ width: width ? width : 'auto' }}>
//       <InputLabel id="dynamic-select-label"
//         shrink // Bu başlığın her zaman daraltılmış halde olmasını sağlar
//         sx={{ 
//           position: 'relative', // İçeriğin üzerine gelmesini engellemek için konumlandırma
//           backgroundColor: 'white', // Yazının üzerine gelen çizgiyi kapatmak için
//           paddingRight: '0.5em', // Metin ve çizgi arasında boşluk bırakır
//           width: 'auto', // Genişliği içeriğe göre ayarlar
//           transform: 'none', // Transform efektini kaldırır
//           top: '0', // Üst pozisyonu sıfırlar
//         }}
//       >{titleLabel}</InputLabel>
//       <Select
//         labelId="dynamic-select-label"
//         id="dynamic-select"
//         value={selectedValue}
//         label="Choose a Batch"
//         displayEmpty
//         onChange={handleChange}
//         renderValue={
//           selectedValue !== "" ? undefined : () => <span style={{ opacity: 0.7 }}>{t('SelectABatch')}</span>
//         }
//       >
//         {Array.isArray(data) ? data.map((option, index) => (
//           <MenuItem key={index} value={option.batchname}>
//             {option.batchname}
//           </MenuItem>
//         )) : <MenuItem disabled>No options available</MenuItem>}
//       </Select>
//     </FormControl>
//   );
// }
// export default DynamicSelect;
