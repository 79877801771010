import React, { useState, useEffect }  from 'react';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import contract1 from '../../assets/contracts/240118_Oak_Gmbh_Enrolment_Agreement.pdf';
import config from '../../config';
import { useTranslation } from 'react-i18next'; //Multi Language
import DynamicSelect from '../../components/select/dynamicSelect';
// @mui
import {
  Card, Table, Stack, Paper, Avatar,  Popover, Checkbox, TableRow,
  MenuItem, TableBody, TableCell, TextField, Container, Typography, IconButton,
  TableContainer, TablePagination, InputLabel, FormControl, Select, Button, Box, Snackbar, Alert,
  Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';

const { PUBLIC_URL } = config;

function PdfViewer() {

  const { t } = useTranslation();
  const [selectData, setSelectData] = useState(["ABCD","EFGH"]);
  const [titleLabel, setTitleLabel] = useState("Oak Contracts Names");
  const [selectedBatch, setSelectedBatch] = useState(""); //from

  const options = selectData?.map((data, index) => (
    <option key={index} value={data}>{data}</option>
  ));

  useEffect(() => {
    // const fetchBatchNames = async () => {
    //   const data = await getBatchNames();
    //   setSelectData(data);
    // };
    // fetchBatchNames();
    // getstudentDatas();
    setSelectData([...selectData, "newValue"]);
  }, []);

  const handleSelectionChange = (value) => { //from dynamicSelect.js
    // setSelectedBatch(value);
    // setPage(0);
    // setfilterStudentId(value);    
  };
  const [selectedValue, setSelectedValue] = useState('');

  // Seçim değiştiğinde çağrılan fonksiyon
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleAllBatchAttendancePageOpen = async () => {
    // const batchName = filterStudentId;
    // if(filterRecords.length > 0){
    //   const getEmails = filterRecords.map((selected, index) => {
    //     return selected.contact.mail['mailaddress-1'];
    //   });
    //   const reportAllBatchZoomAttendances = await ReportStudentAttendances(getEmails);   
    //   navigate(`/dashboard/studentattendances`, { state: { reportAllBatchZoomAttendances, batchName } });
    // }
  };

  return (
    <div>
      <div>
      <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '16px', // Yuvarlak köşeler için
                border: 1, // Çerçeve kalınlığı
                borderColor: 'grey.300', // Çerçeve rengi, tema renklerine göre ayarlanmalı
                p: 2, // padding, içerik ve çerçeve arasındaki boşluk
                bgcolor: 'background.paper', // Arka plan rengi, tema renklerine göre ayarlanmalı
              }}
            >
              <InputLabel id="demo-simple-select-label" sx = {{pr:1}}>{titleLabel}</InputLabel>
              <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedValue}
              label="Seçim"
              onChange={handleSelectionChange}
            >
              <MenuItem value={"ABCD"}>240118_Oak_Gmbh_Enrolment_Agreement.pdf</MenuItem>
              <MenuItem value={"EFGH"}>240118_Oak_UK_Enrolment_Agreement .pdf</MenuItem>
            </Select>
              {/* <DynamicSelect
                sx={{ flex: 1, height: '100%' }} 
                width={200}
                data={["ABCD", "EFGH"]} 
                titleLabel={titleLabel}
                onSelectionChange={handleSelectionChange}
              /> */}
              {/* <Button 
                variant="contained" 
                startIcon={<Iconify icon="eva:plus-fill" />}
                sx={{ ml: 2, height: '100%', marginTop: 2.8, backgroundColor: '#5141C3'}}
                onClick={handleAllBatchAttendancePageOpen}
              >
                {t('GetAllBatchAttendances')}
              </Button> */}
            </Box>
      </div>
      <Worker workerUrl={`${PUBLIC_URL}/assets/pdf.worker.min.js`}>
        <Viewer fileUrl={contract1} />
      </Worker>
    </div>
  );
}
export default PdfViewer;