import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const setPasswordEmailUser= async (mail, newPasswd) => {
    try {
        const token = await getToken();
        const response = await axios.patch(`${BaseUrl}/email/users/setpassword`, {
          email: mail,
          newPassword: newPasswd
        },
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error("Update error: ", error);
        return [];
    }
};

export default setPasswordEmailUser;
