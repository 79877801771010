import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Modal, Snackbar, Alert, Dialog, DialogTitle,DialogContent, DialogActions, 
  TextField, CircularProgress, Select, InputLabel, FormControl, FormHelperText
 } from '@mui/material';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import Label from '../../components/label';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import ModalRegisterForm from '../../components/userForm/ModalRegisterForm';
import ModalEditForm from '../../components/userForm/Edit/ModalEditForm';
import USERLIST from '../../_data/user/getAllUser';
import updateStatusUser from '../../_data/user/setStatusUser'

// -------------------------COLUMN TITLES--------------------------------
const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'username', label: 'Username', alignRight: false },
    { id: 'roles', label: 'Roles', alignRight: false },
    { id: 'jobs', label: 'Jobs', alignRight: false },
    { id: 'isactive', label: 'IsActive', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: '' },
  ];

// ----------------------------------------------------------------------

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #f4f6f8',
  borderRadius: '4px',
  boxShadow: 24,
  p: 4,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (!Array.isArray(array)) {
    return [];
  };

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_student) => _student.id.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('id');

  const [filterMeetId, setfilterMeetId] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [userDatas, setUserDatas] = useState([USERLIST]);
  
  const [recordsNumber, setRecordsNumber] = useState(0);

  const [postReqSuccess, setPostReqSuccess] = useState("");

  const [openAlertMsg, setOpenAlertMsg] = useState(false); //Notification Show/hide
  const [severity, setSeverity] = useState('success'); //Notification Show/hide  
  const [alertMessage, setAlertMessage] = useState(''); 
  const [selectedMail, setSelectedMail] = useState("");
  //const [selectedUserId, setSelectedUserId] = useState(null); //user id is selected for display, and edit

  // -------------------------------Post request function---------------------------------------
  function handleSuccessfulSubmit() {
    setOpenRegisterModal(false);
    setPostReqSuccess("Registration completed successfully!");
    setOpenAlertMsg(true);
    setSeverity('success');
    setAlertMessage('Registered Success!');
    getusersDatas();
    handleCloseRegisterModal();
  }
  const handleErrorSubmit = useCallback(() => {
    setOpenRegisterModal(false);
    setPostReqSuccess("An error occurred during the registration process!");
    setOpenAlertMsg(true);
    setSeverity('error');
    setAlertMessage('Registered Failed!');
  }, [setOpenAlertMsg, setSeverity, setAlertMessage]);  
// -------------------------------Post request function---------------------------------------

   const getusersDatas = useCallback(async () => {
    try {
      const data = await USERLIST();
      if (data && data.length > 0) {
        setRecordsNumber(data.length);
        setUserDatas(data);
      } else {
        setRecordsNumber(0);
      }
    } catch (error) {
      handleErrorSubmit();
      setRecordsNumber(0);
      setUserDatas([]);
    }
  }, [handleErrorSubmit]); // Bağımlılıklar bu durumda boş bir dizi olabilir çünkü fonksiyon içindeki state setter'ları zaten sabittir.
  
  useEffect(() => {
    getusersDatas();
    //fetchUsers();
  }, [getusersDatas]);


  const fetchUsers = async () => {
    try {
        getusersDatas();
        // const data = await emailusers();
        // setUserList(data);
    } catch (error) {
        console.error("Error fetching users: ", error);
    }
  };





  // const getusersDatas = async () => {
  //   try {
  //       const data = await USERLIST();
  //       if(data && data.length > 0){
  //         setRecordsNumber(data.length);
  //         setUserDatas(data);
  //       }                  
  //       else{
  //         setRecordsNumber(0);
  //       }        
  //   } catch (error) {
  //       handleErrorSubmit();
  //       setRecordsNumber(0);
  //       setUserDatas([]);
  //   }
  // };

  // --------------- New user Button & Modals
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const handleOpenRegisterModal = () => {
    setOpenRegisterModal(true)
    //console.log('button clicked');
  };
  const handleCloseRegisterModal = () => setOpenRegisterModal(false);
  // --------------- New user Button & Modals

  // --------------- Edit user Button & Modals
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleOpenEditModal = () => {
    setOpenEditModal(true);
    //console.log(`edit button clicked`);
  }
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    getusersDatas();
  }
  // --------------- Edit user Button & Modals
  const [selectedFullName, setSelectedFullName] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');
  const handleOpenMenu = (event, select) => {
    setOpen(event.currentTarget);
    setSelected([select.id]);
    setSelectedUserName(select.username);
    setSelectedFullName(select.name_surname);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userDatas.map((n) => n.id);
      //console.log("newSelecteds: ", newSelecteds);
      setSelected(newSelecteds);
      return;
    }
   // console.log(`selected: ${selected}`);
    setSelected([]);
  };

  const handleClick = (event, select) => {
    const selectedIndex = selected.indexOf(select.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, select.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setfilterMeetId(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userDatas.length) : 0;
  const filteredUsers = applySortFilter(userDatas, getComparator(order, orderBy), filterMeetId);
  const isNotFound = !filteredUsers.length && !!filterMeetId;
  const [msgOpen, setMsgOpen] = useState(false);

  const MENU_OPTIONS = [
    { label1: 'Edit ', },
    { label2: 'Delete ', },
    { label3: 'Role_Details ', },
    { label4: 'Active', },
    { label5: 'Inactive', },
  ];

  const handleDownRecord = async (event) => {
    try {          
      const label = event.currentTarget.textContent;
      if(selected.length > 0){
        switch(label){          
          case 'Edit':
            //handleOpenRequestDialog(selected[0].email, selected[0].fullname);
            break;
          case 'Delete':                   
            //handleConfirmDialogOpen(selected, 1);
            break;
          case 'Role Details':  
          //  console.log(selected);                 
            openUserRoleDetailsPage(selected, 0);            
            break;
          case 'Active':          
            handleConfirmDialogOpen(selected, selectedUserName, selectedFullName, 1);
            break;
          case 'Inactive':                   
            //handleDetailRecordsDialogOpen(selected[0].email);
            handleConfirmDialogOpen(selected, selectedUserName, selectedFullName, 0);
            break;
          default:
            break;
        };
    }else{          
      setSeverity('error');
      setAlertMessage('Not selected Meeting Record!');
      setMsgOpen(true);
    }
    } catch (error) {
        console.log("handleDownRecord-Error: ", error);
    }
  };
  const navigate = useNavigate();
  const openUserRoleDetailsPage = (userId) =>{    
  //  console.log("userId: ", userId);
    if (userId && userId.length > 0) {
      navigate(`/dashboard/userroledetails`, { state: { userId } });      
    }
  };

  const [isDetailRecordsDialogOpen, setIsDetailRecordsDialogOpen] = useState(false);
  const handleDetailRecordsDialogOpen = (email) => {
    try {
      setSelectedMail(email);
      setIsDetailRecordsDialogOpen(true);
    } catch (error) {      
    }
  };
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState({ userid: null, username: "", name_surname: "[]", status: null });

  const handleConfirmDialogOpen = (userid, username, name_surname, status) => {
    setCurrentAction({userid, username, name_surname, status});
    setOpenConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const performAction = async () => {    
    const response = await updateStatusUser(currentAction.userid[0], currentAction.status);
  //  console.log("performAction-response(AffectedRows): ", response);
    if (response.message === 'Status updated successfully.' && response.affectedRows > 0) {
   //   console.log("performAction-response(if): ", response);
      setSeverity('success');
      setAlertMessage('Registration update process completed successfully.');
      setOpenAlertMsg(true);
      //setMsgOpen(true);
      fetchUsers();
      handleCloseMenu();
    }
  };

  const handleConfirm = () => {
    performAction(currentAction);
    handleConfirmDialogClose();
  };

  const [isRequestDialogOpen, setIsRequestDialogOpen] = useState(false);
  const handleCloseRequestDialog = () => {
    setIsRequestDialogOpen(false);
  };

  const [contentLinkError, setContentLinkError] = useState(false);
  const [contentLink, setContentLink] = useState("");
  const handleContentLinkChange = (event) => {
    setContentLink(event.target.value);
  };

  const [contentDescriptionError, setContentDescriptionError] = useState(false);
  const [contentDescription, setContentDescription] = useState("");
  const handleContentDescriptionChange = (event) => {
    setContentDescription(event.target.value);
  };

  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('Ready to submit');
  const getStatusLabel = (idstatus) => {
    //console.log('idstatus: ', idstatus)
    switch (idstatus) {
      case 1:
        return 'UNASSIGNED';
      case 2:
        return 'USER';
      case 3:
        return 'STUDENT';
      default:
        return 'UNKNOWN';
    }
  };

  return (
    <>    
      <Helmet>
        <title> Oak Users| Oak Academy </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>           
            Oak Users List (Record Of Number: {recordsNumber})
          </Typography>
          <br />
          {postReqSuccess && <>
          <p>{postReqSuccess}</p>
          </>}
          <Button variant="contained" onClick={handleOpenRegisterModal} startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button>
          <Modal
            open={openRegisterModal}
            onClose={handleCloseRegisterModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <ModalRegisterForm onError={handleErrorSubmit} onSuccess={handleSuccessfulSubmit} />              
            </Box>
          </Modal>
          {selected && 
            <>
              <Modal
                open={openEditModal}
                onClose={handleCloseEditModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle}>
                  <ModalEditForm userId={selected} />
                </Box>
              </Modal>
            </>
          }
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterMeetId={filterMeetId} onfilterMeetId={handleFilterByName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={recordsNumber}                             
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>                
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {                                          
                    const { id, name_surname, username, photourl, roles, jobs, is_active, idstatus} = row;                     
                    const selectedUsers = selected.indexOf(id) !== -1;                    
                    return (
                      <TableRow hover key={id !== undefined ? id.toString() : ''} tabIndex={-1} role="checkbox" selected={selectedUsers}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUsers} onChange={(event) => handleClick(event, {id, username, name_surname} )} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                             <Avatar alt={id !== undefined ? id.toString() : ''} src={photourl} />
                            <Typography variant="subtitle2" noWrap>
                              {name_surname}
                            </Typography>
                          </Stack>
                        </TableCell>                                                      
                        <TableCell align="left">{username}</TableCell>                     
                        <TableCell align="left">
                          {roles && roles.Roles && roles.Roles.length > 0 ? (
                            <div>{roles.Roles.join(', ')}</div>
                          ) : (
                            <div>No Roles</div>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {jobs && (
                            <div>
                              <strong>Title:</strong> {jobs.title}<br />
                              <strong>Department:</strong> {jobs.Department}
                            </div>
                          )}
                        </TableCell>                        
                        <TableCell align="left">
                          <Label color={(is_active === 1 && 'success') || (is_active === 0 && 'error') || 'default'}>
                            {is_active === 1 ? 'Active' : (is_active === 2 ? 'Pending' : 'Inactive')}
                          </Label>
                        </TableCell>    
                        <TableCell align="left">
                          {/* {status} Bu yapiyi nasil dynamic hale getiririz dusunelim*/}
                        {/* <Label color={(status === 'USER' && 'success') || (status === 'STUDENT' && 'primary') || 'default'}> */}
                        <Label color={(idstatus === 2 && 'success') || (idstatus === 3 && 'primary') || 'default'}>
                          {getStatusLabel(idstatus)}
                          </Label>
                        </TableCell>                     
                        <TableCell align="right">
                          <IconButton 
                            size="large" 
                            color="inherit" 
                            onClick={(event) => {
                              handleOpenMenu(event, {id, username, name_surname})
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterMeetId}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, { value: -1, label: 'All' }]}
            component="div"
            count={recordsNumber}            
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem  key={MENU_OPTIONS.label1} onClick={handleOpenEditModal}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        <MenuItem key={MENU_OPTIONS.label5} onClick={handleDownRecord} sx={{ color: 'prinary.main' }}>
          <Iconify icon={'eva:slash-outline'} sx={{ mr: 2 }} />
            Inactive
        </MenuItem>
        <MenuItem key={MENU_OPTIONS.label4} onClick={handleDownRecord} sx={{ color: 'success.dark' }}>
          <Iconify icon={'eva:undo-fill'} sx={{ mr: 2 }} />
            Active
        </MenuItem>
        <MenuItem key={MENU_OPTIONS.label2} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>

        <MenuItem key={MENU_OPTIONS.label3} onClick={handleDownRecord} sx={{ color: 'secondary.main' }}>
          <Iconify icon={'eva:more-vertical-fill'} sx={{ mr: 2 }} />
          Role Details
        </MenuItem>
      </Popover>

      <Snackbar anchorOrigin={{ vertical:'top', horizontal:'right' }} open={openAlertMsg}  autoHideDuration={6000} onClose={() => {setOpenAlertMsg(false)}}>        
        <Alert severity={severity} 
          onClose={() => {setOpenAlertMsg(false)}} 
          sx={{ 
            width: 450, 
            minWidth: 288,
            fontSize: 15, 
            fontStyle: 'normal' 
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={openConfirmDialog}
        onClose={handleConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          {selected.map((userid, index) => (
            <p key={index} style={{fontWeight: 'bold', color: "#1640D6", marginBottom: 2 }}> 
               {currentAction.name_surname} {' ('} {currentAction.username} {') '}
            </p>
          ))}
          <p>
            The above username will be set as{' '}
            <span style={{ fontWeight: 'bold', color: currentAction.status === 0 ? 'red' : 'green' }}>
              {currentAction.status === 0 ? 'Inactive' : 'Active'}
            </span>.
            <br/>Are you sure you want to do this?
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleConfirm} 
            autoFocus
            style={{
              backgroundColor: currentAction.status === 0 ? 'red' : 'green',
              color: '#FFFFFF',
            }}
          >
            OK
          </Button>
        </DialogActions>        
      </Dialog>
      <Dialog open={isRequestDialogOpen} onClose={handleCloseRequestDialog}>
        <DialogTitle style={{ color: 'red'}}>New Request Comfirm Record</DialogTitle>
        <Typography variant="body1" color="mycolor.main">
          <span style={{ color: 'black',fontWeight: 'bold', fontStyle: 'normal', marginLeft: 20}}>{selected[0]?.fullname} | {selected[0]?.email}</span>
          </Typography>
        <DialogContent>
          <TextField
            error={contentLinkError}
            helperText={contentLinkError ? "This field is required." : ""}
            autoFocus
            margin="dense"
            label="Content Link"
            type='text'
            fullWidth
            variant="outlined"
            value={contentLink}
            onChange={handleContentLinkChange}
          />
          <TextField
            error={contentDescriptionError}
            helperText={contentDescriptionError ? "This field is required." : ""}
            margin="normal"
            label="Content Of Description"
            type="text"
            fullWidth
            variant="outlined"
            value={contentDescription}
            onChange={handleContentDescriptionChange}
          />
          {/* <FormControl error={selectedLanguageError} fullWidth margin="dense">
            <InputLabel id="lang-select-label">Select Language</InputLabel>
            <Select
              labelId="lang-select-label"
              name="mailtype"
              label="Select Language"
              value={selectedLanguage}
              onChange={handleChangeMailTypes}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {mailTypes.map((type, index) => (
                <MenuItem key={index} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
            {selectedLanguageError && <FormHelperText>This field is required.</FormHelperText>}
          </FormControl> */}
          <Typography 
            color="gray"            
            variant="caption"
          >
            {/* Current Date: {currentDate} */}
          </Typography> 
        </DialogContent>
        {loading && (
          <div style={{ textAlign: 'right', padding: '8px 24px' }}>
            <p>{statusMessage}</p> {/* Yalnızca loading iken durum mesajını göster */}
          </div>
        )}
        <DialogActions>
          <Button onClick={handleCloseRequestDialog}>Cancel</Button>       
          {/* <Button
            style={{
              backgroundColor: loading ? 'grey' : 'blue', // Yükleme sırasında buton rengini değiştir
              color: '#FFFFFF',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleSubmitNewPaticipantApprovalDetail}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" style={{ color: '#FFFFFF' }} />
            ) : (
              'Save & Send Request'
            )}
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}