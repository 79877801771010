import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getStudentFinancialDatas = async (idstudent) => {
  try {
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/finance/getregiteredstudentfinancialdatas`, {
        params: { idstudent },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const info = response.data || [];
      return info;
  } catch (error) {
      return [];
  }
};

export default getStudentFinancialDatas;
