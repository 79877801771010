import axios from 'axios';
import config from '../../config';
const { BaseUrl } = config;

const ceateFolder = async (folderName) => {
  try {
   // console.log("getAMeeting: ", folderName);
    let config = {
        method: 'get',
        maxBodyLength: Infinity,        
        url: `${BaseUrl}/api/zoom/createFolder/${folderName}`,          
        headers: { }
      };
    const response = await axios.request(config);
   // console.log("ceateFolder Response: ",response)
    return response;
  }
  catch (error) {
    console.log("ceateFolder: ", error);
  }
}

export default ceateFolder;