import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
import formatDateTime from '../../utils/formatDateTime';
const { BaseUrl } = config;

const getApprovalUserMailDetails= async (pk) => {
    try {
        const token = await getToken();
        const response = await axios.get(`${BaseUrl}/permissionreq/getApprovalUsersMailDetails`, {
            params: {
                pk: pk,
            },
            headers: {
                'Authorization': `Bearer ${token}`
            }
          });
        const info = response.data || [];
        const approvalDetailsList = info.map((item, index) => {
            return {
              idparticipants_approvals_details: item.idparticipants_approvals_details || '',
              idparticipants_approvals: item.idparticipants_approvals || '',
              permission_content: item.permission_content || '',
              mailtype: item.mailtype || '',
              permission_date: item.permission_date ? formatDateTime(item.permission_date) : '',
              approved_date: item.approved_date ? formatDateTime(item.approved_date) : '',
              numberofcalls: item.numberofcalls || '',
              approved: item.approved || '',
              permission_description: item.permission_description || '',
              iduser: item.iduser || '',
              at_created: item.at_created || '',
              at_updated: item.at_updated || '',
              isdeleted: item.isdeleted || '',
            };
        });
        return approvalDetailsList;
    } catch (error) {
        console.error('Error fetching approval user mail details:', error);
        return [];
    }
};

export default getApprovalUserMailDetails;