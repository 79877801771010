import { fDate, fDateX } from '../../utils/formatTime';
import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;
const qs = require('qs');

const getCloudRecordList = async (params) => {
  try {
    const token = await getToken();
    params.from = fDateX(params.from);
    params.to = fDateX(params.to);
    const axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,      
      url: `${BaseUrl}/zoom/records/listcloudrecordings`,
      headers: {
        'Authorization': `Bearer ${token}`
      },
      data: params,
    };
    const response = await axios.request(axiosConfig);
    //console.log("getCloudRecordList :", response.data);
    return response.data;
  } catch (error) {
    //console.log('getCloudRecordList-[ERROR]: ', error);
    console.error(error);
    return [];
  }
};

export default getCloudRecordList;

// url: `${BaseUrl}/zoom/records/listcloudrecordings/?userId=${params.userId}&from=${params.from}&to=${params.to}&page_size=${params.page_size}`,

