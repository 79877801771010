//import React from 'react';
import React, { useEffect, useState, useRef } from 'react';
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails, 
         Paper, Divider, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email'; // E-posta ikonu için
import { useTranslation } from 'react-i18next'; //Multi Language
import config from '../../config';

const CustomDataDisplay = ({ data }) => {
  const { PUBLIC_URL } = config;
  const GmailIcon = `${PUBLIC_URL}/assets/icons/navbar/gmail_icon.svg`;
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  // WhatsApp linkini oluşturmak ve yeni sekmede açmak için bir fonksiyon
  const openWhatsApp = (number) => {
    // Telefon numarasını + ile başlayacak şekilde düzenle
    const phoneNumber = number.startsWith('+') ? number : `+${number}`;
    // WhatsApp linkini oluştur
    const whatsappLink = `https://wa.me/${phoneNumber.replace(/[^0-9+]/g, '')}`;
    // Linki yeni sekmede aç
    window.open(whatsappLink, '_blank');
  };

  const openEmailClient = (emailAddress) => {
    // window.location.href = `mailto:${emailAddress}`;
    window.open("https://mail.google.com/mail/?tab=rm&authuser=0&ogbl", '_blank');
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderObject = (obj) => {
    return Object.entries(obj).map(([key, val], index, array) => {     
      if (val.trim() === '') return null;  // Don't show empty string values!
      const isWhatsAppKey = key.toLowerCase().includes('whatsapp');
      const isEmailKey = key.toLowerCase().includes('mailaddress');
      return (
        <Typography key={key} variant="body2" component="div">
          <Box display="flex" alignItems="center">
            <span style={{ fontWeight: 'bold', color: '#7F27FF' }}>{capitalizeFirstLetter(key)}:</span>
            <Box ml={1}>
              {isWhatsAppKey ? (
                <React.Fragment>
                  {val}
                  <IconButton 
                    size="small" 
                    onClick={() => openWhatsApp(val)}
                    style={{ color: 'white', backgroundColor: '#25D366', marginLeft: '8px' }}
                  >
                    <WhatsAppIcon />
                  </IconButton>
                </React.Fragment>
              ) : isEmailKey ? (
                <React.Fragment>
                  {val}
                  <IconButton 
                    size="small" 
                    onClick={() => openEmailClient(val)}
                    style={{ color: 'white', marginLeft: '8px' }}
                  >
                    <img src={GmailIcon} alt="Gmail" style={{ width: 24, height: 24 }} />
                  </IconButton>
                </React.Fragment>
              ) : val}
            </Box>            
          </Box>
        </Typography>
      );
    });
  };

  return (
    // <Accordion sx={{ width: 'inherit', maxWidth: 200 }}>
    <Accordion
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
      sx={{
        maxWidth: expanded ? 385 : 180,
        transition: 'max-width 0.3s ease',
        '&:before': { display: 'none' }, // MUI Accordion'daki default border'ı kaldırır
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{t('ContactDetails')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Paper elevation={3} sx={{ p: 2 }}>
          {data && Object.entries(data).map(([key, value], index, array) => {
            const notLastItem = index < array.length - 1;
            const isObject = typeof value === 'object' && value !== null && !Array.isArray(value);
            return (
              <Box key={key} sx={{ maxWidth: 350, mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom component="div" sx={{ fontWeight: 'bold', textDecoration: 'underline'}}>
                  {capitalizeFirstLetter(key)}:
                </Typography>
                {isObject ? renderObject(value) : value}
                {notLastItem && <Divider sx={{ my: 1 }} />}
              </Box>
            );
          })}
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
};

// A helper function to capitalize the first letter.
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
const DataDisplay = ({ customData }) => {
  return <CustomDataDisplay data={customData} />;
};

export default DataDisplay;
