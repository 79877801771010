import React from 'react';
import { Paper, Box, Typography, LinearProgress, CircularProgress, Card, CardContent, CardHeader, useTheme, SvgIcon } from '@mui/material';
import { BorderStyle, Padding } from '@mui/icons-material';

const LabDashboardCard = ({ title, value, maxValue, unit, backcolor }) => {
  const normalise = value => (value - 0) * 100 / (maxValue - 0); // Değerleri yüzdelik bir orana çevirir
  return (
    <Card sx={{ backgroundColor: backcolor }}>
      <CardHeader title={title} />
      <CardContent >
        <Box display="flex" alignItems="center" >
          <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" value={normalise(value)} />
          </Box>
          <Box minWidth={60}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(value)}${unit}`}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

function ColoredCircle({ value }) {
  const theme = useTheme();
  const color = value ? theme.palette.success.main : theme.palette.error.main;  
  return (
    <SvgIcon>
      <circle cx="12" cy="12" r="8" fill={color} />
    </SvgIcon>
  );
}

const LabDashboard = (props) => {
  const { nodes } = props;
  const cardStyle = {
    backgroundColor: 'background.paper', // İkinci resimdeki panel arka planı gibi
    borderRadius: '4px', // Yuvarlak köşeler için
    boxShadow: 'none', // Dış gölgeyi kaldırmak için
    p: 2, // padding
    my: 1 // Daha fazla dikey boşluk için
  };
  const days = Math.floor(nodes.uptime / (24 * 3600));
  const hours = Math.floor((nodes.uptime % (24 * 3600)) / 3600);
  const minutes = Math.floor((nodes.uptime % 3600) / 60);
  const seconds = nodes.uptime % 60;
  // const uptimeString1 = `${days} days ${hours} hours`;// TODO: Buradkileri yukarı taşıyabiliriz.
  // const uptimeString2 = `${minutes} munites ${seconds} seconds`;// TODO: Buradkileri yukarı taşıyabiliriz.
  const uptimeString = `${days} days ${hours} hours ${minutes} munites ${seconds} seconds`;// TODO: Buradkileri yukarı taşıyabiliriz.
  //console.log("cpu: ", nodes.cpu);
  //console.log("maxcpu: ", nodes.maxcpu);
  return (
    <Box p={3} component={Card} sx={cardStyle}>
      <Typography color="mycolor.main" variant="h3" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'left' }}>
        Lab1
      </Typography>
      <Box display="flex" justifyContent="space-around" flexWrap="wrap" gap={2}>
        <Box sx={{ textAlign: 'left', minWidth: '200px' }}>
          <Typography variant="h6" sx={{ mb: 1 }} color="textPrimary">
            <Box component="span" sx={{ fontWeight: 'bold', color: 'primary.main' }}>Status:</Box>
            <span style={{ verticalAlign: 'middle' }}><ColoredCircle value={nodes.status} /></span>
          </Typography>
          <Typography variant="h6" sx={{ mt: 1, maxWidth: '240px', wordWrap: 'break-word'}} color="textPrimary">
            <Box component="span" sx={{ fontWeight: 'bold', color: 'primary.main' }}>Uptime:</Box> {uptimeString}
          </Typography>
        </Box>
        <LabDashboardCard title="CPU Usage" value={nodes.cpu} maxValue={nodes.maxcpu} unit="%" backcolor="mycolor.lighter2"/>
        <LabDashboardCard title="Memory Usage" value={nodes.mem/1073741824} maxValue={nodes.maxmem/1073741824} unit="GB" backcolor="mycolor.light1"/>
        <LabDashboardCard title="Disk Usage" value={nodes.disk/1073741824} maxValue={nodes.maxdisk/1073741824} unit="GB" backcolor="mycolor.lighter"/>
      </Box>
    </Box>
  );
};
export default LabDashboard;