import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DynamicSelect from '../../components/select/dynamicSelect';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  TextField,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  InputLabel, FormControl, Select, Box,SvgIcon, useTheme
} from '@mui/material';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import { ReactComponent as Icon_Attendance } from '../../assets/icons/attendance.svg';
import { ReactComponent as Icon_Payment } from '../../assets/icons/icon_payment.svg';
import Scrollbar from '../../components/scrollbar'
// sections
import { StudentListHead, StudentListToolbar } from '../../sections/@dashboard/student';
import { getRecordedLectures } from '../../_data/lectures/getRecordedLectures';
import ReportStudentAttendances from '../../_data/student/getReportParticipant';
import DataDisplay from '../student/CustomDataDisplay';
import { getBatchNames } from '../../_data/batchs/getBatchNames';


// -------------------------COLUMN TITLES-------------------------------------------------------

const TABLE_HEAD = [
  { id: 'idzoom_records', label: 'Lecture Topic', alignRight: false },
  { id: 'start_time', label: 'Start Time', alignRight: false },
  { id: 'gprocess_result', label: 'To GDrive', alignRight: false },
  { id: '' },
  //idzoom_records, meeting_uuid, meeting_id, start_time, topic, topic_changes, gprocess_result
];        

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(stabilizedThis.map((el) => el[0]), (student) => {
      // Burada öğrencinin tüm özelliklerini kontrol ediyoruz
      return Object.values(student).some((value) => 
        String(value).toLowerCase().includes(query.toLowerCase())
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function StudentPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('start_time');

  const [filterStudentId, setfilterStudentId] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [lectureDatas, setLectureDatas] = useState([getRecordedLectures]);
  
  const [recordsNumber, setrecordsNumber] = useState(0);
  const [openAlertMsg, setOpenAlertMsg] = useState(false); //Notification Show/hide
  const [severity, setSeverity] = useState('success'); //Notification Show/hide  
  const [alertMessage, setAlertMessage] = useState('');
  const [selectData, setSelectData] = useState();
  const [selectedBatch, setSelectedBatch] = useState(""); //from
  const [msgOpen, setMsgOpen] = useState(false);
  const [titleLabel, setTitleLabel] = useState("Oak Batch Names");
  const navigate = useNavigate();

  const MENU_OPTIONS = [
    { label1: 'Edit ', },
    { label2: 'Delete ', },
    { label3: 'Attendance', },
    { label4: 'Finance', },
  ];

  function ColoredCircle({ value }) {
   // console.log("value: ", value);
    const theme = useTheme();
    const color = value ? theme.palette.success.main : theme.palette.error.main;
    
    return (
      <SvgIcon>
        <circle cx="12" cy="12" r="8" fill={color} />
      </SvgIcon>
    );
  }


  const handleDownRecord = async (event, ) => {
    try {     
      const label = event.currentTarget.textContent;
      selected.forEach((item) => {
        // Burada her seçili öğe için gerekli işlemleri yapın
      });

      if(selected.length > 0){
        switch(label){          
          case 'Edit':
            //console.log("Edit: ", selected);
            //handleOpenRequestDialog(selected[0].email, selected[0].fullname);
            break;
          case 'Delete':
            //console.log("Delete: ", selected);
            //handleConfirmDialogOpen(selected, 1);
            break;
          case 'Attendance':
            const mail = selected[0]?.contact?.mail['mailaddress-1'] || ''; //or default email address
            handleStudentAttendancePageOpen(mail);
            break;
          case 'Finance':          
            //handleConfirmDialogOpen(selected, selectedUserName, selectedFullName, 1);
            break;
          default:
            break;
        };
    }else{          
      setSeverity('error');
      setAlertMessage('Not selected Meeting Record!');
      setMsgOpen(true);
    }
    } catch (error) {
        console.log("handleDownRecord-Error: ", error);
    }
  };

  const handleStudentAttendancePageOpen = async (email) => {
    const reportStudentZoomAttendances = await ReportStudentAttendances(email);    
    const MeetItem = async (index) => {
      if (index < selected.length) {
        navigate(`/dashboard/studentattendances`, { state: { reportStudentZoomAttendances } });
        await MeetItem(index + 1);
      }
    };
    await MeetItem(0);
  }

  const handleAllBatchAttendancePageOpen = async () => {
    const batchName = filterStudentId;
    if(filterRecords.length > 0){
      const getEmails = filterRecords.map((selected, index) => {
        return selected.contact.mail['mailaddress-1'];
      });
      const reportAllBatchZoomAttendances = await ReportStudentAttendances(getEmails);   
      navigate(`/dashboard/studentattendances`, { state: { reportAllBatchZoomAttendances, batchName } });
    }
  };

  const handleSelectionChange = (value) => {
    setSelectedBatch(value);
    setPage(0);
    setfilterStudentId(value);    
  };

  useEffect(() => {
    const fetchBatchNames = async () => {
      const data = await getBatchNames();
      setSelectData(data);
    };
    fetchBatchNames();
  }, []);

  useEffect(() => {
    const getlecturesDatas = async () => {
      try {
          const data = await getRecordedLectures();
          setrecordsNumber(data.length);
          setLectureDatas(data);
      } catch (error) {
          console.error(error);
          setLectureDatas([]);
      }
    };    
    getlecturesDatas();
  }, []);
  
  const [filterRecords, setFilterRecords] = useState();

  useEffect(() => {
    const filteredStudents = applySortFilter(lectureDatas, getComparator(order, orderBy), filterStudentId);
    setrecordsNumber(filteredStudents.length);
    setFilterRecords(filteredStudents);
  }, [lectureDatas, order, orderBy, filterStudentId]);


  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {   
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = lectureDatas.map((n) => n.idzoom_records);
    //  console.log("newSelecteds: ", newSelecteds);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, student) => {
    const selectedIndex = selected.findIndex((item) => item.id === student.id);
    let newSelected = [];  
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, student);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
  
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setfilterStudentId(event.target.value);
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - lectureDatas.length) : 0;
  
  const filteredStudents = applySortFilter(lectureDatas, getComparator(order, orderBy), filterStudentId);

  const isNotFound = !filteredStudents.length && !!filterStudentId;
  return (
    <>    
      <Helmet>
        <title> Recorded Lectures| Oak Academy </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
              Oak Recorded Lectures
            </Typography>
            {`Total Records: ${recordsNumber}`}            
          </div>
        </Stack>
        <Card>
          <StudentListToolbar numSelected={selected.length} filterName={filterStudentId} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <StudentListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={recordsNumber}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>                                              
                {
                  filteredStudents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { idzoom_records, meeting_uuid, meeting_id, start_time, topic, topic_changes, gprocess_result } = row;
                    // const { idzoom_records, account_id, meeting_id, meeting_uuid, topic, start_time, timezone } = row;
                    const selectedStudent = selected.indexOf(idzoom_records) !== -1;
                    const isItemSelected = selected.some(selectedItem => selectedItem?.id === row?.idzoom_records);
                    return (
                      <TableRow hover key={index} tabIndex={-1} role="checkbox" selected={selectedStudent}>
                        <TableCell padding="checkbox">                          
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, { id: idzoom_records })}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {topic}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{start_time}</TableCell>
                        <TableCell align="left">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ColoredCircle value={gprocess_result} />
                          </div>
                        </TableCell>                      
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterStudentId}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, { value: -1, label: 'All' }]}
            component="div"
            count={recordsNumber}            
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem 
          key={MENU_OPTIONS.label3} 
          onClick={(event) => handleDownRecord(event)} 
          sx={{ color: 'secondary.text' }}
        >
          <SvgIcon component={Icon_Attendance} sx={{ mr: 1 }} />
          Attendances
        </MenuItem>
      </Popover>
    </>
  );
}
