import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getCoordinatorsTotalTasks = async () => {
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/students/getcoordinatorstotaltasks`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    const TotalTask = response.data || [];
   // console.log('getCoordinatorsTotalTasks-firstStudentsRecord: ', TotalTask);
    return TotalTask;
   // return 0;
  } catch (error) {
    console.error('Error fetching First Students Records: ', error);
    return [];
  }
};
export default getCoordinatorsTotalTasks;