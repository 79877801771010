// component
import SvgColor from '../../../components/svg-color';
import React from 'react';
import { IconButton } from '@mui/material';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import { Campaign } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';




const getNavConfig = (t) => {
  // const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
  const icon = (srcOrComponent) => {
    if (typeof srcOrComponent === 'string') {
      return <SvgColor src={`/assets/icons/navbar/${srcOrComponent}.svg`} sx={{ width: 1, height: 1 }} />;
    }
    return React.cloneElement(srcOrComponent, { sx: { width: 1, height: 1 } });
  };

  return [
    {
      title: t('Dashboard'),
      path: '/dashboard/app',
      icon: icon('ic_analytics'),
      permission: 'user',
    },
    {
      title: t('Title.NavMenu.Users'),
      icon: icon('ic_user'),
      children: [
        {
          title: 'Users List',
          path: '/dashboard/user',
          icon: icon('ic_userlist'),
        },
        {
          title: 'User Roles',
          path: '/dashboard/userroles',
          icon: icon( <SupervisorAccountIcon /> ),
        },
      ],
    },
    {
      title: t('Title.NavMenu.CoursCoordination'),
      icon: icon('ic_student'),
      children: [
        {
          title: t('Lists'),
          path: '/dashboard/student',
          icon: icon('ic_student2'),
        },
        {
          title: t('Meta Ads'),
          path: '/dashboard/metaadspages',
          icon: icon( <Campaign /> ),
        },
        {
          title: t('JobCenter Workflow'),
          path: '/dashboard/jobcenterworkflow',
          icon: icon('workflow'),
        },
        {
          title: t('Job Center-Assets'),
          path: '/dashboard/assets',
          icon: icon('assets-64'),
        },        
        // {
        //   title: t('Exam Preparation'),
        //   path: '/dashboard/exampreparation',
        //   icon: icon('question'),
        // },
        {
          title: t('Finance'),
          path: '/dashboard/registeredstudents',
          icon: icon('ic_payment'), 
        },        
        // {
        //   title: t('Contracts'),
        //   path: '/dashboard/contracts',
        //   icon: icon('ic_contact'), 
        // },
        // {
        //   title: t('MyFollowing'),
        //   path: '/dashboard/myfollowinginteractions',
        //   icon: icon('ic_transaction'), 
        // },
        // {
        //   title: t('Settings'),
        //   path: '/dashboard/coursecoordinationsettings',
        //   icon: icon( <SettingsIcon /> ), 
        // },
      ],
    },
    // {
    //   title: t('Academic'),
    //   icon: icon('ic_academy'),
    //   children: [
    //     {
    //       title: t('AcademicCalendar'),
    //       path: '/dashboard/academiccalendar',
    //       icon: icon('ic_calendar'),
    //     },
    //     {
    //       title: t('OnlineLectures'),
    //       path: '/dashboard/recordedlectures',
    //       icon: icon('ic_lecture'),
    //     },
    //     {
    //       title: t('LecturesPlanning'),
    //       path: '/dashboard/lecturesplanning',
    //       icon: icon('ic_lecturesplanning'),
    //     },
    //     {
    //       title: t('AcademicSettings'),
    //       path: '/dashboard/syllabussettings',
    //       icon: icon( <SettingsIcon /> ),
    //     },
    //   ],
    // },
  /*  {
      title: 'Google GSuite',
      // path: '/dashboard/google',
      icon: icon('google-icon'),
      children: [
        {
          title: 'Google Drive Files',
          path: '/dashboard/google',
          icon: icon('google-icon'),
        },
        // {
        //   title: 'Google Users',
        //   //path: '/dashboard/zoomUsers',
        //   icon: icon('google-icon'),
        // },
        // {
        //   title: 'Google X',
        //   // path: '/dashboard/zoomMeetings',
        //   icon: icon('google-icon'),
        // },
        // {
        //   title: 'Google Y',
        //   // path: '/dashboard/zoomRegistrants',
        //   icon: icon('google-icon'),
        // },
      ],
    },*/
    // {
    //   title: 'Zoom',
    //   icon: icon('zoom-icon1'),
    //   children: [
    //     {
    //       title: 'zoom Downloader',
    //       path: '/dashboard/zoomDownloader',
    //       icon: icon('zoom-icon1'),
    //     },
    //     {
    //       title: 'zoom Users',
    //       path: '/dashboard/zoomUsers',
    //       icon: icon('zoom-icon1'),
    //     },
    //     {
    //       title: 'zoom Mettings',
    //       path: '/dashboard/zoomMeetings',
    //       icon: icon('zoom-icon1'),
    //     },
    //     {
    //       title: 'zoom Registrans',
    //       path: '/dashboard/zoomRegistrants',
    //       icon: icon('zoom-icon1'),
    //     },
    //     {
    //       title: 'meeting Participants',
    //       path: '/dashboard/meetingParticipants',
    //       icon: icon('zoom-icon1'),
    //     },
    //   ],
    // },
    
    // {
    //   title: 'TryHackMe',
    //   path: '/dashboard/tryhackme',
    //   icon: icon('tryhackme'),
    //   children: [
    //     {
    //       title: 'TryHackme Users',
    //       path: '/dashboard/tryhackme',
    //       icon: icon('tryhackme'),
    //     },
    //     {
    //       title: 'TrayHackMe X',
    //       path: '/dashboard/zoomMeetings',
    //       icon: icon('tryhackme'),
    //     },
    //     {
    //       title: 'TrayHacMe Y',
    //       path: '/dashboard/zoomRegistrants',
    //       icon: icon('tryhackme'),
    //     },
    //   ],
    // },
    {
      title: 'Oak-LMS',
      //path: '/dashboard/lms',
      icon: icon('lmsmain'),
      children: [
        {
          title: 'Course Lists',
          path: '/dashboard/lmscourses',
          icon: icon('lms'),
        },
        {
          title: 'All Traniee Lists',
          path: '/dashboard/lmstraniee',
          icon: icon('lmsmain'),
        },
        {
          title: 'LMS-Hybrid Traniee',
          path: '/dashboard/lms-hybrid',
          icon: icon('lms'),
        },
      ],
    },
    {
      title: t('Oak-Lab'),
      path: '/dashboard/lab',
      icon: icon('assets'),
      children: [
        {
          title: t('Lab1'),
          path: '/dashboard/lab',
          icon: icon('assets-64'),
        },
        {
          title: t('Lab2'),
          path: '/dashboard/assets',
          icon: icon('assets-64'),
        },
      ],
    },
    {
      title: t('IT-Assets'),
      path: '/dashboard/assets',
      icon: icon('assets'),
      children: [
        {
          title: t('DigitalAssets'),
          path: '/dashboard/assets',
          icon: icon('assets-64'),
        },
        {
          title: t('IT-Assets.Infrust'),
          path: '/dashboard/assets',
          icon: icon('assets-64'),
        },
      ],
    },
    {
      title: 'E-mail& Approval',
      path: '/dashboard/mailsetting',
      icon: icon('emails'),
      children: [
        {
          title: t('MailConfigurations'),
          path: '/dashboard/mail',
          icon: icon('email-settings'),
        },
        {
          title: t('PermissionApproval'),
          path: '/dashboard/aproval',
          icon: icon('request-approval'),
        },
      ],
    },
  ];
};
export default getNavConfig;







