import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getStudent = async (studentId) => {
  try {
      const token = await getToken();
     // console.log('getStudent');
      const response = await axios.get(`${BaseUrl}/students/getstudent/`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
            idstudent: studentId
        }
      });
      const info = response.data[0] || [];
    //  console.log('response: ', info);
      return info;
  } catch (error) {
      return [];
  }
};

const getStudents = async () => {
  try {
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/students/getallstudents`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const info = response.data || [];
      return response.data;
  } catch (error) {
      return [];
  }
};

const getNoS = async () => { //NoS:Numbers Of Student
    try {
        const token = await getToken();
        const response = await axios.get(`${BaseUrl}/students/getnos`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const info = response.data || [];
        const studentNumbers = info.map((item, index) => {
            return {
                coursename: item.coursename || '',
                numbers: item.numbers || '',
                index: index
            };
        });
        return studentNumbers;
    } catch (error) {
        return [];
    }
};

export { getStudent, getStudents, getNoS };

