import React from 'react';
import { useLoading } from '../../utils/contexts/LoadingContext';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
// import { sentenceCase } from 'change-case';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DynamicSelect from '../../components/select/dynamicSelect';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  TextField,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Menu, Snackbar, Alert,
  InputLabel, FormControl, Select, Box,SvgIcon, useTheme
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveIcon from '@mui/icons-material/Remove';

// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import { ReactComponent as Icon_Attendance } from '../../assets/icons/attendance.svg';
import { ReactComponent as Icon_Payment } from '../../assets/icons/icon_payment.svg';
import Scrollbar from '../../components/scrollbar'
// sections
import { JobCenterWorkflowHead, JobCenterWorkflowToolbar } from '../../sections/@dashboard/student';
import getJobCenterData from '../../_data/student/getJobCenterData';
import ReportStudentAttendances from '../../_data/student/getReportParticipant';
import DataDisplay from './CustomDataDisplay';
import { getBatchNames } from '../../_data/batchs/getBatchNames';
import { deleteEnExams, deleteItExams } from '../../_data/coursecoordination/deleteExams';
import { useTranslation } from 'react-i18next'; //Multi Language
import formatDateTime from '../../utils/formatDateTime';
import JobCenterDetailsDialog from '../../components/course-coortination/jobcenter-details-dialog';
import FileViewerPanel from '../../components/course-coortination/fileViewerPanel';      
import { Message } from '@mui/icons-material';
import FileUploadPanel from '../../components/course-coortination/uploadFile';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (!Array.isArray(array)) {
   //console.error('applySortFilter: Input is not found.', array);
    return [];
  };
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(stabilizedThis?.map((el) => el[0]), (student) => {
      return Object.values(student).some((value) => 
        String(value).toLowerCase().includes(query.toLowerCase())
      );
    });
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function StudentPage() {
  const { showLoading, hideLoading } = useLoading();
  const { t } = useTranslation();
// -------------------------COLUMN TITLES-------------------------------------------------------

const TABLE_HEAD = [
  { id: 'namesurname', label: t('NameSurname'), alignRight: false },
  { id: 'updatesat', label: t('LastChange'), alignRight: false },
  { id: 'offer', label: t('Offer'), alignRight: false },
  { id: 'certificatelang', label: t('RegisteredLanguage'), alignRight: false },
  { id: 'initializationState', label: t('Initialization'), alignRight: false },
  { id: 'it-en-test', label: '   En&IT Test', alignRight: false },
  { id: 'langcertificated', label: t('CertificateLang'), alignRight: false },
  { id: 'criminaleport', label: t('CriminalReport'), alignRight: false },
  { id: 'coursetermscontract', label: 'Course Terms', alignRight: false },
  { id: 'courseprivacycontract', label: 'Privacy Contract', alignRight: false },
  { id: 'participantcontract', label: 'Participant Contract', alignRight: false },
  { id: 'bildungsgutschein', label: t('EducationVoucher'), alignRight: false },  
  { id: 'vouchersubmit', label: t('VoucherSubmit'), alignRight: false },
  { id: 'devicecontract', label: t('DeviceContract'), alignRight: false },
  { id: '' },
];  

  const [open, setOpen] = useState(null);
  const [filterCoordinatorId, setfilterCoordinatorId] = useState('');
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('start_time');

  const [filterStudentId, setfilterStudentId] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [jobCenterDatas, setJobCenterDatas] = useState([getJobCenterData]);
  
  const [recordsNumber, setrecordsNumber] = useState(0);
  const [openAlertMsg, setOpenAlertMsg] = useState(false); //Notification Show/hide
  const [severity, setSeverity] = useState('success'); //Notification Show/hide  
  const [alertMessage, setAlertMessage] = useState('');
  const [selectData, setSelectData] = useState();
  const [selectedBatch, setSelectedBatch] = useState(""); //from
  const [msgOpen, setMsgOpen] = useState(false);
  const [titleLabel, setTitleLabel] = useState("Oak Batch Names");
  const navigate = useNavigate();
  //const [isUploadPanelOpen, setIsUploadPanelOpen] = useState(true); // Panel görünürlüğünü kontrol eden state

  const MENU_OPTIONS = [
    { label1: 'VoucherSubmit ', },
    { label2: 'Delete ', },
    { label3: 'Attendance', },
    { label4: 'Finance', },
  ];

  const handleUploadComplete = async (severity, message) => {
    setSeverity(severity);
    setAlertMessage(message);
    setMsgOpen(true);
    if (severity === 'success') {
      setShowUploadPanel(false);
      await getJobCenterDatas();
    }
  };

  const handleCancelUpload = () => {
    setShowUploadPanel(false); // İptal sonrası panel kapatma
  };

  function ColoredCircle({ value }) {
    const theme = useTheme();
    const color = value ? theme.palette.success.main : theme.palette.error.main;    
    return (
      <SvgIcon>
        <circle cx="12" cy="12" r="8" fill={color} />
      </SvgIcon>
    );
  }

  function ColoredCircleButton({ value, onClick }) {
    return (
      <IconButton onClick={onClick} sx={{ padding: 0 }}>
        <div
          style={{
            width: value ? '19px' : '15px',
            height: value ? '19px' : '15px',
            borderRadius: '50%',
            backgroundColor: value ? 'green' : 'red',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          {/* İstenirse, içine bir ikon veya metin ekleyebiliriz */}
        </div>
      </IconButton>
    );
  };

  function ColoredCheckButton({ value, onClick }) {
    return (
      <IconButton onClick={onClick} sx={{ padding: 0 }}>
        {value ? (
          <CheckIcon style={{ fontSize: '19px', color: 'blue' }} />
        ): ( 
          <RemoveIcon style={{ fontSize: '14px', color: '#ff1744' }} />
        )}
      </IconButton>
    );
  };

  const handleCancelEnExam = async () => {
    const examEnLectureId = 7;
    try {
      if(selected.length > 0){
        const CancelExam = await deleteEnExams(selected[0]?.studentid, examEnLectureId);
        if(CancelExam.message === 'Exam deleted successfully.' && CancelExam.affectedRows === 1){
        setSeverity('success');
        setAlertMessage('The existing English Exam record was deleted successfully.');
        setMsgOpen(true);
        }else if (CancelExam.message === 'Exam not found.'){
          setSeverity('error');
          setAlertMessage('Exam not found.');
          setMsgOpen(true);
        }
      }else{
        setSeverity('error');
        setAlertMessage('Not selected Meeting Record!');
        setMsgOpen(true);
      }
    } catch (error) {
      console.log('handleCancelEnExam')
    }      
  };

  const handleCancelItExam = async () => {
    const examItLectureId = 8;
    try {
      if(selected.length > 0){
        const CancelExam = await deleteItExams(selected[0]?.studentid, examItLectureId);
        if(CancelExam.message === 'Exam deleted successfully.' && CancelExam.affectedRows === 1){
          setSeverity('success');
          setAlertMessage('The existing It Exam record was deleted successfully.');
          setMsgOpen(true);
        }else if (CancelExam.message === 'Exam not found.'){
          setSeverity('error');
          setAlertMessage('Exam not found.');
          setMsgOpen(true);
        }
      }else{
        setSeverity('error');
        setAlertMessage('Not selected Meeting Record!');
        setMsgOpen(true);
      }
    } catch (error) {
      console.log('handleCancelItExam')
    }      
  };

  const [docType, setDocType] = useState(false);
  const [showUploadPanel, setShowUploadPanel] = useState(false);

  const getVoucherSubmissionFile = async(studentid, type) =>{
    setDocType(type);
    setShowUploadPanel(true);     
  };
  
  const handleOfferSubmissionFile = async(studentid, type) =>{
    setDocType(type);
    setShowUploadPanel(true);       
  };

  const handleDownRecord = async (event, ) => {
    try {     
      const label = event.currentTarget.textContent;
      if(selected.length > 0){
        switch(label){           
          case t('VoucherSubmit'):
            getVoucherSubmissionFile(selected[0].studentid, 'Upload Your Voucher File!'); // TODO: CONST olarak veya ENUM olarak tanimlanmaliyiz!
            break;
          case t('OfferSubmit'):
            handleOfferSubmissionFile(selected[0].studentid, 'Upload Your Offer File!')
            break;
          default:
            break;
        };
    }else{          
      setSeverity('error');
      setAlertMessage('Not selected Meeting Record!');
      setMsgOpen(true);
    }
    } catch (error) {
        console.log("handleDownRecord-Error: ", error);
    }
  };
  const [selectedStudentData, setSelectedStudentData] = useState(null);
  const studentDetailsDialogRef = useRef();
  const handleStudentDetails = (getSelected) => {
    try { 
      setSelectedStudentData(getSelected[0]);
      if (studentDetailsDialogRef.current) {
        studentDetailsDialogRef.current.handleOpen();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }; 

  const handleStudentAttendancePageOpen = async (email) => {
    const reportStudentZoomAttendances = await ReportStudentAttendances(email);    
    const MeetItem = async (index) => {
      if (index < selected.length) {
        navigate(`/dashboard/studentattendances`, { state: { reportStudentZoomAttendances } });
        await MeetItem(index + 1);
      }
    };
    await MeetItem(0);
  }

  const handleSelectionChange = (value) => {
    setSelectedBatch(value);
    setPage(0);
    setfilterStudentId(value);    
  };

  const getJobCenterDatas = async () => {
    try {
      showLoading();
      const data = await getJobCenterData();
      setrecordsNumber(data.length);
      setJobCenterDatas(data);
    } catch (error) {
      console.error(error);
      setJobCenterDatas([]);
    }finally{
      hideLoading();
    }
  };   

  useEffect(() => {     
    getJobCenterDatas();
  }, []);
  
  const [filterRecords, setFilterRecords] = useState();

  useEffect(() => {
    const filteredStudents = applySortFilter(jobCenterDatas, getComparator(order, orderBy), filterStudentId);
    setrecordsNumber(filteredStudents.length);
    setFilterRecords(filteredStudents);
  }, [jobCenterDatas, order, orderBy, filterStudentId]);


  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  // const handleCloseMenu = () => {
  //   setOpen(null);
  //   setSelected([]);
  // };

  const handleRequestSort = (event, property) => {   
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = jobCenterDatas?.map((n) => n.idzoom_records);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleCloseStudentMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  const handleClick = (event, student) => {
    //console.log('handleClick-student: ', student);
    const selectedIndex = selected.findIndex((item) => item.id === student.id);
    let newSelected = [];  
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, student);      
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    //console.log('handleClick-newSelected: ', newSelected);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setfilterStudentId(event.target.value);
  };

  const handleFilterCoordinatorByName = (newValue) => {
    if (!newValue) {
      setPage(0);
      setfilterCoordinatorId('');
      return;
    }
    setPage(0);
    setfilterCoordinatorId(newValue.id);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - jobCenterDatas.length) : 0;
  
  const filteredStudents = applySortFilter(jobCenterDatas, getComparator(order, orderBy), filterStudentId);

  const isNotFound = !filteredStudents.length && !!filterStudentId;

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  const [selectedFileType, setSelectedFileType] = useState('');
  const [fileData, setFileData] = useState(null);

  const handleFileCellClick = async (fileBlob, fileType) => {
    try {
      if (fileBlob?.type === 'Buffer' && Array.isArray(fileBlob.data)) {
        // change Buffer type to Uint8Array
        const byteArray = new Uint8Array(fileBlob.data);
        fileBlob = new Blob([byteArray], { type: fileType });
      } else if (!(fileBlob instanceof Blob)) {
        return;
      }
      const FileBase64 = await blobToBase64(fileBlob);
      setFileData(FileBase64); 
      setSelectedFileType(fileType);
    } catch (error) {
      console.error('Error converting blob to base64:', error);
    }
  };

  const handleClosePanel = () => {
    setFileData(null);
    setSelectedFileType('');
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl(null);
    setOpen(null);
  //   setSelected([]);
  };

  // const handleSubMenuClick = (event) => {
  //   event.stopPropagation(); // Üst menünün kapanmasını engeller
  //   event.preventDefault(); // Varsayılan davranışı engeller
  //   setSubMenuAnchorEl(event.currentTarget);
  // };
  const replaceDateTime = (dateString) => {
    if (!dateString || typeof dateString !== 'string') {
      return '';
    };
    // Cut T ve Z char
    const formattedDate = dateString.replace('T', ' ').replace('Z', '').slice(0, 19);
    return formattedDate;
  };

  return (
    <>    
      <Helmet>
        <title> Job Center Workflow| Oak Academy </title>
      </Helmet>
      <Container maxWidth={false} sx={{ marginLeft: 0, maxWidth: 'none' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
              {t('OakJobCenterWorkflow')}
            </Typography>
            {t('TotalRecords')} {`: ${recordsNumber}`}            
          </div>
          <JobCenterDetailsDialog ref={studentDetailsDialogRef} selectedStudent={selectedStudentData}/>
        </Stack>
        <Card>
          <JobCenterWorkflowToolbar 
            numSelected={selected.length} 
            filterName={filterStudentId} 
            onFilterName={handleFilterByName} 
            onCoordinatorName={handleFilterCoordinatorByName} 
          />
          {showUploadPanel &&<FileUploadPanel studentid={selected[0]?.studentid}  onUploadComplete={handleUploadComplete} type ={docType} onCancel={handleCancelUpload} />}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <JobCenterWorkflowHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={recordsNumber}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>                                              
                  {
                    filteredStudents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { idstudent_jobcenter , name, surname, langlevelname, acknowledged, studentid, updatedat, coursetermscontract, coursetermssigndate,
                    courseprivacycontract, courseprivacysigndate, participantcontract, participantsigndate,
                    certificatelang, certificatefile, certificatetype, criminalreportfile, criminalreporttype, offerfile, offerfiletype, vouchessubmissionfile, 
                    vouchessubmissionfiletype, bildungsgutscheinfile, bildungsgutscheinfiletype, devicecontract, devicesigndate, entest, ittest} = row;

                    const selectedStudent = selected.indexOf(idstudent_jobcenter) !== -1;
                    const isItemSelected = selected.some(selectedItem => selectedItem?.id === row?.idstudent_jobcenter);
                    return (
                      <TableRow hover key={index} tabIndex={-1} role="checkbox" selected={selectedStudent}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, { id: idstudent_jobcenter, studentid: studentid })}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {name} {surname}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="center">{formatDateTime(updatedat)}</TableCell>
                        <TableCell align="center" sx = {{padding: 0}}>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <ColoredCircleButton value={!!offerfile} onClick={() => handleFileCellClick(offerfile, offerfiletype)} /> 
                          </div>
                        </TableCell> 
                        <TableCell align="center">{certificatelang}</TableCell>
                        <TableCell align="center" 
                          sx={ (acknowledged === 1) ? {color: 'green', fontWeight: 'bold'} : {color: 'red', fontWeight: 'normal'}}>
                          {acknowledged == 1 ? t('Started') : ('Not Started')}
                        </TableCell>
                        <TableCell align="center">
                          <span
                            style={{
                              color: entest >= 70 ? 'blue' : 'red',
                              fontWeight: entest >= 70 ? 'normal' : 'normal',
                            }}
                          >
                            {entest}
                          </span>
                          {" | "}
                          <span
                            style={{
                              color: ittest >= 70 ? 'blue' : 'red',
                              fontWeight: ittest >= 70 ? 'normal' : 'normal',
                            }}
                          >
                            {ittest}
                          </span>
                        </TableCell>
                        <TableCell align="center" sx = {{padding: 0}}>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <ColoredCircleButton value={!!certificatefile} onClick={() => handleFileCellClick(certificatefile, certificatetype)} />                            
                          </div>
                        </TableCell>                        
                        <TableCell align="center" sx = {{padding: 0}}>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <ColoredCircleButton value={!!criminalreportfile} onClick={() => handleFileCellClick(criminalreportfile, criminalreporttype)} /> 
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <ColoredCheckButton value={!!coursetermscontract} /> 
                        </TableCell>
                        <TableCell align="center">
                          <ColoredCheckButton value={!!courseprivacycontract} /> 
                        </TableCell>
                        <TableCell align="center">
                          <ColoredCheckButton value={!!participantcontract} /> 
                        </TableCell>
                        <TableCell align="center">
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <ColoredCircleButton value={!!bildungsgutscheinfile} onClick={() => handleFileCellClick(bildungsgutscheinfile, bildungsgutscheinfiletype)} /> 
                          </div>
                        </TableCell>                        
                        <TableCell align="center">
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <ColoredCircleButton value={!!vouchessubmissionfile} onClick={() => handleFileCellClick(vouchessubmissionfile, vouchessubmissionfiletype)} /> 
                          </div>
                        </TableCell>                        
                        <TableCell align="center">
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <ColoredCheckButton value={!!devicecontract} /> 
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterStudentId}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar> 
          {fileData && <FileViewerPanel fileData={fileData} fileType={selectedFileType} onClose={handleClosePanel} />}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, { value: -1, label: 'All' }]}
            component="div"
            count={recordsNumber}            
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>            


      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem key="voucher" onClick={handleDownRecord} sx={{ color: 'secondary.main', minWidth: 400 }}>
          <Iconify icon={'eva:more-vertical-fill'} sx={{ mr: 2 }} />
          {t('VoucherSubmit')}
        </MenuItem>
        <MenuItem key="cancel-exam" onClick={handleSubMenuClick} sx={{ color: 'secondary.main', minWidth: 400 }}>
          <Iconify icon={'eva:more-vertical-fill'} sx={{ mr: 2 }} />
          {t('CancelExam')}
          <Menu
            anchorEl={subMenuAnchorEl}
            open={Boolean(subMenuAnchorEl)}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem onClick={() => alert('IT test canceled')}>IT test</MenuItem>
            <MenuItem onClick={() => alert('En test canceled')}>En test</MenuItem>
          </Menu>
        </MenuItem>
      </Popover> */}

      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={5000} 
        onClose={handleCloseStudentMessage}
      >
        <Alert severity={severity} onClose={handleCloseStudentMessage} 
        sx={{ 
          width: 480, minWidth: 350, minheight: 100, height: 130, fontSize: 14, 
          fontStyle: 'normal',
          whiteSpace: 'pre-line' //  Burada \n yerine satir atlamak icin whiteSpace stilini uyguluyoruz.
        }} >
          <Typography style={{ whiteSpace: 'pre-line' }}>
            {alertMessage}
          </Typography>
        </Alert>
      </Snackbar>



       <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 220,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
       <MenuItem key={MENU_OPTIONS.label1} onClick={handleDownRecord} sx={{ color: 'secondary.main' , minWidth: 450}}>
          <Iconify icon={'eva:more-vertical'} sx={{ mr: 2 }} />
          {t('VoucherSubmit')}
        </MenuItem>
       <MenuItem key={MENU_OPTIONS.label1} onClick={handleDownRecord} sx={{ color: 'secondary.main' , minWidth: 450}}>
          <Iconify icon={'eva:more-vertical'} sx={{ mr: 2 }} />
          {t('OfferSubmit')}
        </MenuItem>
        <MenuItem key={MENU_OPTIONS.label1} onClick={handleCancelEnExam} sx={{ color: 'secondary.main' , minWidth: 450}}>
          <Iconify icon={'eva:more-vertical'} sx={{ mr: 2 }} />
          {t('CancelEnExam')}
        </MenuItem>
        <MenuItem onClick={handleCancelItExam} sx={{ color: 'secondary.main', minWidth: 450 }}>
        <Iconify icon={'eva:more-vertical'} sx={{ mr: 2 }} />
        {t('CancelITExam')}
        </MenuItem>
      </Popover>       
    </> 
  );
}
