import React, { forwardRef } from 'react';
import MaskedInput from 'react-text-mask';
import TextField from '@mui/material/TextField'; 

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(maskRef) => {
        if (maskRef) {
          ref(maskRef.inputElement);
          if (typeof inputRef === 'function') {
            inputRef(maskRef.inputElement);
          } else if (inputRef) {
            inputRef.current = maskRef.inputElement;
          }
        }
      }}
      mask={['+', /[1-9]/, /\d/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
      guide={true} // Yer tutucu karakterleri her zaman göster
    />
  );
});

const PhoneInput = ({ value, onChange, returnEvent = false }) => {
  const handleValueChange = (event) => {
    onChange(event);
  };
  // const handleValueChange = (event) => {
  //   onChange(event.target.value.replace(/^\+/, ''));
  // };//org. degisiklik yatim ama mail approval alaninda new create de calismadi ve kapattim takip etmeliyim diger kullanilarda da sorunsuz calisir ise silerim. 
  // const handleValueChange = (event) => {
  //   if (returnEvent) {
  //     onChange(event);
  //   } else {
  //     onChange(event.target.value.replace(/^\+/, ''));
  //   }
  // };

  // return (
  //   <TextField
  //     value={value}
  //     onChange={handleValueChange}
  //     name="phone"
  //     label="Phone"
  //     variant="outlined"
  //     fullWidth
  //     InputProps={{
  //       inputComponent: TextMaskCustom,
  //     }}
  //   />
  // );
  return (
    <TextField
      value={value}
      onChange={handleValueChange}
      name="phone"
      label="Phone"
      variant="outlined"
      fullWidth
      InputProps={{
        inputComponent: TextMaskCustom,
        // İlk tıklamada imlecin satır başına gitmesini sağlar
        inputProps: {
          onClick: (e) => {
            if (e.target.value === '+   (   )    -    ') {
              e.target.setSelectionRange(1, 1);
            }
          },
        },
      }}
    />
  );
};

export default PhoneInput;

// const PhoneInput = ({ value, onChange }) => {
//   const handleValueChange = (event) => {
//     // '+' işaretini kaldır ve geri kalan değeri onChange'e gönder
//     onChange(event.target.value.replace(/^\+/, ''));
//   };
//   return (
//     <TextField
//       value={value}
//       onChange={onChange}
//       name="phone"
//       label="Phone"
//       variant="outlined"
//       fullWidth
//       InputProps={{
//         inputComponent: MaskedInput, // MaskedInput'ı burada inputComponent olarak kullanıyoruz
//         inputProps: {
//           // mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
//           // mask: ['(', /[+]/, /\d/, /\d/, ')', '-', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
//           mask: ['+', /[1-9]/, /\d/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
//           placeholder: 'Enter a phone number',
//           showMask: true, // Show first '+' point
//           guide: false,
//         },
//       }}
//     />
//   );
// }

//export default PhoneInput;
