import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const createmailuser= async (formData) => {
    try {
      const token = await getToken();
      //console.log("setPasswordEmailUser: email : ", formData.domain_name, formData.email, formData.password, formData.fullname, formData.department); 
      const response = await axios.post(`${BaseUrl}/email/users/createmailuser`, {
        domain_name: formData.domain_name, 
        email: formData.email, 
        password: formData.password,
        fullname: formData.fullname,
        department: formData.department
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
    //   console.log("response.data: ", response.data);
        return response.data;
    } catch (error) {
        console.error("Insert error: ", error);
        return [];
    }
};

export default createmailuser;
