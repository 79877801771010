import axios from 'axios';
import getToken from '../../api/getToken';
import config from '../../../config';
const { BaseUrl } = config;

const getVms= async () => {
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/lab1/getvms`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const vmsList = response.data.data.map((item, index) => {
    return {
        vmid: item.vmid || '',
        name: item.name || '',
        status: item.status || '',
        uptime: item.uptime || '',
        mem: item.mem || '',
        maxmem: item.maxmem || '',
        cpus: item.cpus || '',
        cpu: item.cpu || '',
        maxdisk: item.maxdisk || '',
        pid: item.pid || '',
        netin: item.netin || '',
        netout: item.netout || '',
        diskread: item.diskread || '',
        diskwrite: item.diskwrite || '',
        disk: item.disk || '',
    };
    });
    console.log("getVms.vmsList: ", vmsList);
    return vmsList;
  } catch (error) {
    console.log("Error[getVms]: ", error);
    return [];
  }
};

const getNodes= async () => {
    try {
        const token = await getToken();
        const response = await axios.get(`${BaseUrl}/lab1/getnodes`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        //console.log("response.data: ", response.data.data[0]);
       return response.data.data[0];
    } catch (error) {
        console.log("error: ", error);
        return [];
    }
};
export default { getVms, getNodes };

/* 
nodes:
{
    "data": [
        {
            "maxcpu": 36,
            "disk": 65541779456,
            "id": "node/lab",
            "ssl_fingerprint": "2D:2B:5C:9A:97:27:1A:5B:DC:96:2E:DF:D7:42:A3:CD:50:5F:98:72:78:87:16:47:92:90:84:7E:E4:63:54:8B",
            "cpu": 0.066566900937976,
            "mem": 249983954944,
            "node": "lab",
            "maxmem": 337590575104,
            "maxdisk": 100861726720,
            "level": "",
            "uptime": 5115230,
            "type": "node",
            "status": "online"
        }
    ]
}
maxcpu, disk, id, ssl_fingerprint, cpu, mem, node, maxmem, maxdisk, level, uptime, type, statu


vms:
"netin": 16411232991,
"status": "running",
"mem": 2986364918,
"uptime": 5149580,
"diskread": 0,
"netout": 1751411016,
"disk": 0,
"name": "Win10-HR2",
"pid": 51164,
"diskwrite": 0,
"maxmem": 4294967296,
"maxdisk": 37580963840,
"cpu": 0,
"cpus": 2,
"vmid": 601
*/