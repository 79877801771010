import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getContactStates= async () => {
  try {  
    const token = await getToken();      
    const response = await axios.get(`${BaseUrl}/students/getcontactstates`,{
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const info = response.data || [];
    const ContactStateList = info.map((item, index) => {
      return { 
        statename: item.statename || '',
        idcontactstate: item.idstudent_contactstate || '',
        description: item.description || '',
      };
     // console.log("getContactStates: ", ContactStateList);

    });
    return ContactStateList;
  } catch (error) {
        console.log("getContactStates Error: ", error);
  }
};

export { getContactStates };
