import React, { useState, useRef, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, 
InputLabel, FormControl, Alert, Snackbar, FormControlLabel, RadioGroup, Radio, Box,Typography,
FormGroup, Checkbox,Accordion, AccordionSummary, AccordionDetails, Paper, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; 
import Iconify from '../iconify';
import InputAdornment from '@mui/material/InputAdornment';
import creatstudent from '../../_data/student/createstudent';
import { useTranslation } from 'react-i18next'; //Multi Language
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import PhoneInput from './PhoneInput';
import getSession from '../../utils/getSession';
import style from '../../theme/blink.css';
import { getActiveBatches } from '../../_data/batchs/getActiveBatches';

export default function CreateCourseDialog({ onStudentAdded }) {
  const account = getSession();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',    
    batchname: '',
    idbatches: '' ,
    contact: {city: "", mail: "", phone: "", address: "", country: ""},
    memberships: {Quizizz: false, TryHackMe: false, DigitalCampus: false, DiscordUserName: ""},
    resourcecode: '',
    interninfo: '',
    status: '',
    description: '',
    bycreated: account.user[0].iduser
  }); 
  const [newResource, setNewResource] = useState("");
  //const CourseNames = ['BATCH-7', 'BATCH-8', 'BATCH-9']; //TODO: Batch tablosundan dynamic almalı tablodaki isactive=1 olanlar gösterilmeli  
  const Status = [{key:4, value:'Prospective Student'}, 
  {key:1, value:'Registered Student'}, 
  {key:2, value:'Intern Student'}, {key:3, value:'Timed Access Granted'}, 
  {key:0, value:'Relationship Ended'}]; //TODO: Batch tablosundan dynamic almalı tablodaki isactive=1 olanlar gösterilmeli  
  const handleMembershipCheckboxChange = (event) => {
    setFormData({
      ...formData,
      memberships: {
        ...formData.memberships,
        [event.target.name]: event.target.checked
      }
    });
  };

  const [courseNames, setCourseNames] = useState([])
  useEffect(() => {
    const fetchActiveBatches = async () => {
      const data = await getActiveBatches();
      setCourseNames(data); //
    };
    fetchActiveBatches();
  }, []);



  const handleMembershipTextChange = (event) => {
    setFormData({
      ...formData,
      memberships: {
        ...formData.memberships,
        [event.target.name]: event.target.value
      }
    });
  };

  const copyToClipboard = async (text) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
        showMessage('Create e-Mail User & Password copied.');
      } else {
        // Eski tarayıcılar için alternatif yöntem
        let textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        showMessage('Create e-Mail User & Password copied.');
      }
    } catch (err) {
      console.error('Could not copy password to clipboard!', err);
    }
  };

  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success'); // or 'error'
  const [alertMessage, setAlertMessage] = useState('');
  const showMessage = (message, severityType = 'success') => {
    setAlertMessage(message);
    setSeverity(severityType);
    setMsgOpen(true);
  };
  const handleCloseStudentMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeStatus = (event) => {
    const { name, value } = event.target;
   // console.log("status value: ", value);
    setFormData({ ...formData, status: value });
  };

  const handleChangeCourseName = (event) => {
    const { name, value } = event.target;
  //  console.log("handleChangeCourseName: ", " name: ",name, " value: ", value);
    setFormData({ ...formData, idbatches: value, batchname: name });
  };

  const handleChange = (event) => {
    try {
    //  console.log("handleChange ilk satır.")
      const { name, value } = event.target;
   //   console.log("name, value: ", name, value);
      if(name === 'contact.mail.mailaddress1'){
        setFormData({ ...formData, email: value });
      }
      const nameParts = name.split('.'); // Nokta ile ayrılmış yolu parçalara ayır
      setFormData((prevFormData) => {
        let updatedValue = { ...prevFormData }; // İlk kopyayı oluştur
        nameParts.reduce((acc, key, index) => {
          if (index === nameParts.length - 1) {
            // Eğer son parçadaysak değeri güncelle
            acc[key] = value;
          } else {
            // Eğer son parça değilse, bir sonraki parçaya geç
            acc[key] = acc[key] || {}; // Eğer bir sonraki anahtar yoksa, yeni bir nesne oluştur
          }
          return acc[key];
        }, updatedValue);
        return updatedValue; // Güncellenmiş değeri döndür
      });
    // }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleChangeResource = (event) => {
    setFormData({ ...formData, resourcecode: event.target.value });
  };

  const nameRef = useRef(null);
  const surnameRef = useRef(null);
  const resourcecodeRef = useRef(null);
  const statusRef = useRef(null);
  const batcheRef = useRef(null);
  const mailRef = useRef(null);
  const [formErrors, setFormErrors] = useState({});
  const [firstErrorField, setFirstErrorField] = useState(null);

  const validateForm = () => {
    let errors = {};
    let firstError = null;
    //console.log("formData.status: ", formData.status === 4 ? `Pospective Student`: `Sorun var ikaz et.`); //4 = Pospective Student
    if (!formData.name) {
      errors.name = true;
      firstError = firstError || 'name';
      showMessage("Fill in (name) the required fields! ", 'warning');
    }
    if (!formData.surname) {
      errors.surname = true;
      firstError = firstError || 'surname';
      showMessage("Fill in (surname) the required fields! ", 'warning');
    }
    if (!formData.resourcecode && formData.status !== 4 ) {
      errors.resourcecode = true;
      firstError = firstError || 'resourcecode';
      showMessage("Fill in (recourse) the required fields! ", 'warning');
    }
    if (!formData.status) {
      errors.status = true;
      firstError = firstError || 'status';
      showMessage("Fill in (status) the required fields! ", 'warning');
    }
    if (!formData.idbatches && formData.status !== 4) {
      errors.idbatches = true;
      firstError = firstError || 'coursename';
      showMessage("Fill in (course name) the required fields! ", 'warning');
    }
    if (!formData.email) {
      errors.email = true;
      firstError = firstError || 'email';
      showMessage("Fill in (contac->mail) the required fields! ", 'warning');
    }
    setTimeout(() => setFormErrors(false), 3000);
    setFormErrors(errors);
    setFirstErrorField(firstError);
    
    if(firstError) {
      // İlk hata alanına odaklan
      const refs = {name: nameRef, surname: surnameRef, resourcecode: resourcecodeRef, status: statusRef, coursename: batcheRef, email: mailRef};
      refs[firstError].current?.focus();
    }
    return Object.keys(errors).length === 0;
  };

  const updatedFormData = {
    ...formData,
  };

  const handleSubmit = async() => {    
    setFormData(updatedFormData);
    const isValid = validateForm();
    if (!isValid) return;
    //console.log("395-creatstudent(formData): ", formData);
    const result = await creatstudent(formData);
    if(result.affectedRows === 1 && result.message === "New Student created successfully."){
      showMessage(t('NewStudentCreateSuccess'), 'success');
      onStudentAdded();
      handleClose();
    } else {
      //console.log("result.message.............");
      showMessage(result.message, 'error');
    //  console.log("result: ", result);
    }    
  };

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj) || '';
  };

  const [contentEmailError, setContentEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');
  const [contentFullnameError, setContentFullnameError] = useState(false); 
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const handleBlur = (e) => {
    const isValid = emailRegex.test(e.target.value);
    if (!isValid) {
      // Set error message if not valid.
      setEmailHelperText("Please enter a valid email address.");
      setContentEmailError(true);
    } else {
      setEmailHelperText("");
      setContentEmailError(false);
    }
  };

  return (
    <div>
      <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen} >
        {t('NewCourse')}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{'Create New LMS Course'}</DialogTitle>
        <DialogContent>
          <TextField
            inputRef={nameRef}
            margin="dense"
            name="coursename"
            label={t('CourseName')}
            type="text"
            fullWidth            
            variant="outlined"
            value={formData.name}
            onChange={handleChange}            
            className={formErrors.name ? 'blinking' : ''}
          />
          <TextField
            margin="dense"
            name="heading"
            label={t('Heading')}
            type="text"
            fullWidth
            variant="outlined"
            value={null}
            onChange={handleChange}
          />            
          <Box sx={{ p: 2 }}>              
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.memberships.Quizizz}
                    onChange={handleMembershipCheckboxChange}
                    name="is_published"
                  />
                }
                label="Published ?"
              />                    
            </FormGroup>
          </Box>        
          <TextField
            margin="dense"
            name="image_url"
            label={t('Image Url')}
            type="text"
            fullWidth
            variant="outlined"
            value={null}
            onChange={handleChange}
          />                    
          <TextField
            margin="dense"
            name="description"
            label={t('Description')}
            type="text"
            fullWidth
            variant="outlined"
            value={formData.description}
            onChange={handleChange}
          />                    
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button onClick={handleSubmit} autoFocus
            style={{
              backgroundColor:'blue',
              color: '#FFFFFF',
            }}>{t('Create')}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={5000} 
        onClose={handleCloseStudentMessage}
      >
        <Alert severity={severity} onClose={handleCloseStudentMessage} sx={{ width: 450, minWidth: 288, height: 75, fontSize: 14, fontStyle: 'normal'}} >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
