// import React, { useState, useEffect } from 'react';
// import FullCalendar from '@fullcalendar/react';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
// import interactionPlugin from '@fullcalendar/interaction';
// import { Drawer, Box, TextField, Button, Typography, FormControl, FormControlLabel, RadioGroup, Radio, IconButton } from '@mui/material';
// import { LocalizationProvider, DateTimePicker } from '@mui/lab';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import DeleteIcon from '@mui/icons-material/Delete'; // Delete ikonunu ekleyin

// function CalendarComponent() {
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [events, setEvents] = useState([]);
//   const [newEvent, setNewEvent] = useState({
//     id: null,
//     title: '',
//     start: new Date(),
//     end: new Date(),
//     allDay: false,
//     color: '', // Renk için ekstra bir alan ekliyoruz
//   });
//   const [currentView, setCurrentView] = useState('dayGridMonth');

//   useEffect(() => {
//     const handleKeyPress = (event) => {
//       if (event.key === 'Enter') {
//         handleAddOrUpdateEvent();
//       }
//     };

//     document.addEventListener('keydown', handleKeyPress);
//     return () => {
//       document.removeEventListener('keydown', handleKeyPress);
//     };
//   }, [newEvent]);

//   const handleDateClick = (arg) => {
//     const isAllDay = currentView === 'dayGridMonth';
//     setNewEvent({ ...newEvent, start: arg.date, end: arg.date, allDay: isAllDay, color: '' });
//     setDrawerOpen(true);
//   };

//   const handleEventClick = (clickInfo) => {
//     const event = events.find(event => event.id.toString() === clickInfo.event.id);
//     setNewEvent({
//       ...event,
//     });
//     setDrawerOpen(true);
//   };

//   const handleAddOrUpdateEvent = () => {
//     if (newEvent.start > newEvent.end) {
//       alert('End date must be later than start date.');
//       return;
//     }
//     const updatedEvents = newEvent.id
//       ? events.map(event => event.id === newEvent.id ? { ...newEvent, backgroundColor: newEvent.color } : event)
//       : [...events, { ...newEvent, id: Date.now().toString(), backgroundColor: newEvent.color }];
//     setEvents(updatedEvents);
//     setDrawerOpen(false);
//     setNewEvent({ id: null, title: '', start: new Date(), end: new Date(), allDay: false, color: '' });
//   };

//   const handleChangeColor = (event) => {
//     setNewEvent({ ...newEvent, color: event.target.value });
//   };

//   const eventContent = (eventInfo) => {
//     return (
//       <div style={{ backgroundColor: eventInfo.backgroundColor, color: 'white', padding: '5px', borderRadius: '5px' }}>
//         {eventInfo.event.title}
//       </div>
//     );
//   };

//   const handleDeleteEvent = () => {
//     if (newEvent.id) {
//       setEvents(events.filter(event => event.id !== newEvent.id));
//       setDrawerOpen(false);
//       setNewEvent({ id: null, title: '', start: new Date(), end: new Date(), allDay: false, color: '' });
//     }
//   };

//   return (
//     <>
//       <FullCalendar
//         plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
//         initialView="dayGridMonth"
//         events={events}
//         dateClick={handleDateClick}
//         eventClick={handleEventClick}
//         eventContent={eventContent}
//         headerToolbar={{
//           left: 'prev,next today',
//           center: 'title',
//           right: 'dayGridMonth,timeGridWeek,timeGridDay'
//         }}
//         datesSet={(dateInfo) => setCurrentView(dateInfo.view.type)}
//       />
//       <Drawer
//         anchor="right"
//         open={drawerOpen}
//         onClose={() => setDrawerOpen(false)}
//       >
//         <Box sx={{ width: 350 }} role="presentation" p={2}>
//           <Typography variant="h6" marginBottom={2}>{newEvent.id ? 'Edit Event' : 'Add New Event'}</Typography>
//           <TextField
//             label="Event Title"
//             type="text"
//             fullWidth
//             value={newEvent.title}
//             onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
//             margin="normal"
//             onKeyPress={(e) => e.key === 'Enter' && handleAddOrUpdateEvent()} // Enter tuşuyla submit
//           />
//           <LocalizationProvider dateAdapter={AdapterDateFns}>
//             <DateTimePicker
//               label="Start Date"
//               renderInput={(props) => <TextField {...props} />}
//               value={newEvent.start}
//               onChange={(newValue) => setNewEvent({ ...newEvent, start: newValue })}
//             />
//             <DateTimePicker
//               label="End Date"
//               renderInput={(props) => <TextField {...props} />}
//               value={newEvent.end}
//               onChange={(newValue) => setNewEvent({ ...newEvent, end: newValue })}
//               minDateTime={newEvent.start}
//             />
//           </LocalizationProvider>
//           <FormControl component="fieldset" sx={{ marginTop: 2 }}>
//             <RadioGroup row aria-label="color" name="row-radio-buttons-group" value={newEvent.color} onChange={handleChangeColor}>
//               <FormControlLabel value="blue" control={<Radio />} label="Mavi" />
//               <FormControlLabel value="red" control={<Radio />} label="Kırmızı" />
//               <FormControlLabel value="green" control={<Radio />} label="Yeşil" />
//             </RadioGroup>
//           </FormControl>
//           <Button variant="contained" onClick={handleAddOrUpdateEvent} sx={{ marginTop: 2 }}>
//             {newEvent.id ? 'Update Event' : 'Add Event'}
//           </Button>
//         </Box>
//       </Drawer>
      
      
     

//     </>
//   );
// }

// export default CalendarComponent;


import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Drawer, Box, TextField, Button, Typography, FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DynamicSelect from '../../components/select/dynamicSelect';

function CalendarComponent() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({
    id: null,
    title: '',
    start: new Date(),
    end: new Date(),
    allDay: false,
    color: '', // Renk için ekstra bir alan ekliyoruz
  });
  const [currentView, setCurrentView] = useState('dayGridMonth');

  const [titleLabel, setTitleLabel] = useState("Event Types");
  const [selectedBatch, setSelectedBatch] = useState(""); //from
  const handleSelectionChange = (value) => { //from dynamicSelect.js
    setSelectedBatch(value);
    //setPage(0);
    //setfilterStudentId(value);    
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleAddOrUpdateEvent();
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [newEvent]);

  const handleDateClick = (arg) => {
    const isAllDay = currentView === 'dayGridMonth';
    setNewEvent({ ...newEvent, start: arg.date, end: arg.date, allDay: isAllDay, color: '' });
    setDrawerOpen(true);
  };

  const handleEventClick = (clickInfo) => {
    const event = events.find(event => event.id.toString() === clickInfo.event.id);
    setNewEvent({
      ...event,
    });
    setDrawerOpen(true);
  };

  const handleAddOrUpdateEvent = () => {
    if (newEvent.start > newEvent.end) {
      alert('End date must be later than start date.');
      return;
    }
    const updatedEvents = newEvent.id
      ? events.map(event => event.id === newEvent.id ? { ...newEvent, backgroundColor: newEvent.color } : event)
      : [...events, { ...newEvent, id: Date.now().toString(), backgroundColor: newEvent.color }];
    setEvents(updatedEvents);
    setDrawerOpen(false);
    setNewEvent({ id: null, title: '', start: new Date(), end: new Date(), allDay: false, color: '' });
  };

  const handleChangeColor = (event) => {
    setNewEvent({ ...newEvent, color: event.target.value });
  };

  const eventContent = (eventInfo) => {
    return (
      <div style={{ backgroundColor: eventInfo.backgroundColor, color: 'white', padding: '5px', borderRadius: '5px' }}>
        {eventInfo.event.title}
      </div>
    );
  };
  const selectData = ['Management', 'Oak MEDIA', 'Human Resources', 'Finance', 'Marketing', 'Education', 'Infrastructure']; 

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        eventContent={eventContent}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        datesSet={(dateInfo) => setCurrentView(dateInfo.view.type)}
      />
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box sx={{ width: 350 }} role="presentation" p={2}>
          <Typography variant="h6" marginBottom={2}>{newEvent.id ? 'Edit Event' : 'Add New Event'}</Typography>
          <TextField
            label="Event Title"
            type="text"
            fullWidth
            value={newEvent.title}
            onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
            margin="normal"
            onKeyPress={(e) => e.key === 'Enter' && handleAddOrUpdateEvent()} // Enter tuşuyla submit
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Start Date"
              renderInput={(props) => <TextField {...props} />}
              value={newEvent.start}
              onChange={(newValue) => setNewEvent({ ...newEvent, start: newValue })}
            />
            <DateTimePicker
              label="End Date"
              renderInput={(props) => <TextField {...props} />}
              value={newEvent.end}
              onChange={(newValue) => setNewEvent({ ...newEvent, end: newValue })}
              minDateTime={newEvent.start}
            />
          </LocalizationProvider>
          <div>
            <FormControl component="fieldset" sx={{ marginTop: 2 }}>
              {/* <RadioGroup row aria-label="color" name="row-radio-buttons-group" value={newEvent.color} onChange={handleChangeColor}>
                <FormControlLabel value="blue" control={<Radio />} label="Mavi" />
                <FormControlLabel value="red" control={<Radio />} label="Kırmızı" />
                <FormControlLabel value="green" control={<Radio />} label="Yeşil" />
              </RadioGroup> */}
              
              <DynamicSelect
                  sx={{ flex: 1, height: '100%' }} 
                  width={200}
                  data={selectData} 
                  titleLabel={titleLabel}
                  onSelectionChange={handleSelectionChange}
                />
                
            </FormControl>
          </div>
          <Button variant="contained" onClick={handleAddOrUpdateEvent} sx={{ marginTop: 2 }}>
            {newEvent.id ? 'Update Event' : 'Add Event'}
          </Button>
        </Box>
      </Drawer>
     </>
  );
}

export default CalendarComponent;