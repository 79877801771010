import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getPaymentMethods = async () => {
  try {
      const token = await getToken();
      // console.log('getPaymentMethods');
      const response = await axios.get(`${BaseUrl}/finance/getpaymentmethods`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const info = response.data || [];
      // console.log('getPaymentMethods-response: ', info);
      return info;
  } catch (error) {
      return [];
  }
};

export { getPaymentMethods };

