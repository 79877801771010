import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

/* // TODO: Storage Usage kapsamında iki durum vardır. İlki esas kaynaktan(zoom api) veri çekmek.
diğeri ilk durumda periyodik olarak alınan bilgiyi database üzerine almak ve oradan göstermek.
Burada ikinci durum kodlanmıştır. İlk durum için event takibi yapılarak her kayıt sonrası alınan veri databasede güncellenmektedir. */
const zoomStorageUsage = async (meetID) => {
  try {
    const token = await getToken();
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BaseUrl}/zoom/dbstorageusage`,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      };
    const response = await axios.request(config);
    return response;
  }
  catch (error) {
    console.log("zoomStorageUsage: ", error);
  }
}

export default zoomStorageUsage;
