
import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const updateFirstMail= async (mailData) => {
    try {
        const token = await getToken(); 
        const response = await axios.put(`${BaseUrl}/student/setstudentfirstmail`, 
            mailData,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );  
        return response.data;
    } catch (error) {  
        console.error("updateFirstMail error: ", error);
        return [];
    }
};

export default updateFirstMail;