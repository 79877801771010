import React, { useState, useRef, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, 
InputLabel, FormControl, Alert, Snackbar, FormControlLabel, RadioGroup, Radio, Box,Typography,
FormGroup, Checkbox,Accordion, AccordionSummary, AccordionDetails, Paper, Grid } from '@mui/material';
//import IconButton from '@mui/material/IconButton';
//import WhatsAppIcon from '@mui/icons-material/WhatsApp'; 
import Iconify from '../iconify';
//import InputAdornment from '@mui/material/InputAdornment';
import creatstudent from '../../_data/student/createstudent';
import { useTranslation } from 'react-i18next'; //Multi Language
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneInput from './PhoneInput';
import getSession from '../../utils/getSession';
//import style from '../../theme/blink.css';
import { getActiveBatches } from '../../_data/batchs/getActiveBatches';
import getAllLangLevels from '../../_data/student/getAllLangLevel';
import getAllOccupations from '../../_data/student/getAllOccupations';
import getAllGraduations from '../../_data/student/getAllGraduations';
import getAllReferences from '../../_data/student/getAllReferences';
import  getStatus  from '../../_data/student/getStatus';
import  getAllCountries  from '../../_data/student/getAllCountries';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
//import trLocale from 'date-fns/locale/tr';
//import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US'; // TODO: dil secimine gore buradan gerekli tanimi dondurmeliyiz.

export default function CreateStudentDialog({ onStudentAdded }) {
  const account = getSession();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    dateofbirth: '',
    allreferences: '',
    batchname: '',
    idbatches: '' ,
    idlanglevel: '',
    idoccupations: '',
    idgraduations:'',
    contact: {city: "", mail: "", phone: "", address: "", country: ""},
    memberships: {Quizizz: false, TryHackMe: false, DigitalCampus: false, DiscordUserName: ""},
    resourcecode: '',
    interninfo: '',
    status: '',
    description: '',
    bycreated: account.user[0].iduser
  });

  const initialFormData = {
    name: '',
    surname: '',
    email: '',
    dateofbirth: '',
    allreferences: '',
    batchname: '',
    idbatches: '' ,
    idlanglevel: '',
    idoccupations: '',
    idgraduation:'',
    contact: {city: "", mail: "", phone: "", address: "", country: ""},
    memberships: {Quizizz: false, TryHackMe: false, DigitalCampus: false, DiscordUserName: ""},
    resourcecode: '',
    interninfo: '',
    status: '',
    description: '',
    bycreated: account.user[0].iduser
  };
  const [newResource, setNewResource] = useState("");
  const [status, setStatus] = useState([]);
  const nameRef = useRef(null);
  const surnameRef = useRef(null);
  const resourcecodeRef = useRef(null);
  const statusRef = useRef(null);
  const batcheRef = useRef(null);
  const mailRef = useRef(null);
  const countryRef = useRef(null);
  const [formErrors, setFormErrors] = useState({});
  const [firstErrorField, setFirstErrorField] = useState(null);
  const [langLevel, setLangLevel] = useState(null);
  const [occupations, setOccupations] = useState(null);
  const [graduations, setGraduations] = useState(null);
  const [countries, setCountries] = useState(null);
  const [references, setReferences] = useState([]);

  const handleMembershipCheckboxChange = (event) => {
    setFormData({
      ...formData,
      memberships: {
        ...formData.memberships,
        [event.target.name]: event.target.checked
      }
    });
  };

  const handleReferencesAdsCheckboxChange = (event) => {
    setFormData({
      ...formData,
      allreferences: {
        ...formData.allreferences,
        [event.target.name]: event.target.checked
      }
    });
  };


  const [courseNames, setCourseNames] = useState([])
  useEffect(() => {
    fetchActiveBatches();
    fetchLangLevel();
    fetchOccupations();
    fetchGraduations();
    fetchReferences();
    fetchCountries();
  }, []);

  const fetchActiveBatches = async () => {
    const data = await getActiveBatches();
    const Status =  await getStatus();    
    setStatus(Status);
    const courses = Array.isArray(data) ? data : [];
    setCourseNames(courses);
  };

  const fetchLangLevel = async () => {
    const data = await getAllLangLevels();
    const langlevels = Array.isArray(data) ? data : [];
    setLangLevel(langlevels);
  };

  const fetchCountries = async () => {
    const data = await getAllCountries();
    const allcountries = Array.isArray(data) ? data : [];
    setCountries(allcountries);
  };

  const fetchOccupations = async () => {
    const data = await getAllOccupations();
    const occupation = Array.isArray(data) ? data : [];
    setOccupations(occupation);
  };

  const fetchGraduations = async () => {
    const data = await getAllGraduations();
    const graduation = Array.isArray(data) ? data : [];
    setGraduations(graduation);
  };

  const fetchReferences = async () => {
    const data = await getAllReferences();
    const referencename = Array.isArray(data) ? data : [];
    setReferences(referencename);
  };

  const handleMembershipTextChange = (event) => {
    setFormData({
      ...formData,
      memberships: {
        ...formData.memberships,
        [event.target.name]: event.target.value
      }
    });
  };

  const handleReferenceAdsTextChange = (event) => {
    setFormData({
      ...formData,
      memberships: {
        ...formData.memberships,
        [event.target.name]: event.target.value
      }
    });
  };

  const copyToClipboard = async (text) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
        showMessage('Create e-Mail User & Password copied.');
      } else {
        // Eski tarayıcılar için alternatif yöntem
        let textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        showMessage('Create e-Mail User & Password copied.');
      }
    } catch (err) {
      console.error('Could not copy password to clipboard!', err);
    }
  };

  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success'); // or 'error'
  const [alertMessage, setAlertMessage] = useState('');
  const showMessage = (message, severityType = 'success') => {
    setAlertMessage(message);
    setSeverity(severityType);
    setMsgOpen(true);
  };
  const handleCloseStudentMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFormData(initialFormData);
    setOpen(false);
  };

  const handleChangeStatus = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, status: value });
  };

  const handleChangeCourseName = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, idbatches: value, batchname: name });
  };

  const maxChars = 900;
  const [charCount, setCharCount] = useState(0);
  
  useEffect(() => {
    setCharCount(formData.description.length);
  }, [formData.description]);

  const handleChange = (event) => {
    try {
      const { name, value } = event.target;
      if(name === 'contact.mail.mailaddress1'){
        setFormData({ ...formData, email: value });
      }
      const nameParts = name.split('.'); // Nokta ile ayrılmış yolu parçalara ayır
      setFormData((prevFormData) => {
        let updatedValue = { ...prevFormData }; // İlk kopyayı oluştur
        nameParts.reduce((acc, key, index) => {
          if (index === nameParts.length - 1) {
            // Eğer son parçadaysak değeri güncelle
            acc[key] = value;
          } else {
            // Eğer son parça değilse, bir sonraki parçaya geç
            acc[key] = acc[key] || {}; // Eğer bir sonraki anahtar yoksa, yeni bir nesne oluştur
          }
          return acc[key];
        }, updatedValue);
        return updatedValue; // Güncellenmiş değeri döndür
      });

      // if (name === 'description') {
      //   if (value.length <= maxChars) {
      //     setCharCount(value.length);
      //   }
      // }
      if (name === 'description') {
        let newValue = value;
        if (value.length > maxChars) {
          newValue = value.substring(0, maxChars);
        }
        setCharCount(newValue.length);
        setFormData((prevFormData) => ({
          ...prevFormData,
          description: newValue,
        }));
      }

    // }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleChangeResource = (event) => {
    setFormData({ ...formData, resourcecode: event.target.value });
    setNewResource(event.target.value);
  };

  const validateForm = () => {
    //console.log('formData: ', formData);
    let errors = {};
    let firstError = null;
    if (!formData.name) {
      errors.name = true;
      firstError = firstError || 'name';
      showMessage("Fill in (name) the required fields! ", 'warning');
    }
    if (!formData.surname) {
      errors.surname = true;
      firstError = firstError || 'surname';
      showMessage("Fill in (surname) the required fields! ", 'warning');
    }

    if (!formData.status) {
      errors.status = true;
      firstError = firstError || 'status';
      showMessage("Fill in (status) the required fields! ", 'warning');
    }

    if (!formData.email) {
      errors.email = true;
      firstError = firstError || 'email';
      showMessage("Fill in (contac->mail) the required fields! ", 'warning');
    }

    if (!formData.contact.country) {
      errors.country = true;
      firstError = firstError || 'country';
      showMessage("Fill in (contac->country) the required fields! ", 'warning');
    }

    setTimeout(() => setFormErrors(false), 3000);
    setFormErrors(errors);
    setFirstErrorField(firstError);
    
    if(firstError) {      
      const refs = {name: nameRef, surname: surnameRef, status: statusRef, email: mailRef, country: countryRef};
      refs[firstError].current?.focus();
    }
    return Object.keys(errors).length === 0;
  };

  const updatedFormData = {
    ...formData,
  };

  const handleSubmit = async() => {    
    setFormData(updatedFormData);
    const isValid = validateForm();
    if (!isValid) return;
    const result = await creatstudent(formData);
    if(result.affectedRows === 1 && result.message === "New Student created successfully."){
      showMessage(t('NewStudentCreateSuccess'), 'success');
      onStudentAdded();
      handleClose();
      setFormData(initialFormData);
    } else {
      showMessage(result.message, 'error');
    }    
  };

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeLangLevel = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, idlanglevel: value});
  };

  const handleChangeOccupation = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, idoccupations: value});
  };

  const handleChangeGraduation = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, idgraduations: value});
  };

  const handleChangeCountries = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, countries: value});
  };

  const handleChangeDateOfBirth = (date) => {
    const formattedDate = date ? format(date, 'dd/MM/yyyy') : null;
    setFormData({ ...formData, dateofbirth: formattedDate});
  };

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj) || '';
  };

  const [contentEmailError, setContentEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');
  const [contentFullnameError, setContentFullnameError] = useState(false);
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const handleBlur = (e) => {
    const isValid = emailRegex.test(e.target.value);
    if (!isValid) {
      setEmailHelperText("Please enter a valid email address.");
      setContentEmailError(true);
    } else {
      setEmailHelperText("");
      setContentEmailError(false);
    }
  };

  const handleCheckboxChange = (event) => {
    const referenceName = event.target.name;
    const isChecked = event.target.checked;
    setFormData((prevFormData) => {
      const updatedReferences = isChecked 
          ? [...(prevFormData.allreferences.References || []), referenceName] 
          : prevFormData.allreferences.References.filter(ref => ref !== referenceName);
      return {
          ...prevFormData,
          allreferences: {
              ...prevFormData.allreferences,
              "References": updatedReferences
          }
      };
    });
  };

  // const handleCheckboxChange = (event) => {
  //   setFormData({
  //     ...formData,
  //     allreferences: {
  //       ...formData.allreferences,
  //       "References": [event.target.name]
  //     }
  //   });
  // };

  return (
    <div>
      <Button sx={{ position: 'fixed', right: 0, top: 100, margin: 2 }} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen} >
        {t('NewRecord')}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('Title.CNR')}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              inputRef={statusRef}
              labelId="status-select-label"
              name="status"
              label="Status"
              value={formData.status}
              onChange={handleChangeStatus}                
              className={formErrors.status ? "blinking" : ""}
            >
              {status
                .filter((status) => status.idstudent_status === 4) //4 = Prospective (FirtRecord)
                .map((status, index) => (
                  <MenuItem key={index} value={status.idstudent_status}>
                    {status.status_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField inputRef={nameRef}
            margin="dense"
            name="name"
            label={t('Name')}
            type="text"
            fullWidth            
            variant="outlined"
            value={formData.name}
            onChange={handleChange}            
            className={formErrors.name ? 'blinking' : ''}
          />
          <TextField
            inputRef={surnameRef}
            margin="dense"
            name="surname"
            label={t('Surname')}
            type="text"
            fullWidth            
            variant="outlined"
            value={formData.surname}
            onChange={handleChange}
            className={formErrors.surname ? "blinking" : ""}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
            <DatePicker
              label="Date Of Birth"
              value={formData.dateofbirth ? new Date(formData.dateofbirth) : null}
              onChange={handleChangeDateOfBirth}
              renderInput={(params) => <TextField {...params} />}
              sx={{mt:2}}
            />
          </LocalizationProvider>
          <FormControl fullWidth margin="dense" sx={{mt:3}}>
            <InputLabel id="langLevel-select-label">{t('LangLevel')}</InputLabel>
            <Select
              labelId="langLevel-select-label"
              name="levelname"
              label={t('LangLevel')}
              value={formData.idlanglevel || ''}
              onChange={handleChangeLangLevel}
            >
              { langLevel && langLevel.length > 0 && langLevel.map((lang) => (                                
                <MenuItem key={lang.idlanglevel} value={lang.idlanglevel}>
                  {lang.langname} - {lang.levelname}                  
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense" sx={{mt:3}}>
            <InputLabel id="occupation-select-label">{t('occupation')}</InputLabel>
            <Select
              labelId="occupation-select-label"
              name="occupation"
              label={t('occupation')}
              value={formData.idoccupations || ''}
              onChange={handleChangeOccupation}
            >
              { occupations && occupations.length > 0 && occupations.map((occupation) => (                                
                <MenuItem key={occupation.idoccupations} value={occupation.idoccupations}>
                  {occupation.occupationname}                
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense" sx={{mt:3}}>
            <InputLabel id="graduation-select-label">{t('graduation')}</InputLabel>
            <Select
              labelId="graduation-select-label"
              name="graduation"
              label={t('graduation')}
              value={formData.idgraduations || ''}
              onChange={handleChangeGraduation}
            >
              { graduations && graduations.length > 0 && graduations.map((graduation) => (                                
                <MenuItem key={graduation.idgraduations} value={graduation.idgraduations}>
                  {graduation.edulevelname || ''} - { graduation.edulevelname_de  || ''}               
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Paper 
            sx={{
              mt:1.5,
              border: 1,
              borderColor: 'divider'
            }}
          >  
          <AccordionDetails>
            <Box sx={{ p: 2 }}>
              <FormGroup>
                {references.map((ref, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={formData.allreferences.References?.includes(ref.referencename) || false}
                        onChange={handleCheckboxChange}
                        name={ref.referencename}
                      />
                    }
                    label={ref.referencename}
                  />
                ))}
              </FormGroup>
            </Box>
          </AccordionDetails>         
          </Paper>
          <Paper 
              sx={{
                mt:1,
                pl:2,
                border: 1,
                borderColor: 'divider'
              }}
          >
            <FormControl sx={{ ml: 0 }} fullWidth margin="dense">
              <InputLabel sx={{ pl: 0 }} id="resource-select-label" shrink>{t('ResourceCode')}</InputLabel>
              <RadioGroup 
                // inputRef={resourcecodeRef}
                row aria-label="resourcecode" 
                name="row-radio-buttons-group" 
                value={newResource}  
                onChange={handleChangeResource} 
                sx={{ mt: 1 }}
                className={formErrors.resourcecode ? "blinking" : ""}
              >
                <FormControlLabel value="P" control={<Radio />} label={t('Private')} sx={{ mr: 8 }}/>
                <FormControlLabel value="J" control={<Radio />} label={t('JobCenter')} />
              </RadioGroup>
            </FormControl>
          </Paper>
          <FormControl fullWidth margin="dense">
            <InputLabel id="coursename-select-label">{t('CourseName')}</InputLabel>
            <Select
              labelId="coursename-select-label"
              name="batchename"
              label={t('CourseName')}
              value={formData.idbatches || ''}
              onChange={handleChangeCourseName}
              className={formErrors.idbatches ? "blinking" : ""}
            >
              { courseNames.length > 0 && courseNames.map((courseName) => (                                
                <MenuItem key={courseName.idbatches} value={courseName.idbatches}>
                  {courseName.batchname}                 
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Paper 
              sx={{
                mt:1,
                border: 1,
                borderColor: 'divider'
              }}
          >
            <Accordion expanded={expanded === 'contact'} onChange={handleAccordionChange('contact')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="contactbh-content"
                id="contactbh-header"
              >
                <Typography variant="h6">{t('Contact')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <FormControl fullWidth margin="dense" sx={{mt:3}}>
                <InputLabel id="country-select-label">{t('Countries')}</InputLabel>
                <Select
                  labelId="country-select-label"
                  name="contact.country"
                  label={t('Countries')}
                  value={getNestedValue(formData, "contact.country")}
                  inputRef={countryRef}
                  className={formErrors.surname ? "blinking" : ""}
                  onChange={handleChange}
                >
                  { countries && countries.length > 0 && countries.map((country) => (                                
                    <MenuItem key={country.idcountries} value={country.countriesname}>
                      {country.countriesname || ''}                                
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <TextField            
                  margin="dense"
                  name="contact.country"
                  label="Country"
                  type="text"
                  fullWidth            
                  variant="outlined"
                  value={getNestedValue(formData, "contact.country")}
                  onChange={handleChange}
                /> */}
                <TextField            
                  margin="dense"
                  name="contact.address"
                  label="Address"
                  type="text"
                  fullWidth            
                  variant="outlined"
                  value={getNestedValue(formData, "contact.address")}
                  onChange={handleChange}
                />                
                <TextField            
                  margin="dense"
                  name="contact.city"
                  label="City"
                  type="text"
                  fullWidth            
                  variant="outlined"
                  value={getNestedValue(formData, "contact.city")}
                  onChange={handleChange}
                />                
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                  <TextField
                    autoFocus={contentEmailError}
                    inputRef={mailRef}
                    error={contentEmailError}
                    helperText={contentEmailError ? emailHelperText : ""}
                    type="email"
                    margin="dense"
                    name="contact.mail.mailaddress1"
                    label="Mail Address 1"
                    fullWidth
                    variant="outlined"
                    value={getNestedValue(formData, "contact.mail.mailaddress1")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    //className={mailFieldClassName ? "blinking" : ""}
                    className={formErrors.email ? "blinking" : ""}
                  />                  
                </Grid>
                  {/* <Grid item xs={6}>
                    <TextField
                      autoFocus={contentEmailError}
                      error={contentEmailError}
                      helperText={contentEmailError ? emailHelperText : ""}
                      type="email"
                      margin="dense"
                      name="contact.mail.mailaddress2"
                      label="Mail Address 2"
                      fullWidth
                      variant="outlined"
                      value={getNestedValue(formData, "contact.mail.mailaddress2")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid> */}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <PhoneInput 
                      margin="dense"
                      name="contact.phone.cellphone1"
                      label="Phone CellPhone 1"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={getNestedValue(formData, "contact.phone.cellphone1")}
                      onChange={handleChange} 
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneInput 
                        margin="dense"
                        name="contact.phone.whatsapp"
                        label="Phone WhatsApp Number"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={getNestedValue(formData, "contact.phone.whatsapp")}
                        onChange={handleChange} 
                      />
                  </Grid>                  
                </Grid>                   
              </AccordionDetails>
            </Accordion>
          </Paper>
          <Paper 
            sx={{
              mt:1.5,
              border: 1,
              borderColor: 'divider'
            }}
          >    
            <Accordion expanded={expanded === 'memberShips'} sx={{ order: 1, borderColor: 'divider'}} onChange={handleAccordionChange('memberShips')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="memberShipsbh-content"
                id="memberShipsbh-header"
              >
                <Typography variant="h6" gutterBottom>{t('MemberShips')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ p: 2 }}>              
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.memberships.Quizizz}
                          onChange={handleMembershipCheckboxChange}
                          name="Quizizz"
                        />
                      }
                      label="Quizizz"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.memberships.TryHackMe}
                          onChange={handleMembershipCheckboxChange}
                          name="TryHackMe"
                        />
                      }
                      label="TryHackMe"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.memberships.DigitalCampus}
                          onChange={handleMembershipCheckboxChange}
                          name="DigitalCampus (LMS)"
                        />
                      }
                      label="DigitalCampus"
                    />
                    <TextField
                      label="Discord Username"
                      name="DiscordUserName"
                      value={formData.memberships.DiscordUserName}
                      onChange={handleMembershipTextChange}
                      margin="normal"
                      fullWidth
                    />
                  </FormGroup>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Paper>
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="caption" fontWeight={'bold'} mt={3} color={ charCount < maxChars ? 'green' : 'red'}>
              {`${maxChars - charCount} characters remaining`}
            </Typography>
          </Box>
          <TextField
            margin="dense"
            name="description"
            label={t('Description')}
            type="text"
            fullWidth
            multiline
            variant="outlined"
            value={formData.description}
            onChange={handleChange}
          />
          {/* <Typography variant="caption" fontWeight={'bold'} color={ charCount < maxChars ? 'green' : 'red'}>
            {`${maxChars - charCount} characters remaining`}
          </Typography> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button onClick={handleSubmit} autoFocus
            style={{
              backgroundColor:'blue',
              color: '#FFFFFF',
            }}>{t('Create')}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={5000} 
        onClose={handleCloseStudentMessage}
      >
        <Alert severity={severity} onClose={handleCloseStudentMessage} sx={{ width: 450, minWidth: 288, height: 75, fontSize: 14, fontStyle: 'normal'}} >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}