import axios from 'axios';
import getToken from '../../api/getToken';
import config from '../../../config';
const { BaseUrl } = config;

const getSnapshots = async (_node, _vmid) => {
    try {
        const token = await getToken();
        console.log("[getSnapshots] node, vmid", _node, _vmid);
        const response = await axios.get(`${BaseUrl}/nodes/lab/snapshots`, {
            params: {
                node: _node, 
                vmid: _vmid
            },         
            headers: {
              'Authorization': `Bearer ${token}`
            }
        });  
        console.log("getSnapshots: ", response);
        return response.data;
    } catch (error) {
        console.log("Error[getSnapshots]: ", error);
        return error;
    }
};

export { getSnapshots };
