import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StyledNavItem, StyledNavItemIcon } from './styles';

NavSection.propTypes = {
  data: PropTypes.array,
};
export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          item.children ? (
            <NavItemAccordion key={item.title} item={item} />
          ) : (
            <NavItem key={item.title} item={item} />
          )
        ))}
      </List>
    </Box>
  );
}
NavItem.propTypes = {
  item: PropTypes.object,
};
function NavItem({ item }) {
  const { title, path, icon, info } = item;
  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </StyledNavItem>
  );
}
function NavItemAccordion({ item }) {
  const { title, children, icon } = item;
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
      </AccordionSummary>
      <AccordionDetails>
        <List component="div" disablePadding>
          {children.map((child) => (
            <NavItem key={child.title} item={child} />
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
NavItemAccordion.propTypes = {
  item: PropTypes.object,
};