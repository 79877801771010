import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getLeadFormDatas = async () => {
  try {
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/facebook/getleadformdatas?page_id=106551322161905`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
      });
      const info = response.data || [];
      return info;
  } catch (error) {
      return [];
  }
};

const getAdsParticipant = async (lead_id) => {
  try {
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/facebook/getleadformparticipants?lead_id=${lead_id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
      });
      const info = response.data || [];
      return info;
  } catch (error) {
      return [];
  }
};

export { getLeadFormDatas, getAdsParticipant };