
import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getRoles = async () =>{
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/roles/getroles`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });    
    return response.data;
  } catch (error) {  
      console.error("getRoles Error: ", error);
      return [];
  }
};

export default getRoles;