import React from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
// import { sentenceCase } from 'change-case';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DynamicSelect from '../../components/select/dynamicSelect';
import { useTranslation } from 'react-i18next'; //Multi Language


// @mui
import {
  Card, Table, Stack, Paper, Avatar, Button, Popover, Checkbox, TableRow,
  MenuItem, TableBody, TableCell, Container, Typography, IconButton,
  TableContainer, TablePagination, InputLabel, FormControl, Select, Box, Snackbar, Alert,
  Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, LinearProgress
} from '@mui/material';
import { SvgIcon } from '@mui/material';

import  getLmsUsers   from '../../_data/lms/getLmsUsers';
import  convertDateTime from '../../utils/convertDateTime';


// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import { ReactComponent as Icon_Attendance } from '../../assets/icons/attendance.svg';
import { ReactComponent as Icon_Payment } from '../../assets/icons/icon_payment.svg';
import { ReactComponent as Icon_Extract } from '../../assets/icons/extract.svg';
import { ReactComponent as Icon_Lecture } from '../../assets/icons/video-lecture.svg';

import Scrollbar from '../../components/scrollbar'
// sections
import { LmsCoursesListHead, LmsCoursesListToolbar } from '../../sections/@dashboard/lms';

//database
//import { getStudent } from '../../_data/student/getStudent';
import getLmsCourses  from '../../_data/lms/getLmsCourses';
import getCourseLecture  from '../../_data/lms/getLmsCourseLectures';
import getLmsCourseTrainees  from '../../_data/lms/getLmsCourseTrainees';

import ReportStudentAttendances from '../../_data/student/getReportParticipant';

//import InterviewTransaction from '../../_data/student/getInterviewTransaction';
import DataDisplay from './CustomDataDisplay';
import CreateCourseDialog from '../../components/lms/create-course-dialog';
//import UpdateStudentDialog from '../../components/student/update-student-dialog';
// import DeleteStudent from '../../_data/student/deleteStudent';
import getSession from '../../utils/getSession'; //Session Management

import config from '../../config';
const { PUBLIC_URL } = config;
const TransactionIcon = `${PUBLIC_URL}/assets/icons/navbar/ic_transaction.svg`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, filterStatusId) {
  //console.log('array: ', array.lmsCoursesInfo);
  if (!Array.isArray(array)) {
    return [];
  }
 // console.log('applySortFilter- array: ', array);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]).filter((course) => {
    // Genel arama sorgusu için filtreleme
    const lowerCaseQuery = String(query).toLowerCase(); // query'yi string'e çevir   
    const matchQuery = query ?
      Object.values(course).some((value) => String(value).toLowerCase().includes(lowerCaseQuery)) :
      true;
    // Status ID'ye göre filtreleme
    const matchStatus = filterStatusId ? course.status === filterStatusId : true;
    return matchQuery && matchStatus;
  });
};

export default function LmsCoursesPage() {
  const { t } = useTranslation();  
  // -------------------------COLUMN TITLES-------------------------------------------------------
  const TABLE_HEAD = [
    { id: 'name', label: t('CourseName'), alignRight: false },
    { id: 'heading', label: t('Heading'), alignRight: false },    
    { id: 'is_published', label: t('Status'), alignRight: false },
    { id: 'image_url', label: t('Image'), alignRight: false },
    { id: 'description', label: t('description'), alignRight: false },
    { id: '' },
  ];
  // ---------------------------------------------------------------------------------------------------------

  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('coursename');
  const [filterStudentId, setfilterStudentId] = useState('');
  const [filterStatusId, setfilterStatusId] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [courseDatas, setCourseDatas] = useState([getStudent]);
  const [courseDatas, setCourseDatas] = useState([getLmsCourses]);
  const [courseMetaData, setCourseMetaData] = useState([]);
  const [recordsNumber, setrecordsNumber] = useState(0);
  const [openAlertMsg, setOpenAlertMsg] = useState(false); //Notification Show/hide
  const [severity, setSeverity] = useState('success'); //Notification Show/hide  
  const [alertMessage, setAlertMessage] = useState('');
  const [selectData, setSelectData] = useState();
  const [selectedBatch, setSelectedBatch] = useState(""); //from
  const [msgOpen, setMsgOpen] = useState(false);
  const [titleLabel, setTitleLabel] = useState("Oak Batch Names");
  const navigate = useNavigate();  
  const MENU_OPTIONS = [
    { label1: 'Edit ', },
    { label2: 'Delete ', },
    { label3: 'AllExtract ', },
    { label4: 'Attendance', },
    { label5: 'Finance', },
  ];

  const handleDownRecord = async (event, ) => {
    try {     
      const label = event.currentTarget.textContent;
      selected.forEach((item) => {
      });
     // console.log("label: ", label);
      if(selected.length > 0){
        switch(label){          
          case 'Lectures':
            //console.log('first: ', selected[0].id, selected[0].name);
            handleLecturesDialogOpen(selected[0]);
            // setSeverity('error');
            // setAlertMessage(`Lectures List (Course ID:) ${selected[0].id} ${selected[0].name}`);
            // setMsgOpen(true);
            break;
          case 'Enrollments':
          //  console.log('first: ', selected[0].id, selected[0].name);
            // setSeverity('success');
            // setAlertMessage(`Enrollments List (Course ID:) ${selected[0].id}`);
            // setMsgOpen(true);
            handleEnrollmentDialogOpen(selected[0]);            
            break;
          default:
            break;
        };
    }else{          
      setSeverity('error');
      setAlertMessage('Not selected Meeting Record!');
      setMsgOpen(true);
    }
    } catch (error) {
        console.log("handleDownRecord-Error: ", error);
    }
  };
  
  const handleEnrollmentDialogClose = () => {
    setOpenEnrollmentDialog(false);
  };

  const handleCloseStudentMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };
  
  // const handleStudentAttendancePageOpen = async (email) => {
  //   const reportStudentZoomAttendances = await ReportStudentAttendances(email);  
  //   const MeetItem = async (index) => {
  //     if (index < selected.length) {
  //       navigate(`/dashboard/studentattendances`, { state: { reportStudentZoomAttendances } });
  //       await MeetItem(index + 1);
  //     }
  //   };
  //   await MeetItem(0);
  // }

  // const handleInterviewTransactionPageOpen = async (selectedId) => {
  //   console.log("handleInterviewTransactionPageOpen: ", selectedId);
  //   const TransactionItem = async (index) => {
  //     if (index < selected.length) {
  //        navigate(`/dashboard/interviewtransaction`, { state: { selectedId } });
  //       await TransactionItem(index + 1);
  //     }
  //   };
  //   await TransactionItem(0);
  // }

  // const handleAllBatchAttendancePageOpen = async () => {
  //   const batchName = filterStudentId;
  //   if(filterRecords.length > 0){
  //     const getEmails = filterRecords.map((selected, index) => {
  //       return selected.contact.mail['mailaddress-1'];
  //     });
  //     const reportAllBatchZoomAttendances = await ReportStudentAttendances(getEmails);   
  //     navigate(`/dashboard/studentattendances`, { state: { reportAllBatchZoomAttendances, batchName } });
  //   }
  // };

  // const handleSelectionChange = (value) => { //from dynamicSelect.js
  //   setSelectedBatch(value);
  //   setPage(0);
  //   setfilterStudentId(value);    
  // };  

  const [students, setStudents] = useState([]);
  const handleNewStudentAdded = async() => {
    try {      
      // const data = await getStudent();
      const data = await getLmsCourses();
      setrecordsNumber(data.length);
      setCourseDatas(data);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const [filterRecords, setFilterRecords] = useState();
  // Filtrelenmiş öğrenci listesi ve kayıt sayısını güncellemek için kullanılan useEffect
  useEffect(() => {    
    const filteredCourses = applySortFilter(courseDatas, getComparator(order, orderBy), filterStudentId);
    setrecordsNumber(filteredCourses.length);
    setFilterRecords(filteredCourses);
  }, [courseDatas, order, orderBy, filterStudentId]); // Bu useEffect, belirtilen bağımlılıklar her değiştiğinde çalışır.
  
  // useEffect(() => {
  //   const filteredStatus = applySortFilter(courseDatas, getComparator(order, orderBy), filterStatusId);
  //   //console.log("filteredStatus: ", filterStatusId);
  //   setrecordsNumber(filteredStudents.length);
  //   setFilterRecords(filteredStudents);
  // }, [courseDatas, order, orderBy, filterStatusId]); // Bu useEffect, belirtilen bağımlılıklar her değiştiğinde çalışır.

  const [isLoading, setIsLoading] = useState(true);
  const session = getSession();

  useEffect(() => {   
    try {
      getMessage(true);      
      getlmsCoursesDatas(); 
      handleAllTeachableTrainees();
    } catch (error) {
      
    } finally{
      getMessage(false);
    }
          
  }, []);
  
  const getMessage = (IsLoading) =>{
    if(IsLoading){
      //console.log('getMessage: ', IsLoading);
      setIsLoading(IsLoading);
      setSeverity('success');
      setAlertMessage('Interview Transaction.');
      setMsgOpen(true);
      }else{
      //console.log('getMessage: ', IsLoading);
       setIsLoading(IsLoading);      
      setMsgOpen(false);
    }
  };

  const updateDialogRef = useRef();
  const [selectedStudentData, setSelectedStudentData] = useState(null);
  const handleEditStudent = () => {
    try { 
      setSelectedStudentData(selected);
      if (updateDialogRef.current) {
        updateDialogRef.current.handleOpen();
      }
    } catch (error) {
      console.log("error: ", error);;      
    }
  };

  const getlmsCoursesDatas = async () => {
    try {
      // console.log("session: ", session?.user[0]?.iduser);
      const userId = session?.user[0]?.iduser;
        // const data = await getStudent(userId);
        const data = await getLmsCourses();
        setCourseDatas(data.lmsCoursesInfo);
        setCourseMetaData(data.meta);
        const filteredStudents = applySortFilter(data, getComparator(order, orderBy), filterStudentId);
        //console.log("267-filteredStudents: ", filteredStudents);
        setrecordsNumber(filteredStudents.length);
        setFilterRecords(filteredStudents);
        
    } catch (error) {
        console.error(error);
        setCourseDatas([]);
    }
  }; 


  // --------------- Edit user Button & Modals
  const [selectedFullName, setSelectedFullName] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');
  const handleOpenMenu = (event, { id, name, heading, is_published, image_url, description }) => {
    setOpen(event.currentTarget);
    setSelected([{ id: id, name: name, heading: heading, is_published: is_published, image_url: image_url, description: description }]);
    //setSelectedUserName(email);
    //setSelectedFullName(`${name} ${surname}`);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {   
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredStudents.map((n) => ({
        id: n.id,
        is_published: n.is_published,
        heading: n.heading,
        name: `${n.name}`,
      }));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, course) => {
  //  console.log("handleClick: ", course);
    //studentData
    const selectedIndex = selected.findIndex((item) => item.id === course.id);
    let newSelected = [];
  
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, course);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
   // console.log('newSelected: ', newSelected);
    setSelected(newSelected);
  };

  const refreshStudentData = () => {
    //getlmsCoursesDatas();
  };

  const performAction = async () => {
    try {
      // const ids = currentAction.map((data, index) => {
      //   return data.id;      
      // });
      //const idsQueryString = ids.join(',');
      //const response = await DeleteStudent(idsQueryString);
      // if (response.message === 'course deleted successfully.' && response.affectedRows > 0) {
      //   setSeverity('success');
      //   setAlertMessage('course deleted successfully.');
      //   setMsgOpen(true);
      //   getstudentDatas();
      //   handleCloseMenu();
      // }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  // const performExtractAction = async () => {
  //   try {      
  //   } catch (error) {
  //     console.log("Error: ", error);
  //   }
  // };
  
  const openTransactionDetails = (follower) => {
    //console.log("idstudent-transaction: ", follower);
    // window.location.href = `mailto:${emailAddress}`;
    //window.open("https://mail.google.com/mail/?tab=rm&authuser=0&ogbl", '_blank');
  };
  
  // const handleConfirm = () => {    
  //   performAction(currentAction);
  //   handleConfirmDialogClose();
  // };

  // const handleConfirmExtract = () => {    
  //   performExtractAction(currentAction);
  //   handleConfirmExractDialogClose();
  // };
  
  // const handleConfirmExtractDialogClose = () => {
  //   setOpenConfirmDialog(false);
  // };

  // const handleConfirmExractDialogClose = () => {
  //   setOpenExtractConfirmDialog(false);
  // };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [openLecturesDialog, setOpenLecturesDialog] = useState(false);
  const [currentCourseId, setcurrentCourseId] = useState(false);
  const [currentCourseLecturesData, setCurrentCourseLecturesData] = useState(['']);

  const handleLecturesDialogOpen = async (items) => {
    try {
      // items'ı kontrol edin ve boşsa işlemi durdurun
    if (!items || Object.keys(items).length === 0) {
    //  console.log('No items provided');
      return;
    }

    setcurrentCourseId(items);
    const data = await getCourseLecture(items.id);
    
    
    // data.lmsCoursesInfo'yu kontrol edin ve boşsa işlemi durdurun
    if (!data.lmsCoursesInfo || data.lmsCoursesInfo.length === 0) {
    //  console.log('No lecture data available');
      return;
    }

    setCurrentCourseLecturesData(data.lmsCoursesInfo);
    setOpenLecturesDialog(true);
    } catch (error) {  
      //console.log('error: ', error);
    }    
  };

  const handleLecturesDialogClose = () => {
    setOpenLecturesDialog(false);
  };

  const [openEnrollmentDialog, setOpenEnrollmentDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState();
  const handleEnrollmentDialogOpen =  async (items) => {
    try {
      // items'ı kontrol edin ve boşsa işlemi durdurun
      if (!items || Object.keys(items).length === 0) {
      //  console.log('No items provided');
        return;
      }          
      setcurrentCourseId(items);
      const data = await getLmsCourseTrainees(items.id);    
    if (!data.lmsCoursesInfo || data.lmsCoursesInfo.length === 0) {// data.lmsCoursesInfo'yu kontrol edin ve boşsa işlemi durdurun
    //  console.log('No lecture data available');
      return;
    }
      setCurrentAction(data.lmsCoursesInfo);
      //TODO: donen id degerlerine gore name surname ve email degerlerini almaliyiz ve panelde gostermeliyiz.
      setOpenEnrollmentDialog(true);
    } catch (error) {  
      console.log('error: ', error);
    }  
  };

const [lmsHybridTraineeDatas, setLmsHybridTraineeDatas] = useState([]);
const handleAllTeachableTrainees = async() => {
  try {
    let allData = [];
    let data = await getLmsUsers(1);//ilk degeri ve meta ile toplam kac sayfa oldugunu al.    
    for (let i = 1; i <= data.meta.number_of_pages; i++) {
      allData = allData.concat(data.lmsUsersInfo);
      data = await getLmsUsers(i+1);
    }
    setLmsHybridTraineeDatas(allData);
  } catch (error) {
    console.log("error: ", error);
  }
};


  const handleChangeRowsPerPage = (event) => {
   // console.log("handleChangeRowsPerPage(event):", parseInt(event.target.value, 10));
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setfilterStudentId(event.target.value);
  };

  const handleFilterStatusByName = (newValue) => {
    //console.log("handleFilterStatusByName:....................... ", newValue );
    if (!newValue) {
      //console.log("Filtre sıfırlanıyor...");
      setPage(0);
      setfilterStatusId(''); // veya başka bir sıfırlama değeri
      return;
    }
    setPage(0);
    setfilterStatusId(newValue.id);
  };


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - courseDatas.length) : 0;
  
  //const filteredStudents = applySortFilter(courseDatas, getComparator(order, orderBy), filterStudentId);
  const filteredStudents = applySortFilter(courseDatas, getComparator(order, orderBy), filterStudentId);

  //const isNotFound = !filteredStudents.length && !!filterStudentId;
  const isNotFound = !filteredStudents.length && (!!filterStudentId || !!filterStatusId);


  //const filteredStatuses = applySortFilter(courseDatas, getComparator(order, orderBy), filterStatusId);
  //isNotFound = !filteredStudents.length && !!filterStatusId;
  return (
    <>    
      <Helmet>
        <title> {t('Page.Title')} | Oak Academy </title>
      </Helmet>     
      <Container maxWidth={false} sx={{ marginLeft: 0, maxWidth: 'none' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
              {t('Lms Courses List')}
            </Typography>
            { t('TotalRecords')} {`: ${ recordsNumber } `}
          </div>          
          <CreateCourseDialog onStudentAdded={handleNewStudentAdded} />
          {/* <UpdateStudentDialog ref={updateDialogRef} initialData={selectedStudentData} onStudentUpdate={refreshStudentData}/> */}
        </Stack>
        <Card>
          <LmsCoursesListToolbar 
            numSelected={selected.length} 
            filterName={filterStudentId} 
            onFilterName={handleFilterByName} 
            onStatusName={handleFilterStatusByName} 
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <LmsCoursesListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={recordsNumber}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>                                              
                {      
                  (rowsPerPage === -1 ? filteredStudents : filteredStudents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
                    .map((row, index) => {
                      const { id, name, heading, is_published, image_url, description } = row;                                      
                      const selectedStudent = selected.indexOf(id) !== -1;
                      const isItemSelected = selected.some(item => item.id === id);
                    return (
                      <TableRow hover key={index} tabIndex={-1} role="checkbox" selected={selectedStudent}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            //onChange={(event) => handleClick(event, { id: id, name: name, heading: heading, is_published: is_published, image_url: image_url, description: description })}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none" sx={{ width: '20%'}}>
                        {`${name}`}
                        </TableCell>
                        <TableCell align="left" sx={{pl:1, width: '35%'}}>{heading}</TableCell>
                        <TableCell align="left" sx={{ fontWeight: 'bold', textAlign: 'center', 
                          fontSize: '14px', width: '5%', color: is_published ? 'red' : 'secondary.main'}}>
                          {is_published ? 'PUBLISHED' : 'UNPUBLISHED'}
                        </TableCell>                                       
                        <TableCell align="left">
                          <img src={image_url} alt="image" sx={{ maxWidth: '100%', height: 'auto', width: '25%' }} />
                        </TableCell>                                              
                        <TableCell align="left" sx={{pl:1, width: '15%'}}>{description}</TableCell>
                        <TableCell align="right">
                          <IconButton 
                            size="large" 
                            color="inherit" 
                            onClick={(event) => {
                              handleOpenMenu(event, {id, name})
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterStudentId}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, { value: -1, label: 'All' }]}
            component="div"
            count={recordsNumber}            
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 175, //PopupMenü genişliği
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Tooltip title = {t('Enrollment Lectures List for this course')}>
          <MenuItem key={MENU_OPTIONS.label3} onClick={handleDownRecord} sx={{ color: 'text.dark', Width: 300}}>          
              <Iconify component={Icon_Lecture} sx={{ mr: 2 }} />          
            {t('Lectures')}
          </MenuItem>
        </Tooltip>
        <Tooltip title = {t('Participant Trainees List for this course')}>
          <MenuItem 
            key={MENU_OPTIONS.label4}
            onClick={(event) => handleDownRecord(event)} 
            sx={{ color: 'secondary.text' , minWidth: 300}}
          >
            <SvgIcon component={Icon_Attendance} sx={{ mr: 2 }} />
            {t('Enrollments')}
          </MenuItem>
        </Tooltip>
      </Popover>
      <Snackbar 
        // anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOrigin={isLoading ? { vertical: 'center', horizontal: 'center' } : { vertical: 'top', horizontal: 'right' }}
        open={msgOpen}  
        autoHideDuration={5000} 
        onClose={handleCloseStudentMessage}
      >
        {/* <Alert severity={severity} onClose={handleCloseStudentMessage} sx={{ width: 450, minWidth: 288, height: 75, fontSize: 14, fontStyle: 'normal'}} > */}
        <Alert
          severity={severity} 
          onClose={handleCloseStudentMessage} 
          sx={isLoading ? { width: 550, minWidth: 388, height: 75, fontSize: 16, fontStyle: 'normal'} : { width: 450, minWidth: 288, height: 75, fontSize: 14, fontStyle: 'normal'}}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={openLecturesDialog}
        onClose={handleLecturesDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Trainee Course Tracking</DialogTitle>
        <DialogContent sx={{ width:'600px'}}>
            {currentCourseId.id }{' /  '}<strong style={{color: 'red'}}>{currentCourseId.name }</strong>{'  '}
            <p/>
            {
              currentCourseLecturesData ? (
                currentCourseLecturesData.map((item, index) => {
                 // console.log('item: ', item);
                  return(
                    <React.Fragment key={index}>
                      <div>
                        <strong style={{ color: 'blue' }}>LECTURES:</strong>
                      </div>
                      {
                        item.lecture_sections ? (
                          item.lecture_sections.map((section, secIndex) => (
                            <div key={secIndex}>
                              {`${secIndex + 1} . ${section.name}`}
                              <br/>
                            </div>
                          ))
                        ) : (
                          <div>No lecture sections available.</div>
                        )
                      }
                      <br/>
                    </React.Fragment>
                  );
                })
              ) : (
                null
              )
            }
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleLecturesDialogClose} 
            autoFocus
            style={{
              backgroundColor: 'blue',
              color: '#FFFFFF',
            }}>
            OK
          </Button>          
        </DialogActions>        
      </Dialog>
      <Dialog
        open={openEnrollmentDialog}
        onClose={handleEnrollmentDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {Array.isArray(currentAction) && currentAction.length > 0 && selected[0] && (
            <>
              <Typography variant="body4" component="span" style={{ color: 'red' }}>
                {selected[0].name}
              </Typography>
              <br/> 
              <Typography variant="body2" component="span" style={{ color: 'text' }}>
                Number of Trainees : {currentAction.length}
              </Typography>
              
            </>
          )}
        </DialogTitle>
        <DialogContent>
          {Array.isArray(currentAction) && currentAction.length > 0 && currentAction.map((select, index) => {
            const match = lmsHybridTraineeDatas.find(item2 => item2.id === select.id);
            return (
              <Box key={index} sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '16px', marginBottom: '16px' }}>                         
                {match && (                  
                  <Typography variant="body1" component="p" style={{ fontWeight: 'bold', color: "#1679AB", marginBottom: 2 }}>
                    <Typography variant="caption" sx={{ position: 'absolute', top: '18px', left: '8px', backgroundColor: 'white', padding: '2px 4px', borderRadius: '4px' }}>
                       
                    </Typography> 
                    {index + 1} {'. '} {match.name} {' / '} {match.email}
                  </Typography>
                )}
                <Typography variant="body2" component="p" style={{ fontWeight: 'normal', color: "#000000", marginBottom: 2 }}>
                  Enrolled at: {convertDateTime(select.enrolled_at)}
                </Typography>
                <Box display="flex" alignItems="center" mt={2}>
                  <Box width="100%" mr={1}>
                    <LinearProgress variant="determinate" value={select.percent_complete} />
                  </Box>
                  <Box minWidth={35}>
                    <Typography variant="body2" color="textSecondary">{`${Math.round(select.percent_complete)}%`}</Typography>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </DialogContent>
        {/* <DialogContent>
          {Array.isArray(currentAction) && currentAction.length > 0 && currentAction.map((select, index) => {
            let match = lmsHybridTraineeDatas.find(item2 => item2.id === select.id);
            console.log('match: ', match);
            return (
              <div key={index}>
                {match && (
                  <p key={select.id} style={{ fontWeight: 'bold', color: "#1679AB", marginBottom: 2 }}>
                    {match.name} {' / '} {match.email}
                  </p>
                )}
                <p style={{ fontWeight: 'normal', color: "#000000", marginBottom: 2 }}>
                  {'Enrolled at: '} {select.enrolled_at}
                </p>
                <Box display="flex" alignItems="center">
                  <Box width="100%" mr={1}>
                    <LinearProgress variant="determinate" value={select.percent_complete} />
                  </Box>
                  <Box minWidth={35}>
                    <Typography variant="body2" color="textSecondary">{`${Math.round(select.percent_complete)}%`}</Typography>
                  </Box>
                </Box>
              </div>
            );
          })}
        </DialogContent> */}
        <DialogActions>
          <Button
            sx = {{ pl: 2, pr: 2, textAlign: 'center' }}
            onClick={handleEnrollmentDialogClose}
            autoFocus
            style={{
              backgroundColor: '#6C757D',
              color: '#FFFFFF',
            }}
          >
            CLOSE
          </Button>          
        </DialogActions>        
      </Dialog>
    </>
  );
};