import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
import { cleanDigitSectionValue } from '@mui/x-date-pickers/internals/hooks/useField/useField.utils';
const { BaseUrl } = config;

const getAllAssets= async () => {
    try {
        const token = await getToken();
        const response = await axios.get(`${BaseUrl}/assets/getAllAssets`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const info = response.data || [];
        const assetsList = info.map((assetData, index) => {
            return {
              idassets: assetData.idassets || '',
              asset_name: assetData.assetname || '',
              typeid: assetData.typeid || '',
              brand: assetData.brand || '',
              model: assetData.model || '',
              serial_number: assetData.serial_number || '',
              purchase_date :assetData.purchase_date || '',
              warranty_date: assetData.warranty_date || '',
              assigned_to: assetData.assigned_to || '',
              location: assetData.location || '',
              status: assetData.status || '',
              purchase_price: assetData.purchase_price || '',
              vendor: assetData.vendor || '',
              last_maintenance_date: assetData.last_maintenance_date || '',
              next_maintenance_date: assetData.next_maintenance_date || '',
              notes: assetData.notes || '',
            };
        });
        return assetsList;
    } catch (error) {
        return [];
    }
};

const getJobCenterAssets= async () => {
    try {
        const token = await getToken(); 
        const response = await axios.get(`${BaseUrl}/assets/getJobCenterAssets`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        console.log("response: ", response);
        const info = response.data || [];
        const assetsList = info.map((assetData, index) => {
            return {
              idassets: assetData.idassets || '',
              asset_name: assetData.assetname || '',
              typeid: assetData.typeid || '',
              brand: assetData.brand || '',
              model: assetData.model || '',
              serial_number: assetData.serial_number || '',
              purchase_date :assetData.purchase_date || '',
              warranty_date: assetData.warranty_date || '',
              assigned_to: assetData.assigned_to || '',
              location: assetData.location || '',
              status: assetData.status,
              purchase_price: assetData.purchase_price || '',
              vendor: assetData.vendor || '',
              last_maintenance_date: assetData.last_maintenance_date || '',
              next_maintenance_date: assetData.next_maintenance_date || '',
              notes: assetData.notes || '',
              at_create: assetData.at_create || '',
            };
        });
        return info;
    } catch (error) {  
        console.log("getJobCenterAssets-error: ", error);
        return [];
    }
};

export { getAllAssets, getJobCenterAssets };
