import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
import updateRoleDefinitions from '../../utils/updateJsonFormat';
const { BaseUrl } = config;

const deleteRoleDefinition = async (selectedRoleId, newRoleDefinitionData) =>{
  try {    
    //let newRoleDefinitionData = '{"Modules": ' + JSON.stringify(jsonData) + '}';
    const token = await getToken();
    const response = await axios.patch(`${BaseUrl}/roles/updateroledefinition`, {         
        roleid: selectedRoleId,
        definitions: JSON.stringify(newRoleDefinitionData),
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
     return response.data;
  } catch (error) {  
      console.error("deleteRoleDefinition Error: ", error);
      return [];
  }
};

export default deleteRoleDefinition; 