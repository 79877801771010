
import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getModules = async (selectedAppsId) =>{
  try {    
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/roles/getmodules`, {
      params: {
        idapplication: selectedAppsId
      },      
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });    
    return response.data;
  } catch (error) {  
      console.error("getModules Error: ", error);
      return [];
  }
};

export default getModules;