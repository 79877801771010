const updateRoleDefinitions = (data, update, action) => {
  // Eğer data yoksa veya boşsa, yeni bir yapı oluştur.
  if (!data || data.length === 0) {
    data = [{
      "ModuleName": update.selectedModuleName,
      "Pages": [{
        "PageName": update.selectedPageName,
        "FieldNames": update.selectedFieldName ? [{
          "FieldName": update.selectedFieldName,
          "editable": update.permission === 'editable'
        }] : []
      }]
    }];
  } else {
    let moduleExists = false;
    let pageExists = false;
    // Varolan her modül için
    data.forEach(module => {
      if (module.ModuleName === update.selectedModuleName) {
        moduleExists = true;
        module.Pages.forEach(page => {
          if (page.PageName === update.selectedPageName) {
            pageExists = true;
            if (action === "append") {
              const newField = { FieldName: update.selectedFieldName, editable: update.permission === 'editable' };
              if (!page.FieldNames.some(field => field.FieldName === newField.FieldName)) {
                page.FieldNames.push(newField);
              }
            } else if (action === "delete") {
              const index = page.FieldNames.findIndex(field => field.FieldName === update.selectedFieldName);
              if (index !== -1) {
                page.FieldNames.splice(index, 1);
              }
            }
          }
        });
        // Eğer modül var ama sayfa yoksa, sayfayı ve alan adını ekle
        if (!pageExists) {
          module.Pages.push({
            "PageName": update.selectedPageName,
            "FieldNames": update.selectedFieldName ? [{
              "FieldName": update.selectedFieldName,
              "editable": update.permission === 'editable'
            }] : []
          });
        }
      }
    });
    // Eğer modül yoksa, modülü, sayfayı ve alan adını ekle
    if (!moduleExists) {
      data.push({
        "ModuleName": update.selectedModuleName,
        "Pages": [{
          "PageName": update.selectedPageName,
          "FieldNames": update.selectedFieldName ? [{
            "FieldName": update.selectedFieldName,
            "editable": update.permission === 'editable'
          }] : []
        }]
      });
    }
  } 
  return data;
};

export default updateRoleDefinitions;










// const updateRoleDefinitions = (data, update, action) => {
//   if (!data || data.length === 0) {
//     data = [{
//       "ModuleName": update.selectedModuleName,
//       "Pages": [{
//         "PageName": update.selectedPageName,
//         "FieldNames": update.selectedFieldName ? [{
//           "FieldName": update.selectedFieldName,
//           "editable": update.permission === 'editable'
//         }] : []
//       }]
//     }];
//   } else {
//     data.forEach(module => {
//       if (module.ModuleName === update.selectedModuleName) {
//         module.Pages.forEach(page => {
//           if (page.PageName === update.selectedPageName) {
//             if (action === "append") {
//               const newField = { FieldName: update.selectedFieldName, editable: update.permission === 'editable' ? true : false };
//               // Make sure the same field has not been added before!
//               if (!page.FieldNames.some(field => field.FieldName === newField.FieldName)) {
//                 page.FieldNames.push(newField);
//               }
//             } else if (action === "delete") {
//               const index = page.FieldNames.findIndex(field => field.FieldName === update.selectedFieldName);
//               if (index !== -1) {
//                 page.FieldNames.splice(index, 1);
//               }
//             }
//           }          
//         });
//       }      
//     }); 
//   } 
//   return data;
// };

// export default updateRoleDefinitions;