import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  TextField,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import { ReactComponent as Icon_Attendance } from '../../assets/icons/attendance.svg';
import { ReactComponent as Icon_Payment } from '../../assets/icons/icon_payment.svg';
import { SvgIcon } from '@mui/material';
import Scrollbar from '../../components/scrollbar'
// sections
import { StudentListHead, StudentListToolbar } from '../../sections/@dashboard/student';


// -------------------------COLUMN TITLES-------------------------------------------------------

const TABLE_HEAD = [
  { id: 'namesurname', label: 'Name Surname', alignRight: false },
  { id: 'resourcecode', label: 'ResourceCode', alignRight: false },
  { id: 'coursename', label: 'CourseName', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'contact', label: 'Contact', alignRight: false },
  { id: '' },
];
//idstudents,coursename, name, surname, contact, memberships, resourcecode, interninfo, status, description
// ---------------------------------------------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  // const stabilizedThis = array.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  // if (query) {
  //   return filter(stabilizedThis.map((el) => el[0]), (student) => {
  //     // Burada öğrencinin tüm özelliklerini kontrol ediyoruz
  //     return Object.values(student).some((value) => 
  //       String(value).toLowerCase().includes(query.toLowerCase())
  //     );
  //   });
  // }
  // return stabilizedThis.map((el) => el[0]);
}

export default function StudentPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('status');

  const [filterStudentId, setfilterStudentId] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  //const [studentDatas, setStudentDatas] = useState([getStudent]);
  
  const [recordsNumber, setrecordsNumber] = useState(0);
  const [openAlertMsg, setOpenAlertMsg] = useState(false); //Notification Show/hide
  const [severity, setSeverity] = useState('success'); //Notification Show/hide  
  const [alertMessage, setAlertMessage] = useState('');
  const [msgOpen, setMsgOpen] = useState(false);
  const navigate = useNavigate();

  const MENU_OPTIONS = [
    { label1: 'Edit ', },
    { label2: 'Delete ', },
    { label3: 'Attendance', },
    { label4: 'Finance', },
  ];

  const handleDownRecord = async (event, ) => {
    try {     
      const label = event.currentTarget.textContent;
      
      selected.forEach((item) => {
        //console.log("Selected Item: ", item);
        // Burada her seçili öğe için gerekli işlemleri yapın
      });

      //console.log("Incoming label: ", label, " selected.length: ", selected.length );
      if(selected.length > 0){
        switch(label){          
          case 'Edit':
       //     console.log("Edit: ", selected);
            //handleOpenRequestDialog(selected[0].email, selected[0].fullname);
            break;
          case 'Delete':
    //        console.log("Delete: ", selected);
            //handleConfirmDialogOpen(selected, 1);
            break;
          case 'Attendance':
            const mail = selected[0]?.contact?.mail['mailaddress-1'] || ''; //or default email address
            handleStudentAttendancePageOpen(mail);
            break;
          case 'Finance':          
            //handleConfirmDialogOpen(selected, selectedUserName, selectedFullName, 1);
            break;
          default:
            break;
        };
    }else{          
      setSeverity('error');
      setAlertMessage('Not selected Meeting Record!');
      setMsgOpen(true);
    }
    } catch (error) {
        console.log("handleDownRecord-Error: ", error);
    }
  };

  const handleStudentAttendancePageOpen = async (email) => {
   // const reportStudentZoomAttendances = await ReportStudentAttendances(email);
    // const MeetItem = async (index) => {
    //   if (index < selected.length) {
    //     navigate(`/dashboard/studentattendances`, { state: { reportStudentZoomAttendances } });
    //     await MeetItem(index + 1);
    //   }
    // };
    // await MeetItem(0);
  }

  useEffect(() => {
    const getstudentDatas = async () => {
      try {
          //const data = await getStudent();
        //  setrecordsNumber(data.length);
          //setStudentDatas(data);
      } catch (error) {
          console.error(error);
      //setStudentDatas([]);
      }
    };
    getstudentDatas();
  }, []);// Bağımlılık dizisi boş olduğu için bu useEffect yalnızca bileşen yüklendiğinde çalışır.
  
  // Filtrelenmiş öğrenci listesi ve kayıt sayısını güncellemek için kullanılan useEffect
  // useEffect(() => {
  //   //const filteredStudents = applySortFilter(studentDatas, getComparator(order, orderBy), filterStudentId);
  //   setrecordsNumber(filteredStudents.length);
  // }, [studentDatas, order, orderBy, filterStudentId]); // Bu useEffect, belirtilen bağımlılıklar her değiştiğinde çalışır.


  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {   
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

   const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = studentDatas.map((n) => n.id);
  //     console.log("newSelecteds: ", newSelecteds);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
   };

  const handleClick = (event, student) => {
    // const selectedIndex = selected.findIndex((item) => item.id === student.id);
    // let newSelected = [];
  
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, student);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1)
    //   );
    // }
  
    // setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setfilterStudentId(event.target.value);
  };
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - studentDatas.length) : 0;
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - 0) : 0;
  
  // const filteredStudents = applySortFilter(studentDatas, getComparator(order, orderBy), filterStudentId);
  const filteredStudents = applySortFilter(0, getComparator(order, orderBy), filterStudentId);

  //const isNotFound = !filteredStudents.length && !!filterStudentId;
  const isNotFound = !!filterStudentId;
  return (
    <>    
      <Helmet>
        <title> Oak Students| Oak Academy </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
            Oak Payments List
            </Typography>
            {`Total Records: ${recordsNumber}`}
          </div>
          <Button variant="contained" startIcon={<Iconify icon="eva:setting-outline" />}>
            Payments Settings
          </Button>
        </Stack>
        <Card>
          <StudentListToolbar numSelected={selected.length} filterName={filterStudentId} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <StudentListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={recordsNumber}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>                                              
                      <TableRow hover key={0} tabIndex={-1} role="checkbox">
                        <TableCell padding="checkbox">
                          {/* <Checkbox checked={selectedStudent} onChange={(event) => handleClick(event, idstudents, row.contact['mailaddress-1'])} /> */}
                          <Checkbox
                            checked={false}
                            onChange={(event) => handleClick(event)}
                          />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                             <Avatar /> 
                            <Typography variant="subtitle2" noWrap>
                             
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>                      
                        <TableCell align="left">
                          {/* <Label color={status ? 'success' : 'error'}>{status ? 'Active Student' : 'Intern'}</Label> */}
                        </TableCell>
                        <TableCell align="left">
                          {/* <DataDisplay customData = {contact}/> */}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterStudentId}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, { value: -1, label: 'All' }]}
            component="div"
            count={recordsNumber}            
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem key={MENU_OPTIONS.label1} onClick={handleDownRecord} sx={{ color: 'primary.main' }}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        <MenuItem key={MENU_OPTIONS.label2} onClick={handleDownRecord} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
        <MenuItem 
          key={MENU_OPTIONS.label3} 
          onClick={(event) => handleDownRecord(event)} 
          sx={{ color: 'secondary.text' }}
        >
          <Iconify icon={'eva:hand-left-sharp'} sx={{ mr: 2 }} /> 
          <SvgIcon component={Icon_Attendance} sx={{ mr: 2 }} />
          Attendance
        </MenuItem>
        <MenuItem key={MENU_OPTIONS.label4} onClick={handleDownRecord} sx={{ color: 'mycolor.main' }}>
          <Iconify component={Icon_Payment} sx={{ mr: 2, fontWeight: 'bold' }} />   
          Finance
        </MenuItem>
      </Popover>
    </>
  );
}
