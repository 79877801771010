import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getAssessmentLevels= async () => {
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/students/getassessmentlevels`,{
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const info = response.data || [];
    const EssessmentLevelList = info.map((item, index) => {
      return {           
        assesmentlevel: item.levelname || '',
        idassesmentlevel: item.idstudent_assessmentlevel || '',
        description: item.description || '',
      };
     // console.log("EssessmentLevelList: ", EssessmentLevelList);

    });
    return EssessmentLevelList;
  } catch (error) {
        console.log("EssessmentLevelList Error: ", error);
  }
};

export { getAssessmentLevels };
