import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';

const FilePicker = () => {
  const [fileContent, setFileContent] = useState('');
  const [fileName, setFileName] = useState('');

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = (e) => {
      setFileContent(reader.result);
    };

    reader.readAsText(file);
    setFileName(file.name);
  };

  return (
    <div>
      <input
        accept="*/*" // Burada hangi dosya türlerini kabul edeceğinizi belirleyebilirsiniz.
        style={{ display: 'none' }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={handleFileRead}
      />
      <label htmlFor="raised-button-file">
        <Button variant="raised" component="span">
          Upload File
        </Button>
      </label>
      <TextField
        id="outlined-read-only-input"
        label="Dosya İçeriği"
        multiline
        rows={4}
        value={fileContent}
        margin="normal"
        InputProps={{
          readOnly: true,
        }}
        variant="outlined"
      />
      <div>File Name: {fileName}</div>
    </div>
  );
};

export default FilePicker;
