import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const deleteStudent= async (idsQueryString) => {
  try {
    const token = await getToken();
    const response = await axios.delete(`${BaseUrl}/student/deletestudent`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      params: {
        idstudents: idsQueryString
      }
    });  
  //  console.log("response.data: ", response); 
    return response.data;
  } catch (error) {  
    console.error("delete error: ", error);
    return [];
  }
};

export default deleteStudent;