import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, 
  Alert, Snackbar, Typography, FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material'; 
import Iconify from '../iconify';
import updateApprovalUser from '../../_data/approvals/updateApprovalUsers';
import PhoneInput from '../../utils/phoneInput';
import getSession from '../../utils/getSession';

const UpdateApprovalDialog = ({ open, onClose, user, onApprovalSuccess }) => {

  const account = getSession();
  const [formData, setFormData] = useState({
    createdby: account.user[0].iduser,
    pk: '',
    email: '',    
    fullname: '',
    contact: {
      Address: '',
      CellPhone: ''
    },
    notyourmail: '',
  });

  useEffect(() => {
    if (user) {
      setFormData({
        ...formData,
        pk: user.id,
        email: user.email,
        fullname: user.fullname,
        contact: user.contact,
        notyourmail: user.notyourmail,
      });
    }
  }, [user]);

  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const showMessage = (message, severityType = 'success') => {
    setAlertMessage(message);
    setSeverity(severityType);
    setMsgOpen(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [contentEmailError, setContentEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');
  const [contentFullnameError, setContentFullnameError] = useState(false); 
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const handleBlur = (e) => {
    const isValid = emailRegex.test(e.target.value);
    if (!isValid) {
      setEmailHelperText("Please enter a valid email address.");
      setContentEmailError(true);
    } else {
      setEmailHelperText("");
      setContentEmailError(false);
    }
  };
  const handleSubmit = async () => {
    const contactJson = JSON.stringify(formData.contact);
    const dataToSend = {
      ...formData,
      contact: contactJson      
    };
    try {
      setContentEmailError(false);
      setContentFullnameError(false);
      if (!formData.email) {
        setContentEmailError(true);
        return;
      }
      const isEmailValid = emailRegex.test(formData.email);
      if (!isEmailValid) {
        setEmailHelperText("Please enter a valid email address.");
        setContentEmailError(true);
        return;
      }
      if (!formData.fullname) {
        setEmailHelperText("This field is required.");
        setContentFullnameError(true);
        return;
      }
      const result = await updateApprovalUser(dataToSend);
      if (result.status === 200) {
      //  console.log('updateApprovalUser-200: ', result);
        onApprovalSuccess();
        showMessage('The record has been updated successfully.', 'success');
        handleClose();
      } else {
        if (result.message === 'Error inserting database'){
          if(result.error.includes("Duplicate entry")){ 
            const alertMessage = `An error occurred while adding a record.\nThe email (${formData.email}) has been saved before.`;
            const splitMessage = alertMessage.split('\n').map((str, index) => <div key={index}>{str}</div>);
            showMessage(splitMessage, 'error');
          }
        }else{ 
          if(result.message === 'Request failed with status code 400'){
            const alertMessage = "email and fullname fields required.";
            const splitMessage = alertMessage.split('\n').map((str, index) => <div key={index}>{str}</div>);
            showMessage(splitMessage, 'error');
          }
        }
      }
    } catch (error) {
      console.error(error);
      showMessage('Error: ' + error, 'error');
    }
    handleClose();
  };

  const handleContactChange = (event, field) => {
    const { value } = event.target;
    setFormData({
      ...formData,
      contact: { ...formData.contact, [field]: value }
    });
  };  

  const handleClose = () => {
    onClose(false);
  };
  //console.log('formData: ',formData);
  return (
    <div>  
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Approvel Record</DialogTitle>
        <DialogContent>          
          <TextField
            autoFocus={contentEmailError}
            error={contentEmailError}
            helperText={contentEmailError ? emailHelperText : ""}
            type="email"
            margin="dense"
            name="email"
            label="Email"
            fullWidth
            variant="outlined"
            value={formData.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            autoFocus={contentFullnameError && !contentEmailError}
            error={contentFullnameError}
            helperText={contentFullnameError ? "This field is required." : ""}
            margin="dense"
            name="fullname"
            label="Full Name"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.fullname}
            onChange={handleChange}
          />
          <div style={{
            border: '1px solid #ced4da',
            borderRadius: '8px',
            padding: '20px',
            margin: '10px 0'
          }}>
            <Typography variant="h6" gutterBottom style={{
              marginBottom: '20px'
            }}>
              Contact Information
            </Typography>
            <TextField
              margin="dense"
              name="address"
              label="Address"
              type="text"
              fullWidth
              variant="outlined"
              value={formData.contact ? formData.contact.Address : ''}
              onChange={(event) => handleContactChange(event, 'Address')}
              style={{
                marginBottom: '10px'
              }}
            />
            <PhoneInput 
              margin="dense"
              name="cellPhone"
              label="Cell Phone"
              type="text"
              fullWidth
              variant="outlined"
              value={formData.contact ? formData.contact.CellPhone : ''}
              onChange={(event) => {handleContactChange(event, 'CellPhone')}} 
            />
          </div>
          <div style={{
            border: '1px solid #ced4da',
            borderRadius: '8px',
            padding: '20px',
            margin: '10px 0'
          }}>
            <Typography variant="h6" gutterBottom style={{
              marginBottom: '20px'
            }}>
              Not Your Mail
            </Typography>
            <RadioGroup
              name="notyourmail"
              value={formData.notyourmail}
              onChange={handleChange}
              row
            >
              <FormControlLabel value="1" control={<Radio />} label="Not Mine" />
              <FormControlLabel value="0" control={<Radio />} label="Yes Mine" />
            </RadioGroup>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} autoFocus
            style={{
              backgroundColor: 'blue',
              color: '#FFFFFF',
            }}>Update</Button>
        </DialogActions>
      </Dialog>
      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={10000} 
        onClose={handleCloseMessage}
      >        
        <Alert severity={severity} onClose={handleCloseMessage} 
          sx={{ 
            width: 450, 
            minWidth: 288,
            fontSize: 15, 
            fontStyle: 'normal' 
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default UpdateApprovalDialog;