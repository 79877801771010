import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Typography,
  Box,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import getAllLangLevel from '../../../_data/student/getAllLangLevel';

const LanguageLevelDefinitions = () => {
  const [rows, setRows] = useState([{ langname: '', levelname: '', description: '' }]);
  const [editIndex, setEditIndex] = useState(null);
  const [editRow, setEditRow] = useState({ idlanglevel: '', langname: '', levelname: '', description: '' });
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const newRowRef = useRef(null);

  useEffect(() => {
    const fetchLangLevels = async () => {
      try {
        const langLevels = await getAllLangLevel();
        setRows(langLevels);
      } catch (error) {
        console.error('Failed to fetch language levels:', error);
      }
    };

    fetchLangLevels();
  }, []);

  // const handleAddRow = () => {
  //   setRows([...rows, { langname: '', levelname: '', description: '' }]);
  // };

  const handleAddRow = () => {
    setRows((prevRows) => {
      const newRows = [...prevRows, { langname: '', levelname: '', description: '' }];
      return newRows;
    });
    setTimeout(() => {
      if (newRowRef.current) {
        newRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        newRowRef.current.focus();
      }
    }, 100); // Zaman aşımı, DOM'un güncellenmesi için gerekli olabilir
  };

  const handleRemoveRow = async (index) => {
    // Burada silme işlemini gerçekleştirin
    setRows(rows.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);
  };

  const handleEditRow = (index) => {
    setEditIndex(index);
    setEditRow(rows[index]);
    setOpenEdit(true);
  };

  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setEditRow({ ...editRow, [name]: value });
  };

  const handleEditSubmit = () => {
    const newRows = [...rows];
    newRows[editIndex] = editRow;
    setRows(newRows);
    setOpenEdit(false);
    // Burada update işlemini gerçekleştirin
    //console.log('Updated Row:', editRow);
  };

  const handleDeleteConfirm = (index) => {
    setDeleteIndex(index);
    setOpenDelete(true);
  };

  const handleDelete = () => {
    handleRemoveRow(deleteIndex);
    setOpenDelete(false);
  };

  const handleSubmit = () => {
    // Veritabanına gönderme işlemini burada gerçekleştirin
   // console.log(rows);
  };

  return (
    <Box>
      <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            * In this section, we ensure that the language levels of the Trainee Candidate are recorded in the database to enable them to be selected within the application.
          </Typography>
        </Box>
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Language Name</TableCell>
                <TableCell>Level Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="right">
                  <IconButton onClick={handleAddRow} color="primary">
                    <Add />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={row.idlanglevel || index}>
                  <TableCell>
                    <TextField
                      fullWidth
                      name="langname"
                      value={row.langname}
                      onChange={(event) => handleInputChange(index, event)}
                      inputRef={index === rows.length - 1 ? newRowRef : null}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      name="levelname"
                      value={row.levelname}
                      onChange={(event) => handleInputChange(index, event)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      name="description"
                      value={row.description}
                      onChange={(event) => handleInputChange(index, event)}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleEditRow(index)} color="primary">
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteConfirm(index)} color="secondary">
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Box>
        <Modal open={openEdit} onClose={() => setOpenEdit(false)}>
          <Box sx={{ ...style, width: 400 }}>
            <Typography variant="h6" component="h2">
              Edit Row
            </Typography>
            <TextField
              fullWidth
              label="Language Name"
              name="langname"
              value={editRow.langname}
              onChange={handleEditInputChange}
              margin="normal"              
            />
            <TextField
              fullWidth
              label="Level Name"
              name="levelname"
              value={editRow.levelname}
              onChange={handleEditInputChange}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={editRow.description}
              onChange={handleEditInputChange}
              margin="normal"
            />
            <Button onClick={handleEditSubmit} color="primary" variant="contained">
              Save
            </Button>
          </Box>
        </Modal>
        <Dialog
          open={openDelete}
          onClose={() => setOpenDelete(false)}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this row?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDelete(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default LanguageLevelDefinitions;








// import React, { useState, useEffect } from 'react';
// import {
//   Container,
//   Typography,
//   Box,
//   TextField,
//   IconButton,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Button,
//   Modal,
// } from '@mui/material';
// import { Add, Delete, Edit } from '@mui/icons-material';
// import getAllLangLevel from '../../../_data/student/getAllLangLevel';

// const LanguageLevelDefinitions = () => {
//   const [rows, setRows] = useState([{ name: '', description: '' }]);
//   const [editIndex, setEditIndex] = useState(null);
//   const [editRow, setEditRow] = useState({ name: '', description: '' });
//   const [open, setOpen] = useState(false);

//   useEffect(() => {
//     const fetchLangLevels = async () => {
//       try {
//         const langLevels = await getAllLangLevel();
//         console.log('langLevels...: ', langLevels);
//         setRows(langLevels);
//       } catch (error) {
//         console.error('Failed to fetch language levels:', error);
//       }
//     };

//     fetchLangLevels();
//   }, []);

//   const handleAddRow = () => {
//     setRows([...rows, { name: '', description: '' }]);
//   };

//   const handleRemoveRow = (index) => {
//     const newRows = [...rows];
//     newRows.splice(index, 1);
//     setRows(newRows);
//   };

//   const handleInputChange = (index, event) => {
//     const { name, value } = event.target;
//     const newRows = [...rows];
//     newRows[index][name] = value;
//     setRows(newRows);
//   };

//   const handleEditRow = (index) => {
//     setEditIndex(index);
//     setEditRow(rows[index]);
//     setOpen(true);
//   };

//   const handleEditInputChange = (event) => {
//     const { name, value } = event.target;
//     setEditRow({ ...editRow, [name]: value });
//   };

//   const handleEditSubmit = () => {
//     const newRows = [...rows];
//     newRows[editIndex] = editRow;
//     setRows(newRows);
//     setOpen(false);
//   };

//   const handleSubmit = () => {
//     // Veritabanına gönderme işlemini burada gerçekleştirin
//     console.log(rows);
//   };

//   return (
//     <Box>
//       <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column' }}>
//         <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2  }}>
//           <Typography variant="body2" color="textSecondary" gutterBottom>
//             * In this section, we ensure that the language levels of the Trainee Candidate are recorded in the database to enable them to be selected within the application.
//           </Typography>
//         </Box>
//         <TableContainer component={Paper} sx={{ marginTop: 2 }}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell>Language Name</TableCell>
//                 <TableCell>Level Name</TableCell>
//                 <TableCell>Description</TableCell>
//                 <TableCell align="right">
//                   <IconButton onClick={handleAddRow} color="primary">
//                     <Add />
//                   </IconButton>
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>            
//               {rows.map((row, index) => (
//                 <TableRow key={row.idlanglevel}>
//                   <TableCell>
//                     <TextField
//                       fullWidth
//                       name="langname"
//                       value={row.langname}
//                       onChange={(event) => handleInputChange(index, event)}
//                     />
//                   </TableCell>
//                   <TableCell>
//                     <TextField
//                       fullWidth
//                       name="levelname"
//                       value={row.levelname}
//                       onChange={(event) => handleInputChange(index, event)}
//                     />
//                   </TableCell>
//                   <TableCell>
//                     <TextField
//                       fullWidth
//                       name="description"
//                       value={row.description}
//                       onChange={(event) => handleInputChange(index, event)}
//                     />
//                   </TableCell>
//                   <TableCell align="right">
//                     <IconButton onClick={() => handleEditRow(index)} color="primary">
//                       <Edit />
//                     </IconButton>
//                     <IconButton onClick={() => handleRemoveRow(index)} color="secondary">
//                       <Delete />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
//           <Button variant="contained" color="primary" onClick={handleSubmit}>
//             Save
//           </Button>
//         </Box>
//         <Modal open={open} onClose={() => setOpen(false)}>
//           <Box sx={{ ...style, width: 400 }}>
//             <Typography variant="h6" component="h2">
//               Edit Row
//             </Typography>
//             <TextField
//               fullWidth
//               label="Name"
//               name="name"
//               value={editRow.name}
//               onChange={handleEditInputChange}
//               margin="normal"
//             />
//             <TextField
//               fullWidth
//               label="Description"
//               name="description"
//               value={editRow.description}
//               onChange={handleEditInputChange}
//               margin="normal"
//             />
//             <Button onClick={handleEditSubmit} color="primary" variant="contained">
//               Save
//             </Button>
//           </Box>
//         </Modal>
//       </Container>
//     </Box>
//   );
// };

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   bgcolor: 'background.paper',
//   boxShadow: 24,
//   p: 4,
// };

// export default LanguageLevelDefinitions;