import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Header from './header';
import Nav from './nav';
import { useAlert } from '../../utils/contexts/AlertContext';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function DashboardLayout() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  //const { alert } = useAlert(); // Take Message
  const { alert, showAlert } = useAlert();
  // console.log('PermissionAletMessage: ', alert);
  const handleClose = () => {
    //setOpen(false);
    showAlert(null); // Alert mesajını kapat
  };
  return (   
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />      
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      <Main>
        <Outlet />
      </Main>
      <Snackbar 
        open={!!alert.message} 
        autoHideDuration={3000} 
        onClose={handleClose} 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%', fontWeight: 'bold', fontSize:14, color:'black'}}>
          { t('PermissionAletMessage') }
        </Alert>
      </Snackbar>
    </StyledRoot>
  );
}