import React, { useState, useEffect } from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Select, 
MenuItem, TextField, Button, Typography, Checkbox } from '@mui/material';
import axios from 'axios';
import { getOnboardLecturesList } from '../../_data/lectures/getOnboardLecturesList';
import { getOnboardQuestionList } from '../../_data/academic/getQuestionList';
import { getOnboardAnswerList } from '../../_data/academic/getAnswerList';

const QuestionAnswerManagement = () => {
  const [lectures, setLectures] = useState([]);
  const [selectedLecture, setSelectedLecture] = useState('');
  const [questions, setQuestions] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [newAnswer, setNewAnswer] = useState('');
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const areAllQuestionsSelected = questions?.length > 0 && selectedQuestions.length === questions.length;
  const areAllAnswersSelected = answers.length > 0 && selectedAnswers.length === answers.length;
  const isIndeterminate = selectedQuestions.length > 0 && selectedQuestions.length < questions.length;

  // Bir sorunun seçimini değiştirmek için
const handleQuestionSelect = (questionId) => {
  setSelectedQuestions(prevSelected =>
    prevSelected.includes(questionId)
      ? prevSelected.filter(id => id !== questionId)
      : [...prevSelected, questionId]
  );
};

// Tüm soruları seçmek veya seçimlerini kaldırmak için
const handleSelectAllQuestions = () => {
  if (areAllQuestionsSelected) {
    setSelectedQuestions([]); // Tüm seçimleri kaldır
  } else {
    setSelectedQuestions(questions.map(q => q.idquestions)); // Tüm soruları seç
  }
};

// Bir cevabın seçimini değiştirmek için
const handleAnswerSelect = (answerId) => {
  setSelectedAnswers(prevSelected =>
    prevSelected.includes(answerId)
      ? prevSelected.filter(id => id !== answerId)
      : [...prevSelected, answerId]
  );
};

// Tüm cevapları seçmek veya seçimlerini kaldırmak için
const handleSelectAllAnswers = () => {
  if (areAllAnswersSelected) {
    setSelectedAnswers([]); // Tüm seçimleri kaldır
  } else {
    setSelectedAnswers(answers.map(a => a.idanswers)); // Tüm cevapları seç
  }
};
  useEffect(() => {
    loadLectures();
    loadQuestions();
    loadAnswers();
  }, []);
  
  const loadLectures = async()=>{
    const allLecture = await getOnboardLecturesList();
   // console.log('lecture: ', allLecture);
    setLectures(allLecture);
  };
  
  const loadQuestions = async()=>{
    const onboardQuestions = await getOnboardQuestionList();
   // console.log('onboardQuestions: ', onboardQuestions);
    setAllQuestions(onboardQuestions);
    //setQuestions(onboardQuestions);
  };

  const loadAnswers = async()=>{
    const onboardAnswers = await getOnboardAnswerList();
   // console.log('onboardQuestions: ', onboardAnswers);
    setAnswers(onboardAnswers);
  };

  // useEffect(() => {
  //   if (selectedLecture) {
  //     // Fetch questions based on selected lecture
  //     // axios.get(`/api/questions?lectureId=${selectedLecture}`)
  //     //   .then(response => {
  //     //     setQuestions(response.data);
  //     //   })
  //     //   .catch(error => {
  //     //     console.error("There was an error fetching the questions!", error);
  //     //   });
  //   }
  // }, [selectedLecture]);

  useEffect(() => {
    if (selectedQuestion) {
      // axios.get(`/api/answers?questionId=${selectedQuestion}`)
      //   .then(response => {
      //     setAnswers(response.data);
      //   })
      //   .catch(error => {
      //     console.error("There was an error fetching the answers!", error);
      //   });
    }
  }, [selectedQuestion]);

  const handleLectureChange = (event) => {
    setSelectedLecture(event.target.value);
   // console.log('Selected Lecture: ', event.target.value);
    // questions array'ini filtreleyip sadece seçilen lectureid'ye sahip olanları göster
  //  console.log('allQuestions: ', allQuestions);
    setQuestions(allQuestions);
    const filteredQuestions = allQuestions.filter(question => question.idquestionslecture === selectedLecture);
  //  console.log('filteredQuestions: ', filteredQuestions );
    setQuestions(filteredQuestions);
  };  

  const handleSaveQuestion = () => {
    // Save new question
    // axios.post('/api/questions', {
    //   questionstext: newQuestion,
    //   idquestionslecture: selectedLecture
    // })
    //   .then(response => {
    //     console.log('Question saved successfully!');
    //     setQuestions([...questions, response.data]);  // Update the questions list
    //     setNewQuestion('');  // Clear input field
    //   })
    //   .catch(error => {
    //     console.error("There was an error saving the question!", error);
    //   });
  };

  const handleSaveAnswer = () => {
    // Save new answer
    // axios.post('/api/answers', {
    //   answertext: newAnswer,
    //   relatedquestions: [selectedQuestion]
    // })
    //   .then(response => {
    //     console.log('Answer saved successfully!');
    //     setAnswers([...answers, response.data]);  // Update the answers list
    //     setNewAnswer('');  // Clear input field
    //   })
    //   .catch(error => {
    //     console.error("There was an error saving the answer!", error);
    //   });
  };

  return (
    <Grid container spacing={2} sx={{ pl: 4 }}>
    <Grid item xs={12} sx={{ mb: 2 }}>
      <Typography variant="h5" gutterBottom>
        Question and Answer Management
      </Typography>
    </Grid>
  
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Select
          value={selectedLecture}
          onChange={handleLectureChange}
          fullWidth
          displayEmpty
        >
          <MenuItem value="" disabled>Select Lecture</MenuItem>
          {lectures?.map((lecture) => (
            <MenuItem key={lecture.idsyllabus_lecture} value={lecture.idsyllabus_lecture}>
              {lecture.lecturetopic}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={5}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <TextField
              label="New Question Text"
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" onClick={handleSaveQuestion} fullWidth>
              Add New Question
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={9}>
            <TextField
              label="New Answer Text"
              value={newAnswer}
              onChange={(e) => setNewAnswer(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Button variant="contained" onClick={handleSaveAnswer} fullWidth>
              Add New Answer
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  // Bu checkbox tüm soruları seçmek için kullanılabilir
                  indeterminate={isIndeterminate}
                  checked={areAllQuestionsSelected}
                  onChange={handleSelectAllQuestions}
                />
              </TableCell>
              <TableCell>Question ID</TableCell>
              <TableCell>Question Text</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions?.map((question) => (
              <TableRow key={question.idquestions}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedQuestions.includes(question.idquestions)}
                    onChange={() => handleQuestionSelect(question.idquestions)}
                  />
                </TableCell>
                <TableCell>{question.idquestions}</TableCell>
                <TableCell>{question.questionstext}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
  
      <Grid item xs={6}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  // Bu checkbox tüm cevapları seçmek için kullanılabilir
                  indeterminate={isIndeterminate}
                  checked={areAllAnswersSelected}
                  onChange={handleSelectAllAnswers}
                />
              </TableCell>
              <TableCell>Answer ID</TableCell>
              <TableCell>Answer Text</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {answers.map((answer) => (
              <TableRow key={answer.idanswers}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAnswers.includes(answer.idanswers)}
                    onChange={() => handleAnswerSelect(answer.idanswers)}
                  />
                </TableCell>
                <TableCell>{answer.idanswers}</TableCell>
                <TableCell>{answer.answertext}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  </Grid>
  

    // <Grid container spacing={2} sx={{pl:4}}>
    //   <Grid item xs={12} sx={{mb: 2}}>
    //     <Typography variant="h5" gutterBottom>
    //       Question and Answer Management
    //     </Typography>
    //   </Grid>
    //   <Grid container spacing={2}>
    //     <Grid container spacing={2}>
    //       <Grid item xs={2}>
    //         <Select
    //           value={selectedLecture}
    //           onChange={handleLectureChange}
    //           fullWidth
    //           displayEmpty
    //         >
    //           <MenuItem value="" disabled>Select Lecture</MenuItem>
    //           {lectures?.map((lecture) => (
    //             <MenuItem key={lecture.idsyllabus_lecture} value={lecture.idsyllabus_lecture}>
    //               {lecture.lecturetopic}
    //             </MenuItem>
    //           ))}
    //         </Select>          
    //       </Grid>    
    //       <Grid item xs={6}>
    //         <Grid container spacing={2} alignItems="center">
    //           <Grid item xs={10}>
    //             <TextField
    //               label="New Question Text"
    //               value={newQuestion}
    //               onChange={(e) => setNewQuestion(e.target.value)}
    //               fullWidth
    //             />
    //           </Grid>
    //           <Grid item xs={2}>
    //             <Button variant="contained" onClick={handleSaveQuestion} fullWidth>
    //               Add New Question
    //             </Button>
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //       <Grid item xs={4}>
    //         <Grid container spacing={2} alignItems="center">
    //           <Grid item xs={9}>
    //             <TextField
    //               label="New Answer Text"
    //               value={newAnswer}
    //               onChange={(e) => setNewAnswer(e.target.value)}
    //               fullWidth
    //             />
    //           </Grid>
    //           <Grid item xs={3}>
    //             <Button variant="contained" onClick={handleSaveAnswer} fullWidth>
    //               Add New Answer
    //             </Button>
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //   </Grid>
    // </Grid>
    //   <Grid item xs={12}>
    //     <Table>
    //       <TableHead>
    //         <TableRow>
    //           <TableCell>Question ID</TableCell>
    //           <TableCell>Question Text</TableCell>
    //           <TableCell>Select</TableCell>
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //         {questions?.map((question) => (
    //           <TableRow key={question.idquestions} onClick={() => handleQuestionSelect(question.idquestions)}>
    //             <TableCell>{question.idquestions}</TableCell>
    //             <TableCell>{question.questionstext}</TableCell>
    //             <TableCell>
    //               <Button
    //                 variant="contained"
    //                 onClick={() => handleQuestionSelect(question.idquestions)}
    //               >
    //                 Select
    //               </Button>
    //             </TableCell>
    //           </TableRow>
    //         ))}
    //       </TableBody>
    //     </Table>
    //   </Grid>
    //   <Grid item xs={12}>
    //     <Table>
    //       <TableHead>
    //         <TableRow>
    //           <TableCell>Answer ID</TableCell>
    //           <TableCell>Answer Text</TableCell>
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //         {answers.map((answer) => (
    //           <TableRow key={answer.idanswers}>
    //             <TableCell>{answer.idanswers}</TableCell>
    //             <TableCell>{answer.answertext}</TableCell>
    //           </TableRow>
    //         ))}
    //       </TableBody>
    //     </Table>
    //   </Grid>
    // </Grid>
  );
};

export default QuestionAnswerManagement;




// import React, { useState, useEffect } from 'react';
// import { Grid, List, ListItem, ListItemText, Card, CardContent, TextField, Checkbox, Button, Typography } from '@mui/material';
// import axios from 'axios';

// const QuestionManagementPage = () => {
//   const [questions, setQuestions] = useState([]);
//   const [selectedQuestion, setSelectedQuestion] = useState(null);
//   const [answers, setAnswers] = useState([]);
//   const [selectedAnswer, setSelectedAnswer] = useState(null);
//   const [newQuestion, setNewQuestion] = useState('');
//   const [newAnswers, setNewAnswers] = useState(['', '', '', '']); // For a 4-option structure

//   useEffect(() => {
//     // Fetch all questions
//     axios.get('/api/questions')
//       .then(response => {
//         setQuestions(response.data);
//       })
//       .catch(error => {
//         console.error("There was an error fetching the questions!", error);
//       });
//   }, []);

//   const handleQuestionSelect = (question) => {
//     setSelectedQuestion(question);
//     // Fetch answers related to the selected question
//     axios.get(`/api/answers?questionId=${question.idquestions}`)
//       .then(response => {
//         setAnswers(response.data);
//         setSelectedAnswer(question.idcorrectanswer); // Pre-select the correct answer if already set
//       })
//       .catch(error => {
//         console.error("There was an error fetching the answers!", error);
//       });
//   };

//   const handleAnswerSelect = (answerId) => {
//     setSelectedAnswer(answerId);
//   };

//   const handleSaveQuestion = () => {
//     // Save the new question and related answers
//     axios.post('/api/questions', {
//       questionstext: newQuestion,
//       answers: newAnswers,
//       idcorrectanswer: selectedAnswer
//     })
//       .then(response => {
//         console.log('Question and answers saved successfully!');
//         // Add the new question to the list
//         setQuestions([...questions, response.data]);
//         setNewQuestion(''); // Clear the input field
//         setNewAnswers(['', '', '', '']); // Clear the answers
//       })
//       .catch(error => {
//         console.error("There was an error saving the question!", error);
//       });
//   };

//   const handleAnswerChange = (index, value) => {
//     const updatedAnswers = [...newAnswers];
//     updatedAnswers[index] = value;
//     setNewAnswers(updatedAnswers);
//   };

//   return (
//     <Grid container spacing={2}>
//       <Grid item xs={3}>
//         <List>
//           {questions.map((question) => (
//             <ListItem button key={question.idquestions} onClick={() => handleQuestionSelect(question)}>
//               <ListItemText primary={question.questionstext} />
//             </ListItem>
//           ))}
//         </List>
//       </Grid>

//       <Grid item xs={9}>
//         {selectedQuestion ? (
//           <Card>
//             <CardContent>
//               <Typography variant="h6">{selectedQuestion.questionstext}</Typography>
//               {answers.map((answer, index) => (
//                 <div key={answer.idanswers}>
//                   <Checkbox
//                     checked={selectedAnswer === answer.idanswers}
//                     onChange={() => handleAnswerSelect(answer.idanswers)}
//                   />
//                   {answer.answerimage === 1 ? (
//                     <img src={answer.imagepath} alt="Answer Image" />
//                   ) : (
//                     <TextField
//                       value={answer.answertext}
//                       onChange={(e) => handleAnswerChange(index, e.target.value)}
//                       fullWidth
//                     />
//                   )}
//                 </div>
//               ))}
//               <Button variant="contained" onClick={handleSaveQuestion}>
//                 Save
//               </Button>
//             </CardContent>
//           </Card>
//         ) : (
//           <Card>
//             <CardContent>
//               <Typography variant="h6">Add New Question</Typography>
//               <TextField
//                 label="Question Text"
//                 value={newQuestion}
//                 onChange={(e) => setNewQuestion(e.target.value)}
//                 fullWidth
//                 margin="normal"
//               />
//               {newAnswers.map((answer, index) => (
//                 <TextField
//                   key={index}
//                   label={`Answer ${index + 1}`}
//                   value={answer}
//                   onChange={(e) => handleAnswerChange(index, e.target.value)}
//                   fullWidth
//                   margin="normal"
//                 />
//               ))}
//               <Button variant="contained" onClick={handleSaveQuestion}>
//                 Save
//               </Button>
//             </CardContent>
//           </Card>
//         )}
//       </Grid>
//     </Grid>
//   );
// };

// export default QuestionManagementPage;















// import React, { useState } from 'react';
// import { Grid, List, ListItem, ListItemText, Card, CardContent, Checkbox, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

// const questions = [
//   { id: 1, question: "What is the capital of France?", options: ["Paris", "Berlin", "Madrid", "Rome"] },
//   { id: 2, question: "Which is the largest planet?", options: ["Earth", "Jupiter", "Mars", "Venus"] },
//   // Add more questions as needed
// ];

// const QuestionAnswerPage = () => {
//   const [selectedQuestion, setSelectedQuestion] = useState(null);
//   const [selectedAnswers, setSelectedAnswers] = useState([]);
//   const [selectedOption, setSelectedOption] = useState('');

//   const handleQuestionSelect = (question) => {
//     setSelectedQuestion(question);
//     setSelectedAnswers([]);
//     setSelectedOption('');
//   };

//   const handleAnswerChange = (option) => {
//     setSelectedAnswers((prev) =>
//       prev.includes(option)
//         ? prev.filter((ans) => ans !== option)
//         : [...prev, option]
//     );
//   };

//   const handleSave = () => {
//     // Handle saving the selected answers
//     console.log(`Saved answers for question ${selectedQuestion.id}:`, selectedAnswers);
//     console.log(`Selected option: ${selectedOption}`);
//   };

//   return (
//     <Grid container spacing={2}>
//       <Grid item xs={3}>
//         <List>
//           {questions.map((q) => (
//             <ListItem button key={q.id} onClick={() => handleQuestionSelect(q)}>
//               <ListItemText primary={q.question} />
//             </ListItem>
//           ))}
//         </List>
//       </Grid>

//       <Grid item xs={9}>
//         {selectedQuestion && (
//           <Card>
//             <CardContent>
//               <h2>{selectedQuestion.question}</h2>
//               {selectedQuestion.options.map((option) => (
//                 <div key={option}>
//                   <Checkbox
//                     checked={selectedAnswers.includes(option)}
//                     onChange={() => handleAnswerChange(option)}
//                   />
//                   <span>{option}</span>
//                 </div>
//               ))}
//               <FormControl fullWidth>
//                 <InputLabel id="select-option-label">Options</InputLabel>
//                 <Select
//                   labelId="select-option-label"
//                   value={selectedOption}
//                   onChange={(e) => setSelectedOption(e.target.value)}
//                 >
//                   <MenuItem value="Option 1">Option 1</MenuItem>
//                   <MenuItem value="Option 2">Option 2</MenuItem>
//                   {/* Add more options as needed */}
//                 </Select>
//               </FormControl>
//               <Button variant="contained" onClick={handleSave}>
//                 Save
//               </Button>
//             </CardContent>
//           </Card>
//         )}
//       </Grid>
//     </Grid>
//   );
// };

// export default QuestionAnswerPage;
