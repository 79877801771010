import React, { useState, useEffect } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import api from '../../_data/api/backendApi';
import { Grid, Button, Box, Container, Stack, Typography, List, ListItem, ListItemText,
Paper, IconButton, TextField, Snackbar, Alert} from '@mui/material';
// components
import Iconify from '../../components/iconify';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from  '../../sections/@dashboard/blog';
// mock
import POSTS from '../../_data/blog';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import RefreshIcon from '@mui/icons-material/Refresh';
import getRoles from '../../_data/roles/getRoles';
import setRoles from '../../_data/user/setUserRoles';
import getUser from '../../_data/user/getUser';
// ----------------------------------------------------------------------

export default function UserRoleDetails() {
  const [userData, setUserData] = useState("");
  const location = useLocation();
  const userId = location.state?.userId[0];
  const [availableRoles, setAvailableRoles] = useState([]);
  const [assignedRoles, setAssignedRoles] = useState([]);

  useEffect(() => { 
    (async () => {
      const data = await fetchUserData();
      setUserData(data);
      setAssignedRoles(data.roles.Roles);
      loadRoles(); // TODO: Burada gittiği function async ama yukarıdaki kodda async olmadığı için önce o şekilde çağırdık her iki kullanımı da yapmak istedim.
    })();
  }, []);
  
  
  const fetchUserData = async () => {
    try {           
      const response = await getUser(userId);
      return response[0];
    } catch (err) {
      console.error("Error occured when user data fetching: ", err);
    }
  };

  const loadRoles = async () => {
    try {
        const response = await getRoles(); //coming all roles        
        setAvailableRoles(response);
    } catch (error) {
        console.error('Error loading roles:', error);
    }
  };
  const assignRole = (role) => {
    if (assignedRoles.includes(role.roletitle)) {
      showMessage("This role has already been assigned.", 'error');
    } else {
      setAvailableRoles(availableRoles.filter(r => r !== role.roletitle));
      setAssignedRoles([...assignedRoles, role.roletitle]);
    }
  };

  const removeRole = (role) => {
    setAssignedRoles(assignedRoles.filter(r => r !== role));
  };

  const saveRoles = async () => {
    //console.log('Saving roles&userData:', assignedRoles, userData);
    const result = await setRoles(assignedRoles, userData.id);
    //console.log("result: ", result);
    if(result.message === "Roles updated successfully."){
      showMessage(result.message, 'success');
      //console.log("OK!!!!");
    }else{
      showMessage(result.message, 'warning');
    }
    // Here you would use something like axios to send a PUT or PATCH request
  };

  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const handleRoleClick = (roletitle, idroles) => {
    setSelectedRoleId(idroles);   
  };

  const [selectedRoleTitle, setSelectedRoleTitle] = useState(null);
  const handleUserRoleClick = (roleTitle) => {
    setSelectedRoleTitle(roleTitle);  
  };

  const [roleTitle, setRoleTitle] = useState(null);
  const removeUserRole = (roleTitle) => { 
  };

  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const showMessage = (message, severityType = 'success') => {
    setAlertMessage(message);
    setSeverity(severityType);
    setMsgOpen(true);
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  return (
    //TODO: User create edildiği zaman ilk olarak users role modüle ekleyelim. Bu modül kendi ayarlarını görmeleri için gereklidir.
    <>
      <Helmet>
        <title> User Role Settings | OAK Academy </title>
        {/* 
          // TODO: Şirket Adı Dinamik olmalı........ 
        */}
      </Helmet>

      <Container maxWidth={false} sx={{ marginLeft: 0, maxWidth: 'none' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User Role Settings ({userData ? userData.name_surname : 'ERROR'})
          </Typography>
          <Button variant="contained" onClick={saveRoles} startIcon={<Iconify icon="eva:plus-fill" />}>
            Save
          </Button>
        </Stack>
        <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">          
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper sx = {{margin: 'auto', width: '100%'}}>
              <Typography variant="h5" sx = {{textAlign: 'center'}}>
                Roles
              </Typography>
              <List style={{ width: 'auto', maxWidth: 'none' }}>
                {availableRoles && availableRoles.map((role, index) => (
                  <ListItem button key={role.idroles} onClick={() => handleRoleClick(role.roletitle, role.idroles)}>                    
                    <Tooltip title={`${role.idroles}. ${role.roletitle}`} placement="top" arrow>
                      <ListItemText                                          
                        primary={`${role.idroles}. ${role.roletitle}`}                         
                        primaryTypographyProps={{
                          noWrap: true,
                          style: {
                            width: 'auto',
                            backgroundColor: selectedRoleId === role.idroles ? '#D4E7C5' : 'transparent', 
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }
                        }}                            
                      />
                    </Tooltip>
                    <IconButton onClick={(event) => {
                      event.stopPropagation(); // ListItem'ın onClick olayını tetiklemesini önler
                      assignRole(role);
                    }}>
                      <ArrowForwardIosIcon color="secondary"/>
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper sx = {{margin: 'auto', width: '100%'}}>
              <Typography variant="h5" sx = {{textAlign: 'center'}} >Defined User Roles</Typography>
                <List>
                  {/* {userData && userData.roles && userData.roles.Roles && userData.roles.Roles.map((roleTitle, index) => ( */}
                  {assignedRoles && assignedRoles.map((roleTitle, index) => (
                    <ListItem button key={index} onClick={() => handleUserRoleClick(roleTitle)}>                      
                      <IconButton onClick={(event) => {
                        event.stopPropagation(); // ListItem'ın onClick olayını tetiklemesini önler
                        removeRole(roleTitle); // Kullanıcı rolü kaldırma işlemi
                      }}>
                        <RemoveCircleOutlineIcon color="error" />
                      </IconButton>
                      <ListItemText
                        primary={roleTitle}
                        style={{
                          backgroundColor: selectedRoleTitle === roleTitle ? '#D4E7C5' : 'transparent'
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
            </Paper>
          </Grid>          
        </Grid>
      </Container>
      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={10000} 
        onClose={handleCloseMessage}
      >        
        <Alert severity={severity} onClose={handleCloseMessage} 
          sx={{ 
            width: 450, 
            minWidth: 288,
            fontSize: 15, 
            fontStyle: 'normal' 
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};