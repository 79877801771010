import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, SvgIcon, 
Grid, Autocomplete, Button, Box, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { ReactComponent as Icon_Attendance } from '../../../assets/icons/attendance.svg';
// component
import Iconify from '../../../components/iconify';
//Multi Language
import { useTranslation } from 'react-i18next'; 
import getStatus from '../../../_data/student/getStatus';
//import DynamicSelect from '../../../components/select/dynamicSelect';
import { getBatchNames } from '../../../_data/batchs/getBatchNames';
import getAllCourseCoordinators from '../../../_data/user/getAllCourseCoordination';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

StudentListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  statusName: PropTypes.string,
  onStatusName: PropTypes.func,
  BatchName: PropTypes.string,
  onBatchName: PropTypes.func,
};

export default function StudentListToolbar({ numSelected, filterName, onFilterName, onStatusName, onBatchName, onCoordinatorName, onCallInDays, onFirstRecord }) {
  const { t } = useTranslation();
  const autocompleteStatusRef = useRef(null);
  const autocompleteBatchRef = useRef(null);
  const autocompleteCoordinatorRef = useRef(null);
  const [statuses, setStatuses] = useState('');
  const [selectedStatusTitle, setSelectedStatusTitle] = useState([]);
  const [selectedStatusId, setSelectedStatusId] = useState([]);
  const [allstatuses, setAllStatuses] = useState([]); // List of All Statuses
  const [selectData, setSelectData] = useState();
  const [titleLabel, setTitleLabel] = useState("Search Course Name");
  const [selectedBatch, setSelectedBatch] = useState("");
  const [page, setPage] = useState(0);
  const [filterStudentId, setfilterStudentId] = useState('');
  const [selectedBatchValue, setSelectedBatchValue] = useState('');  
  const [allcoordinators, setAllCoordinators] = useState([]);  
  const [checked, setChecked] = useState(false);
  const [firstRecordChecked, setFirstRecordChecked] = useState(false);

  useEffect(() => {
    loadStatuses();
    loadBatchNames();
    loadCoordinators();
  }, []);

  const loadStatuses = async () => {
    try {
       const response = await getStatus(); //coming all statuses
       setAllStatuses(response);
    } catch (error) {
        console.error('Error loading loadStatuses:', error);
    }
  };

  const loadBatchNames = async () => {
    const data = await getBatchNames();    
    if (!data) {
      console.error("Result is undefined.");
      return;
    }
    setSelectData(data);
  };

  const loadCoordinators = async () => {
    try {
       const response = await getAllCourseCoordinators();
      // console.log('getAllCourseCoordinators: ', response);
       setAllCoordinators(response);
    } catch (error) {
        console.error('Error loading loadCoordinators:', error);
    }
  };  

  const handleSelectStatus = (status) => {
    if (statuses.indexOf(status.label) === -1) {
      setStatuses(statuses + (status.length > 0 ? ", " : "") + status.label);
    }
  };

  const handleSelectionChange = (value) => { 
  //  console.log('onBatchName: ', value);
    onBatchName(value);
  };

  const handleBatchChange = (event, newValue) => {
    setSelectedBatchValue(newValue);
    if (newValue) {
      onBatchName(newValue);
    } else {
      onBatchName(null);
    }
  };

  const handleCheck = (event) => {
   // console.log('handleCheck: ', event.target.checked);
    if(event.target.checked){
      setFirstRecordChecked(false);
      onFirstRecord(false); // Diğer checkbox'ın callback'ini de tetikleyin
    }
    setChecked(event.target.checked);
    onCallInDays(event.target.checked);
  };

  const handleFirstRecordCheck = (event) => {
    if(event.target.checked){
      setChecked(false);
      onCallInDays(false); // Diğer checkbox'ın callback'ini de tetikleyin
    }
    setFirstRecordChecked(event.target.checked);
    onFirstRecord(event.target.checked);
  };

  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} {t('CheckBoxSelected')}
        </Typography>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <StyledSearch
              value={filterName}
              onChange={onFilterName}
              placeholder={t('SearchStudent')}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
            />
          </Grid>
          {/* <Grid item xs={1}>
            <FormControlLabel
              control={<Checkbox name="firstrecord" 
                checked={firstRecordChecked}
                onChange={handleFirstRecordCheck} />}
              label="First Record"
            />
          </Grid>
          <Grid item xs={1}>
            <FormControlLabel
              control={<Checkbox name="callIn15Days" 
                checked={checked}
                onChange={handleCheck} />}
              label="Call in 15 Days"
            />
          </Grid> */}

          {/* <Grid item xs={2}>          
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={allcoordinators ? allcoordinators.map(coordinator => ({
                id: coordinator.id,
                label: coordinator.name_surname
              })) : []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ flexGrow: 1 }}
              renderInput={(params) => <TextField {...params} label="Search Coordinator" inputRef={autocompleteCoordinatorRef} />}
              onChange={(event, newValue) => {
                onCoordinatorName(newValue); // `newValue` seçilen değerdir. Bu, bir obje olabilir.
              }}
              ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }} // We set the height and scroll behavior here
              renderOption={(props, option, { selected }) => (
                <Box component="li" sx={{ '& > button': { mr: 2 }, fontWeight: 'normal' ,color: '#E90074' }} {...props}>
                  {option.label}
                </Box>
              )}
            />
          </Grid> */}

          {/* <Grid item xs={2}>
            <Autocomplete
              disablePortal
              id="combo-box-batch"
              options={(selectData && Array.isArray(selectData))  ? selectData?.map(batch => ({
                id: batch.batchname,
                label: batch.batchname
              })) : []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ flexGrow: 1 }}
              renderInput={(params) => <TextField {...params} label={t('SearchCourseName')} inputRef={autocompleteBatchRef} />}
              onChange={handleBatchChange}
              ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }} // We set the height and scroll behavior here
              renderOption={(props, option, { selected }) => (
                <Box component="li" sx={{ '& > button': { mr: 2 } }} {...props}>
                  {option.label}
                </Box>
              )}
            />
          </Grid> */}

          {/* <Grid item xs={3}>          
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={allstatuses ? allstatuses.map(status => ({
                id: status.idstudent_status,
                label: status.status_name
              })) : []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ flexGrow: 1 }}
              renderInput={(params) => <TextField {...params} label="Search Status" inputRef={autocompleteStatusRef} />}
              onChange={(event, newValue) => {
                onStatusName(newValue); // `newValue` seçilen değerdir. Bu, bir obje olabilir.
              }}
              ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }} // We set the height and scroll behavior here
              renderOption={(props, option, { selected }) => (
                <Box component="li" sx={{ '& > button': { mr: 2 } }} {...props}>
                  {option.label}
                </Box>
              )}
            />
          </Grid> */}
        </Grid>        
      )}
      {numSelected > 1 ? (
        <Tooltip title={t('Attendances')}>
          <IconButton>
            <SvgIcon component={Icon_Attendance} sx={{ mr: 2 }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t('FilterList')}>
          <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>
      )}
    </StyledRoot>
  );
}