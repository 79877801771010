import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const setStatusUser= async (userid, status) => {
    try {
        const token = await getToken();
        const response = await axios.put(`${BaseUrl}/users/updateUserStatus`, {
          userid: userid,
          status: status
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        );
        return response.data;
    } catch (error) {
        console.error("Update error: ", error);
        return [];
    }
};

export default setStatusUser;
