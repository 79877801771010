import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Snackbar, Alert, 
CircularProgress, Box, FormControlLabel } from '@mui/material'; /* Snackbar sağ köşede görünüp kaybolan notification için */
//import { LoadingButton } from '@mui/lab';
import LoadingButton from '@mui/lab/LoadingButton';

// components
import Iconify from '../../../components/iconify';

//getUsers
import CheckUser from './getCheckUser';
import config from '../../../config';


// ----------------------------------------------------------------------
const MESSAGES = {
  DatabaseMaitinance: 'The service is temporarily under maintenance. Please try again later. ',
  OtherMessage: 'Inactive', //TODO: bu alana gelen hatalara da bakmalıyım. Internet bağlantısı olmadığını (veya vpn) ayrı bir case'de ele alayım.--> net::ERR_CONNECTION_REFUSED bu hata backend çalışmayınca geliyor
  label3: 'Active',
};

export default function LoginForm() {
  const navigate = useNavigate();
  const vertical = 'top', horizontal = 'right';
  const [severity, setSeverity] = useState('success'); //Notification Show/hide  
  const [open, setOpen] = useState(false); //Notification Show/hide
  const [showPassword, setShowPassword] = useState(false);
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [alertMessage, setAlertMessage] = useState('');   
  const [isLoading, setIsLoading] = useState(false);
  //const [userMessage, setUserMessage] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const validateEmail = (email) => {
    // const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChangeUserName = (event) => {
    const { value } = event.target;
    setUsername(value);
    setEmailError(!validateEmail(value));
  };
  
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };
  
  // const handleClick = async (event) => {    
  //   try {
  //     if (!userName.trim() || !password.trim()) {
  //       setSeverity('error');
  //       setAlertMessage('Email address and password are required.');
  //       setOpen(true);
  //       // setAlert({ message: 'Email address and password are required.', severity: 'error', open: true });
  //       return;
  //     }
  //     //event.preventDefault(); // Formun varsayılan gönderme işlemini engelle
  //     if (!validateEmail(userName)) {
  //       setEmailError(true); // E-posta hatalıysa, hata durumunu ayarla
  //       setSeverity('error');
  //       setAlertMessage('Email address format is not  valid!.');
  //       setOpen(true);
  //       return; // Fonksiyonu burada sonlandır, böylece form gönderilmez
  //     }
  //     setIsLoading(true);
  //     const result = await CheckUser(userName, password);
  //     console.log("result.data:", result.data);
  //     // if (result.data){
  //       //   if (result.data && typeof result.data === 'object' && Object.keys(result.data).length <= 0)     
  //       if (result && result.status > 200){
  //         if(result.status === 401 && result.message === "Invalid username or password."){
  //           setSeverity('error');
  //           setAlertMessage(result.message);
  //           setOpen(true); 
  //         }else if (result.status === 200)

  //     //   if (result && typeof result === 'object' && Object.keys(result).length <= 0)     
  //     //   {      
  //     //     setSeverity('error');
  //     //     setAlertMessage('  Login Failed ! ');
  //     //     setOpen(true);    
  //     //   } else if(!result.data.user[0].is_active){
  //     //     setSeverity('warning');
  //     //     setAlertMessage('  User has not been activated yet !!!');
  //     //     setOpen(true);
  //     //   }
  //     //   else{                   
  //     //     setOpen(false);
  //     //     navigate('/dashboard', { replace: true });
  //     //   }
  //     // }else 



  //     }
  //     else{
  //       if(result === "The service is temporarily under maintenance. Please try again later.") //TODO: bu messagelar için global ve hatta database'den değer verilebilen bir yapı tesis et.
  //       {        
  //         setAlertMessage(MESSAGES.DatabaseMaitinance);
  //       }else{
  //         //console.log("Other Message: ", result);
  //         // setAlertMessage(MESSAGES.OtherMessage);
  //         setAlertMessage(result);
  //       }
  //       setSeverity('error');        
  //       setOpen(true);
  //     } 
  //   } catch (error) {
  //     console.log("handleClick: ",error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const appCode = config.AppCode;
  const handleClick = async (event) => {    
    try {
      if (!userName.trim() || !password.trim()) {
        setSeverity('error');
        setAlertMessage('Email address and password are required.');
        setOpen(true);
        return;
      }
      if (!validateEmail(userName)) {
        setEmailError(true);
        setSeverity('error');
        setAlertMessage('Email address format is not valid!.');
        setOpen(true);
        return;
      }
      setIsLoading(true);
      const result = await CheckUser(userName, password, appCode);
  
      // result'ın tanımsız olup olmadığını kontrol edelim
      if (!result) {
        console.error("Result is undefined.");
        setAlertMessage('An unexpected error occurred. Please try again.');
        setSeverity('error');        
        setOpen(true);
        return;
      }
  
      // Buradan itibaren, result'ın tanımlı olduğunu biliyoruz ve daha önceki kontrol mekanizmalarını kullanabiliriz.
      if (result.status === 200 && result.data) {
        //console.log('result.data.user[0]: ', result.data.user[0]);
        if(!result.data.user[0].is_active){
          setSeverity('warning');
          setAlertMessage('User has not been activated yet!');
          setOpen(true);
        } else {
          setOpen(false);
          navigate('/dashboard', { replace: true });
        }
      } else if(result.status === 401) {
        setSeverity('error');
        setAlertMessage(result.message || 'Invalid username or password.');
        setOpen(true);
      } else {
        setAlertMessage(result.message || 'Login failed! Please try again.');
        setSeverity('error');
        setOpen(true);
      }
    } catch (error) {
      console.error("handleClick: ",error);
      setAlertMessage('An unexpected error occurred. Please try again.');
      setSeverity('error');        
      setOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  
  const handleClose = () => {
    setOpen(false);
  };

  // const handleKeyDown = (event) => {
  //   if (event.key === 'Enter') {
  //     console.log('Press on Enter');
  //     // Enter tuşuna basıldığında istediğiniz işlevi burada çağırın
  //     handleClick(); // Örneğin login işlevi
  //   }
  // };

  const handleSubmit = (event) => {
    event.preventDefault(); // Formun sayfayı yenilemesini önler
    handleClick(); // Burası LoadingButton'un onClick fonksiyonunu çağırır
  };

  return (
    <>
    <form onSubmit={handleSubmit}>
      <Stack spacing={3}>
        {/* <TextField name="email" label="Email address" required onChange={handleChangeUserName}/> */}      
        <TextField
          error={emailError}
          helperText={emailError ? "Please enter a valid email address." : ""}
          name="email"
          label="Email address"
          required
          value={userName}
          autoComplete="email"
          onChange={handleChangeUserName}
          // onKeyDown={handleKeyDown}
        />

        <TextField
          name="password"
          label="Password"
          required
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          // onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handleChangePassword}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" value="Remember Me"/> 
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
      <FormControlLabel
        control={<Checkbox disabled name="remember" />}
        label="Remember me"
      />
      <Link variant="subtitle2" underline="hover">
        Forgot password?
      </Link>
    </Stack>



      {/* <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClick}
        loading={isLoading}
        loadingPosition="end"
        endIcon={<img src='/assets/logo.svg'  alt="Oak Academy logo" width="40" height="40" />}
      >Login</LoadingButton> */}
      {/* <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClick}
        loading={isLoading}
        loadingPosition="end"
        endIcon={
          isLoading ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            <img src='/assets/logo.svg' alt="Oak Academy logo" width="45" height="45" />
          )
        }
      >
        Login
      </LoadingButton> */}
      {/* <LoadingButton  burada işlemden sonra logo görünüyor ve circular ile yanyana kalıyor
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClick}
        loading={isLoading}
        loadingIndicator={
          <>
            <CircularProgress color="inherit" size={24} />
            {isLoading && <img src='/assets/logo.svg' alt="Oak Academy logo" style={{ marginLeft: 8, width: 40, height: 40 }} />}
          </>
        }
      >
        {isLoading ? '' : 'Login'}
      </LoadingButton> */}

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClick}
        loading={isLoading}
        sx={{ fontSize: isLoading ? '22' : '18',
          bgcolor: isLoading ? '#5141C3' : 'blue',
          color: 'white',
          ':hover': {
            bgcolor: isLoading ? '#5141C3' : '#5141C3',
            fontSize: isLoading ? '18' : '24'
          },
          ':disabled': {
            bgcolor: isLoading ? '#5141C3' : '',
            color: isLoading ? 'white' : '',
          }
        }}  
        startIcon={isLoading ? <CircularProgress color="inherit" size={30} /> : null}>        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '9%' }}> {isLoading ? 'Loading...' : 'Login'}          
          <Box component="img" src='/assets/logo.svg' alt="Oak Academy logo" sx={{  marginLeft:10, width: 50, height: 50, display: isLoading ? '/assets/logo.svg' : 'block' }} />
        </Box>
      </LoadingButton>

      <Snackbar anchorOrigin={{ vertical, horizontal }} open={open}  autoHideDuration={8000} onClose={handleClose}>
        <Alert severity={severity} onClose={handleClose} 
          sx={{ 
            width: 450, 
            minWidth: 288,
            fontSize: 15, 
            fontStyle: 'normal' 
          }}
        >
        {alertMessage}
      </Alert>
      </Snackbar>
      </form>
    </>
  );
};



