import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useRef } from 'react';
// @mui
import { Container, Stack, Typography, Card, List, ListItem, Box, Grid, Paper, 
ListItemText, Button, TextField, Alert, Snackbar, Dialog, DialogTitle, 
DialogContent, DialogActions } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
// components
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../../sections/@dashboard/products';
// mock
import getModules from '../../_data/syllabus/getModules';
import setModules from '../../_data/syllabus/setModules';
import deleteModules from '../../_data/syllabus/deleteModules';
import updateModules from '../../_data/syllabus/updateModules';
import getSession from '../../utils/getSession';
import getLectures from '../../_data/syllabus/getLectures';
import setLectures from '../../_data/syllabus/setLectures';

// ----------------------------------------------------------------------

export default function SyllabusSettings() {
  const textFieldRefModuleName = useRef(null);
  const textFieldRefDuration = useRef(null);
  const [modulesInfo, setModulesInfo] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [selectedModuleId, setSelectedModuleId] = useState(0);
  const [selectedModuleName, setSelectedModuleName] = useState("");
  const [newModuleName, setNewModuleName] = useState("");
  const [selectedDuration, setSelectedDUration] = useState("");
  const [newDuration, setNewDuration] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [selectedDecription, setSelectedDescription] = useState("");    
  const [error, setError] = useState(false);
  const [isEnabledInsertBtn, setIsEnabledInsertBtn] = useState(false);
  const [isEnabledDeleteBtn, setIsEnabledDeleteBtn] = useState(true);
  const [isEnabledUpdateBtn, setIsEnabledUpdateBtn] = useState(true);
  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [helperTextModuleName, setHelperTextModuleName] = useState('');
  const [fieldModuleNameError, setFieldModuleNameError] = useState(false);
  const [helperTextDuration, setHelperTextDuration] = useState('');
  const [fieldDurationError, setFieldDurationError] = useState(false);
  const account = getSession();
  const bycreated = account.user[0].iduser;
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState({ action: null });

  const [lectureError, setLectureError] = useState(false);
  const textFieldRefLectureTopic = useRef(null);
  const textFieldRefLectureDuration = useRef(null);
  const [lecturesInfo, setLecturesInfo] = useState([]);
  const [selectedLectureId, setSelectedLectureId] = useState(0);
  const [selectedLectureTopic, setSelectedLectureTopic] = useState("");
  const [newLectureTopic, setNewLectureTopic] = useState("");
  const [newLectureDuration, setNewLectureDuration] = useState("");
  const [newLectureDescription, setNewLectureDescription] = useState("");
  const [selectedLectureDuration, setSelectedLectureDuration] = useState(""); 
  const [selectedLectureDescription, setSelectedLectureDescription] = useState("");
  const [isEnabledLectureInsertBtn, setIsEnabledLectureInsertBtn] = useState(false);
  const [isEnabledLectureDeleteBtn, setIsEnabledLectureDeleteBtn] = useState(true);
  const [isEnabledLectureUpdateBtn, setIsEnabledLectureUpdateBtn] = useState(true);
  const [helperTextLectureTopic, setHelperTextLectureTopic] = useState('');
  const [fieldLectureTopicError, setFieldLectureTopicError] = useState(false);
  const [helperTextLectureDuration, setHelperTextLectureDuration] = useState('');
  const [fieldLectureDurationError, setFieldLectureDurationError] = useState(false);
  const [filteredLectures, setFilteredLectures] = useState([]);
  const [lessonTimeOverflow, setLessonTimeOverflow] = useState(false);
  const [expanded1, setExpanded1] = useState(true);
  const [expanded2, setExpanded2] = useState(true);
  const [expanded3, setExpanded3] = useState(true);

  const expandIconStyle1 = expanded1 ? { transform: 'rotate(180deg)' } : {};
  const expandIconStyle2 = expanded2 ? { transform: 'rotate(180deg)' } : {};
  const expandIconStyle3 = expanded3? { transform: 'rotate(180deg)' } : {};

  const handleExpand1Click = () => {
    setExpanded1(!expanded1);
  };
  const handleExpand2Click = () => {
    setExpanded2(!expanded2);
  };
  const handleExpand3Click = () => {
    setExpanded3(!expanded3);
  };

  const showMessage = (message, severityType = 'success') => {
    setAlertMessage(message);
    setSeverity(severityType);
    setMsgOpen(true);
  };

  const handleConfirmDialogOpen = (selectedAction) => {
    //console.log("XXX selectedAction: ", selectedAction);
    setCurrentAction(selectedAction);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirm = async () => {
    if(currentAction === 0){ //0 for delete
      const result = await deleteModules(selectedModuleId, account.user[0].iduser);
      if(result.data.affectedRows > 0){
        showMessage(result.data.message, 'success');
        setResetFields();
        loadModules();
      }
    } else if(currentAction === 1){ //1 for update
      const result = await updateModules(selectedModuleId, newModuleName, newDuration, newDescription, account.user[0].iduser);      
      if(result && result.affectedRows > 0){
        showMessage(result.message, 'success');
        setResetFields();
        loadModules();
      }
    }
    handleConfirmDialogClose();
  };

  const validateForm = () => {
    let isValid = true;
    if (!newModuleName) {
      setFieldModuleNameError(true);
      setHelperTextModuleName('This field cannot be empty.');
      isValid = false;
      focusTextModuleName();
    } else {
      setFieldModuleNameError(false);
      setHelperTextModuleName('');
    }
    if (!newDuration || parseFloat(newDuration) <= 0) {
      setFieldDurationError(true);
      setHelperTextDuration('This field must be greater than 0.');
      isValid = false;
      focusTextDuration();
    } else {
      setFieldDurationError(false);
      setHelperTextDuration('');
    }
    return isValid;
  };

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  const focusTextModuleName = () => {
    textFieldRefModuleName.current.focus();
  };

  const focusTextDuration = () => {
    textFieldRefDuration.current.focus();
  };

  useEffect(() => {
    loadModules();
    loadLectures(selectedModuleId);
  }, []);

  const loadModules = async () => {
    const result = await getModules();
    setModulesInfo(result);
   // console.log("result: ", result);
    if(result && result.length > 0)
      handleModulesClick(result[0].idsyllabus_modules,result[0].modulename, result[0].lessonduration,  result[0].description, result[0].lessontimeoverflow);
  };

  const [openFilter, setOpenFilter] = useState(false);
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleModulesClick = (ModuleId, ModuleName, Duration, Decription, LessonTimeOverflow) => {
    setSelectedModuleId(ModuleId);
    setSelectedModuleName(ModuleName);
    setSelectedLectureDuration(Duration);
    setSelectedDescription(Decription);
    setNewModuleName(ModuleName);
    setNewDuration(Duration);
    setNewDescription(Decription);
    setIsEnabledInsertBtn(false);
    setIsEnabledDeleteBtn(true);
    setIsEnabledUpdateBtn(true);
    setFieldModuleNameError(false);
    setHelperTextModuleName('');
    setFieldDurationError(false);
    setHelperTextDuration('');
    //console.log("lecturesInfo: ", lecturesInfo);
    const filtered = lecturesInfo.filter(lecture => lecture.moduleid === ModuleId);
   // console.log("filtered: ", filtered); 
    setFilteredLectures(filtered);
  //  console.log("LessonTimeOverflow: ", LessonTimeOverflow);
    setLessonTimeOverflow(LessonTimeOverflow);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleInsert = async() => {
    if (validateForm()) {
      //console.log("handleInsert: ", newModuleName, newDuration, newDescription, bycreated);
      const result = await setModules(newModuleName, newDuration, newDescription, bycreated);
      //console.log("result: ", result);
      if(result.affectedRows > 0){
        showMessage(result.message, 'success');
        setResetFields();
        setIsEnabledInsertBtn(false);
        setIsEnabledDeleteBtn(true);
        setIsEnabledUpdateBtn(true);
        loadModules();
      }
    }
  };
  
  const setResetFields = () => {
    setNewDuration("");
    setNewModuleName("");
    setNewDescription("");
  }

  const handleDurationChange = (value) => {
    if (/^\d*$/.test(value)) { // regex for only numeric       
      setError(false);
      setFieldDurationError(false);
      setHelperTextDuration('');
      setNewDuration(value)
    } else {
      setError(true);
    }
  };

  const handleNew = () => {
    setResetFields();
    setIsEnabledInsertBtn(!isEnabledInsertBtn);
    setIsEnabledDeleteBtn(!isEnabledDeleteBtn);
    setIsEnabledUpdateBtn(!isEnabledUpdateBtn);
    focusTextModuleName();
  };

  const handleUpdate = () => {
    handleConfirmDialogOpen(1); //1 for updating...
  };

  const handleDelete = async () => {
    handleConfirmDialogOpen(0); //0 for deleting...
  };
  /*********************************LECTURES***********************************/

  const loadLectures = async (modulid) => {
    const result = await getLectures();
   // console.log("Lectures result: ", result);
    setLecturesInfo(result);
    if(result && result.length > 0)
      handleLecturesClick(result[0].idsyllabus_modules,result[0].lecturetopic, result[0].lectureduration,  result[0].lecturedescription);
  };

  const validateLectureForm = () => {
    let isValid = true;
    if (!newLectureTopic) {
      setFieldLectureTopicError(true);
      setHelperTextLectureTopic('This field cannot be empty.');
      isValid = false;
      focusTextLectureTopic();
    } else {
      setFieldLectureTopicError(false);
      setHelperTextLectureTopic('');
    }
    if (!newDuration || parseFloat(newDuration) <= 0) {
      setFieldLectureDurationError(true);
      setHelperTextLectureDuration('This field must be greater than 0.');
      isValid = false;
      focusTextLectureDuration();
    } else {
      setFieldLectureDurationError(false);
      setHelperTextLectureDuration('');
    }
    return isValid;
  };

  const handleLectureInsert = async() => {
    if (validateLectureForm()) {
    //  console.log("handleInsert: ", newLectureTopic, newLectureDuration, newLectureDescription, bycreated);
      const result = await setLectures(newLectureTopic, newLectureDuration, newLectureDescription, bycreated);
    //  console.log("result (handleLectureInsert): ", result);
      if(result.affectedRows > 0){
        showMessage(result.message, 'success');
        setResetFields();
        setIsEnabledLectureInsertBtn(false);
        setIsEnabledLectureDeleteBtn(true);
        setIsEnabledLectureUpdateBtn(true);
        loadLectures();
      }
    }
  };

  const handleLectureUpdate = () => {
    handleConfirmDialogOpen(2); //1 for lecture updating...
  };

  const handleLectureDelete = async () => {
    handleConfirmDialogOpen(3); //0 for lecture deleting...
  };

  const setResetLectureFields = () => {
    setNewLectureDuration("");
    setNewLectureTopic("");
    setNewLectureDescription("");
  }
  const focusTextLectureTopic = () => {
    textFieldRefModuleName.current.focus();
  };

  const focusTextLectureDuration = () => {
    textFieldRefDuration.current.focus();
  };

  const handleLecturesClick = (LectureId, ModuleId, LectureTopic, LectureDuration, LectureDecription) => {
    setSelectedLectureId(LectureId);    
    setSelectedLectureTopic(LectureTopic);
    setSelectedLectureDuration(LectureDuration);
    setSelectedLectureDescription(LectureDecription);
    setNewLectureTopic(LectureTopic);
    setNewLectureDuration(LectureDuration);
    setNewLectureDescription(LectureDecription);
    setIsEnabledLectureInsertBtn(false);
    setIsEnabledLectureDeleteBtn(true);
    setIsEnabledLectureUpdateBtn(true);
    setFieldLectureTopicError(false);
    setHelperTextLectureTopic('');
    setFieldLectureDurationError(false);
    setHelperTextLectureDuration('');
  };
  const handleLectureDurationChange = (value) => {
    if (/^\d*$/.test(value)) { // regex for only numeric       
      setLectureError(false);
      setFieldLectureDurationError(false);
      setHelperTextLectureDuration('');
      setNewLectureDuration(value)
    } else {
      setLectureError(true);
      
    }
  };

 /*********************************LECTURES***********************************/
  return (
    <>
      <Helmet>
        <title> Academic Settings | Oak Academy </title>
      </Helmet>
      <Container>
        <Card>
          <CardHeader
          avatar={
            <IconButton
              onClick={handleExpand1Click}
              aria-expanded={expanded1}
              aria-label="show more"
            >
              <ExpandMoreIcon style={expandIconStyle1} />
            </IconButton>
          }
          title = "Syllabus Modules"
          sx = {{ cursor: 'pointer', userSelect: 'none' }}
          onClick={handleExpand1Click}
        />
          <Collapse in={expanded1} timeout="auto" unmountOnExit>
          <CardContent>
              <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-start" sx={{ mb: 1, pl: 1}}>
                <Stack direction="row" justifyContent="flex-start" spacing={1} flexShrink={0} sx={{ mb: 0 }}>
                  <Box 
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '16px',
                      border: 1,
                      borderColor: 'grey.300',
                      p: 2,
                      bgcolor: 'background.paper',
                      width: 600,
                      maxWidth: 1000,
                      // Set the maximum height value here and then put the scrollbar.
                      overflow: 'auto',
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item sx = {{textAlign: 'left', width: '100%'} } >                      
                        <Paper style={{ width: '100%', maxHeight: 500, overflow: 'auto' }}>
                        <List>
                          {modulesInfo && modulesInfo.map((module, index) => (                         
                            <ListItem button key={index} onClick={() => handleModulesClick(module.idsyllabus_modules, 
                            module.modulename, module.lessonduration, module.description, module.lessontimeoverflow)}>
                              <ListItemText 
                                primary={
                                  <Typography component="span" variant="body1" sx={{ color: '#31363F'}}>
                                    {`${index+1}. ${module.modulename} | `}
                                    <Typography component="span" variant="body1" sx={{ fontWeight: 'bold', color: '#F7418F' }}>
                                      {`${module.lessonduration} dakika`}
                                    </Typography>
                                    {` | ${module.description}`} {` | ${module.lessontimeoverflow}`}
                                  </Typography>                                
                                }                               
                                style={{ backgroundColor: selectedModuleId === module.idsyllabus_modules ? '#D4E7C5' : 'transparent' }}
                              />
                            </ListItem>                         
                          ))}
                        </List>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Box>                
                  <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 2,
                        border: '1px solid #c4c4c4',
                        borderRadius: '8px',
                        bgcolor: 'white',
                        boxShadow: 1,
                        '&:hover': {
                            borderColor: 'primary.main',
                        },
                        width: 400,
                        maxWidth: 600,
                        marginBottom: 2
                    }}
                    noValidate
                    autoComplete="off"
                >                
                    <TextField
                        label="Module Name"
                        variant="outlined"
                        multiline
                        inputRef={textFieldRefModuleName}
                        value={newModuleName}
                        onChange={(e) => setNewModuleName(e.target.value)}
                        placeholder="Module Name"
                        error={fieldModuleNameError}
                        helperText={helperTextModuleName}                     
                        sx={{ ml:2, width: 'calc(100% - 32px)'}}
                    />
                    <TextField
                        //error={error}
                        label="Lesson Duration Munites (only numaric)"
                        //helperText={error ? "Please insert a numeric value!" : ""}
                        variant="outlined"
                        inputRef={textFieldRefDuration}
                        value={newDuration}
                        error={fieldDurationError}
                        helperText={helperTextDuration}
                        onChange={(e) => handleDurationChange(e.target.value)}
                        placeholder="Lesson Duration (Munites)"
                        sx={{ ml:2, width: 'calc(100% - 32px)' }}
                        fullWidth
                    /> {error && <Alert severity="error" sx={{ width: 'calc(100% - 32px)', mt: -1, mb: 2 }}>Please insert a numeric value!</Alert>}
                    <TextField
                        label="Description"
                        variant="outlined"
                        multiline
                        value={newDescription}
                        onChange={(e) => setNewDescription(e.target.value)}
                        placeholder="Description"
                        sx={{ ml:2, width: 'calc(100% - 32px)' }}
                        fullWidth
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                      <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          sx={{ py: '20px', px: '20px', my: 1}}
                          onClick={handleNew}
                          >
                          New
                      </Button>
                      <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          sx={{ py: '20px', px: '20px', my: 1}}
                          disabled={!isEnabledInsertBtn}
                          onClick={handleInsert}
                          >
                          Insert
                      </Button>
                      <Button
                          type="submit"
                          variant="contained"
                          color="success"
                          sx={{ py: '20px', px: '20px', my: 1}}
                          disabled={!isEnabledUpdateBtn}
                          onClick={handleUpdate}
                          >
                          Update
                      </Button>
                      <Button
                          type="submit"
                          variant="contained"
                          color="error"
                          sx={{ py: '20px', px: '20px', my: 1}}
                          disabled={!isEnabledDeleteBtn}
                          onClick={handleDelete}
                          >
                          Delete
                      </Button>
                    </div>
                  </Box>
                </Stack>
              </Stack>
            </CardContent>
          </Collapse>
        </Card>
        <Card sx = {{mt: 3}}>
          <CardHeader
            avatar={
              <IconButton
                onClick={handleExpand2Click}
                aria-expanded={expanded2}
                aria-label="show more"
              >
                <ExpandMoreIcon style={expandIconStyle2} />
              </IconButton>
            }
            title = "Syllabus Lectures"
            sx = {{ cursor: 'pointer', userSelect: 'none', color: 'brown'}}
            onClick={handleExpand2Click}
          />
          <AccessTimeIcon
            sx={{ ml:3, color: lessonTimeOverflow ? 'red' : 'green' }}
          />
          <Collapse in={expanded2} timeout="auto" unmountOnExit>
          <CardContent>

          {/* <Typography variant="h5" sx={{ mb: 1, pl:2 , pt:2, color: 'brown'}}>
          Syllabus Lectures
          <AccessTimeIcon
            sx={{ ml:3, color: lessonTimeOverflow ? 'red' : 'green' }}
          />
            {lessonTimeOverflow && (
              <Typography
                sx={{ color: 'red', marginLeft: 1 }}
              >
                Plan time overflow!
              </Typography>
            )}
            </Typography> */}
              <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-start" sx={{ mb: 1, pl: 1}}>
                <Stack direction="row" justifyContent="flex-start" spacing={1} flexShrink={0} sx={{ mb: 0 }}>
                  <Box 
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '16px',
                      border: 1,
                      borderColor: 'grey.300',
                      p: 2,
                      bgcolor: 'background.paper',
                      width: 600,
                      maxWidth: 1000,
                      overflow: 'auto',
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item sx = {{textAlign: 'left', width: '100%'} } >                      
                        <Paper style={{ width: '100%', maxHeight: 500, overflow: 'auto' }}>                                           
                        <List>
                          {filteredLectures && filteredLectures.map((lecture, index) => (
                            <ListItem button key={index} onClick={() => handleLecturesClick(lecture.idsyllabus_lecture, 
                              lecture.moduleid, lecture.lecturetopic, lecture.lectureduration, lecture.lecturedescription)}>                          
                              <ListItemText 
                                primary={
                                  <Typography component="span" variant="body1" sx={{ color: '#31363F' }}>
                                    {`${index+1}. ${lecture.lecturetopic} | `}
                                    <Typography component="span" variant="body1" sx={{ fontWeight: 'bold', color: '#F7418F' }}>
                                      {`${lecture.lectureduration} dakika`}
                                    </Typography>
                                    {` | ${lecture.description}`}
                                  </Typography>
                                } 
                                style={{ backgroundColor: selectedLectureId  === lecture.idsyllabus_lecture ? '#D4E7C5' : 'transparent' }}
                              />
                            </ListItem>
                          ))}
                        </List>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Box>                
                  <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 2,
                        border: '1px solid #c4c4c4',
                        borderRadius: '8px',
                        bgcolor: 'white',
                        boxShadow: 1,
                        '&:hover': {
                            borderColor: 'primary.main',
                        },
                        width: 400,
                        maxWidth: 600,
                        marginBottom: 2
                    }}
                    noValidate
                    autoComplete="off"
                >                
                    <TextField
                        label="Lecture Name"
                        variant="outlined"
                        multiline
                        inputRef={textFieldRefModuleName}
                        value={newLectureTopic}
                        onChange={(e) => setNewLectureTopic(e.target.value)}
                        placeholder="Lecture Topic"
                        error={fieldLectureTopicError}
                        helperText={helperTextLectureTopic}                     
                        sx={{ ml:2, mb:2, width: 'calc(100% - 32px)'}}
                    />
                    <TextField
                        //error={error}
                        label="Lecture Duration Munites (only numaric)"
                        //helperText={error ? "Please insert a numeric value!" : ""}
                        variant="outlined"
                        inputRef={textFieldRefLectureDuration}
                        value={newLectureDuration}
                        error={fieldLectureDurationError}
                        helperText={helperTextLectureDuration}
                        onChange={(e) => handleLectureDurationChange(e.target.value)}
                        placeholder="Lecture Duration (Munites)"
                        sx={{ ml:2, mb:2, width: 'calc(100% - 32px)' }}
                        fullWidth
                    /> {error && <Alert severity="error" sx={{ width: 'calc(100% - 32px)', mt: -1, mb: 2 }}>Please insert a numeric value!</Alert>}
                    <TextField
                        label="Lecture Description"
                        variant="outlined"
                        multiline
                        value={newLectureDescription}
                        onChange={(e) => setNewLectureDescription(e.target.value)}
                        placeholder="Description"
                        sx={{ ml:2, width: 'calc(100% - 32px)' }}
                        fullWidth
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                      <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          sx={{ py: '20px', px: '20px', my: 1}}
                          onClick={handleNew}
                          >
                          New
                      </Button>
                      <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          sx={{ py: '20px', px: '20px', my: 1}}
                          disabled={!isEnabledInsertBtn}
                          onClick={handleLectureInsert}
                          >
                          Insert
                      </Button>
                      <Button
                          type="submit"
                          variant="contained"
                          color="success"
                          sx={{ py: '20px', px: '20px', my: 1}}
                          disabled={!isEnabledUpdateBtn}
                          onClick={handleLectureUpdate}
                          >
                          Update
                      </Button>
                      <Button
                          type="submit"
                          variant="contained"
                          color="error"
                          sx={{ py: '20px', px: '20px', my: 1}}
                          disabled={!isEnabledDeleteBtn}
                          onClick={handleLectureDelete}
                          >
                          Delete
                      </Button>
                    </div>
                  </Box>
                </Stack>
              </Stack>
            </CardContent>
          </Collapse>
        </Card>
        <Card sx = {{mt: 3}}>
          <CardHeader
          avatar={
            <IconButton
              onClick={handleExpand3Click}
              aria-expanded={expanded3}
              aria-label="show more"
            >
              <ExpandMoreIcon style={expandIconStyle3} />
            </IconButton>
          }
          title = "Instructors"
          sx = {{ cursor: 'pointer', userSelect: 'none' }}
          onClick={handleExpand3Click}
        />
          <Collapse in={expanded3} timeout="auto" unmountOnExit>
          <CardContent>
              <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-start" sx={{ mb: 1, pl: 1}}>
                <Stack direction="row" justifyContent="flex-start" spacing={1} flexShrink={0} sx={{ mb: 0 }}>
                  <Box 
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '16px',
                      border: 1,
                      borderColor: 'grey.300',
                      p: 2,
                      bgcolor: 'background.paper',
                      width: 600,
                      maxWidth: 1000,
                      // Set the maximum height value here and then put the scrollbar.
                      overflow: 'auto',
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item sx = {{textAlign: 'left', width: '100%'} } >                      
                        <Paper style={{ width: '100%', maxHeight: 500, overflow: 'auto' }}>
                        <List>
                          {modulesInfo && modulesInfo.map((module, index) => (                         
                            <ListItem button key={index} onClick={() => handleModulesClick(module.idsyllabus_modules, 
                            module.modulename, module.lessonduration, module.description, module.lessontimeoverflow)}>
                              <ListItemText 
                                primary={
                                  <Typography component="span" variant="body1" sx={{ color: '#31363F'}}>
                                    {`${index+1}. ${module.modulename} | `}
                                    <Typography component="span" variant="body1" sx={{ fontWeight: 'bold', color: '#F7418F' }}>
                                      {`${module.lessonduration} dakika`}
                                    </Typography>
                                    {` | ${module.description}`} {` | ${module.lessontimeoverflow}`}
                                  </Typography>                                
                                }                               
                                style={{ backgroundColor: selectedModuleId === module.idsyllabus_modules ? '#D4E7C5' : 'transparent' }}
                              />
                            </ListItem>                         
                          ))}
                        </List>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Box>                
                  <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 2,
                        border: '1px solid #c4c4c4',
                        borderRadius: '8px',
                        bgcolor: 'white',
                        boxShadow: 1,
                        '&:hover': {
                            borderColor: 'primary.main',
                        },
                        width: 400,
                        maxWidth: 600,
                        marginBottom: 2
                    }}
                    noValidate
                    autoComplete="off"
                >                
                    <TextField
                        label="Module Name"
                        variant="outlined"
                        multiline
                        inputRef={textFieldRefModuleName}
                        value={newModuleName}
                        onChange={(e) => setNewModuleName(e.target.value)}
                        placeholder="Module Name"
                        error={fieldModuleNameError}
                        helperText={helperTextModuleName}                     
                        sx={{ ml:2, width: 'calc(100% - 32px)'}}
                    />
                    <TextField
                        //error={error}
                        label="Lesson Duration Munites (only numaric)"
                        //helperText={error ? "Please insert a numeric value!" : ""}
                        variant="outlined"
                        inputRef={textFieldRefDuration}
                        value={newDuration}
                        error={fieldDurationError}
                        helperText={helperTextDuration}
                        onChange={(e) => handleDurationChange(e.target.value)}
                        placeholder="Lesson Duration (Munites)"
                        sx={{ ml:2, width: 'calc(100% - 32px)' }}
                        fullWidth
                    /> {error && <Alert severity="error" sx={{ width: 'calc(100% - 32px)', mt: -1, mb: 2 }}>Please insert a numeric value!</Alert>}
                    <TextField
                        label="Description"
                        variant="outlined"
                        multiline
                        value={newDescription}
                        onChange={(e) => setNewDescription(e.target.value)}
                        placeholder="Description"
                        sx={{ ml:2, width: 'calc(100% - 32px)' }}
                        fullWidth
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                      <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          sx={{ py: '20px', px: '20px', my: 1}}
                          onClick={handleNew}
                          >
                          New
                      </Button>
                      <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          sx={{ py: '20px', px: '20px', my: 1}}
                          disabled={!isEnabledInsertBtn}
                          onClick={handleInsert}
                          >
                          Insert
                      </Button>
                      <Button
                          type="submit"
                          variant="contained"
                          color="success"
                          sx={{ py: '20px', px: '20px', my: 1}}
                          disabled={!isEnabledUpdateBtn}
                          onClick={handleUpdate}
                          >
                          Update
                      </Button>
                      <Button
                          type="submit"
                          variant="contained"
                          color="error"
                          sx={{ py: '20px', px: '20px', my: 1}}
                          disabled={!isEnabledDeleteBtn}
                          onClick={handleDelete}
                          >
                          Delete
                      </Button>
                    </div>
                  </Box>
                </Stack>
              </Stack>
            </CardContent>
          </Collapse>
        </Card>
      </Container>
      <Dialog
        open={openConfirmDialog}
        onClose={handleConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
            <p style={{fontWeight: 'bold', color: "#1640D6", marginBottom: 2 }}>
              {selectedModuleName}
            </p>
          <p>The above 'Syllabus Module Name' will be {` `}</p>
            <span style={{ fontWeight: 'bold', color: currentAction.action === 0 ? 'red' : 'green' }}>
              {currentAction.action === 0 ? 'DELETE' : 'UPDATE'}
            </span>.
          <p>Are you sure you want to do this?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleConfirm} 
            autoFocus
            style={{
              // backgroundColor: currentAction.status === 0 ? 'red' : 'green',
              backgroundColor: 'red',
              color: '#FFFFFF',
            }}
          >
            OK
          </Button>
        </DialogActions>        
      </Dialog>
      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={10000} 
        onClose={handleCloseMessage}
      >        
        <Alert severity={severity} onClose={handleCloseMessage} 
          sx={{ 
            width: 450, 
            minWidth: 288,
            fontSize: 15, 
            fontStyle: 'normal' 
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
