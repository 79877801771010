import React, { forwardRef } from 'react';
import MaskedInput from 'react-text-mask';
import TextField from '@mui/material/TextField';

const TextMaskCustom = forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <MaskedInput
      {...props}
      ref={(inputElement) => {
        // MaskedInput'a ref olarak bir fonksiyon veriliyor
        // Bu fonksiyon içinde, dışarıdan gelen ref'e DOM elemanı iletiliyor
        if (typeof ref === 'function') {
          ref(inputElement);
        } else if (ref) {
          ref.current = inputElement;
        }
      }}
      mask={['+', /[1-9]/, /\d/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      onChange={onChange}
    />
  );
});

const PhoneInput = forwardRef(({ ...props }, ref) => {
return (
  <TextField
    {...props}
    InputProps={{
      inputComponent: TextMaskCustom,
      inputProps: {
        ...props.inputProps,
        ref: ref, // ref'i inputProps üzerinden TextMaskCustom'a aktar
      },
    }}
  />
);
});
export default PhoneInput;












/*
import React, { forwardRef } from 'react';
import MaskedInput from 'react-text-mask';
import TextField from '@mui/material/TextField';

// const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
//   // props'tan onChange ve diğer gerekli prop'ları al
//   const { onChange, ...other } = props;

//   // inputRef prop'unu MaskedInput'a doğrudan ref olarak iletiyoruz
//   return (
//     <MaskedInput
//       {...other}
//       ref={ref} // ref'i doğrudan iletiyoruz
//       mask={['+', /[1-9]/, /\d/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
//       placeholderChar={'\u2000'}
//       showMask
//       guide={false}
//       onChange={onChange}
//     />
//   );
// });
// TODO: Yukarıdaki kod da alttaki açık olan da çalışıyor hata almamak için yeniden yazdık ama sonuç değişmedi.
const TextMaskCustom = forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <MaskedInput
      // {...props}
      {...other}
      ref={(inputElement) => {
        // MaskedInput'a ref olarak bir fonksiyon veriliyor
        // Bu fonksiyon içinde, dışarıdan gelen ref'e DOM elemanı iletiliyor
        if (typeof ref === 'function') {
          ref(inputElement);
        } else if (ref) {
          ref.current = inputElement;
        }
      }}
      // Diğer prop'lar ve maskeleme ayarları...
      mask={['+', /[1-9]/, /\d/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
      onChange={onChange}
    />
  );
});

const PhoneInput = forwardRef(({ ...props }, ref) => {
 // const { inputProps, ...otherProps } = props; // inputProps'u ayrıştır
  return (
    <TextField
       {...props}
      InputProps={{
        inputComponent: TextMaskCustom,
        inputProps: {
           ...props.inputProps,
           ref: ref, // ref'i inputProps üzerinden TextMaskCustom'a aktar
        },
      }}
    />
  );
});

// const PhoneInput = (props) => {
//   // TextField'e gelecek tüm props'ları doğrudan aktar
//   return (
//     <TextField
//       {...props}
//       InputProps={{
//         inputComponent: TextMaskCustom, // MaskedInput'ı özel bir wrapper ile kullan
//         inputProps: {
//           ...props.inputProps,
//           ref: props.inputRef, // ref'i inputProps üzerinden TextMaskCustom'a aktar
//         },
//       }}
//     />
//   );
// }


// const PhoneInput = ({ inputRef, ...props }) => {
//   return (
//     <TextField
//       {...props}
//       InputProps={{
//         inputComponent: TextMaskCustom,
//         inputProps: {
//           ...props.inputProps,
//           ref: inputRef, // ref'i inputProps üzerinden TextMaskCustom'a aktar
//         },
//       }}
//     />
//   );
// };

export default PhoneInput;*/