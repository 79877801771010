
import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getApplications = async () =>{
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/roles/getapps`,{
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });    
    return response.data;
  } catch (error) {  
      console.error("getApplications Error: ", error);
      return [];
  }
};

export default getApplications;