
import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
import { getStudent } from '../../_data/student/getStudent';
import PasswordGenerator from '../../components/userForm/getGeneratePassword';
const { BaseUrl } = config;

const setStudentUser= async (idstudent, recousecode) => {
  try {
    const token = await getToken();   
    const datas = await getStudent(idstudent);
    const userPassword = PasswordGenerator();
    if(datas){
      const studentDatas = {
        name_surname: `${datas.name} ${datas.surname}`, 
        username: datas.email, 
        passwd: userPassword, 
        idstudents: idstudent,
        recousecode: recousecode
      }      
      const response = await axios.post(`${BaseUrl}/users/setstudentusers`, 
        { studentDatas } ,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      return response.data;    
    }else{
        return {message: "Student not found!"}
    }
  } catch (error) {
      console.error("User Insert error: ", error);
      return [];
  }
};

export default setStudentUser;