import axios from 'axios';
import getToken from '../../api/getToken';
import config from '../../../config';
const { BaseUrl } = config;

const setStopVm = async (node, vmid) => {
    try {
      const token = await getToken();
        const response = await axios.post(`${BaseUrl}/lab1/stopvm`, {
            node: node, 
            vmid: vmid
          },
          {  headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
            }
          }
        );  
       // console.log("setStopVm: ", response);
        return response.data;
    } catch (error) {
        console.log("Error[setStopVm]: ", error);
        return error;
    }
};

const setStartVm = async (node, vmid) => {
  try {
    const token = await getToken();
    const response = await axios.post(`${BaseUrl}/lab1/startvm`, {
        node: node, 
        vmid: vmid
      },
      {  headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
        }
      }
    );  
    // console.log("setStartVm: ", response);
    return response.data;
  } catch (error) {
      console.log("Error[setStartVm]: ", error);
      return error;
  }
};

export {setStopVm, setStartVm};

// "CSRFPreventionToken": "66EAE70C:kTqwbc7fkO0zGaz6w8nmNxJq2lFf4uXSHCJESGfGNSA",
// "ticket": "PVE:API@pve:66EAE70C::yJg0zNG0KS6amt9GAbrkdpRfGkLjqW7bjK6OXTdjbes/CaM1D+lN2WRv/+DwryMh6pxyokntVCQrJP1MDT0gnVqXkUXLKd5/Hjq5yuUmEbrxxdgwDrgm5iJdXCwuVB52cv8j+lqyS+1+SSTXxg+tFNcD7hVEH9e3NT18QRH2RmUQ24mr6ecayx9MgGPoO9cgY8GAd/bv6F3wmHf8woGcsstUG7QHcdDUjthv5nTibBsUTytkCyZMxo9lFyWJvaXSxh5yl6VBWTDF6ltTEG4FU/8rpwtCShAQzHJRW9XmEIsv4rj3gJurcYjWO0vHPVPi+8kPLwAYfA4A8EOzQrhyRA=="