import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getFields = async (pagesId) =>{
  try { 
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/roles/getfields`, {         
      params: {
        idpages: pagesId,
      },      
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {  
      console.error("getPages Error: ", error);
      return [];
  }
};

export default getFields;