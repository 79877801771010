import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import clearSession from '../../utils/clearSession';
import { useNavigate } from 'react-router-dom';

const SessionExpiredDialog = ({ open, onClose }) => {
  const navigate = useNavigate();  
  const [openInvalidTokenDialog, setOpenInvalidTokenDialog] = useState();
  const [close, setClose] = useState();
  const handleInvalidTokenDialog = () => {
    setOpenInvalidTokenDialog(true);
  };

  const handleLogoff = () => {
    clearSession();
    logout();
    navigate('/login');
    onClose();
  };

  function logout() {
    sessionStorage.removeItem('userSession'); 
  }
  return (
<Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }
      }}
    >
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          fontSize: '1.2rem',
          color: '#333',
        }}
      >
        Your Session Expired!
      </DialogTitle>
      <DialogContent
        sx={{
          fontSize: '1rem',
          color: '#555',
        }}
      >
        Your session has ended!<br />
        You must log in again to continue.
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
        }}
      >
        <Button
          onClick={handleLogoff}
          sx={{
            backgroundColor: '#d32f2f',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#b71c1c',
            },
            borderRadius: '8px',
            padding: '8px 16px',
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
    );
  };
  export default SessionExpiredDialog;