import jwtDecode from 'jsonwebtoken';
import getToken from './_data/api/getToken';

const getTokenExpiryTime = () => {
    // Token'ı localStorage'dan alın (veya sizin uygulamanızda nerede saklanıyorsa oradan alın)
    // const token = localStorage.getItem('token'); // veya sessionStorage.getItem('token')
    const token = getToken(); // veya sessionStorage.getItem('token')
  //console.log('(getTokenExpiryTime)-token :', token);
    if (!token) {
        return null;
    }

    try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp) {
            // JWT exp alanı saniye cinsindendir, bu nedenle milisaniyeye çevrilmesi gerekir
            return decodedToken.exp * 1000;
        }
        return null;
    } catch (error) {
        console.error('Invalid token', error);
        return null;
    }
};

export default getTokenExpiryTime;
