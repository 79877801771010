import axios from 'axios';
//setSession
import setSession from '../../../utils/setSession';
import account from '../../../_data/account';
import config from '../../../config';
import getUserRoles from '../../../_data/roles/getUserRoles';
const { BaseUrl } = config;

const CheckUser = async (username, passwd, appCode) => {    
    try {
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${BaseUrl}/login/getuser`,
        data:{
          username,
          passwd,
          appCode
        },
        headers: {}
      };
      const res = await axios.request(config);
      if (res.data && typeof res.data === 'object' && Object.keys(res.data).length > 0){
       // console.log('res.data.status: ', res.data.status);
        if(res.data.message === "Invalid username or password."){
          return { message: res.data.message };
        }
        setSession(res.data); //TODO: Ilk once gelen token session ortamina kaydedilir.
        const allRoles = await getUserRoles(); //TODO: eğer burada roles null veya undefined gelirse kontrol edelim.        
        setSession(res.data, allRoles);
        account.displayName = res.data.user[0].name_surname;
        account.email = res.data.user[0].username;
        account.photoURL = res.data.user[0].photourl;
        account.iduser = res.data.user[0].iduser;
        account.status = res.data.user[0].status;
        account.roles = res.data.user[0].roles;
        account.is_active = res.data.user[0].is_active;
        account.token = res.data.apitoken;

        return res
      // }else if(res.status === 200 && res.data <= 0){
      // }else if(res.status === 401){
      //   return { status: 401, message: "Invalid username or password." };
      // }
      // else{return res.data;
      }
    } catch (error) {
      if (error.response) {
        // Backend'den gelen HTTP yanıtına erişim sağla
        const { status, data } = error.response;
    
        if (status === 401) {
          // Kullanıcıya özel hata mesajı göster
        //  console.log(data.message); // veya kullanıcı arayüzünde bir uyarı göster
          return { status: 401, message: data.message };
        } else {
          // Diğer HTTP hataları için genel bir işlem
        //  console.log("An error occurred.", data.message);
          return { status, message: data.message };
        }
      } else {
        // Hata yanıtı olmadığında (örneğin, ağ hatası)
      //  console.log("Network error or service unavailable.");
        return { status: 0, message: "Network error or service unavailable." };
      }
      // if(res.status === 401){
      //   return { status: 401, message: "Invalid username or password." };
      // }else if (error.response && error.response.status === 503) {
      //   return error.response.data.message;
      // } 
      // console.log("CheckUser Error: ", error);
      // return 0; // Error
    }
};

  export default CheckUser;


