import axios from 'axios';
import config from '../../../config';
const { BaseUrl } = config;

const insertNewUser = async (name_surname, username, passwd) => {
   // console.log("insertNewUser -->name_surname: ", name_surname, " username: ", username, "passwd: ", passwd);
    try {
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${BaseUrl}/signin/setnewuser/?name_surname=${name_surname}&username=${username}&passwd=${passwd}`,
            headers: {},
            //params: {},
        };
        const response = await axios.request(config);
       //console.log("Return Data from ApiService: ", JSON.stringify(response.data));
        // if (response.data.length > 0){ //Is there anything returned inside the json value?
        if (response.data && typeof response.data === 'object' && Object.keys(response.data).length > 0){
       // console.log("response: ", response.data);
        return true; // successful
        }
        else {
        return false; // unsuccessful
        }
    } catch (error) {
        console.log(error);
        return false; // Error
    }
  };

  export default insertNewUser;
