//src/components/userForm/Edit/ModalEditForm.js
import React, {useState, useRef, useEffect} from 'react';
import style from './modalEditForm.module.css'
import { TextField, Button, Stack, Avatar, Typography, Grid, Box, Dialog, Snackbar, Alert,
  DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import api from '../../../_data/api/backendApi'
import Iconify from '../../iconify';
import getRoles from '../../../_data/roles/getRoles';
import updateUser from '../../../_data/user/updateUser';
import { useLoading } from '../../../utils/contexts/LoadingContext';
import uploadImage from '../../../_data/user/uploadImage';
import getUser from '../../../_data/user/getUser';
import setUserPassword  from '../../../_data/user/setUserPassword';

export default function ModalEditForm(props) {
  //const uploadImage = useUploadImage();
  const { userId } = props;
  const [ userDatas, setUserDatas ] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [allroles, setAllRoles] = useState([]); // List of All Roles
  const [selectedRoleId, setSelectedRoleId] = useState([]);
  const [selectedRoleTitle, setSelectedRoleTitle] = useState([]);
  const [roleDefinitions, setRoleDefinitions] = useState({});
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [roles, setRoles] = useState('');
  const [jobs, setJobs] = useState('');
  const [address, setAddress] = useState('');
  const autocompleteRoleRef = useRef(null);
  const autocompleteJobRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedImage, setSelectedImage] = useState(null); //For Avatar
  const [selectedFile, setSelectedFile] = useState(null); // Dosyayı saklamak için yeni bir state
  const [isLoading, setIsLoading] = useState(true); // Loading state eklendi
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(true);  
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordStrengthMessage, setPasswordStrengthMessage] = useState('');
  const [passwordsMatchMessage, setPasswordsMatchMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [msgOpen, setMsgOpen] = useState(false);
  const [open, setOpen] = useState(null);
  const [selectedId, setSelectedId] = useState(0);


  const extractNameDetails = (nameSurname) => {
    let names = nameSurname.split(' ');
    let extractedLastName = names.pop() || ''; 
    let extractedFirstName = names.join(' ') || '';
    return [extractedFirstName, extractedLastName];
  };

  const handleRoleTextFieldClick = () => {
    if (autocompleteRoleRef.current) {
      autocompleteRoleRef.current.focus();
    }
  };

  const handleJobTextFieldClick = () => {
    if (autocompleteJobRef.current) {
      autocompleteJobRef.current.focus();
    }
  };

  const handleChange = (event, value) => {
    setSelectedRoleTitle(value?.label  || ''); //selectedRoleTitle
    setSelectedRoleId(value?.id); //selectedRoleId
  };

  const loadRoles = async () => {
    try {
        const response = await getRoles(); //coming all roles        
        setRoleDefinitions(response);
        setAllRoles(response);
      //  console.log("getRoles:", response);
    } catch (error) {
        console.error('Error loading roles:', error);
    }
  };

  const handleSelectRole = (role) => {
    if (roles.indexOf(role.label) === -1) { // Eğer rol zaten eklenmediyse
      setRoles(roles + (roles.length > 0 ? ", " : "") + role.label); // Rolü mevcut rollere ekleyin
    }
  };

  const handleSelectJob = (job) => {
    if (jobs.indexOf(job.label) === -1) { // Eğer rol zaten eklenmediyse
      setJobs(jobs + (jobs.length > 0 ? ", " : "") + job.label); // Rolü mevcut rollere ekleyin
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  useEffect(() => {    
    fetchUserData();
    loadRoles();
    setSelectedId(userId[0]);
  }, [userId]);

  useEffect(() => {
    if (userDatas && userDatas.length > 0) {
      const [first, last] = extractNameDetails(userDatas[0].name_surname);
      setFirstName(first);
      setLastName(last);
      setEmail(userDatas[0]?.username || '');
      setPhoneNumber(userDatas[0]?.contacts?.Phone || '');
      setRoles(userDatas[0]?.roles?.admin || '');
      setJobs(userDatas[0]?.jobs?.title || '');
      setAddress(userDatas[0]?.contacts?.Address || '');
    }
  }, [userDatas]);

  useEffect(() => {
    // Enable button if password strength "strong" and passwords match
    const isDisabled = !(passwordStrengthMessage === 'Password is strong' && passwordsMatchMessage === 'Passwords match');
    setIsUpdateButtonDisabled(isDisabled);
  }, [passwordStrengthMessage, passwordsMatchMessage]);

  const fetchUserData = async () => {
    setIsLoading(true);
    try {
      const userData = await getUser(userId);
      setUserDatas(userData);
    } catch (err) {
        console.error("Error occured when user data fetching: ", err)
    } finally {      
      setIsLoading(false); // Veri yüklemesi tamamlandığında loading state güncellenir
    }
  }

  const handleUpdateProfile = () => {
    setIsEditing(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsEditing(false);
    const updatedUserData = {
      name_surname: `${firstName} ${lastName}`,
      username: email,
      contacts: {
        Phone: phoneNumber,
        Address: address
      },      
      Roles: [
        roles
      ],
      jobs: {
        title: jobs
      }
    };
    const headers = {
      'Content-Type': 'application/json'
    };    
    try {
      const response = await updateUser(updatedUserData);
    } catch (error) {
      console.error("An error occurred while updating the user: ", error);
    }
  };
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setSelectedImage(URL.createObjectURL(img));
      setSelectedFile(img); // Dosyayı sakla
    }
  };

  const { showLoading, hideLoading } = useLoading();
  const handleUpload = async() => {
    try {
      showLoading();
      if (!selectedImage) {
        hideLoading();
        return;
      }
      const formData = new FormData();    
      formData.append('avatar', selectedFile); // 'avatar' key'i ile seçilen dosyayı ekleyin
      formData.append('iduser', userId);     
      const resume = await uploadImage(formData);
      if (resume.message === 'The avatar has been uploaded successfully.' && resume.affectedRows > 0) { //New Avatar updated successfully
        setSeverity('success');
        setAlertMessage('Avatar updated successfully.');
        setMsgOpen(true);
        handleCloseMenu();
        await fetchUserData();
      } 
    } catch (error) {
      console.error('Database operation failed', error);
    } finally {
      hideLoading();
    }
  };

  const performDatabaseOperation = async () => {
    return new Promise((resolve) => setTimeout(resolve, 5000)); // Simulating a database operation
  };

  const evaluatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[a-z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[!*?+.%-^$=@]/.test(password)) strength += 1; // Check if there is at least one special character.
    return strength; 
  };

  const handleClosePasswordDialog = () => {
    setIsPasswordDialogOpen(false);
    setNewPassword(''); // New password field reset.
    setConfirmNewPassword('')
  };

  const handleNewPasswordChange = (event) => {
   // console.log('handleNewPasswordChange: ', event.target.value);
    let newPasswordValue = event.target.value;
    newPasswordValue = newPasswordValue.replace(/[' ]+/g, '');    
    setNewPassword(newPasswordValue);
    const strength = evaluatePasswordStrength(newPasswordValue);
    setPasswordStrength(strength);
    let strengthMessage = '';
    if (strength < 3) {
      strengthMessage = 'Password is very weak';
    } else if (strength <= 4) {
      strengthMessage = 'Password is weak';
    } else if (strength === 5) {
      strengthMessage = 'Password is strong';
    }
   // console.log('strengthMessage: ', strengthMessage);
    setPasswordStrengthMessage(strengthMessage);    
  };

  const handleSubmitNewPassword = () => {
   // console.log('selectedId: ', selectedId);
   // console.log('selectedId: ', selectedId);
    setNewPassword(''); // New password field reset.
    setConfirmNewPassword('')
    const MIN_STRENGTH = 5;
    let iduser = selectedId;
    if (passwordStrength < MIN_STRENGTH) {
      return; 
    }
   // console.log('handleSubmitNewPassword-id, newPassword: ', selectedId, newPassword);
    if (newPassword === confirmNewPassword) {      
      //setCurrentPasswdAction({email, newPassword});
      passwordPerformAction(iduser, newPassword);
      setIsPasswordDialogOpen(false);
    } 
  };

  const passwordPerformAction = async (id, newPassword) => {
    try {
    //  console.log('id, newPassword: ', id, newPassword);
    const response = await setUserPassword(id, newPassword);
    if (response.message === 'New password updated successfully.' && response.affectedRows > 0) {
      setSeverity('success');
      setAlertMessage('Password updated successfully.');
      setMsgOpen(true);
      handleCloseMenu();
    }  
    } catch (error) {
     console.log('Error: ', error); 
    }    
  };

  const handleClose = () => {
    setMsgOpen(false);
  };

  const handleConfirmNewPasswordChange = (event) => {
    const confirmNewPasswordValue = event.target.value;
    setConfirmNewPassword(confirmNewPasswordValue);
    let matchMessage = '';
    if (newPassword && confirmNewPasswordValue && newPassword !== confirmNewPasswordValue) {
      matchMessage = 'Passwords do not match';
    } else if (newPassword && confirmNewPasswordValue && newPassword === confirmNewPasswordValue) {
      matchMessage = 'Passwords match';
    }
    setPasswordsMatchMessage(matchMessage);
  };
    
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };
  
    const toggleShowConfirmPassword = () => {
      setShowConfirmPassword(!showConfirmPassword);
    };

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClickPasswordDialogOpen = () => {
      setIsPasswordDialogOpen(true);
    };
  
    // InputAdornment render functions to add icons to the end of password input fields
    const renderPasswordAdornment = () => (
      <InputAdornment position="end">
        <IconButton onClick={toggleShowPassword} edge="end">
          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
        </IconButton>
      </InputAdornment>
    );
  
    const renderConfirmPasswordAdornment = () => (
      <InputAdornment position="end">
        <IconButton onClick={toggleShowConfirmPassword} edge="end">
          <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
        </IconButton>
      </InputAdornment>
    );

  return (
    <div>
      {
        isLoading ? (
          "User loading..."
        ) : 
        (
          <>
          {isEditing ? (
            <>
              <h2>User Editing Form</h2>
              <form onSubmit={handleSubmit}> {/*action={<Link to="/login" />}*/}
                {/* <Avatar sx={{ width: 80, height: 80, mb:2, fontSize: 80 }}>
                      
                </Avatar> */}
                {/* <label htmlFor="avatar-upload" style={{ display: 'inline-block' }}>
                  <input
                      ref={fileInputRef}
                      type="file"
                      id="avatar-upload"
                      style={{ display: 'none' }}
                      onChange={handleAvatarChange}
                  />
                  <Avatar
                    src={avatar}
                    sx={{
                      width: 80,
                      height: 80,
                      mb: 2,
                      fontSize: 40,
                      cursor: 'pointer',
                      backgroundColor: !avatar && 'grey'
                    }}
                  >
                    {!avatar && '+'}
                  </Avatar>
                </label> */}
                
                <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                  <TextField
                    type="text"
                    variant='outlined'
                    color='secondary'
                    label="First Name"
                    onChange={e => setFirstName(e.target.value)}
                    value={firstName}
                    fullWidth
                    required
                  />
                  <TextField
                    type="text"
                    variant='outlined'
                    color='secondary'
                    label="Last Name"
                    onChange={e => setLastName(e.target.value)}
                    value={lastName}
                    fullWidth
                    required
                  />
                </Stack>             
                <Box sx={{ marginBottom: 2, display: 'grid', gridTemplateColumns: '1fr', gap: 2 }}>
                <Box sx={{ gridColumn: 'span 2', display: 'flex', flexDirection: 'column', gap: 2,
                    border: 1,
                    borderColor: 'divider',
                    borderRadius: 1,
                    p: 2, }}> 
                    <TextField
                      type="tel"
                      variant='outlined'
                      color='secondary'
                      label="Phone Number"
                      onChange={e => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                      required
                      sx={{ gridColumn: 'span 1' }}
                    />
                    <TextField
                      type="email"
                      variant='outlined'
                      color='secondary'
                      label="Email"
                      onChange={e => setEmail(e.target.value)}
                      value={email}
                      fullWidth
                      required
                      sx={{mb: 1}}
                    />
                    <TextField
                      type="text"
                      variant='outlined'
                      color='secondary'
                      label="Addresses"
                      onChange={e => setAddress(e.target.value)}
                      value={address}
                      fullWidth
                      required
                      sx={{mb: 1}}
                    />                
                  </Box>
                  <Box sx={{ gridColumn: 'span 2', display: 'flex', flexDirection: 'column', gap: 2,
                    border: 1, 
                    borderColor: 'divider',
                    borderRadius: 1,
                    p: 2, }}> 
                    <TextField
                      type="text"
                      variant='outlined'
                      color='secondary'
                      label="Roles"
                      onChange={e => setRoles(e.target.value)}
                      placeholder = "Select a role from the 'Role List' below..."
                      value={roles}
                      required
                      InputProps={{
                        readOnly: true,
                      }}
                      onClick={handleRoleTextFieldClick}
                      sx={{ flexGrow: 1 }}
                    />
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={allroles ? allroles.map(role => ({
                        id: role.idroles,
                        label: role.roletitle
                      })) : []}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      sx={{ flexGrow: 1 }}
                      renderInput={(params) => <TextField {...params} label="Select Role and Add with ↑" inputRef={autocompleteRoleRef} />}
                      onChange={handleChange}
                      ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }} // We set the height and scroll behavior here
                      renderOption={(props, option, { selected }) => (
                        <Box component="li" sx={{ '& > button': { mr: 2 } }} {...props}>
                          <Button onClick={() => handleSelectRole(option)} size="small">
                            ↑
                          </Button>
                          {option.label}
                        </Box>
                      )}
                    />
                  </Box>
                  <Box sx={{ gridColumn: 'span 2', display: 'flex', flexDirection: 'column', gap: 2,
                    border: 1,
                    borderColor: 'divider',
                    borderRadius: 1,
                    p: 2, }}>
                    <TextField
                      type="text"
                      variant='outlined'
                      color='secondary'
                      label="Job Titles"
                      onChange={e => setJobs(e.target.value)}
                      value={jobs}
                      required
                      placeholder = "Select a job title from the 'Job List' below..."
                      InputProps={{
                        readOnly: true,
                      }}
                      onClick={handleJobTextFieldClick}
                      sx={{ gridColumn: 'span 1' }} // Will only be 1 column wide
                    />
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={[
                        {id: 1, label: 'HR Asistant'},                      
                        {id: 2, label: 'Administrative Assistant'}, 
                        {id: 3, label: 'Course Coordinator'}, 
                        {id: 4, label: 'Student Representative'},
                        {id: 5, label: 'Finance Assistant'}
                      ]}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      sx={{ flexGrow: 1 }}
                      renderInput={(params) => <TextField {...params} label="Select Jobs and Add with ↑" inputRef={autocompleteJobRef}/>}
                      onChange={handleChange}
                      ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }}
                      renderOption={(props, option, { selected }) => (
                        <Box component="li" sx={{ '& > button': { mr: 2 } }} {...props}>
                          <Button onClick={() => handleSelectJob(option)} size="small">
                            ↑
                          </Button>
                          {option.label}
                        </Box>
                      )}
                    />
                  </Box>                
                </Box>             
                <Button variant="outlined" color="secondary" type="submit">Save</Button>
              </form>
            </>
          ) : (
            <>
            <Grid container spacing={2}>
              <div>
                {selectedImage && (
                  <Avatar
                    src={selectedImage}
                    style={{ width: '120px', height: '120px', margin: '10px' }}
                  />
                )} 
                  <label htmlFor="raised-button-file">
                    <div className={style.ppEdit}>
                      <Typography variant='h6'>Profile Photo</Typography>
                      <div  className={style.PPIconify}>
                        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }}/>
                        {selectedImage && <img src={selectedImage} alt="Selected" style={{ width: '350px', height: '350px' }} />}
                        <input
                          accept="image/*"
                          type="file"
                          onChange={handleImageChange}
                          style={{ display: 'none' }}
                          id="raised-button-file"
                        />
                      </div>
                    </div>
                  </label>
              </div>
            </Grid>
            {selectedImage && (
              <Button variant="contained" onClick={handleUpload}>
                Load
              </Button>
            )}
              <p><b>User Id: </b>{userDatas[0]?.id}</p>
              {userDatas[0]?.name_surname && <p><b>Name: </b>{userDatas[0]?.name_surname}</p>}
              {userDatas[0]?.username && <p><b>Email: </b>{userDatas[0]?.username}</p>}
              {userDatas[0]?.jobs && userDatas[0]?.jobs.Title && <p><b>Job Title: </b>{userDatas[0]?.jobs.Title}</p>}
              {userDatas[0]?.contacts && userDatas[0]?.contacts.Phone && <p><b>Phone: </b>{userDatas[0]?.contacts.Phone}</p>}
              {/* <div onClick={handleUpdateProfile} className={style.editProfile}>
                Edit Profile
                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
              </div> */}

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div onClick={handleUpdateProfile} className={style.editProfile}>
                  Edit Profile
                  <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                </div>
                <Button
                  variant="text"
                  startIcon={<Iconify icon={'eva:edit-fill'} />}
                  onClick={ handleClickPasswordDialogOpen}
                  // onClick={ => console.log('Change Password clicked')}
                  sx={{ color: 'blue', textTransform: 'none' }}
                >
                  Change Password
                </Button>
              </Box>

            </>
              )}

              <Dialog open={isPasswordDialogOpen} onClose={handleClosePasswordDialog}>
                <DialogTitle>Set New Password For <span style={{ color: 'red' }}>{userDatas[0]?.name_surname && <p><b>Name: </b>{userDatas[0]?.name_surname}</p>}</span></DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="New Password"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    variant="outlined"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    InputProps={{
                      endAdornment: renderPasswordAdornment(),
                    }}
                  />
                  <Typography 
                    color={passwordStrength >= 2 ? "primary" : "error"} 
                    variant="caption"
                  >
                    {passwordStrengthMessage}             
                  </Typography>
                  <TextField
                    margin="normal"
                    label="Confirm New Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    fullWidth
                    variant="outlined"
                    value={confirmNewPassword}
                    onChange={handleConfirmNewPasswordChange}
                    InputProps={{
                      endAdornment: renderConfirmPasswordAdornment(),
                    }}
                  />          
                  <Typography 
                    color={passwordsMatchMessage === 'Passwords match' ? "green" : "red"} 
                    variant="caption"
                  >
                    {passwordsMatchMessage}
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClosePasswordDialog}>Cancel</Button>
                  <Button style={{
                      backgroundColor: 'blue',
                      color: '#FFFFFF',
                    }} onClick={handleSubmitNewPassword}
                    disabled={isUpdateButtonDisabled} // Butonun disabled özelliğini bağla
                  >
                    Update Password
                  </Button>
                </DialogActions>
              </Dialog>
              <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={msgOpen}  autoHideDuration={5000} onClose={handleClose}>          
                <Alert severity={severity} onClose={handleClose} 
                  sx={{ 
                    width: 450, 
                    minWidth: 288, // Min genişliği ayarlayabilirsiniz, isteğe bağlı
                    fontSize: 14, 
                    //fontWeight: 'bold', 
                    fontStyle: 'normal' 
                  }}
                >
                  {alertMessage}
                </Alert>
              </Snackbar>
            </>
        )         
      }    
    </div>
  )
};