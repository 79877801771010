import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getJobCenterData= async (studentid) => {
  try {
    const token = await getToken();    
    const response = await axios.get(`${BaseUrl}/students/getjobcenterworkflow`, {    
      headers: {
        'Authorization': `Bearer ${token}`
      },      
    });
  //  console.log("[response.data]: ", response.data)
    return response.data;
  } catch (error) {  
      console.error("Report error: ", error);
      return [];
  }
};

export default getJobCenterData;