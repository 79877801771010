import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getAllUser = async () => {
    try {
       // console.log("getAllUser: ");
        const token = await getToken();
        const response = await axios.get(`${BaseUrl}/users/getallusers`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const info = response.data || [];
      //  console.log("Info: ", info);
        const userInfo = info.map((item, index) => {       
            return {
                id: item.iduser || '',
                name_surname: item.name_surname || '',
                username: item.username || '',
                passwd: item.passwd || '',
                roles: item.roles || '',
                jobs: item.jobs || '',     
                contacts: item.contacts || '',
                pass_hash: item.pass_hash || '',
                is_active: item.is_active || '',
                photourl: item.photourl || '',
                idstatus: item.idstatus || '',
                index: index
            };
        });
      //  console.log("userInfo: ", userInfo);
        return userInfo;
    } catch (error) {  
        return [];
    }
};

export default getAllUser;




// import { faker } from '@faker-js/faker';
// import { sample } from 'lodash';

// // ----------------------------------------------------------------------

// const users = [...Array(52)].map((_, index) => ({
//   id: faker.datatype.uuid(),
//   avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
//   name: faker.name.fullName(),
//   company: faker.company.name(),
//   isVerified: faker.datatype.boolean(),
//   status: sample(['active', 'banned']),
//   role: sample([
//     'Leader',
//     'Hr Manager',
//     'UI Designer',
//     'UX Designer',
//     'UI/UX Designer',
//     'Project Manager',
//     'Backend Developer',
//     'Full Stack Designer',
//     'Front End Developer',
//     'Full Stack Developer',
//   ]),
// }));

// export default users;
