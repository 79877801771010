import axios from 'axios';
import config from '../../../config';
const { BaseUrl } = config;

const getVms= async () => {
    try {     
        const response = await axios.get(`${BaseUrl}/lab1/getvms`);
        const info = response.data || [];
        const vmsList = info.map((item, index) => {
            return {
                vmid: item.vmid || '',
                name: item.name || '',
                status: item.status || '',
                uptime: item.uptime || '',
                mem: item.mem || '',
                maxmem: item.maxmem || '',
                cpus: item.cpus || '',
                cpu: item.cpu || '',
                maxdisk: item.maxdisk || '',
                pid: item.pid || '',
                netin: item.netin || '',
                netout: item.netout || '',
                diskread: item.diskread || '',
                diskwrite: item.diskwrite || '',
                disk: item.disk || '',
                
            };
        });
        return vmsList;
    } catch (error) {
        return [];
    }
};

export default getVms;

/*
"netin": 16411232991,
"status": "running",
"mem": 2986364918,
"uptime": 5149580,
"diskread": 0,
"netout": 1751411016,
"disk": 0,
"name": "Win10-HR2",
"pid": 51164,
"diskwrite": 0,
"maxmem": 4294967296,
"maxdisk": 37580963840,
"cpu": 0,
"cpus": 2,
"vmid": 601
*/