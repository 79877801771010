import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const createInterviewTransaction = async (formData) => {
  try {
   // console.log('createInterviewTransaction-Outcoming-formData: ', formData);
    const token = await getToken();
    const response = await axios.post(`${BaseUrl}/students/setinterviewtransaction`, 
      formData,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {  
      console.error("Insert error: ", error);
      return [];
  }
};

export default createInterviewTransaction;