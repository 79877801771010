import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, SvgIcon, 
Grid, Autocomplete, Button, Box, TextField } from '@mui/material';
import { ReactComponent as Icon_Attendance } from '../../../assets/icons/attendance.svg';
// component
import Iconify from '../../../components/iconify';
//Multi Language
import { useTranslation } from 'react-i18next'; 
import getStatus from '../../../_data/student/getStatus';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

LmsCoursesListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  statusName: PropTypes.string,
  onStatusName: PropTypes.func,
};
//LmsCoursesListHead
export default function LmsCoursesListToolbar({ numSelected, filterName, onFilterName, onStatusName }) {
  const { t } = useTranslation();
  const autocompleteStatusRef = useRef(null);
  const [statuses, setStatuses] = useState('');
  const [selectedStatusTitle, setSelectedStatusTitle] = useState([]);
  const [selectedStatusId, setSelectedStatusId] = useState([]);
  const [allstatuses, setAllStatuses] = useState([]); // List of All Statuses
  useEffect(() => {
    loadStatuses();
  }, []);

  const loadStatuses = async () => {
    try {
       const response = await getStatus(); //coming all statuses
       setAllStatuses(response);
    } catch (error) {
        console.error('Error loading roles:', error);
    }
  };

  const handleSelectStatus = (status) => {
    if (statuses.indexOf(status.label) === -1) {
      setStatuses(statuses + (status.length > 0 ? ", " : "") + status.label);
    }
  };

  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} {t('CheckBoxSelected')}
        </Typography>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <StyledSearch
              value={filterName}
              onChange={onFilterName}
              placeholder={t('SearchStudent')}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={6}>          
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={allstatuses ? allstatuses.map(status => ({
                id: status.idstudent_status,
                label: status.status_name
              })) : []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ flexGrow: 1 }}
              renderInput={(params) => <TextField {...params} label="Search Status" inputRef={autocompleteStatusRef} />}
              onChange={(event, newValue) => {
                onStatusName(newValue); // `newValue` seçilen değerdir. Bu, bir obje olabilir.
              }}
              ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }} // We set the height and scroll behavior here
              renderOption={(props, option, { selected }) => (
                <Box component="li" sx={{ '& > button': { mr: 2 } }} {...props}>
                  {option.label}
                </Box>
              )}
            />
          </Grid>
        </Grid>        
      )}
      {numSelected > 1 ? (
        <Tooltip title={t('Attendances')}>
          <IconButton>
            <SvgIcon component={Icon_Attendance} sx={{ mr: 2 }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t('FilterList')}>
          <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>
      )}
    </StyledRoot>
  );
}
