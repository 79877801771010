import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui
import {
  Card, Table, Stack, Paper, Avatar, Button, Popover, Checkbox, TableRow, MenuItem, TableBody,TableCell,
  Container,Typography, IconButton, TableContainer, TablePagination, Snackbar, Alert, Dialog, Box,
  DialogActions, DialogContent, DialogTitle, TextField, Select, InputLabel, FormControl, FormHelperText,
  CircularProgress} from '@mui/material';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { ApprovalListHead, ApprovalListToolbar } from '../../sections/@dashboard/approvals';
// mysql datase
import approvalusers from '../../_data/approvals/getApprovalUsers';
import CreateApprovalDialog from '../../components/approvals/create-approval-dialog';
import UpdateApprovalDialog from '../../components/approvals/update-approval-dialog';
import RequestComfirmDialog from '../../components/approvals/request-confirm-dialog';
import SendReqConfirm from '../../_data/approvals/sendReqConfirm';
import getSession from '../../utils/getSession';
import formatDateTime from '../../utils/dateTimeFormatter';
import { Bolt } from '@mui/icons-material';
import { blue } from '@mui/material/colors';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'fullname', label: 'Fullname', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'contact', label: 'Contact', alignRight: false },  
  { id: '' },
];
// ----------------------------------------------------------------------
const currentDate = formatDateTime();
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {  
  const account = getSession();
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('email');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success')
  const [alertMessage, setAlertMessage] = useState(''); 
  const [userList, setUserList] = useState([]);
  const [createdby, setCreatedBy] = useState(1);  
  const handleClose = () => {
    setMsgOpen(false);
  };
  const handleDownRecord = async (event) => {
    try {          
      const label = event.currentTarget.textContent;
      if(selected.length > 0){
        switch(label){          
          case 'New Request Confirm':
            handleOpenRequestDialog(selected[0].email, selected[0].fullname);
            break;
          case 'Edit Contact Information':
          //  console.log('selected[0].contact: ', selected[0].contact);
            setSelectedUser({
              id: selected[0].idparticipants_approvals,
              email: selected[0].email,
              fullname: selected[0].fullname,
              contact: selected[0].contact,
              notyourmail: selected[0].notyourmail
            });
            setUpdateDialogOpen(true);
            //openUpdateUserInfoDialogOpen(selected[0].idparticipants_approvals, selected[0].email, selected[0].fullname);
            break;          
          case 'Record Details':
            //console.log('Selected Record Details: ', selected[0]);
            openParticipantApprovalDetailsPage(selected[0].idparticipants_approvals, selected[0].email, selected[0].fullname);
            break;
          default:
            break;
        };
    }else{          
      setSeverity('error');
      setAlertMessage('Not selected Meeting Record!');
      setMsgOpen(true);
    }
    } catch (error) {
        console.log("handleDownRecord-Error: ", error);
    }
  };
  const [selectedUser, setSelectedUser] = useState({});
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const handleCloseUpdateDialog = () => {
    setUpdateDialogOpen(false);
  };

  const navigate = useNavigate();
  const openParticipantApprovalDetailsPage = (pk, email, fullname) =>{    
    if (pk && pk > 0) {
      navigate(`/dashboard/approvaldetails`, { state: { pk, email, fullname } });      
    }
  };

  const dataRows = [];
  const [selectedMail, setSelectedMail] = useState("");
  const [isDetailRecordsDialogOpen, setIsDetailRecordsDialogOpen] = useState(false);

  const handleDetailRecordsOpen = (pk) => {
    try {
      setSelectedMail(pk);
    } catch (error) {      
    }
  }

 const handleCloseDetailRecordsDialog = () => {
    setIsDetailRecordsDialogOpen(false);
  };
  
  
  const [isRequestDialogOpen, setIsRequestDialogOpen] = useState(false);
  const handleOpenRequestDialog = (email, fullname) => { 
    try {
      setSelectedMail(email);
      setIsRequestDialogOpen(true);
      //Dialog Panel aç ve link değerini açıklamsını vs girmesini iste  
              
    } catch (error) {
      console.log("handleOpenRequestDialog error: ", error);
    }
  };

  const handleCloseRequestDialog = () => {
    setIsRequestDialogOpen(false);
  };

  const MENU_OPTIONS = [
    { label1: 'Request Confirmation ', },
    { label2: 'Inactive', },
    { label3: 'Active', },
  ];

  const mailTypes = [
    { label: 'Language English', value: 'AENLAMFSMC' },
    { label: 'Language German', value: 'ADELAMFSMC' },
  ]

  const [contentLinkError, setContentLinkError] = useState(false);
  const [contentDescriptionError, setContentDescriptionError] = useState(false);
  const [selectedLanguageError, setSelectedLanguageError] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedFullname, setSelectedFullname] = useState('');
  
  const handleChangeMailTypes = (event) => {
    setSelectedLanguage(event.target.value);
  };
  const handleSubmitNewPaticipantApprovalDetail = async (event) => {    
    // Reset Error States
    setContentLinkError(false);
    setContentDescriptionError(false);
    setSelectedLanguageError(false);

    // Checked for each field
    if (!contentLink) {
      setContentLinkError(true);
      return;
    }
    if (!contentDescription) {
      setContentDescriptionError(true);
      return;
    }
    if (!selectedLanguage) {
      setSelectedLanguageError(true);
      return;
    }
    try {       
      setCreatedBy(account.user[0].iduser);
      setLoading(true); //start circularProgress
      setStatusMessage('Submitting your request...');
      setStatusMessage('Record is saved and sending e-mail...');
      const data = await SendReqConfirm(createdby, selectedMail, contentLink, contentDescription, selectedLanguage, selected[0].fullname);
     // console.log('data-209: ', data);
     // console.log('data.response-209: ', data.response);
    //  console.log('data.response.status-209: ', data.response.status);
      let errorMessage = "";
      let successMessage = "Your registration has been successfully created and a confirmation email has been sent."; //TODO: Hata yönetim yapısından ve dynamic olarak alınması daha iyi olur. 
      if(data.status === 500){
        if (data.error.startsWith("Duplicate entry")){
          errorMessage = "Do not repeat the recording for which permission was previously requested.";
        }
        setLoading(false); //stop circularProgress
        setSeverity('error');
        setAlertMessage(errorMessage);
        setMsgOpen(true);
      }
      //TODO: "else if(data.status === 200){...." icin mail gittiginde bu sonuc aliniyor mu iyice kontrol et ona gore yukleme yapmadan tekrar tekrar kontrol edelim. oak.oakacademy.de de yuklu olan direk else ile calisiyor araya aldigim status=200 sorun olur mu iyice kontrol et
      // else if(data.status === 200){
      //   fetchUsers();
      //   //console.log("İşlem başarıyla tamamlanmıştır ve panel kapatılıp mesaj verdirilmelidir. Ancak daha önce Status 200 değerinin geldiği kontrol edilmelidir.");
      //   setSeverity('success');
      //   setAlertMessage(successMessage);
      //   setMsgOpen(true);
      //   setLoading(false); //stop circularProgress
      //   handleCloseRequestDialog();
      // } 
      else{
        setMsgOpen(false);
        fetchUsers();
        setSeverity('error');
        setAlertMessage('Do not send e-mail!');
        setMsgOpen(true);
        setLoading(false); //stop circularProgress
        handleCloseRequestDialog();
      }
    } catch (error) {
      console.log("Error(handleSubmitNewPaticipantApprovalDetail) : ", error);
    }
  };  

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState({ emails: [], status: null });
  const handleConfirmDialogOpen = (emails, status) => {
    setCurrentAction({emails, status});
    setOpenConfirmDialog(true);
  };
  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const fetchUsers = async () => {
    try {      
        const data = await approvalusers();                
        setUserList(data);
      //  console.log('data:', data);
    } catch (error) {
        console.error("Error fetching users: ", error);
    }
  };
  
  useEffect(() => {
    fetchUsers();
    const intervalId = setInterval(fetchUsers, 180000); // Refresh per 180 second
    //console.log('Interval çalıştı...');
    return () => clearInterval(intervalId); // Cleanup fonksiyonu
  }, []);
  
  const handleCreateApproval = async (success, props) => { //callback fonksiyonu ile child paneldeki veriyi main/parent page üzerine alıp düzenleme veya güncelleme yapıyoruz.
   // console.log('handleCreateApproval')
    if (success) {
      props.onApprovalSuccess();
    }
  };
  // const handleUpdateApproval = async (success, props) => { //callback fonksiyonu ile child paneldeki veriyi main/parent page üzerine alıp düzenleme veya güncelleme yapıyoruz.
  //   console.log('handleUpdateApproval')
  //   if (success) {
  //     props.onApprovalSuccess();
  //   }
  // };

  const handleApprovalSuccess = () => {
    fetchUsers();
    setSeverity('success');
    setAlertMessage('The record has been updated successfully.');
    setMsgOpen(true);
  };

  const handleUpdateApproval = (success) => {
    if (success) {
      handleApprovalSuccess();
    }
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelecteds = userList.map((n) => n.email);
      const newSelecteds = userList.map((n) => ({ email: n.email, fullname: n.fullname, 
      pk: n.idparticipants_approvals, contact: n.contact, notyourmail: n.notyourmail }));      
      setSelected(newSelecteds);
      //console.log('newSelecteds: ' ,newSelecteds);
      return;
    }
    setSelected([]);
  }; 

  const handleClick = (event, select) => {
    // Önce selected dizisini kopyalayarak başlıyoruz
    let newSelected = [...selected];  
    // newSelected içinde select.email eşleşmesini arıyoruz
    const selectedIndex = newSelected.findIndex(item => item.email === select.email);  
    if (selectedIndex === -1) {
      // Eğer bulunamazsa, yeni bir öğe olarak ekliyoruz
      newSelected.push({email: select.email, fullname: select.fullname, idparticipants_approvals: select.idparticipants_approvals, 
      contact: select.contact, notyourmail: select.notyourmail});
    } else {
      // Eğer bulunursa, o öğeyi newSelected dizisinden çıkarıyoruz
      newSelected.splice(selectedIndex, 1);
    }
    //console.log('selected: ', newSelected);
    setSelected(newSelected);
  };

// Circular Progress
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('Ready to submit');

  //Get Table Background Color Style 
  const getRowStyle = (pendingApprovals, notyourmail) => {
    //console.log('notyourmail: ', notyourmail);
    if (notyourmail === 0 && pendingApprovals === 1) {
      //console.log('1: ', pendingApprovals, notyourmail);
      return { backgroundColor: '#ffddee' }; // Örneğin, pending_approvals > 0 için açık kırmızı
    } else if(notyourmail === 0 && pendingApprovals === 0){
      //console.log('2: ', pendingApprovals, notyourmail);
      return { backgroundColor: '#90ee90' }; // Örneğin, pending_approvals = 0 için Yesil}
    }else if(notyourmail === 1 && pendingApprovals >= 0){
      //console.log('3: ', pendingApprovals, notyourmail);
      //return { backgroundColor: '#D10363', color: 'white' }; // Örneğin, notyourmail = 1 için açık kirmizi
      //return { backgroundColor: '#B00250' }; // Örneğin, notyourmail = 1 için açık kirmizi
      return { backgroundColor: '#8A013E' }; // Örneğin, notyourmail = 1 için açık kirmizi
    }
  };

  const getCellStyle = (notyourmail) => {
    if (notyourmail === 1) {
      return { color: 'white' }; // Örneğin, notyourmail = 1 için beyaz yazı rengi
    }
    return {}; // Diğer durumlar için varsayılan renk
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);    
  };

  const [contentDescription, setContentDescription] = useState("");
  const handleContentDescriptionChange = (event) => {
    setContentDescription(event.target.value);
  }

  const [contentLink, setContentLink] = useState("");
  const handleContentLinkChange = (event) => {
    setContentLink(event.target.value);
  }
  
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;
  const filteredUsers = applySortFilter(userList, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && !!filterName;
  const [permissionContent, setPermissionContent] = useState("");
  const [link, setLink] = useState("");
  return (
    <>
      <Helmet>
        <title> Content Permission  | Oak Academy </title>
      </Helmet>

      <Container sx={{ minWidth: 800, padding: 0 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Oak Content Permission Approval 
          </Typography>
          <CreateApprovalDialog onApprovalSuccess={fetchUsers}/>                              
        </Stack>
        <Card>
          <ApprovalListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar>
            <TableContainer padding="normal" sx={{ width: '100%', minWidth: 800 }}>
              <Table>
                <ApprovalListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={userList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>                
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {                    
                    const { avatarUrl, fullname, email,  contact, pending_approvals, idparticipants_approvals, notyourmail } = row;
                    const selectedUser = selected.some(item => item.email === email);                                     
                    return (
                      <TableRow hover key={email} tabIndex={-1} role="checkbox" selected={selectedUser} style={getRowStyle(row.pending_approvals, row.notyourmail)}>
                        <TableCell padding="checkbox" sx={getCellStyle(row.notyourmail)}>
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, {email, fullname, idparticipants_approvals, contact, notyourmail})} />                          
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none" sx={getCellStyle(row.notyourmail)}>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={fullname} src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {fullname}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left" sx={getCellStyle(row.notyourmail)}>{email}</TableCell>
                        <TableCell align="left" sx={getCellStyle(row.notyourmail)}>
                          {contact.Address && <div>Address: {contact.Address}</div>}
                          {contact.CellPhone && <div>Phone: {contact.CellPhone}</div>}                          
                        </TableCell>
                        <TableCell align="right" sx={getCellStyle(row.notyourmail)}>
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>
                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Box 
            color="gray"            
            variant="caption"
          >
            <Box>
              Pending: 
                <Box 
                  ml={1} 
                  width={20} 
                  height={20} 
                  bgcolor="#ffddee" 
                  borderRadius="4px" 
                  display="inline-block" 
                  mr={3}
                />     
              Approved:
              <Box 
                  ml={1} 
                  width={20} 
                  height={20} 
                  bgcolor="#90ee90" 
                  borderRadius="4px" 
                  display="inline-block"
                  mr={3}
                /> 
              Not Your Mail:
              <Box 
                  ml={1}
                  width={20} 
                  height={20} 
                  bgcolor="#D10363" 
                  borderRadius="4px" 
                  display="inline-block"
                  mr={3}
                /> 
            </Box>
          </Box>
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 240,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem key={MENU_OPTIONS.label1} onClick={handleDownRecord} 
          sx={{
              typography: 'body1',
              color: 'red',
              '&:hover': {
              bgcolor: 'mycolor.dark'}, width: 250} } >
          <Iconify icon={'eva:paper-plane-outline'} sx={{ mr: 2, color: 'mycolor.main'}} />
          <Typography variant="body1" color="mycolor.main">
            New Request Confirm
          </Typography>
        </MenuItem>              
        <MenuItem key={MENU_OPTIONS.label3} onClick={handleDownRecord} sx={{ color: 'secondary.main' }}>
          <Iconify icon={'eva:more-vertical-fill'} sx={{ mr: 2 }} />
            Record Details
        </MenuItem>
        <MenuItem key={MENU_OPTIONS.label2} onClick={handleDownRecord} sx={{ color: 'prinary.main' }}>
          <Iconify icon={'eva:edit-outline'} sx={{ mr: 2 }} />
            Edit Contact Information
        </MenuItem> 
      </Popover>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={msgOpen} autoHideDuration={8000} onClose={handleClose}>
        <Alert severity={severity} onClose={handleClose} sx={{ 
          minWidth: '300px', // Minimum genişlik
          maxWidth: '90%', // Maksimum genişlik, ekranın %90'ını geçmez
          width: 'auto', // Genişlik içeriğe bağlı olarak ayarlanır
          fontSize: 14, 
          fontWeight: 'normal', 
          fontStyle: 'normal',
          overflow: 'hidden', // İçerik kutunun dışına taştığında gizle
          wordWrap: 'break-word', // Uzun kelimeleri satır sonunda böler
        }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {/* <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={msgOpen}  autoHideDuration={8000} onClose={handleClose}>
          <Alert severity={severity} onClose={handleClose} sx={{ width: 450, height: 70, fontSize: 14, fontWeight: 'bold', fontStyle: 'normal'}} > {alertMessage} </Alert>
      </Snackbar> */}
      <Dialog
        open={openConfirmDialog}
        onClose={handleConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          {selected.map((email, index) => (
            <p key={index} style={{fontWeight: 'bold', color: "#1640D6", marginBottom: 2 }}>
              {email}
            </p>
          ))}
          <p>
            The above e-mail addresses will be set as{' '}
            <span style={{ fontWeight: 'bold', color: currentAction.status === 0 ? 'red' : 'green' }}>
              {currentAction.status === 0 ? 'Inactive' : 'Active'}
            </span>.
            Are you sure you want to do this?
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            Cancel
          </Button>
          <Button 
            // onClick={handleConfirm} 
            autoFocus
            style={{
              backgroundColor: currentAction.status === 0 ? 'red' : 'green',
              color: '#FFFFFF',
            }}
          >
            OK
          </Button>
        </DialogActions>        
      </Dialog>
      <Dialog open={isRequestDialogOpen} onClose={handleCloseRequestDialog}>
        <DialogTitle style={{ color: 'red'}}>New Request Comfirm Record</DialogTitle>
        <Typography variant="body1" color="mycolor.main">
          <span style={{ color: 'black',fontWeight: 'bold', fontStyle: 'normal', marginLeft: 20}}>{selected[0]?.fullname} | {selected[0]?.email}</span>
          </Typography>
        <DialogContent>
          <TextField
            error={contentLinkError}
            helperText={contentLinkError ? "This field is required." : ""}
            autoFocus
            margin="dense"
            label="Content Link"
            type='text'
            fullWidth
            variant="outlined"
            value={contentLink}
            onChange={handleContentLinkChange}
          />
          <TextField
            error={contentDescriptionError}
            helperText={contentDescriptionError ? "This field is required." : ""}
            margin="normal"
            label="Content Of Description"
            type="text"
            fullWidth
            variant="outlined"
            value={contentDescription}
            onChange={handleContentDescriptionChange}
          />
          <FormControl error={selectedLanguageError} fullWidth margin="dense">
            <InputLabel id="lang-select-label">Select Language</InputLabel>
            <Select
              labelId="lang-select-label"
              name="mailtype"
              label="Select Language"
              value={selectedLanguage}
              onChange={handleChangeMailTypes}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {mailTypes.map((type, index) => (
                <MenuItem key={index} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
            {selectedLanguageError && <FormHelperText>This field is required.</FormHelperText>}
          </FormControl>
          <Typography 
            color="gray"            
            variant="caption"
          >
            Current Date: {currentDate}
          </Typography> 
        </DialogContent>
        {loading && (
          <div style={{ textAlign: 'right', padding: '8px 24px' }}>
            <p>{statusMessage}</p> 
          </div>
        )}
        <DialogActions>
          <Button onClick={handleCloseRequestDialog}>Cancel</Button>       
          <Button
            style={{
              backgroundColor: loading ? 'grey' : 'blue',
              color: '#FFFFFF',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleSubmitNewPaticipantApprovalDetail}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" style={{ color: '#FFFFFF' }} />
            ) : (
              'Save & Send Request'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        {updateDialogOpen && (
          <UpdateApprovalDialog
            open={updateDialogOpen}
            onClose={handleCloseUpdateDialog}
            user={selectedUser}
            // onApprovalSuccess={fetchUsers}
            onApprovalSuccess={() => handleUpdateApproval(true)}
          />
        )}
    </div>
    </>
  );
}