import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLoading } from '../../utils/contexts/LoadingContext';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, 
InputLabel, FormControl, Alert, Snackbar, FormControlLabel, RadioGroup, Radio, Box,Typography,
FormGroup, Checkbox,Accordion, AccordionSummary, AccordionDetails, Paper, Grid, FormLabel, 
Autocomplete, DialogContentText, TableContainer, Table, TableRow, CircularProgress  } from '@mui/material';
import { deepOrange, amber, purple, orange, cyan, red, green } from '@mui/material/colors';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Divider from '@mui/material/Divider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { fDate, fDateX } from '../../utils/formatTime';

import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; 
import Iconify from '../iconify';
import InputAdornment from '@mui/material/InputAdornment';
import creatstudent from '../../_data/student/createstudent';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneInput from './PhoneInput';
import getSession from '../../utils/getSession';
import style from '../../theme/blink.css';
import  getPaymentDefinitions  from '../../_data/coursecoordination/getPaymentDefinitions';
import { getContactStates } from '../../_data/contacstates/getContactStates';
import { getContactChannels } from '../../_data/contactchannels/getContactChannels';
import { getAssessmentLevels } from '../../_data/assesmentlevels/getAssessmentLevels';
import createInterviewTransaction from '../../_data/student/createInterviewTransaction';
import setUpdateFollower from '../../_data/student/setUpdateFollower';
import convertDateTime from '../../utils/convertDateTime';
import getActiveCourseTypes from '../../_data/courses/getActiveCourseTypes';
import { getActiveFinanceTypes } from '../../_data/finance/getRegisteredStudent';
import getAllCourseCoordinators from '../../_data/user/getAllCourseCoordination';
import getJobCenterCoordinators from '../../_data/user/getJobCenterCoordinators';
import formatDateTime from '../../utils/dateTimeFormatter';
import LoadingButton from '@mui/lab/LoadingButton';
import PaymentOptionCards from '../../components/course-coortination/paymentoptioncards';
import getStudentCountryData from '../../_data/student/getStudentCountryData';
import studentPaymentContract from '../../_data/coursecoordination/createStudentPaymentContract';
import insertStudentUser from '../../_data/user/setStudentUser'
import getStudentUser from '../../_data/user/getStudentUser'

export default function AddTransactionDialog({ onTransactionAdded, follower, studentId, transactionid, email, country, openDialog, onClose }) {
  const { showLoading, hideLoading } = useLoading();
  const [stdCountryData, setStdCountryData] = useState();  
  const fetchCountryfeetypeid = async () => {
    const data = await getStudentCountryData(country);
    setStdCountryData(data);
  }
  /*
      country.countriesname: "Fransa"
      coursefeetypeid: 1
      idcountries: 9
   */
  const { t } = useTranslation();
  const account = getSession();
  const [open, setOpen] = useState(openDialog);
  const [followerId, setFollowerId] = useState(follower.followerId);
  const [newfollowerId, setNewFollowerId] = useState();
  const [followerName, setFollowerName] = useState(follower.followerName);
  const [currentTime, setCurrentTime] = useState(formatDateTime());
  const [howmanycall, setHowManyCall] = useState(null);
  const [nextCall, setNextCall] = useState(dayjs().subtract(1, 'month'));
  const [allcoordinators, setAllCoordinators] = useState([]);
  const [allJobCenterCoordinators, setAllJobCenterCoordinators] = useState([]);
  const [coordinatorId, setCoordinatorId] = useState([]);
  const [activeCourseTypes, setActiveCourseTypes] = useState([]);
  const [activeFinanceTypes, setActiveFinanceTypes] = useState([]);
  const autocompleteCoordinatorRef = useRef(null);
  const [sendEmailConfirm, setSendEmailConfirm] = useState(false);
  const [showNewTask, setShowNewTask] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openSelectedTaskConfirmation, setOpenSelectedTaskConfirmation] = useState(false);
  const [paymentDefinitions, setPaymentDefinitions] = useState([]);
  const [recourcecode, setRecourcecode] = useState('');
  let formattedDate = new Date();
  let year = formattedDate.getFullYear();
  let month = String(formattedDate.getMonth() + 1).padStart(2, "0");
  let day = String(formattedDate.getDate()).padStart(2, "0");
  let today = `${year}-${month}-${day}`;

  const convertDateFormat = (dateStr) => {
    if (!dateStr) {
      return 'Invalid Date';
    }
    // DD.MM.YYYY HH:MM:SS formatına uygun regex
    const parts = dateStr.match(/(\d{2})\.(\d{2})\.(\d{4}) (\d{2}):(\d{2}):(\d{2})/);
    if (!parts || parts.length < 7) {
        return 'Invalid Date';
    }
    const day = parts[1];
    const month = parts[2];
    const year = parts[3];
    const hours = parts[4];
    const minutes = parts[5];
    const seconds = parts[6];
    // YYYY-MM-DD HH:MM:SS formatında string oluştur
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  const [formData, setFormData] = useState({
    studentid: studentId,
    contactdatetime: convertDateFormat(currentTime),   
    contactchannel: '',
    contactstate: '' ,
    newaction: '' ,
    descriptionabout: '',
    assessmentlevel: -1,
    howmanycall: 1,
    nextcalldatetime: '',
    relatedtaskid: transactionid,
    transactionby: account.user[0].iduser,
    toMail: email, 
    sendmail: sendEmailConfirm,
    coursetypeid: -1,
    coursetypename: '',
    financetypeid: -1,
    financetypename: '',
    paymenttype: ''
  });

  const [paymentContractData, setPaymentContractData] = useState({
    studentid: studentId,
    coursetypeid: formData?.coursetypeid,   //Online mi LMS-Hybrid
    coursetypename: formData?.coursetypename, //Bunu almasak simdilik olur ama Online veya Lms Hybrid degerini almaliyiz.***
    financetypeid: formData?.financetypeid, //J-->Job Center, P-->Private
    financetypename: formData?.financetypename,
    coursefeeregion: country,  //Ulke
    currency: paymentDefinitions?.currency, // € or $
    registrationfee: Number(paymentDefinitions?.registrationfee), //Ilk kayit ucreti
    paymentfrequency: paymentDefinitions?.paymentfrequency, //Odeme yontemi (Cash' Installment)
    paymentfirstdetails: {  // Pesin odemede bu alandaki cashamount degeri dolu gelir Installment ise bu alan sifir gelir taksit ay ve odeme bilgisi dolu gelir.
      installmentcount: paymentDefinitions?.installmentcount,	
      installmentamount:paymentDefinitions?.installmentamount, 
      cashamount:  paymentDefinitions?.cashamount, 
      totalfirstamount: paymentDefinitions?.totalfirstamount
    },
    paymentseconddetails:{  //Ise basladiktan sonraki secimi ifade etmektedir.
      annualincomestartingending: paymentDefinitions?.selectedNestedCard?.annualincomestartingending || '',  //Ise basladiktan sonra gelir durumunu ifade eden alan
      installmentcount1: paymentDefinitions?.selectedNestedCard?.installmentcount1 || 0, 
      installmentamount1: paymentDefinitions?.selectedNestedCard?.installmentamount1 || 0, 
      installmentcount2: 0, 
      installmentamount2: 0, 
      balloonpayment: paymentDefinitions?.selectedNestedCard?.balloonpayment || 0, 
      totalsecondamount: paymentDefinitions?.totalsecondamount, 
      description: '',
      onetimepayment: paymentDefinitions?.onetimepayment || 0
    },
    totalamount: (Number(paymentDefinitions?.totalfirstamount) + Number(paymentDefinitions?.totalsecondamount) + Number(paymentDefinitions?.registrationfee) || 0),
    createdby: formData?.transactionby
  });

  const handleMembershipCheckboxChange = (event) => {
    setFormData({
      ...formData,
      memberships: {
        ...formData.memberships,
        [event.target.name]: event.target.checked
      }
    });
  };
  
  const [contactState, setContactState] = useState([])
  
  //const [selectedPaymentDefinitions, setSelectedPaymentDefinitions] = useState([])
  const [contactChannel, setContactChannel] = useState([])
  const [assessmentLevel, setAssessmentLevel] = useState([])
  const [selectedFinanceTypeId, setSelectedFinanceTypeId] = useState(Number(formData.financetypeid));

  useEffect(() => {
    clearForm();
    fetchContactState();
    fetchCountryfeetypeid();
    fetchContactChannel();
    fetchAssessmentLevel();
    loadCoordinators();
    loadCourseTypes();
    loadFinanceTypes();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(formatDateTime());
    }, 1000);
    return () => clearInterval(timer); // Bu hook sadece zaman için çalışır ve her saniye güncellenir.
  }, []);

  const clearForm = () => {
    setFormErrors({});
  };

  const fetchContactState = async () => {
    const data = await getContactStates();
    setContactState(data);
  };

  const fetchPaymentDefinitions = async (selectedPaymentType) => {
    try {
      setFormData({ ...formData, paymenttype: selectedPaymentType});
      const data = await getPaymentDefinitions(stdCountryData.data[0].coursefeetypeid, formData.coursetypeid, selectedPaymentType); //coursefeeregiontype, coursetype, paymentfrq
      setPaymentDefinitions(data);
      //console.log('payment method: ', data);

    } catch (error) {
      console.log('fetchPaymentDefinitions-Error: ',error);
    }
  };

  const fetchContactChannel = async () => {
    const data = await getContactChannels();
    setContactChannel(data);
  };

  const fetchAssessmentLevel = async () => {
    const data = await getAssessmentLevels();
    setAssessmentLevel(data);
  };

  // dayjs.extend(utc);
  // const handleNextCallChange = (newValue) => {
  //   if (dayjs.isDayjs(newValue)) {
  //     let utcDate = newValue.utc().format();
  //     let mysqlDatetime = dayjs(utcDate).utc().format('YYYY-MM-DD HH:mm:ss');
  //     setNextCall(newValue);
  //     setFormData({ ...formData, nextcalldatetime: mysqlDatetime});
  //   } else {
  //       console.log('newValue is not a Dayjs object:', newValue);
  //   }
  // };
  const handleNextCallChange = (newValue) => {
    //console.log('Selected Action Date: ', newValue);
    if (dayjs.isDayjs(newValue)) {
      let localDatetime = newValue.format('YYYY-MM-DD HH:mm:ss');
      //console.log('Selected Action Formatted Date: ', localDatetime);
      setNextCall(newValue);
      setFormData({ ...formData, nextcalldatetime: localDatetime });
    } else {
    //  console.log('newValue is not a Dayjs object:', newValue);
    }
  };

  const copyToClipboard = async (text) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
        showMessage('Create e-Mail User & Password copied.');
      } else {
        let textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        showMessage('Create e-Mail User & Password copied.');
      }
    } catch (err) {
      console.error('Could not copy password to clipboard!', err);
    }
  };

  const loadCoordinators = async () => {
    try {
       const response = await getAllCourseCoordinators();
       setAllCoordinators(response);
       const response_JobCenter = await getJobCenterCoordinators();
       setAllJobCenterCoordinators(response_JobCenter);
    } catch (error) {
        console.error('Error loading Course Coordinators:', error);
    }
  };

  const loadCourseTypes = async () => {
    try {
       const response = await getActiveCourseTypes();       
       setActiveCourseTypes(response);
    } catch (error) {
        console.error('Error loading Course Types:', error);
    }
  };

  const loadFinanceTypes = async () => {
    try {
       const response = await getActiveFinanceTypes();
       setActiveFinanceTypes(response);
    } catch (error) {
        console.error('Error loading Finance Types:', error);
    }
  };

  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [alertMessages, setAlertMessages] = useState([]);
  const showMessage = (message, severityType = 'warning', duration = 8000) => {
    const id = new Date().getTime();
    const newMessage = { id, message, severityType };
    setAlertMessages(prevMessages => [...prevMessages, newMessage]);
    setSeverity(severityType);
    setMsgOpen(true);
    setTimeout(() => {
      setAlertMessages(prevMessages => prevMessages.filter(msg => msg.id !== id));
    }, duration);
  };

  const handleCloseStudentMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  const handleClickOpen = () => {
    setOpenSelectedTaskConfirmation(true);
    if (openSelectedTaskConfirmation) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    onClose(false); // Parent bileşenine kapatma bilgisini gönder
  };  

  const handleContactChannels = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, contactchannel: value });
  };
  
  const handleChangeHowManyCall = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, howmanycall: value});
  };

  const handleChangeContactState = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, contactstate: value });
  };

  const handleChange = (event) => {
    try {
      const { name, value } = event.target;
      if(name === 'contact.mail.mailaddress1'){
        setFormData({ ...formData, email: value });
      }
      const nameParts = name.split('.');
      setFormData((prevFormData) => {
        let updatedValue = { ...prevFormData };
        nameParts.reduce((acc, key, index) => {
          if (index === nameParts.length - 1) {
            acc[key] = value;
          } else {
            acc[key] = acc[key] || {};
          }
          return acc[key];
        }, updatedValue);
        return updatedValue;
      });
    // }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const maxChars = 900;
  const [charCount, setCharCount] = useState(0);

  const handleChangeDescription = (event) => {
    setFormData({ ...formData, descriptionabout: event.target.value });
    let newValue = event.target.value;
    if (event.target.value.length > maxChars) {
      newValue = event.target.value.substring(0, maxChars);
    }
    setCharCount(newValue.length);
    setFormData((prevFormData) => ({
      ...prevFormData,
      description: newValue,
    }));
  };

  // const handleChangepaymentdefinitions = (event) => {
  //   setSelectedPaymentDefinitions(event.target.value );
  //   //console.log('handleChangepaymentdefinitions: ', event.target.value);
  // };

  const handleChangeAssessmentLevel = (event) => {
    setFormData({ ...formData, financetypeid: Number(-1) }); //if there is old value, reset 
    const selectedValue = event.target.value;
    if (assessmentLevel.some(level => Number(level.idassesmentlevel) === Number(selectedValue))) {
        setFormData({ ...formData, assessmentlevel: Number(selectedValue) });
    } else {
        setFormData({ ...formData, assessmentlevel: Number(-1) }); // Varsayılan değer
    }
  };

  const handleIncrement = () => {
    setHowManyCall(prevCount => prevCount + 1);
  };

  const handleDecrement = () => {
    setHowManyCall(prevCount => prevCount - 1);
  };

  const contactstateRef = useRef(null);
  const contactchannelRef = useRef(null);
  const paymentTypeRef = useRef(null);
  const mainPaymentCardRef = useRef(null);
  const nestedPaymentCardRef = useRef(null);
  const assessmentlevelRef = useRef(null);
  const newactionRef = useRef(null);
  const nextcalldatetimeRef = useRef(null);
  const [formErrors, setFormErrors] = useState({});
  const [firstErrorField, setFirstErrorField] = useState(null);

  const validateForm = () => {
    let errors = {};
    let firstError = null;

     //console.log("formData.coursetype", formData.coursetype);
    ////  console.log("formData.paymenttype", formData.paymenttype);

    if(formData.assessmentlevel === 7){ // if assessmentlevel Student 
      if( formData.financetypename !== 'Job Center' && (formData.paymenttype === null || formData.paymenttype === "")){
     ////   console.log("Choose Payment Type...");
        errors.paymentType = true;
        firstError = firstError || 'paymentType';
      };
  
      if(formData.paymenttype === "Cash"){
     ////   console.log('Selected Card:', selectedCardId);        
        if(selectedCardId === null || selectedCardId === 0){
          errors.mainPaymentCard = true;
          firstError = firstError || 'mainPaymentCard';
        }
      };

      if(formData.paymenttype === "Installment"){
       //console.log('selectedNestedCardId & selectedCardId & selectedPaymentOptions, coursetype:', selectedNestedCardId, selectedCardId, selectedPaymentOptions, formData.coursetype);
        if (selectedPaymentOptions === "StandartOptions"){
          if(selectedCardId === null || Number(selectedCardId) === 0){
            errors.mainPaymentCard = true;
            firstError = firstError || 'mainPaymentCard';
          };
         //console.log('formData.coursetype: ', formData.coursetype );
          if (Number(formData.coursetypeid) === 1){
            //console.log('in-formData.coursetype: ', formData.coursetype );
            //console.log('selectedNestedCardId: ', selectedNestedCardId );
            if (selectedNestedCardId === null || selectedNestedCardId === 0){
              //console.log('in-selectedNestedCardId: ', selectedNestedCardId );
              errors.nestedPaymentCard = true;
              firstError = firstError || 'nestedPaymentCard';
            }
          };
          if(selectedPaymentOptions !== null || selectedPaymentOptions !== "" || selectedPaymentOptions !== "StandartOptions"){ //If Custom Options
         ////   console.log("selectedPaymentOptions: ", selectedPaymentOptions);
          };
        }else if(selectedPaymentOptions === "CustomOptions"){
         //// console.log("selectedPaymentOptions: ", selectedPaymentOptions);
        }
      };
      
      // if (!formData.paymenttype) { //TODO: Devam etmeliyim nested secimi veya main card secimi yoksa uyaralim.
      //   errors.paymentType = true;
      //   firstError = firstError || 'paymentType';
      // };
    };

    if (!formData.contactstate) {
      errors.contactstate = true;
      firstError = firstError || 'contactstate';
    }

    if (!formData.contactchannel) {
      errors.contactchannel = true;
      firstError = firstError || 'contactchannel';
    }
    
    if (!formData.assessmentlevel) {
      errors.assessmentlevel = true;
      firstError = firstError || 'assessmentlevel';
    }    
    //console.log('new task isaretli mi? :', showNewTask);
    if(showNewTask){
      if (!formData.nextcalldatetime) {
        errors.nextcalldatetime = true;
        firstError = firstError || 'nextcalldatetime';
      } else {
        const datetime = dayjs(formData.nextcalldatetime);

        if (datetime.isBefore(dayjs(), 'day')) {
          errors.nextcalldatetime = true;
          firstError = firstError || 'nextcalldatetime';
          showMessage(`The selected date (${firstError}) field should not be before today!`, 'warning');
        } else if (datetime.isAfter(dayjs(), 'day')) {
        } else {
          //console.log('Verilen tarih bugün.');     
        }
      }
      if (!formData.newaction) {
        errors.newaction = true;
        firstError = firstError || 'newaction';
      }
    } //else{console.log('Hayir false')}
    setFormErrors(errors);
    setFirstErrorField(firstError);

    if (firstError) {
      const refs = {
        contactstate: contactstateRef,
        contactchannel: contactchannelRef,
        paymentType: paymentTypeRef,
        mainPaymentCard: mainPaymentCardRef,
        nestedPaymentCard: nestedPaymentCardRef,
        assessmentlevel: assessmentlevelRef,
        newaction: newactionRef,
        nextcalldatetime: nextcalldatetimeRef
      };
      refs[firstError].current?.focus();
      showMessage(`Fill or Select in (${firstError}) the required fields!`, 'warning');
    }
    return Object.keys(errors).length === 0;
  };
  const [createButtonDisable, setCreateButtonDisable]=useState(true);

  const updatedFormData = {
    ...formData,
  };

  const matchPaymentInfo = async (paymentContractData)=>{
   // console.log("matchPaymentInfo-paymentContractData: ", paymentContractData);
    const updatedPaymentContractData = {
      ...paymentContractData,       
     coursetypeid: formData?.coursetypeid,   //Online mi LMS-Hybrid
     coursetypename: formData?.coursetypename, //Bunu almasak simdilik olur ama Online veya Lms Hybrid degerini almaliyiz.***
     financetypeid: formData?.financetypeid, //J-->Job Center, P-->Private
     financetypename: formData?.financetypename,
     coursefeeregion: country,  //Ulke
     currency: paymentDefinitions[0]?.currency || '', // € or $
     registrationfee: Number(paymentDefinitions[0]?.registrationfee) || 0, //Ilk kayit ucreti
     paymentfrequency: paymentDefinitions[0]?.paymentfrequency || '', //Odeme yontemi (Cash' Installment)
     paymentfirstdetails: {  // Pesin odemede bu alandaki cashamount degeri dolu gelir Installment ise bu alan sifir gelir taksit ay ve odeme bilgisi dolu gelir.
       installmentcount: paymentDefinitions[0]?.installmentcount || 0,	
       installmentamount:paymentDefinitions[0]?.installmentamount || 0, 
       cashamount:  paymentDefinitions[0]?.cashamount || 0, 
       totalfirstamount: paymentDefinitions[0]?.totalfirstamount || 0
     },
     paymentseconddetails:{  //Ise basladiktan sonraki secimi ifade etmektedir.
      annualincomestartingending: paymentContractData?.paymentseconddetails?.annualincomestartingending || '',  //Ise basladiktan sonra gelir durumunu ifade eden alan
      installmentcount1: paymentContractData?.paymentseconddetails?.installmentcount1 || 0, 
      installmentamount1: paymentContractData?.paymentseconddetails?.installmentamount1 || 0, 
      installmentcount2: paymentContractData?.paymentseconddetails?.installmentcount2 || 0,
      installmentamount2: paymentContractData?.paymentseconddetails?.installmentamount2 || 0,
      balloonpayment: paymentContractData?.paymentseconddetails?.selectedNestedCard?.balloonpayment || 0, 
      totalsecondamount: paymentContractData?.paymentseconddetails?.totalsecondamount, 
      description: paymentContractData?.paymentseconddetails?.description,
      onetimepayment: paymentContractData?.paymentseconddetails?.onetimepayment || 0
    },
    //  totalamount: (Number(paymentDefinitions[0].totalfirstamount) || 0 + Number(paymentDefinitions[0].totalsecondamount) || 0 + Number(paymentDefinitions[0]?.registrationfee) || 0),
     totalamount: (Number(paymentDefinitions[0].totalfirstamount) || 0 + Number(paymentContractData?.paymentseconddetails?.totalsecondamount) || 0 + Number(paymentDefinitions[0]?.registrationfee) || 0),
     createdby: formData.transactionby    
   };
   //console.log('******* setPaymentContractData *********');
   setPaymentContractData(updatedPaymentContractData); 
   //console.log('paymentContractData: ', updatedPaymentContractData);
   return updatedPaymentContractData;
  };

  const checkPaymentInfo = (paymentContractData)=>{    
   // console.log('checkPaymentInfo', paymentContractData);
    if (!paymentContractData || Object.keys(paymentContractData).length === 0) {
      return false;
    }
    return true;
  };
  
  const handleSubmit = async() => {
    try {
      if (isResetting) {
        //console.log('isResetting: ', isResetting);
        return;
      }
      setIsLoading(true);
      setFormData(updatedFormData);
      const isValid = validateForm();
      if (!isValid) return;
      if(Number(formData.assessmentlevel) === 7 && Number(formData.financetypeid) === 1){
        const isUser = await getStudentUser(formData.studentid);
        let isApprovalUser = false;
        if(isUser.length > 0){
          isApprovalUser = true;
        }else{
          isApprovalUser = false;
        };
        if(!isApprovalUser){
          showMessage('Before adding interaction, a "Job Center-Student" user account must be created with "Approval" button!', 'warning');
          setMsgOpen(true);
          return;
        }
        // TODO: Fiyat konusunu burada Offer icin ne gonderilecek ve ogrenci kendi sayfasindan ne gorecek onu burada sectirebiliriz.
        // TODO: Eger ogrenci ve jobcenter isareti varsa user hesabi acilmis mi kontrol et degilse return edip ogrenci hesabini olusturmak icin "Approval" islemini yapmalisiniz uyarisi ver. 
        //  console.log('Job Center Offer Belgesini ilgili ogrencinin sayfasina yukleyecegiz.')
      }else{
        let paymentResult = '';
        if(formData.assessmentlevel === 7){
       //   console.log("paymentContractData: ", paymentContractData);
          

          if(paymentContractData.paymentfrequency === "Installment" && selectedPaymentOptions === "CustomOptions"){
            paymentResult = paymentContractData;
          }else if(paymentContractData.paymentfrequency === "Cash" && selectedPaymentOptions === "CustomOptions"){
            paymentResult = paymentContractData;
          }else{
          //  console.log("paymentContractData: ", paymentContractData);
            paymentResult = await matchPaymentInfo(paymentContractData); 
          //  console.log("paymentResult: ", paymentResult);
          }
          if(!checkPaymentInfo(paymentResult)){
            showMessage(t('MustBeCompletedPaymentOptions'), 'warning');
            setMsgOpen(true);
            return;
          }
          else{
          //  console.log("paymentResult: ", paymentResult);
            const result = await studentPaymentContract(paymentResult);
          };
        };
      };
      const result = await createInterviewTransaction(formData);
      if(result.affectedRows === 1 && result.message === "New transaction created successfully."){
        showMessage(t('NewTransactionCreateSuccess'), 'success');
        setMsgOpen(true);
        //TODO burada da eger Candidate-J ise secili coordinator id ile student tablosunda degsiklik yapalim.
        if(formData.assessmentlevel === 2){
          const result = await setUpdateFollower(coordinatorId, studentId);
        }
        onTransactionAdded();
        handleClose();
        if(showNewTask){
          setOpenConfirmation(true);
        }
        // TODO: Contact icin bildirim iletisi atilsin mi?
      } else {
        showMessage(result.message, 'error');
      }          
    } catch (error) {
      console.log('handleSubmit-Error: ', error);
    }finally{
      setIsLoading(false);
    }
  };

  const [isProcessing, setIsProcessing] = useState(false);
  const handleSubmitJCApproval = async() => {
    try {
      showLoading();
      setIsProcessing(true);
      //   //TODO burada da eger Candidate-J ise secili coordinator id ile student tablosunda degsiklik yapalim.
      // TODO: Insert Student Table Finance Status as Job Center   
   //   console.log('handleSubmitJCApproval');
      const result = await insertStudentUser(studentId, recourcecode);
    //  console.log('result: ', result);
    //  console.log('result.success: ', result.success);
      if(result.success === false) {
      // if(result.message === "The same record already exist." || result.message === `Duplicate entry '${formData.toMail}' for key 'users.username_UNIQUE'`) {
        showMessage(result.message, 'error');
        showMessage(`The same record ${formData.toMail} already exist.`, 'error');
      }else if(result.userRows.affectedRows > 0 && result.success){        
        showMessage(`Student User record and send information mail to ${result.mailResult.result.accepted} is successfully. `, 'success');
      }      
    } catch (error) {
      setIsProcessing(false);
    // console.log('handleSubmitJCApproval-error: ', error);
    }finally{
      hideLoading();
    }
  };

  const handleCloseJCApproval = () => {
    setFormData({
      ...formData,
      assessmentlevel: 2,
      financetypename: '',
      financetypeid: -1
    });
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    clearForm();
  };

  const handleSendMailConfirmation = () => {
    sendEmail(email);
    setOpenConfirmation(false);
    clearForm();
  };

  const sendEmail = (email) => {
    //console.log(`Email sent to ${email} with the next call date and time: ${nextCall}`);
  };
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj) || '';
  };

  const handleCloseSelectedTask = () => {
    setOpenSelectedTaskConfirmation(false);
    clearForm();
  };

  const handleRelatedSelectedTask = () => {
    const idtransaction = 1;
    relatedTask(idtransaction);
    setOpenSelectedTaskConfirmation(false);
    clearForm();
  };

  const relatedTask = (idtransaction) => {
    //console.log('idtransaction: ', idtransaction);
  };

  const [contentEmailError, setContentEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');
  const [contentFullnameError, setContentFullnameError] = useState(false); 
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const handleBlur = (e) => {
    const isValid = emailRegex.test(e.target.value);
    if (!isValid) {
      setEmailHelperText("Please enter a valid email address.");
      setContentEmailError(true);
    } else {
      setEmailHelperText("");
      setContentEmailError(false);
    }
  };

  const checkIsJCCourcoordinator = async(id)=>{
    const found = allJobCenterCoordinators.find(coordinator => coordinator.id === id);
    return found !== undefined;
  };

  const [isStudentUser, setIsStudentUser] = useState(false);
  const [studentUserInfo, setStudentUserInfo] = useState(false);

  const handleRadioChange1 = async (event) => {
    const selectedValue = event.target.value;
    const selectedLabel = event.target.labels[0]?.innerText; // Get Label text
    setFormData({ ...formData, financetypeid: selectedValue, financetypename :selectedLabel });
    setSelectedFinanceTypeId(selectedValue);
    //console.log(typeof selectedValue, selectedValue);
    if(Number(selectedValue) === 1 && Number(formData.assessmentlevel) === 7){
      const isJCoordinator = await checkIsJCCourcoordinator(formData.transactionby);
      if(isJCoordinator){
        setRecourcecode('J'); // Job Center        
      }else{
        showMessage('You do not have authorization to perform this operation!');
        handleCloseJCApproval();
        return;
      }   
      const isUser = await getStudentUser(studentId);          
      if(isUser.length > 0){
        setStudentUserInfo(isUser);
        setIsStudentUser(true);
      }else {
        setIsStudentUser(false);
      }
    }else if(Number(selectedValue) === 2){
      setRecourcecode('P'); //Private
    }
  };

  const handleRadioChange2 = (event) => {
    const selectedLabel = event.target.labels[0]?.innerText; // Get Label text
    setFormData({ ...formData, coursetypeid: event.target.value, coursetypename: selectedLabel});
    setShowPaymentCards(false);
  };

  const handleRadioChange3 = (event) => {
    setFormData({ ...formData, financetypeid: event.target.value });
    fetchPaymentDefinitions(event.target.value);
  };

  const handleRadioChange4 = (event) => {
    setFormData({ ...formData, radioGroup4: event.target.value });
  };

  const handleCoordinatorName = (newValue) => {
    if (!newValue) {
      setCoordinatorId('');
      return;
    }    
    setCoordinatorId(newValue.id);
  };

  const handleCheckboxChange = (event) => {
    setShowNewTask(event.target.checked);
    if(event.target.checked === false){
      setSendEmailConfirm(false);
      setNextCall(null);
      setFormData({ ...formData, 
        newaction: ''
       });

    }
  };

  const handleSendMailCheckboxChange = (event) => {    
    setSendEmailConfirm(event.target.checked);    
  };

  useEffect (() => {    
    setFormData({ ...formData, sendmail: sendEmailConfirm});    
  },[sendEmailConfirm]);

  const [isLoading, setIsLoading] = useState(false);
  
  const [selectedCardId, setSelectedCardId] = useState(0); //Default value = 0
  const [selectedNestedCardId, setSelectedNestedCardId] = useState(0); //Default value = 0
  const [selectedPaymentOptions, setSelectedPaymentOptions] = useState('StandartOptions');
  const onSelect = useCallback((detail) => {
     console.log('*** *** *** >>> detail-useCallback:', detail);
     //console.log('*** *** *** >>> paymentDefinitions-useCallback:', paymentDefinitions);
    // console.log('*** detail.paymentOptions:', detail.paymentOptions);
    // console.log('Selected Card:', detail.selectedCardId);
     setSelectedCardId(detail.selectedCardId);
  //   console.log('Nested Card ID:', detail.selectedNestedCard); //dogru geldi....
     setSelectedNestedCardId(detail.selectedNestedCard);
     setSelectedPaymentOptions(detail.paymentOptions);
     if(detail.paymentOptions === "StandartOptions"){
      if (paymentDefinitions && Object.keys(paymentDefinitions).length > 0) {
        const updatedPaymentContractData = {
          ...paymentContractData,       
          coursetypeid: formData.coursetypeid,   //Online mi LMS-Hybrid
          coursetypename: formData.coursetypename || '', //Bunu almasak simdilik olur ama Online veya Lms Hybrid degerini almaliyiz.***
          financetypeid: Number(formData.financetypeid), //J-->Job Center, P-->Private
          financetypename: formData.financetypename,
          coursefeeregion: country,  //Ulke
          currency: paymentDefinitions[0]?.currency || '', // € or $
          registrationfee: Number(paymentDefinitions[0]?.registrationfee) || 0, //Ilk kayit ucreti
          paymentfrequency: paymentDefinitions[0]?.paymentfrequency || '', //Odeme yontemi (Cash' Installment)
          paymentfirstdetails: {  // Pesin odemede bu alandaki cashamount degeri dolu gelir Installment ise bu alan sifir gelir taksit ay ve odeme bilgisi dolu gelir.
            installmentcount: paymentDefinitions[0]?.installmentcount || 0,	
            installmentamount:paymentDefinitions[0]?.installmentamount || 0, 
            cashamount:  paymentDefinitions[0]?.cashamount || 0, 
            totalfirstamount: paymentDefinitions[0]?.totalfirstamount || 0
          },
          paymentseconddetails:{  //Ise basladiktan sonraki secimi ifade etmektedir.
            annualincomestartingending: detail?.selectedNestedCard?.annualincomestartingending || '',  //Ise basladiktan sonra gelir durumunu ifade eden durum
            installmentcount1: detail?.selectedNestedCard?.installmentcount1 || 0, 
            installmentamount1: detail?.selectedNestedCard?.installmentamount1 || 0, 
            installmentcount2: detail?.selectedNestedCard?.installmentcount2 || 0, 
            installmentamount2: detail?.selectedNestedCard?.installmentamount2 || 0, 
            balloonpayment: detail?.selectedNestedCard?.balloonpayment || 0, 
            totalsecondamount: detail?.selectedNestedCard?.totalsecondamount || 0, 
            description: detail?.selectedNestedCard?.description || '',
            onetimepayment: detail?.selectedNestedCard?.onetimepayment || 0
          },
          totalamount: (Number(paymentDefinitions[0].totalfirstamount) || 0 + Number(paymentDefinitions[0].totalsecondamount) || 0 + Number(paymentDefinitions[0]?.registrationfee) || 0),
          createdby: formData.transactionby    
        };
     // console.log('paymentContractData-StandartOptions: ', updatedPaymentContractData); //Hatali cikis
       setPaymentContractData(updatedPaymentContractData);
      };
    }else{
      const updatedPaymentContractData = {
        ...paymentContractData,       
        coursetypeid: formData.coursetypeid,   //Online mi LMS-Hybrid
        coursetypename: formData.coursetypename || '', //Bunu almasak simdilik olur ama Online veya Lms Hybrid degerini almaliyiz.***
        coursefeeregion: country,  //Ulke
        financetypeid: Number(formData.financetypeid), //J-->Job Center, P-->Private
        financetypename: formData.financetypename,
        currency: paymentDefinitions[0].currency || '', // € or $
        registrationfee: Number(detail.selectedCoursefee)|| 0, //Ilk kayit ucreti
        paymentfrequency: formData.paymenttype|| '', //Odeme yontemi (Cash' Installment)
        paymentfirstdetails: {  // Pesin odemede bu alandaki cashamount degeri dolu gelir Installment ise bu alan sifir gelir taksit ay ve odeme bilgisi dolu gelir.
          installmentcount: detail.amountBefore || 0,	
          installmentamount: detail.monthsBefore || 0, 
          cashamount: detail.cashamount || 0, 
          totalfirstamount: detail.totalBefore || 0
        },
        paymentseconddetails:{  //Ise basladiktan sonraki secimi ifade etmektedir.
          annualincomestartingending: detail?.selectedNestedCard?.annualincomestartingending || '',  //Ise basladiktan sonra gelir durumunu ifade eden durum
          installmentcount1: detail?.selectedNestedCard?.installmentcount1 || 0, 
          installmentamount1: detail?.selectedNestedCard?.installmentamount1 || 0, 
          installmentcount2:  0, 
          installmentamount2:  0, 
          balloonpayment: detail?.selectedNestedCard?.balloonpayment || 0, 
          totalsecondamount: detail?.totalAfter || 0, 
          description: detail?.selectedNestedCard?.description || '',
          onetimepayment: paymentDefinitions[0]?.onetimepayment || 0
        },
        totalamount: (Number(detail?.totalBefore) || 0 + Number(detail?.totalAfter) || 0 + Number(detail?.selectedCoursefee)|| 0 ),
        createdby: formData.transactionby    
      };
   //   console.log('paymentContractData-CustomOptions: ', updatedPaymentContractData);
      setPaymentContractData(updatedPaymentContractData);
    };


  //  console.log('*** paymentContractData ***: ', paymentContractData);

  },[paymentDefinitions, paymentContractData, formData, country]);

  const [showPaymentCards, setShowPaymentCards]= useState(false);
  useEffect(() => {
    if (formData.coursetypeid && formData.paymenttype) {
      // Örneğin veritabanı güncellemesi yapıldıysa
      setShowPaymentCards(true);
    } else {
      setShowPaymentCards(false);
    }
  }, [formData.coursetypeid, formData.paymenttype]);

  const [isResetting, setIsResetting] = useState(false);

  return (
    <Grid sx = {{ml: 8}}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('Title.ANT')} / {country}</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2" noWrap sx={{ fontWeight: 'bold', color: 'mycolor.main', fontSize: 14, pb:2, pt:1, mr: 2 }}>
            {currentTime} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
            <span style={{ color: '#2e7d32', fontWeight: 'bold', marginRight: '5px'}}> {email} </span> 
          </Typography>
          <TextField
            margin="dense"
            name="surname"
            label={t('TransactionBy')}
            fullWidth            
            variant="outlined"
            value={account.user[0].name_surname}
            InputProps={{
              readOnly: true,
            }}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="contactstate-select-label">{t('ContactState')}</InputLabel>
            <Select
              labelId="contactstate-select-label"
              name="contactstate"
              label={t('ContactState')}
              value={formData.contactstate || ''}
              onChange={handleChangeContactState}
              className={formErrors.contactstate ? "blinking" : ""}
            >
              {contactState && contactState.map((contactState) => (                                
                <MenuItem key={contactState.idcontactstate} value={contactState.idcontactstate}>
                  {contactState.statename}        
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
              <InputLabel id="contactchannel-select-label">Contact Channel</InputLabel>
              <Select
                inputRef={contactchannelRef}
                labelId="contactchannel-select-label"
                name="contactchannel"
                label="Contact Channel"
                value={formData.contactchannel}
                onChange={handleContactChannels}                
                className={formErrors.contactchannel ? "blinking" : ""}
              >                
                {contactChannel && contactChannel.map((contactChannel) => (                                
                <MenuItem key={contactChannel.idcontactchannel} value={contactChannel.idcontactchannel}>
                  {contactChannel.contactenvironment}        
                </MenuItem>
              ))}
              </Select>
          </FormControl>          
          <FormControl fullWidth margin="dense">
              <InputLabel id="assessmentlevel-select-label">Assessment Level</InputLabel>
              <Select
                inputRef={assessmentlevelRef}
                labelId="assessmentlevel-select-label"
                name="assessmentlevel"
                label="Assessment Level"
                value={String(formData.assessmentlevel)}
                onChange={handleChangeAssessmentLevel}                
                className={formErrors.contactchannel ? "blinking" : ""}
              >                
                {assessmentLevel && assessmentLevel.map((assessmentLevel) => (                                
                  <MenuItem key={assessmentLevel.idassesmentlevel} value={assessmentLevel.idassesmentlevel}>
                    {assessmentLevel.assesmentlevel}        
                  </MenuItem>
                ))}
              </Select>
              {formData.assessmentlevel === 2 && ( // TODO: must be dynamic This time just "Candidate-J" selected
                <Box mt={2}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Choose an Finance Option</FormLabel>                    
                    <RadioGroup
                      row
                      aria-label="option2"
                      name="radioGroup2"
                      value={Number(formData.financetypeid)}
                      onChange={handleRadioChange1}
                    >                            
                      { Array.isArray(activeFinanceTypes) && activeFinanceTypes.length > 0 ? (
                        activeFinanceTypes.
                        filter((item) => item.idfinancetypes === 1).
                        map((item)=>(
                          <FormControlLabel
                            key={item.idfinancetypes}
                            sx ={{mr: '70px'}} value={item.idfinancetypes} control={<Radio/>} 
                            label={
                              <Typography sx={{fontWeight: Number(formData.financetypeid) === item.idfinancetypes ? 'bold' : 'normal' }}>
                                {item.financetypename}
                              </Typography>}
                          />
                        ))
                      ) : (
                        <Typography sx={{fontWeight: 'bold' }}>
                          No course types available
                        </Typography>
                      )}
                    </RadioGroup>
                    {/* <RadioGroup
                      aria-label="option1"
                      name="radioGroup1"
                      value={formData.financetypeid}
                      onChange={handleRadioChange1}
                    >
                      <FormControlLabel value="Job Center" control={<Radio />} 
                      label="Job Center" />
                      {/* <FormControlLabel value="Private" control={<Radio />} label="Private" />
                    </RadioGroup> 
                    */}
                  </FormControl>

                  {formData.financetypename === 'Job Center' && (
                    <Box mt={2}>
                      <FormControl component="fieldset">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={allJobCenterCoordinators ? allJobCenterCoordinators.map(coordinator => ({
                            id: coordinator.id,
                            label: coordinator.name_surname
                          })) : []}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          sx={{ flexGrow: 1,  width: 'auto', minWidth: 250, maxWidth: 500  }}
                          renderInput={(params) => <TextField {...params} label="Search Coordinator" inputRef={autocompleteCoordinatorRef} />}
                          onChange={(event, newValue) => {
                            handleCoordinatorName(newValue);
                          }}
                          ListboxProps={{ style: { maxHeight: '200px', overflow: 'auto' } }}
                          renderOption={(props, option, { selected }) => (
                            <Box component="li" sx={{ '& > button': { mr: 2 } }} 
                              {...props}
                              key={option.id}
                            >
                              {option.label}
                            </Box>
                          )}
                        />
                      </FormControl>
                    </Box>
                  )}
                </Box>
              )}
              {formData.assessmentlevel === 7 && ( // TODO: must be dynamic or ENUM/CONST This time just "Student" selected
                <Box mt={2}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Choose an Finance Option</FormLabel>
                    <RadioGroup
                      row
                      aria-label="option2"
                      name="radioGroup2"
                      value={Number(formData.financetypeid)}
                      onChange={handleRadioChange1}
                    >                            
                      { Array.isArray(activeFinanceTypes) && activeFinanceTypes.length > 0 ? (
                        activeFinanceTypes.map((item)=>(
                          <FormControlLabel
                            key={item.idfinancetypes}
                            sx ={{mr: '70px'}} value={item.idfinancetypes} control={<Radio/>} 
                            label={
                              <Typography sx={{fontWeight: Number(formData.financetypeid) === item.idfinancetypes ? 'bold' : 'normal' }}>
                                {item.financetypename}
                              </Typography>}
                          />
                        ))
                      ) : (
                        <Typography sx={{fontWeight: 'bold' }}>
                          No course types available
                        </Typography>
                      )}
                    </RadioGroup>
                    {/* <RadioGroup
                      row
                      aria-label="option1"
                      name="radioGroup1"
                      value={formData.financetypeid}
                      onChange={handleRadioChange1}
                    >
                      <FormControlLabel 
                        value="1" 
                        sx ={{mr: '50px'}}
                        control={<Radio />}                                                 
                        label={
                          <Typography
                            sx={{
                              fontWeight: selectedFinanceTypeId === 1 ? 'bold' : 'normal'
                            }}
                          >
                            Job Center
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Private"
                        control={<Radio />}
                        label={
                          <Typography
                            sx={{
                              fontWeight: selectedFinanceTypeId === 2 ? 'bold' : 'normal'
                            }}
                          >
                            Private
                          </Typography>
                        }
                      />
                    </RadioGroup> */}
                  </FormControl>
                  {Number(formData.financetypeid) === 1 &&  !isStudentUser &&(                    
                    <Box mt={2} mb={1} sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      border: '1px solid #ccc',
                      padding: '20px',
                      borderRadius: '8px',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                      opacity: isProcessing ? 0.5 : 1,
                      pointerEvents: isProcessing ? 'none' : 'auto'
                    }}>
                      <Typography sx={{ color: red[400], fontWeight: 'bold', textAlign: 'center', mb: 1 }}>
                        The candidate will be placed in JobCenter status and application account information will be sent for pre-onboarding.
                      </Typography>
                      <Box mt={2} sx={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                        <Button
                          onClick={handleSubmitJCApproval}
                          disabled={isProcessing}
                          sx={{
                            backgroundColor: purple[700],
                            color: '#FFFFFF',
                            '&:hover': {
                              backgroundColor: 'red',
                            }
                          }}
                        >
                          {t('Approval')}
                        </Button>
                        <Button
                          onClick={handleCloseJCApproval}
                          sx={{
                            backgroundColor: 'green',
                            color: '#FFFFFF',
                            '&:hover': {
                              backgroundColor: 'darkgreen',
                            }
                          }}
                        >
                          {t('Cancel')}
                        </Button>
                      </Box>
                    </Box>
                  )}
                  {Number(formData.financetypeid) === 1 && isStudentUser && (
                    <Box mt={2} mb={1} sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      border: '1px solid #ccc',
                      padding: '20px',
                      borderRadius: '8px',
                      boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                    }}>
                      <Typography sx={{ color: '#674188', fontWeight: 'bold', textAlign: 'center', mb: 1 }}>
                        The candidate ("{studentUserInfo[0].name_surname}") was placed in JobCenter status and the application account information was previously sent for pre-orientation.                        
                      </Typography>
                      </Box> 
                  )}
                  {Number(formData.financetypeid) === 2 &&  (
                    <Box mt={2}>                      
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Choose a Course Type</FormLabel>
                        <RadioGroup
                          row
                          aria-label="option2"
                          name="radioGroup2"
                          value={formData.coursetypeid}
                          onChange={(e) => {
                            handleRadioChange2(e);
                            setIsResetting(true); // Reset işlemine başla
                            setFormData((prevData) => ({
                              ...prevData,
                              paymenttype: '', // Paymenttype reset
                            }));
                            setShowPaymentCards(false); // Hide Card
                            setSelectedCardId(0); // main card id reset
                            setSelectedNestedCardId(0); //nested card id reset
                            setTimeout(() => {
                              setIsResetting(false); // Reset işlemi bitti
                            }, 300); // 300ms yeterli olabilir
                          }}
                        >        
                          { Array.isArray(activeCourseTypes.result) && activeCourseTypes.result.length > 0 ? (
                            activeCourseTypes.result.map((item)=>(
                              <FormControlLabel
                                key={item.idcourse_types}
                                sx ={{mr: '70px'}} value={item.idcourse_types} control={<Radio/>} 
                                label={
                                  <Typography sx={{fontWeight: formData.coursetypeid == item.idcourse_types ? 'bold' : 'normal' }}>
                                    {item.typename}
                                  </Typography>}
                              />
                            ))
                          ) : (
                            <Typography sx={{fontWeight: 'bold' }}>
                              No course types available
                            </Typography>
                          )}
                        </RadioGroup>
                      </FormControl>

                      {formData.coursetypeid && (formData.coursetypeid > 0) &&(
                        <Box mt={2}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">Payment Type</FormLabel>
                            <RadioGroup
                              inputRef={paymentTypeRef}
                              row
                              aria-label="option3"
                              name="radioGroup3"
                              value={formData.paymenttype}
                              onChange={handleRadioChange3}
                            >
                              <FormControlLabel 
                                value="Cash" 
                                control={<Radio />} 
                                label={<Typography sx={{fontWeight: formData.paymenttype === 'Cash' ? 'bold' : 'normal'}}>Cash</Typography>}
                              />
                              <FormControlLabel 
                                value="Installment" 
                                control={<Radio />} 
                                label={<Typography sx={{fontWeight: formData.paymenttype === 'Installment' ? 'bold' : 'normal'}}>Installment</Typography>}
                              />
                            </RadioGroup>
                          </FormControl>

                          {/* PaymentType seçildiğinde kartı göster */}
                          {formData.paymenttype && showPaymentCards && (
                            <Box mt={2}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                border: '1px solid #ccc',
                                padding: '20px',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                              }}
                            >
                              <FormControl component="fieldset">
                                <PaymentOptionCards data={paymentDefinitions} onSelect={onSelect} />
                              </FormControl>                                
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  )}

                  {/* {formData.financetype === 'Private' &&  (
                    <Box mt={2}>                      
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Choose a Course Type</FormLabel>
                        <RadioGroup
                        row
                          aria-label="option2"
                          name="radioGroup2"                          
                          value={formData.coursetype}
                          onChange={handleRadioChange2}
                        >        
                          { Array.isArray(activeCourseTypes.result) && activeCourseTypes.result.length > 0 ? (
                            activeCourseTypes.result.map((item)=>(
                            <FormControlLabel
                              key={item.idcourse_types}
                              sx ={{mr: '70px'}} value={item.idcourse_types} control={<Radio/>} 
                              label={
                              <Typography sx={{fontWeight: formData.coursetype == item.idcourse_types ? 'bold' : 'normal' }}>
                                {item.typename}
                              </Typography>}
                            />
                          ))
                        ):( <Typography sx={{fontWeight: 'bold' }}>
                              No course types available
                            </Typography>
                          )
                        }
                        </RadioGroup>
                      </FormControl>
                        {formData.coursetype == 1 && (
                          // TODO: Bu alanda kursiyerin Ulkesine gore ucretlendirme durumunu getirecegiz. Once bu sayfaya ulke id'sini almaliyim.
                          <Box mt={2}>
                            <FormControl component="fieldset">
                              <FormLabel component="legend">Payment Type</FormLabel>
                              <RadioGroup
                                inputRef={paymentTypeRef}
                                row
                                aria-label="option3"
                                name="radioGroup3"
                                value={formData.paymenttype}
                                onChange={handleRadioChange3}
                              >

                                <FormControlLabel value="Cash" control={<Radio />} 
                                //label="Cash" 
                                label={<Typography sx={{fontWeight: formData.paymenttype === 'Cash' ? 'bold' : 'normal'}}>Cash</Typography>}/>
                                <FormControlLabel value="Installment" control={<Radio />} 
                                // label="Installment"/>
                                label={<Typography sx={{fontWeight: formData.paymenttype === 'Installment' ? 'bold' : 'normal'}}>Installment</Typography>}/>
                                { 
                                  // TODO: CASH ve Installment seceneklerini de databaseden almaliyim?
                                }
                              </RadioGroup>
                            </FormControl>
                            {formData.paymenttype && showPaymentCards && ( 
                            // {(formData.paymenttype == 'Cash' || formData.paymenttype == 'Installment') && (                              
                              <Box mt={2}
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  border: '1px solid #ccc',
                                  padding: '20px',
                                  borderRadius: '8px',
                                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                                }}
                              >
                                <FormControl component="fieldset">
                                  <PaymentOptionCards data = {paymentDefinitions} onSelect = {onSelect} />
                                </FormControl>                                
                              </Box>
                            )}
                          </Box>
                        )}
                        {formData.coursetype == 2 && (
                          // TODO: Bu alanda kursiyerin Ulkesine gore ucretlendirme durumunu getirecegiz. Once bu sayfaya ulke id'sini almaliyim.
                          <Box mt={2}>
                            <FormControl component="fieldset">
                              <FormLabel component="legend">Payment Type</FormLabel>
                              <RadioGroup
                                inputRef={paymentTypeRef}
                                row
                                aria-label="option3"
                                name="radioGroup3"
                                value={formData.paymenttype}
                                onChange={handleRadioChange3}
                              >

                                <FormControlLabel value="Cash" control={<Radio />} 
                                //label="Cash" 
                                label={<Typography sx={{fontWeight: formData.paymenttype === 'Cash' ? 'bold' : 'normal'}}>Cash</Typography>}/>
                                <FormControlLabel value="Installment" control={<Radio />} 
                                // label="Installment"/>
                                label={<Typography sx={{fontWeight: formData.paymenttype === 'Installment' ? 'bold' : 'normal'}}>Installment</Typography>}/>
                                { 
                                  //TODO: CASH ve Installment seceneklerini de databaseden almaliyim?
                                }
                              </RadioGroup>
                            </FormControl>
                             
                            {formData.paymenttype && showPaymentCards && (                              
                            // {(formData.paymenttype == 'Cash' || formData.paymenttype == 'Installment') && (                              
                              <Box mt={2}
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  border: '1px solid #ccc',
                                  padding: '20px',
                                  borderRadius: '8px',
                                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                                }}
                              >
                                <FormControl component="fieldset">
                                  <PaymentOptionCards data = {paymentDefinitions} onSelect = {onSelect} />
                                  
                                </FormControl>                                
                              </Box>
                            )}
                          </Box>
                        )}
                    </Box>
                  )} */}
                </Box>
              )} 
          </FormControl>
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="caption" fontWeight={'bold'} mt={3} color={ charCount < maxChars ? 'green' : 'red'}>
              {`${maxChars - charCount} characters remaining`}
            </Typography>
          </Box>
          <TextField
            margin="dense"
            name="description"
            label={t('Description')}
            type="text"
            fullWidth
            variant="outlined"
            value={formData.descriptionabout}
            onChange={handleChangeDescription}
            multiline
          />                    
          <Box>
            <FormControlLabel
              control={<Checkbox checked={showNewTask} onChange={handleCheckboxChange} />}
              label="Create New Task"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontWeight: showNewTask ? 'bold' : 'normal',
                  color: showNewTask ? 'mycolor.main' : 'inherit',
                },
              }}
            />
            {showNewTask && (
              <Box sx={{ border: '1px solid gray', padding: 2, marginTop: 0, marginBottom: 1, borderRadius: 2  }}>
                <TextField
                  margin="dense"
                  name="newaction"
                  label="New Action"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formData.newaction}
                  onChange={handleChange}
                  className={formErrors.newaction ? "blinking" : ""}
                  multiline
                  rows={3}
                  sx={{ mb: 2 }}
                />
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      renderInput={(props) => <TextField {...props} />}
                      label="Next Call Date&Time"
                      value={nextCall}
                      onChange={handleNextCallChange}
                      className={formErrors.nextcalldatetime ? "blinking" : ""}
                    />
                  </LocalizationProvider>
                  <FormControlLabel
                      control={<Checkbox checked={sendEmailConfirm} onChange={handleSendMailCheckboxChange} />}
                      label="Send Email"
                      sx={{
                          '& .MuiFormControlLabel-label': {
                          fontWeight: sendEmailConfirm ? 'bold' : 'normal',
                          color: sendEmailConfirm ? 'error.main' : 'inherit',
                          },
                      }}
                    />
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{mr: 5}}>{t('Cancel')}</Button>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            loading={isLoading}
            sx={{ fontSize: isLoading ? '22' : '18',
              bgcolor: isLoading ? '#5141C3' : 'blue',
              color: 'white',
              maxWidth: '250px',
              ':hover': {
                bgcolor: isLoading ? '#5141C3' : '#5141C3',
                fontSize: isLoading ? '18' : '24'
              },
              ':disabled': {
                bgcolor: isLoading ? '#5141C3' : '',
                color: isLoading ? 'white' : '',
              }
            }}  
            startIcon={isLoading ? <CircularProgress color="inherit" size={30} /> : null}>        
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '40%' }}> {isLoading ? 'Loading...' : 'Create'}          
              <Box component="img" src='/assets/logo.svg' alt="Oak Academy logo" sx={{  marginLeft:4, width: 50, height: 50, display: isLoading ? '/assets/logo.svg' : 'block' }} />
            </Box>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
        PaperProps={{
          sx: {
            padding: 2,
            borderRadius: 3,
            boxShadow: 5,
            maxWidth: '500px',
            margin: 'auto',
          },
        }}
      >
        <DialogTitle id="confirmation-dialog-title" sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.5rem' }}>
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description" sx={{ textAlign: 'center', marginBottom: 2 }}>
            <Typography variant="body1">
              An email will be sent to <strong>{email}</strong> with the next call date and time:
            </Typography>
            <Typography variant="h6" sx={{ marginTop: 1 }}>
              {nextCall ? nextCall.format('MMMM D, YYYY h:mm A') : ''}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              Do you confirm?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={() => handleCloseConfirmation(false)} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSendMailConfirmation} color="primary" variant="contained" sx={{ marginLeft: 2 }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {alertMessages.map((msg, index) => (
        <Snackbar
          key={msg.id} 
          open={msgOpen} 
          autoHideDuration={6000} 
          onClose={() => {
            setAlertMessages(prevMessages => prevMessages.filter(message => message.id !== msg.id));
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx = {{
            position: 'fixed',
            bottom: `${index * 120}px`,
            left: 0,
            right: 0,
            width: 'auto'
          }}
        >
          <Alert severity={msg.severityType}>
            {msg.message}
          </Alert>
        </Snackbar>
      ))}
    </Grid>
  );
};



