import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getApprovalUsers= async () => {
    try {
        const token = await getToken();
        const response = await axios.get(`${BaseUrl}/permissionreq/getallusers`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const info = response.data || [];
        //console.log('info: ', info); 
       // console.log('/permissionreq/getallusers: ', info);
        const approvalsList = info.map((item, index) => {
            return {
                idparticipants_approvals: item.idparticipants_approvals || '',
                email: item.email || '',
                fullname: item.fullname || '',
                contact: item.contact || '',
                pending_approvals: item.pending_approvals || 0,
                notyourmail: item.notyourmail || 0,
            };
        });        
       // console.log('approvalsList: ', approvalsList); 
        return approvalsList;
    } catch (error) {
        console.log('error: ', error);
        return [];
    }
};

export default getApprovalUsers;



