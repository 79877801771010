import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const setUserPassword= async (id, newPasswd) => {
  try {
    const token = await getToken();
    const response = await axios.patch(`${BaseUrl}/users/setpassword`, 
      {
        iduser: id,
        newPassword: newPasswd
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );  
    return response.data;
  } catch (error) {  
      console.error("Update error: ", error);
      return [];
  }
};

export default setUserPassword;
