import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getOnboardQuestionList = async () => {
    try {
        const token = await getToken();
      //  console.log('getOnboardLecturesList. ');
        const response = await axios.get(`${BaseUrl}/student/onboard/exams/getonboardquestions`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
        });
    //    console.log('getOnboardLecturesList: ', response.data);
        const info = response.data || [];
        const questionInfo = info.map((item, index) => {
            return {
                idquestions: item.idquestions || '',
                questionstext: item.questionstext || '',
                questionsimage: item.questionsimage || '',
                imagepath: item.imagepath || '',
                idcorrectanswer: item.idcorrectanswer || '',
                idquestionslecture: item.idquestionslecture || '',
                questionpoint: item.questionpoint || '',
                significanceofquestion: item.significanceofquestion || '',
                questionsource: item.questionsource || '',
                atcreated: item.atcreated || '',
                atupdated: item.atupdated || '',
                index: index
            };
        });
   //     console.log("getQuestionList: ", questionInfo);
        return questionInfo;
    } catch (error) {
        console.log('error: ', error);
        return [];
    }
};

export { getOnboardQuestionList };
