import { Helmet } from 'react-helmet-async';
import { useLoading } from '../../utils/contexts/LoadingContext';
import { filter } from 'lodash';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DynamicSelect from '../../components/select/dynamicSelect';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

// @mui
import {
  Card, Table, Stack, Paper, Avatar, Button, Popover, Checkbox, TableRow,
  MenuItem, TableBody, TableCell, Container, Typography, IconButton,
  TableContainer, TablePagination, InputLabel, FormControl, Select, Box, Snackbar, Alert,
  Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, TableHead
} from '@mui/material';
import { SvgIcon } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import { ReactComponent as Icon_Attendance } from '../../assets/icons/attendance.svg';
import { ReactComponent as Icon_Payment } from '../../assets/icons/icon_payment.svg';
import { ReactComponent as Icon_Extract } from '../../assets/icons/extract.svg';
import { ReactComponent as Icon_Transaction } from '../../assets/icons/transaction.svg';
import  convertDateTime from '../../utils/convertDateTime';

import Scrollbar from '../../components/scrollbar'
// sections
import { MetaAdsListHead, MetaAdsListToolbar } from '../../sections/@dashboard/facebook';

//database
import { getLeadFormDatas, getAdsParticipant } from '../../_data/facebook/getLeadFormDatas';
import SessionExpiredDialog from '../../components/session/sessionExpried';
import getSession from '../../utils/getSession'; //Session Management
import clearSession from '../../utils/clearSession';

import config from '../../config';
import { RestorePageOutlined } from '@mui/icons-material';
const { PUBLIC_URL } = config;
const TransactionIcon = `${PUBLIC_URL}/assets/icons/navbar/ic_transaction.svg`;


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, filterStatusId, filterBatchName, filterCoordinatorId, filterCallInDays, filterFirstRecord, nextTransactions) {
  if (!Array.isArray(array)) {
    return [];
  }
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]).filter((student) => {
    const lowerCaseQuery = String(query).toLowerCase(); 
    const matchQuery = query ?
      Object.values(student).some((value) => String(value).toLowerCase().includes(lowerCaseQuery)) :
      true;
    const matchStatus = filterStatusId ? student.status === filterStatusId : true;
    return matchQuery && matchStatus;
  });
};


export default function MetaAdsPages() {
  const { showLoading, hideLoading } = useLoading();
  const { t } = useTranslation();

  // -------------------------COLUMN TITLES-------------------------------------------------- -----

  const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },    
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'created_time', label: 'Created Time', alignRight: false },
    { id: 'leads_count', label: 'Leads Count', alignRight: false },
    { id: 'id', label: 'Ads Id', alignRight: false },
    { id: '' },
  ];
  // ---------------------------------------------------------------------------------------------------------

  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('coursename');
  const [filterStudentId, setfilterStudentId] = useState('');
  const [filterStatusId, setfilterStatusId] = useState('');
  const [filterBatchName, setfilterBatchName] = useState('');
  const [filterCoordinatorId, setfilterCoordinatorId] = useState('');
  const [filterCallInDays, setfilterCallInDays] = useState(false);
  const [filterFirstRecord, setfilterFirstRecord] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [leadFormDatas, setLeadFormDatas] = useState([]);
  const [recordsNumber, setrecordsNumber] = useState(0);
  const [openAlertMsg, setOpenAlertMsg] = useState(false); //Notification Show/hide
  const [severity, setSeverity] = useState('success'); //Notification Show/hide  
  const [alertMessage, setAlertMessage] = useState('');
  const [selectData, setSelectData] = useState();
  const [selectedBatch, setSelectedBatch] = useState("");
  const [msgOpen, setMsgOpen] = useState(false);
  const [titleLabel, setTitleLabel] = useState("Oak Batch Names");
  const [nextTransactions, setNextTransactions] = useState([]);
  const [firstStudentRecords, setFirstStudentRecords] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [newTotalTasks, setNewTotalTasks] = useState(0);
  const [isCoordinatorLeader, setIsCoordinatorLeader] = useState(false);
  const navigate = useNavigate();
  


  const MENU_OPTIONS = [
    { label0: 'Participants ', },
    { label1: 'Edit ', },
    { label2: 'Delete ', },
    { label3: 'AllExtract ', },
    { label4: 'Attendance', },
    { label5: 'Finance', },
    { label6: 'Details', },
  ];

  const [openParticipantsDialog, setOpenParticipantsDialog] = useState(false);
  const [openLecturesDialog, setOpenLecturesDialog] = useState(false);
  const [currentCourseLecturesData, setCurrentCourseLecturesData] = useState(['']);
  const [currentAction, setCurrentAction] = useState();

  const handleEnrollmentDialogOpen =  async (items) => {
    try {      
      if (!items || Object.keys(items).length === 0) {
        return;
      }          
     const response = await getAdsParticipant(items.id);     
      const data = response.data || [];
      const formattedData = data?.map(item => {
        const emailField = item.field_data.find(field => field.name === 'email');
        const fullNameField = item.field_data.find(field => field.name === 'full_name');
        const whatsappField = item.field_data.find(field => field.name === 'whatsapp_numaranız?');
        return {
          id: item.id,
          created_time: item.created_time,
          email: emailField ? emailField.values[0] : '',
          full_name: fullNameField ? fullNameField.values[0] : '',
          whatsapp: whatsappField ? whatsappField.values[0] : '',
        };
      });
      setCurrentAction(formattedData);
      setOpenParticipantsDialog(true);
    } catch (error) {  
      console.log('error: ', error);
    }  
  };

  const handleAdsParticipantsDialogClose = () => {
    setOpenParticipantsDialog(false);
  };


  const handleDownRecord = async (event, ) => {
    try {     
      const label = event.currentTarget.textContent;
      selected.forEach((item) => {
      });
      if(selected.length > 0){
        switch(label){   
          case t('LFP'):
            console.log("selected[0]: ", selected[0]);
            handleEnrollmentDialogOpen(selected[0]); 
            break;       
          default:
            break;
        };
    }else{          
      setSeverity('error');
      setAlertMessage('Not selected Meeting Record!');
      setMsgOpen(true);
    }
    } catch (error) {
        console.log("handleDownRecord-Error: ", error);
    }
  }; 
  
  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const handleSessionExpiration = () => {
    setIsSessionExpired(true);
  };

  const handleCloseStudentMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  const session = getSession();
  const dynamicStructure = session && session.AllRoles ? session.AllRoles : [];

  const getLeadForms = async () => {
    try {
      showLoading();      
      const data = await getLeadFormDatas();
      setrecordsNumber(data.data.length);
      setLeadFormDatas(data.data);
    } catch (error) {
        console.error(error);
        setLeadFormDatas([]);
    }finally{
      hideLoading();
    }
  };

  useEffect(() => {
    getLeadForms();
  }, []);

  const [filterRecords, setFilterRecords] = useState();
  const [selectedFullName, setSelectedFullName] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');
  const handleOpenMenu = (event, { id, name, status, created_time, leads_count }) => {
    setOpen(event.currentTarget);
    setSelected([{ id: id, name: name, status: status, created_time: created_time, leads_count: leads_count }]);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {   
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      if (!Array.isArray(filteredStudents)) {
        return;
      }
      const newSelecteds = filteredStudents?.map((n) => ({
        id: n.idstudents,
        mail: n.email,
        contact: n.contact,
        fullname: `${n.name} ${n.surname}`,
      }));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, student) => {
    const selectedIndex = selected.findIndex((item) => item.id === student.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, student);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }  
    setSelected(newSelected);
  };

  const refreshLeadFormDatas = () => {
    getLeadForms();
  };
  
  const handleConfirm = () => {    
    handleConfirmDialogClose();
  };

  const handleConfirmExtract = () => {    
    handleConfirmExractDialogClose();
  };
  
  const handleConfirmExtractDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmExractDialogClose = () => {
    setOpenExtractConfirmDialog(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const handleConfirmDialogOpen = (select) => {    
    setCurrentAction(select);
    setOpenConfirmDialog(true);
  };

  const [openConfirmExtractDialog, setOpenExtractConfirmDialog] = useState(false);
  const handleConfirmExtractDialogOpen = (select) => {    
    setCurrentAction(select);
    setOpenExtractConfirmDialog(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setfilterStudentId(event.target.value);
  };

  const handleFilterStatusByName = (newValue) => {
    if (!newValue) {
      setPage(0);
      setfilterStatusId('');
      return;
    }
    setPage(0);
    setfilterStatusId(newValue.id);
  };

  const handleFilterBatchByName = (newValue) => {
    if (!newValue) {
      setPage(0);
      setfilterBatchName('');
      return;
    }
    setPage(0);
    setfilterBatchName(newValue.id);
  };

  const handleFilterCoordinatorByName = (newValue) => {
    if (!newValue) {
      setPage(0);
      setfilterCoordinatorId('');
      return;
    }
    setPage(0);
    setfilterCoordinatorId(newValue.id);
  };

  const handleFilterByCallInDays = (newValue) => {
    if (newValue) {      
      setPage(0);
      setfilterCallInDays(newValue);
    } else{      
      setPage(0);
      setNextTransactions([]); 
      setfilterCallInDays(false);
      return;
    }
  };

  const handleFilterByFirstRecord = (newValue) => {
    try {
      showLoading();
      if (newValue) {      
        setPage(0);
        setfilterFirstRecord(newValue);
      } else{      
        setPage(0);
        setFirstStudentRecords([]); 
        setfilterFirstRecord(false);
        return;
      }      
    } catch (error) {
      console.log("[Error]-handleFilterByFirstRecord: ", error);
    }finally{
      hideLoading();
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - leadFormDatas.length) : 0;
  const filteredStudents = applySortFilter(leadFormDatas, getComparator(order, orderBy), filterStudentId, filterStatusId, filterBatchName, filterCoordinatorId, nextTransactions);
  const isNotFound = Array.isArray(filteredStudents) && !filteredStudents.length && (!!filterStudentId || !!filterStatusId || !!filterBatchName || !!filterCoordinatorId || !!nextTransactions);
  const validFilteredStudents = Array.isArray(filteredStudents) ? filteredStudents : [];
  const filteredStatuses = applySortFilter(leadFormDatas, getComparator(order, orderBy), filterStatusId, filterBatchName, filterCoordinatorId, nextTransactions);
  const [selectedCoordinatorId, setSelectedCoordinatorId] = useState(null);

  const replaceDateTime = (dateString) => {
    if (!dateString || typeof dateString !== 'string') {
      return '';
    };
    // Cut T ve Z char
    const formattedDate = dateString.replace('T', ' ').replace('Z', '').slice(0, 16);
    return formattedDate;
  };

  
  const isPastOrToday = (date) => {
    const berlinTimeString = new Date().toLocaleString('en-US', { timeZone: 'Europe/Berlin' });
    const berlinTime = new Date(berlinTimeString);
    const givenDate = new Date(date);
    const startOfToday = new Date(berlinTime.getFullYear(), berlinTime.getMonth(), berlinTime.getDate(), 0, 0, 0);
    if (givenDate <= startOfToday) {
      return true; 
    }
    return false; 
  };

  const [isChecked, setIsChecked] = useState(false);
  const [openFirstMailConfirmDialog, setOpenFirstMailConfirmDialog] = useState(false);
  const [pendingFirstMailCheckedState, setPendingFirstMailCheckedState] = useState(isChecked);
  const [firstMailIdStudent, setFirstMailIdStudent] = useState(0);
  const [firstMailStudentNameSurname, setFirstMailStudentNameSurname] = useState('');

  const handleCheckboxChangeFirstMail = (event, idstudents, name, surname) => {    
    setFirstMailIdStudent(idstudents);
    const ns = `${name} ${surname}`;
    setFirstMailStudentNameSurname(ns)
    const checkedValue = event.target.checked;
    setPendingFirstMailCheckedState(checkedValue); 
    setOpenFirstMailConfirmDialog(true); // Open confirm panel   
  };

  function formatDate(isoDate) {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Aylar 0-11 olduğu için +1 yapılıyor
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <>    
      <Helmet>
        <title> {t('Page.Title')} | Oak Academy </title>
      </Helmet>     
      <Container maxWidth={false} sx={{ marginLeft: 0, maxWidth: 'none' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>          
          <div>
            <Typography variant="h4" gutterBottom>
              <span style={{ fontWeight: 'bold', fontSize: '25px', color: 'blue' }}>{t('Oak Meta Ads Campaigns List')}</span>
              &nbsp;&nbsp;
              <span style={{ fontStyle: 'normal', fontSize: '15px', color: 'gray' }}> { t('Records')} {`: ${ recordsNumber } `}</span>
            </Typography>            
          </div>
        </Stack>
        <Card>
          <MetaAdsListToolbar 
            numSelected={selected.length} 
            filterName={filterStudentId} 
            onFilterName={handleFilterByName} 
            onStatusName={handleFilterStatusByName} 
            onBatchName={handleFilterBatchByName}
            onCoordinatorName={handleFilterCoordinatorByName}
            onCallInDays={handleFilterByCallInDays}
            onFirstRecord={handleFilterByFirstRecord}

          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <MetaAdsListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={recordsNumber}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>                                              
                {                  
                  (rowsPerPage === -1 ? validFilteredStudents : validFilteredStudents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))?.map((row, index) => {
                      const { id, name, status, created_time, leads_count } = row;                                      
                      const selectedStudent = selected.indexOf(id) !== -1;
                      const isItemSelected = selected.some(item => item.id === id);
                    return (
                      <TableRow hover key={index} tabIndex={-1} role="checkbox" selected={selectedStudent}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, { id: id, name: name, status: status, created_time: created_time, leads_count: leads_count })}
                          />
                        </TableCell>
              
                        
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}> 
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>                                              
                        <TableCell align="left">
                          <Label
                            sx={{ 
                              backgroundColor: status == "ACTIVE" ? 'defaultBackground' : '#ffcccc',
                              color:
                                status == "ACTIVE" ? 'darkgreen' : 
                                status == "PASIVE" ? 'secondary.main' : 'error'
                            }}>
                              { status }
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          {formatDate(created_time)}
                        </TableCell>
                        <TableCell align="left">
                          {leads_count}
                        </TableCell>
                        <TableCell align="left">
                          {id}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton 
                            size="large" 
                            color="inherit" 
                            onClick={(event) => {
                              handleOpenMenu(event, {id, name, status, created_time, leads_count})
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterStudentId}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[15, 25, 50, { value: -1, label: 'All' }]}
            component="div"
            count={recordsNumber}            
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 205,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem key={MENU_OPTIONS.label0} onClick={handleDownRecord} sx={{ color: 'text.dark', Width: 300}}> 
          <Iconify icon={'eva:checkmark-square-2-outline'} sx={{ mr: 2 }} />
          {t('LFP')}
        </MenuItem>
      </Popover>

      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={5000} 
        onClose={handleCloseStudentMessage}
      >
        <Alert severity={severity} onClose={handleCloseStudentMessage} 
        sx={{ 
          width: 480, minWidth: 350, minheight: 100, height: 130, fontSize: 14, 
          fontStyle: 'normal',
          whiteSpace: 'pre-line'
        }} >
          <Typography style={{ whiteSpace: 'pre-line' }}>
            {alertMessage}
          </Typography>
        </Alert>
      </Snackbar>

      <Dialog
        open={openConfirmDialog}
        onClose={handleConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          {selected?.map((select, index) => (              
            <p key={index} style={{fontWeight: 'bold', color: "#1640D6", marginBottom: 2 }}>
              {selectedFullName }{' /  '}{selectedUserName }{'  '}
            </p>
          ))}
          <p>
            The registration of the student(s) whose above information is visible will be deleted.
            <br/>
            Are you sure you want to do this?
            
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
          {t('Cancel')}
          </Button>
          <Button 
            onClick={handleConfirm} 
            autoFocus
            style={{
              backgroundColor: 'red',
              color: '#FFFFFF',
            }}
          >
            {t('Update')}
          </Button>
        </DialogActions>        
      </Dialog>        
      <Dialog
        open={openConfirmExtractDialog}
        onClose={handleConfirmExtractDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          {selected?.map((select, index) => (              
            <p key={index} style={{fontWeight: 'bold', color: "#1640D6", marginBottom: 2 }}>
              {selectedFullName }{' /  '}{selectedUserName }{'  '}
            </p>
          ))}
          <p>
            The interest and registration of the student(s) whose information is shown above will be removed from all platforms.
          </p>
              <br/>
              Are you sure you want to do this?          
        </DialogContent>
        <DialogActions>
          <Button sx = {{ pr: 10 }} onClick={handleConfirmExractDialogClose} color="primary">
          {t('Cancel')}
          </Button>
          <Button 
            sx = {{ pr: 10, textAlign: 'center' }}
            onClick={handleConfirmExtract} 
            autoFocus
            style={{
              backgroundColor: 'red',
              color: '#FFFFFF',
            }}
          >
            {t('Update')}
          </Button>
        </DialogActions>        
      </Dialog>

      <Dialog
        open={openParticipantsDialog}
        onClose={handleAdsParticipantsDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "80%", maxWidth: "none" } 
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {Array.isArray(currentAction) && currentAction.length > 0 && selected[0] && (
            <>
              <Typography variant="body4" component="span" style={{ color: 'red' }}>
                {selected[0].name}
              </Typography>
              <br/> 
              <Typography variant="body2" component="span" style={{ color: 'text' }}>
                Number of Participant : {currentAction.length}
              </Typography>
              
            </>
          )}
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Created Time</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Full Name</TableCell>
                  <TableCell>WhatsApp Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentAction?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{formatDate(row.created_time)}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.full_name}</TableCell>
                    <TableCell>{row.whatsapp}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>             
        </DialogContent>
        <DialogActions>
          <Button
            sx = {{ pl: 2, pr: 2, textAlign: 'center' }}
            onClick={handleAdsParticipantsDialogClose}
            autoFocus
            style={{
              backgroundColor: '#6C757D',
              color: '#FFFFFF',
            }}
          >
            CLOSE
          </Button>          
        </DialogActions>        
      </Dialog>
    </>
  );
};