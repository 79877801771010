import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getAllLangLevel = async () => {
    try {     
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/students/getalllanglevel`, {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      });
      const info = response.data.result || []; 
      if (!Array.isArray(info)) {
        throw new Error('API response is not an array');
      }

      const langlevel = info.map((item, index) => {
        return {
          idlanglevel: item.idlanglevel || '',
          langname: item.langname || '',
          levelname: item.levelname || '',
          description: item.description || '',
        };
      });       
      return langlevel;
    } catch (error) {
      console.error('Error fetching language levels:', error);
      return [];
    }
};

export default getAllLangLevel;