import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const deleteModules = async (id, iduser) => {
    try {
      const token = await getToken();
      const response = await axios.delete(`${BaseUrl}/syllabus/deletemodules`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: {
          idmodules: id,
          bycreated: iduser
        },
     });
      return response;
    } catch (error) {
      console.log("error-getModules: ", error);
    }
};

export default deleteModules;