import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getModules = async () => {
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/syllabus/getmodules`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });
    const info = response.data || [];
    const modulesInfo = info.map((item, index) => {
        return {
          idsyllabus_modules: item.idsyllabus_modules || '',
          modulename: item.modulename || '',
          lessonduration: item.lessonduration || '',
          description: item.description || '',
          lessontimeoverflow: item.lessontimeoverflow || ''
        };
    });
    return modulesInfo;
  } catch (error) {
    console.log("error-getModules: ", error);
      return [];
  }
};

export default getModules;