import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const setUserRoles= async (roles, userid) => {
    try {
        const token = await getToken();
        const response = await axios.patch(`${BaseUrl}/users/updateuserroles`, {
          roletitles: roles,
          iduser: userid
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return response.data;
    } catch (error) {
        console.error("User Roles Update error: ", error);
        return { error: "User roles update failed", details: error.message };
    }
};

export default setUserRoles;