import axios from 'axios';
import config from '../../config';
const { BaseUrl } = config;

const googleFileList= async () => {
    try {
        const response = await axios.get(`${BaseUrl}/google/filelist`);
        const info = response.data || [];
        // console.log("data.files: ", info.files[0]);
        const fileList = info.files.map((item, index) => {
            return {
                //nextPageToken: item.nextPageToken || '',
                //incompleteSearch: item.incompleteSearch || '',
                id: item.id || '',
                name: item.name || '',
                mimeType: item.mimeType || '',
                kind: item.kind || '',
            };
        });
        // console.log("fileList: ", fileList);
        return fileList;
    } catch (error) {
	console.log("googleFileList: ", error);
return [];
    }
};

export default googleFileList;
