// ConfirmDialogContext.js

import React, { createContext, useContext, useState } from 'react';

const ConfirmDialogContext = createContext();

export const useConfirmDialog = () => useContext(ConfirmDialogContext);

export const ConfirmDialogProvider = ({ children }) => {
  const [dialogProps, setDialogProps] = useState({
    open: false,
    title: '',
    message: '',
    onConfirm: () => {},
    onCancel: () => {},
  });

  const openDialog = (options) => {
    setDialogProps({ ...options, open: true });
  };

  const closeDialog = () => {
    setDialogProps((prev) => ({ ...prev, open: false }));
  };

  return (
    <ConfirmDialogContext.Provider value={{ dialogProps, openDialog, closeDialog }}>
      {children}
    </ConfirmDialogContext.Provider>
  );
};
