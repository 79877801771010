import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getReportStudentZoomAttendances= async (emails) => {
  try {
    const token = await getToken();
   // console.log('getReportStudentZoomAttendances: ', emails);
    const response = await axios.get(`${BaseUrl}/students/getreportparticipant`, {    
      headers: {
        'Authorization': `Bearer ${token}`
      },     
      params: {
        user_emails: emails,
      },
    });
    return response.data;
  } catch (error) {  
      console.error("Report error: ", error);
      return [];
  }
};

export default getReportStudentZoomAttendances;
