import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, CircularProgress } from '@mui/material';

const ConfirmDialog = ({ open, onClose, onConfirm, currentAction }) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
          Please Confirm
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box mb={2}>
          <Typography variant="body1" component="span" style={{ fontWeight: 'bold', color: 'navy' }}>
            'Approval Request'
          </Typography>
          <Typography variant="body1" component="span">
            {' '} will be sent to the user again!
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="body1" component="span" style={{ fontWeight: 'bold', color: 'blue' }}>
            {currentAction.fullname} {' ('} {currentAction.email} {') '}
          </Typography>
        </Box>
        <Typography variant="body1" component="div">
          So all requests submitted before this 'Request for Approval' 
          <Typography variant="body1" component="span" style={{ fontWeight: 'bold', color: 'red' }}>
            {' '} will be invalid!
          </Typography>
          <br />
          Are you sure you want to do this?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined" disabled={loading}>
          Cancel
        </Button>
        <Button 
          onClick={handleConfirm} 
          autoFocus
          disabled={loading}
          style={{
            backgroundColor: loading ? 'grey' : (currentAction.status === 0 ? 'red' : 'green'),
            color: '#FFFFFF',
          }}
          variant="contained"
        >
          {loading ? <CircularProgress size={24} style={{ color: '#FFFFFF' }} /> : 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;