import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getBatchNames= async () => {
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/students/getbatchnames`,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    const info = response.data || [];
    const batchList = info.map((item, index) => {
        return { batchname: item.batchname || '', };
    });
    return batchList;

  } catch (error) {
    if (error.response) {
    // Backend'den gelen HTTP yanıtına erişim sağla
    const { status, data } = error.response;

    if (status === 401) {
        // Kullanıcıya özel hata mesajı göster
     //   console.log(data.message); // veya kullanıcı arayüzünde bir uyarı göster
        return { status: 401, message: data.message };
    }else if (status === 403){
      //console.log("Token Status:", status, ' Message: ', data.message);
      return { status, message: data.message };
    }
    else {
        // Diğer HTTP hataları için genel bir işlem
     //   console.log("Bir hata oluştu. :", data.message);
        return { status, message: data.message };
    }
    } else {
    // Hata yanıtı olmadığında (örneğin, ağ hatası)
    //console.log("Ağ hatası veya sunucuya erişilemiyor.");
    return { status: 0, message: "Ağ hatası veya servise erişilemiyor." };
    }
  }
};

const getLmsBatchNames= async () => {
    // const { showAlert } = useAlert();
     try {
         const response = await axios.get(`${BaseUrl}/students/getlmsbatches`);
         const info = response.data || [];
         const batchList = info.map((item, index) => {
             return {
                idbatches: item.idbatches || '',
                batchname: item.batchname || '', 
            };
         });
        // console.log('batchList: ', batchList);         
         return batchList;
   
       } catch (error) {
           if (error.response) {
           // Backend'den gelen HTTP yanıtına erişim sağla
           const { status, data } = error.response;
       
           if (status === 401) {
               // Kullanıcıya özel hata mesajı göster
               //console.log(data.message); // veya kullanıcı arayüzünde bir uyarı göster
               return { status: 401, message: data.message };
           } else {
               // Diğer HTTP hataları için genel bir işlem
              // console.log("Bir hata oluştu: ", data.message);
               return { status, message: data.message };
           }
           } else {
           // Hata yanıtı olmadığında (örneğin, ağ hatası)
          // console.log("Ağ hatası veya sunucuya erişilemiyor.");
           return { status: 0, message: "Ağ hatası veya servise erişilemiyor." };
           }
       }
   };

export { getBatchNames, getLmsBatchNames };
