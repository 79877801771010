import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const addNewRoles = async (newRole) =>{
  try {
    //console.log('newRole: ', newRole);
    const token = await getToken();
    const {name, description} = newRole;
  //  console.log('name, description: ', name, description);
    //TODO: axios.post çağrısında data ve config parametreleri ayrıdır. headers bir config parametresidir ve data kısmıyla karıştırılmamalıdır. Asagidaki gibi ayrilmalidir.
    const response = await axios.post(`${BaseUrl}/roles/addnewrole`,        
      {
        rolename: name,
        roledescription: description,
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {  
      console.error("addNewRoles Error: ", error);
      return [];
  }
};

export default addNewRoles;