import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const downloadRecord = async (uuID, meetID) => {
  try {
    const token = await getToken();
    let _uuID = encodeURIComponent(uuID);
    //console.log("_uuID: ", _uuID);
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BaseUrl}/zoom/cloudrecord/${_uuID}/${meetID}/download`,
        headers: {
          'Authorization': `Bearer ${token}`
        },
      };
      const response = await axios.request(config);
     // console.log("downloadRecord-Response: ",response);
    return response;
  }
  catch (error) {
    console.log("downloadRecord: ", error);
  }
}

export default downloadRecord;
