import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const updateUser= async (datas) => {
    try {
      const token = await getToken();
      //console.log("data: ", datas);
      const response = await axios.put(`${BaseUrl}/users/updateUserStatus`, { 
        datas,        
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
       return response.data;
    } catch (error) {
        console.error("Update error: ", error);
        return [];
    }
};

export default updateUser;
