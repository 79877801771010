import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TextField,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { ZoomParticipantListHead, ZoomParticipantListToolbar } from '../../sections/@dashboard/zoom';
// mock
import ZOOMLIST from '../../_data/zoom/zooms';

// -------------------------COLUMN TITLES--------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Zoom Name', alignRight: false },
  { id: 'user_email', label: 'Email', alignRight: false },
  { id: 'join_time', label: 'Join Time', alignRight: false },
  { id: 'leave_time', label: 'Leave At', alignRight: false },
  { id: 'duration', label: 'Duration', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
]
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (!Array.isArray(array)) {
    return [];
  }
  

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_zoom) => _zoom.meetid.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ZoomPage() {
  
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('meetid');
  const [filterMeetId, setfilterMeetId] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [zoomDatas, setZoomDatas] = useState([ZOOMLIST]);
  const [meetid, setMeetId] = useState('');
  const [meet_uuid, setMeetUuid] = useState('');  
  const [topic, setTopic] = useState('');
  const location = useLocation();

  const reportParticipants = location.state?.reportParticipants;

  useEffect(() => {
    if (reportParticipants) {
      const getZoomDatas = async () => {
        try {    
          setZoomDatas(reportParticipants);
          setMeetId(reportParticipants[0].meetid);
          setMeetUuid(reportParticipants[0].meet_uuid);
          setTopic(reportParticipants[0].topic);
        } catch (error) {
          console.error(error);
          setZoomDatas([]);
        }
      };  
      getZoomDatas();
    }
  }, [reportParticipants]);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = ZOOMLIST.map((n) => n.meetid);
      //console.log("newSelecteds: ", newSelecteds);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, meetid) => {
    const selectedIndex = selected.indexOf(meetid);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, meetid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setfilterMeetId(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ZOOMLIST.length) : 0;

  const filteredZooms = applySortFilter(zoomDatas, getComparator(order, orderBy), filterMeetId);

  const isNotFound = !filteredZooms.length && !!filterMeetId;
  return (
    <>    
      <Helmet>
        <title> Meeting Participants| Oak Academy </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>

        <div>
          <Typography variant="h4" gutterBottom>
            Meeting Participants
          </Typography>
          <TextField 
            id="outlined-basic" 
            variant="standard" // Çerçevesiz görünüm için 'variant' özelliğini 'standard' olarak değiştirdik
            value={topic}
            fullWidth // TextField'ın tam genişlikte olmasını sağlar
          /> 
        </div>
        <TextField id="outlined-basic" variant="outlined" value={meetid}/> 

{/* 
          <Typography variant="h4" gutterBottom>
            Meeting Participants
            <TextField id="outlined-basic" variant="outlined" value={topic} fullWidth/> 
          </Typography>
          <TextField id="outlined-basic" variant="outlined" value={meetid}/>  */}
          {`Total Records: ${zoomDatas.length}`}
{/*            
      
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Meeting
          </Button>           */}
        </Stack>

        <Card>
          <ZoomParticipantListToolbar numSelected={selected.length} filterMeetId={filterMeetId} onfilterMeetId={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ZoomParticipantListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={ZOOMLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {/* {filteredZooms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {    */}
                  { zoomDatas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { user_email, user_id, name, join_time, leave_time, duration, status, meetid, meet_uuid, topic, avatarUrl } = row;                   
                    const selectedZoom = selected.indexOf(user_email) !== -1;
                    const hours = Math.floor(duration / 3600); // Toplam saniyeyi 3600'e bölerek saat değerini bul
                    const minutes = Math.floor((duration % 3600) / 60); // Kalan saniyeyi 60'a bölerek dakika değerini bul
                    const seconds = duration % 60;
                    const uptimeString = `${hours} hours ${minutes} munites ${seconds} seconds`;
                    return (
                      <TableRow hover key={index} tabIndex={-1} role="checkbox" selected={selectedZoom}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedZoom} onChange={(event) => handleClick(event, user_email)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={user_email} src={avatarUrl} />
                            <Typography variant="subtitle2" noWrap>
                              {user_email}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{join_time}</TableCell>
                        <TableCell align="left">{leave_time}</TableCell>
                        <TableCell align="left">{uptimeString}</TableCell>
                        <TableCell align="left">{status}</TableCell>
                        {/* <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell> */}

                        {/* <TableCell align="left">
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell> */}

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterMeetId}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={ZOOMLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          {/* <Iconify icon={'eva:send-fill'} sx={{ mr: 2 }} /> */}
          <Iconify icon={'eva:paper-plane-outline'} sx={{ mr: 2, color: 'blue'}} />
            to Excel
        </MenuItem>       
        {/* Todo: Participand alanında ilgili meetinge katılım yapan kullanıcı(öğrenci) bilgilerinin görüneceği 
        meeting_uuid bilgisi ile veri tabanından sorgu yaparak bir tablo halinde katılımcı sayfası tasarlayacağız. */}
         <MenuItem sx={{ color: 'text.main' }}>
          <Iconify icon={'eva:printer-outline'} sx={{ mr: 2 , color: 'blue'}} />
          Report
        </MenuItem>

        {/*<MenuItem sx={{ color: 'secondary.main' }}>
          <Iconify icon={'eva:more-vertical-fill'} sx={{ mr: 2 }} />   
          Details
        </MenuItem> */}
      </Popover>
    </>
  );
}