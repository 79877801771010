
const setSession = (sessionData, allRoles) => {
    const updatedSessionData = {
        ...sessionData,
        AllRoles: allRoles // `roles` verisini `sessionData` objesine ekliyoruz
      };
    localStorage.setItem('session', JSON.stringify(updatedSessionData));
};
  
export default setSession;
  