import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getReportParticipants= async (meetid, meet_uuid, topic) => {
    try {
        const token = await getToken();
    //  console.log("meetingId: ", meetid, " meet_uuid: ",meet_uuid, " topic: ",topic);
        const response = await axios.get(`${BaseUrl}/zoom/meetings/reports/${meetid}/participants`,{
            headers: {
                'Authorization': `Bearer ${token}`
            },
        }); //TODO: bu degerleri databasedeki view'den cekmeliyiz. Orasi zoom downloeder bitince yazilan bir tablo v_zoom_participant_reports'san almaliyiz.
        const { data } = response;
        const { page_count, page_size, total_records, participants } = data;
        // // Katılımcıları döngüye alıp işleme
        // participants.forEach(participant => {
        //     console.log(participant); 
        //     // Örneğin, katılımcının adına erişmek için participant.name (name, katılımcı objesinin bir özelliği ise)
        // });

       //console.log("response: ", participants);
        const zoomParticipant = participants.map((item, index) => {
            return {
              user_id: item.user_id || '',
              name: item.name || '',
              user_email: item.user_email || '',
              join_time: item.join_time || '',
              leave_time: item.leave_time || '',
              duration: item.duration || '',
              status: item.status || '',
              attentiveness_score: item.attentiveness_score || '',
              failover: item.failover || '',
              customer_key: item.customer_key || '',
              meetid: meetid || '',
              meet_uuid: meet_uuid || '',
              topic: topic || '',
            };
        });
     //   console.log("getReportParticipants-sList: ", zoomParticipant);
        return zoomParticipant;
    } catch (error) {
        console.log("error: ", error);
        return [];
    }
};

export default { getReportParticipants };