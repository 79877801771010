import axios from 'axios';
import config from '../../config';
import updateRoleDefinitions from '../../utils/updateJsonFormat';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const addNewRoleDefinition = async (selectedRoleId, existdata, selectedModuleName, selectedPageName, selectedFieldName, permission) =>{
  try {
    const token = await getToken();
    const data=existdata;
    const update = {selectedModuleName, selectedPageName, selectedFieldName, permission};
    const action = "append"; // TODO: Burada global tanımlı bir değişken kullanmalıyız.
  //  console.log("first ", update);
    const jsonData = updateRoleDefinitions(data, update, action);
  //  console.log("second ", data);
    let newRoleDefinitionData = '{"Modules": ' + JSON.stringify(jsonData) + '}';
    const response = await axios.patch(`${BaseUrl}/roles/updateroledefinition`, 
      {         
        roleid: selectedRoleId,
        definitions: JSON.stringify(newRoleDefinitionData),
      },
      {
        headers: {
            'Authorization': `Bearer ${token}`
        }
      }
    );
     return response.data;
  } catch (error) {  
      console.error("addNewRoleDefinition Error: ", error);
      return [];
  }
};

export default addNewRoleDefinition; 