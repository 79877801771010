import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useConfirmDialog } from '../../utils/contexts/ConfirmDialogContext';

const ConfirmDialog = () => {
  const { dialogProps, closeDialog } = useConfirmDialog();

  const handleCancel = () => {
    dialogProps.onCancel();
    closeDialog();
  };

  const handleConfirm = () => {
    dialogProps.onConfirm();
    closeDialog();
  };

  return (
    <Dialog open={dialogProps.open} onClose={handleCancel}>
      <DialogTitle sx={{color:'navy'}}>{dialogProps.title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{color:'red'}} variant="h6">{dialogProps.messageHeader}</DialogContentText>
        <DialogContentText sx={{color:'black'}}>{dialogProps.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;