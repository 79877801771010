import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const zoomRegistrants = async (meetid) => {
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/zoom/meetings/${meetid}/registrants`,{
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });
     //console.log("response.data.registrants :", response.data.registrants);
    const registrants = response.data.registrants || []; // meetings dizisi boş ise varsayılan olarak boş dizi ataması yapılır.
  //   console.log("Registrant List :", response.data.registrants);    
    const Data = registrants.map((regist) => ({
      id: regist.id || '',
      name_surname: regist.first_name + ' ' +  regist.last_name  || '',
      email: regist.email || '',
      status: regist.status || '',
      create_time: regist.create_time || '',
      join_url: regist.join_url || '',      
    }));
    // console.log("Registrants Datas :", Data);
    return Data;
  } catch (error) {
    console.error(error);    
    return [];
  }
};

export default zoomRegistrants;





/*
{
    "page_size":30,
    "total_records":99,
    "next_page_token":"fxhPtXlV1fbAZuwYtMgG2LWjE7YknVbWlG2",
    "registrants":
    [
        {
            "id":"gqZ8xZ9XTpOqSMrzusOjxw",
            "first_name":"Muhammed Furkan",
            "last_name":"Aydın",
            "email":"furkancy984@gmail.com",
            "address":"",
            "city":"",
            "country":"",
            "zip":"",
            "state":"",
            "phone":"",
            "industry":"",
            "org":"",
            "job_title":"",
            "purchasing_time_frame":"",
            "role_in_purchase_process":"",
            "no_of_employees":"",
            "comments":"",
            "custom_questions":[],
            "status":"approved",
            "create_time":"2023-05-02T16:05:42Z",
            "join_url":"https://us02web.zoom.us/w/85610740209?tk=o04u-6vyv5Wm1HHDmCexXndwW3jk6xlCY6TfysQBP-w.DQMAAAAT7sw58RZncVo4eFo5WFRwT3FTTXJ6dXNPanh3AAAAAAAAAAAAAAAAAAAAAAAAAAAAAA&pwd=bHBQOFZHQ2ZLUDFXanFrOTlFOXhkQT09"
        },
        {
            "id":"BrpZgQ36R1uGJvSde2Dh-g",
            "first_name":"Oxana",
            "last_name":"Kaygana",
            "email":"oxanapanasyuk@mail.ru",
            "address":"",
            "city":"",
            "country":"",
            "zip":"",
            "state":"",
            "phone":"",
            "industry":"",
            "org":"",
            "job_title":"",
            "purchasing_time_frame":"",
            "role_in_purchase_process":"",
            "no_of_employees":"",
            "comments":"",
            "custom_questions":[],
            "status":"approved",
            "create_time":"2023-05-02T16:01:37Z",
            "join_url":"https://us02web.zoom.us/w/85610740209?tk=fLjF2bDw8nyfH3nS7GehklpYL-U229MYF2AYtpmEKso.DQMAAAAT7sw58RZCcnBaZ1EzNlIxdUdKdlNkZTJEaC1nAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA&pwd=bHBQOFZHQ2ZLUDFXanFrOTlFOXhkQT09"
        }
    ]
}
*/
