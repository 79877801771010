const account = {
  displayName: '',
  email: '',
  photoURL: '',
  iduser: 0,
  roles: [],
  status: 0,
  is_active: 0,
  token: '',
};

export default account;
