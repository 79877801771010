import React from 'react';
import { Typography, Box, Stack, Collapse, TableRow, TableCell, Divider, Grid } from '@mui/material';
//import FinancePaymentDetails from '../../components/finance/financialPaymentDetails';

const FinanceContractDetails = ({ isRowOpen, name, surname,  contractdate, payment_contract_details, created_name_surname, contractId }) => { 
  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8} >
        <Collapse in={isRowOpen} timeout="auto" unmountOnExit>
          <Box margin={2} sx={{ backgroundColor: '#f4f6f8', borderRadius: '8px', padding: 2, width: '98%' }}>
            
            {/* Titles */}
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography component="div" sx={{ color: 'blue', fontWeight: 'normal', fontSize: '1.2rem' }}>
                Contract Detail Id: {contractId}
              </Typography>
            </Stack>

            <Divider sx={{ my: 2 }} />

            {/* Fundamental Info */}
            <Box margin={2} sx={{ backgroundColor: 'white', borderRadius: '8px', padding: 2, width: '98%' }}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={2} md={2} display="flex" flexDirection="column" alignItems="center">
                  <Typography sx={{ color: '#4c5270', fontWeight: 'bold' }}>Finance Type</Typography>
                  <Typography sx={{ color: 'red' }}>{payment_contract_details.financetypename}</Typography>
                </Grid>
                <Grid item xs={6} sm={2} md={2} display="flex" flexDirection="column" alignItems="center">
                  <Typography sx={{ color: '#4c5270', fontWeight: 'bold' }}>Course Type</Typography>
                  <Typography sx={{ color: '#f652a0' }}>{payment_contract_details.coursetypename}</Typography>
                </Grid>
                <Grid item xs={6} sm={2} md={2} display="flex" flexDirection="column" alignItems="center">
                  <Typography sx={{ color: '#4c5270', fontWeight: 'bold' }}>Contract Date</Typography>
                  <Typography sx={{ color: '#f652a0' }}>{contractdate}</Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={3} display="flex" flexDirection="column" alignItems="center">
                  <Typography sx={{ color: '#4c5270', fontWeight: 'bold' }}>Payment Frequency</Typography>
                  <Typography sx={{ color: '#f652a0' }}>{payment_contract_details.paymentfrequency}</Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={3} display="flex" flexDirection="column" alignItems="center">
                  <Typography sx={{ color: '#4c5270', fontWeight: 'bold' }}>Registering Employee</Typography>
                  <Typography sx={{ color: '#f652a0' }}>{created_name_surname}</Typography>
                </Grid>
              </Grid>
            </Box>

            <Divider sx={{ my: 2 }} />

            {/* Details */}
            <Box margin={2} sx={{ backgroundColor: 'white', borderRadius: '8px', padding: 2, width: '98%' }}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={4} md={2} display="flex" flexDirection="column" alignItems="center">
                  <Typography sx={{ color: '#4c5270', fontWeight: 'bold' }}>Course Fee Region</Typography>
                  <Typography sx={{ color: '#f652a0' }}>{payment_contract_details.coursefeeregion}</Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={2} display="flex" flexDirection="column" alignItems="center">
                  <Typography sx={{ color: '#4c5270', fontWeight: 'bold' }}>Registration Fee</Typography>
                  <Typography sx={{ color: '#f652a0' }}>{payment_contract_details.currency} {payment_contract_details.registrationfee}</Typography>
                </Grid>              
                <Grid item xs={6} sm={4} md={2} display="flex" flexDirection="column" alignItems="center">
                  <Typography sx={{ color: '#4c5270', fontWeight: 'bold' }}>Total Amount</Typography>
                  <Typography sx={{ color: '#f652a0' }}>{payment_contract_details.currency} {payment_contract_details.totalamount}</Typography>
                </Grid>
                <Grid item xs={6} sm={4} md={3} display="flex" flexDirection="column" alignItems="center">
                  <Typography sx={{ color: '#4c5270', fontWeight: 'bold' }}>Installment Count</Typography>
                  <Typography sx={{ color: '#f652a0' }}>{payment_contract_details.paymentfirstdetails.installmentamount} Months</Typography>
                  {/* <Typography sx={{ color: '#f652a0' }}>{payment_contract_details.paymentfirstdetails.installmentcount} Months</Typography> */}
                </Grid>
                <Grid item xs={6} sm={4} md={3} display="flex" flexDirection="column" alignItems="center">
                  <Typography sx={{ color: '#4c5270', fontWeight: 'bold' }}>Installment Amount</Typography>
                  <Typography sx={{ color: '#f652a0' }}>{payment_contract_details.currency} {payment_contract_details.paymentfirstdetails.installmentcount}</Typography>
                  {/* <Typography sx={{ color: '#f652a0' }}>{payment_contract_details.currency} {payment_contract_details.paymentfirstdetails.installmentamount}</Typography> */}
                  {/* TODO: Bu hatayi duzeltelik ay ve ucret ters geliyor**** */}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default FinanceContractDetails;