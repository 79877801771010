import React, { forwardRef, useImperativeHandle, useState, useRef, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Paper, 
FormControlLabel, Checkbox, Typography, Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import getViewStudent from '../../_data/student/getViewStudent';

const StudentDetailsDialog = forwardRef(({ selectedStudent  }, ref) => {
  
  const [id, setId] = useState(null);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [selectStudentData, setSelectStudentData] = useState();

  //setId(selectedStudent?.id);

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
    handleClose() {
      setOpen(false);
    },
  }));

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  const fetchStudentDatas = async () => {
    const data = await getViewStudent(id);
    //console.log('Student Details data: ', data[0]);
    if (!data) {
      console.error("Result is undefined.");
      // setAlertMessage('An unexpected error occurred while get Batch Names. Please try again.');
      // setSeverity('error');        
      // setOpen(true);
      return;
    }
    setSelectStudentData(data[0]);
  };

  useEffect(() => {
    setId(selectedStudent?.id); //TODO: bunu eklemezsm loop hatasi veriyor. id surekli yenileniyordu.
  }, [selectedStudent]);

  useEffect(() => {
    if(id){
      fetchStudentDatas();
    }
  }, [id]);
  
  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj) || '';
  };

  // const maxChars = 900;
  // const [charCount, setCharCount] = useState(selectStudentData?.description?.length || 0);
  // const handleDescriptionTextChange = (event) => {
  //   const newValue = event.target.value;
  //   if (newValue.length <= maxChars) {
  //     setCharCount(newValue.length);
  //   }
  // };

  //const referencesString = selectStudentData?.allreferences.map(reference => `• ${reference}`).join('\n');
  const referencesString = Array.isArray(selectStudentData?.allreferences?.References)
   ? selectStudentData.allreferences.References.map(reference => `• ${reference}`).join('\n') : '';

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>{t('Student Details')}</DialogTitle>
      <DialogContent>
        <Box>
          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField label={t('Name')} value={selectStudentData?.name || ''}   
                  fullWidth 
                  InputProps={{ readOnly: true, style: { color: 'black' }  }}
                  InputLabelProps={{
                    style: { color: selectStudentData?.name ? 'blue' : 'red'  },
                    shrink: true
                  }} 
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label={t('Surname')} value={selectStudentData?.surname || ''}   
                  fullWidth 
                  InputProps={{ readOnly: true, style: { color: 'black' }  }}
                  InputLabelProps={{
                    style: { color: selectStudentData?.surname ? 'blue' : 'red'  },
                    shrink: true
                  }} 
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label={t('Email')} value={selectStudentData?.email || ''}   
                  fullWidth 
                  InputProps={{ readOnly: true, style: { color: 'black' }  }}
                  InputLabelProps={{
                    style: { color: selectStudentData?.email ? 'blue' : 'red'  },
                    shrink: true
                  }} 
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label={t('Date of Birth')} value={selectStudentData?.dateofbirth?.split('T')[0] || ''}   
                  fullWidth 
                  InputProps={{ readOnly: true, style: { color: 'black' }  }}
                  InputLabelProps={{
                    style: { color: selectStudentData?.dateofbirth ? 'blue' : 'red'  },
                    shrink: true
                  }} 
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box sx={{ border: 1, borderColor: 'grey.400', borderRadius: 1, p: 2 }}>
                  <Typography 
                    variant="subtitle2" 
                    sx={{ 
                      mb: 1, 
                      // color: selectStudentData?.allreferences?.length ? 'blue' : 'red',
                      color: selectStudentData?.allreferences && selectStudentData.allreferences?.References?.length > 0 ? 'blue' : 'red', 
                      fontWeight: 'normal',
                      fontSize: '0.875rem'  // Adjust this value as needed to match the other titles
                    }}
                  >        
                    How did she/he contact us?
                  </Typography>
                    {selectStudentData?.allreferences?.References.map((reference, index) => (
                      <Typography key={index} sx={{ display: 'flex', alignItems: 'center', lineHeight: 1.1 }}>
                        <Box component="span" sx={{ color: 'blue', fontSize: '1.8rem', mr: 1 }}>•</Box> 
                          {reference}
                      </Typography>
                    ))}
                </Box>
              </Grid>              
              <Grid item xs={12} sm={6}>
                <TextField label={t('statusname')} value={selectStudentData?.status_name || ''}   
                  fullWidth 
                  InputProps={{ readOnly: true, style: { color: 'black' }  }}
                  InputLabelProps={{
                    style: { color: selectStudentData?.status_name ? 'blue' : 'red'  },
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label={t('Batch Name')} value={selectStudentData?.batchname || ''}   
                  fullWidth 
                  InputProps={{ readOnly: true, style: { color: 'black' }  }}
                  InputLabelProps={{
                    style: { color: selectStudentData?.batchname ? 'blue' : 'red'  },
                    shrink: true
                  }} 
                />
              </Grid>              
              <Grid item xs={12} sm={6}>
                <TextField label={t('Language Level')} value={ `${selectStudentData?.langname  || ''} - ${selectStudentData?.langlevelname  || ''}`}   
                  fullWidth 
                  InputProps={{ readOnly: true, style: { color: 'black' }  }}
                  InputLabelProps={{
                    style: { color: selectStudentData?.langname ? 'blue' : 'red'  },
                    shrink: true
                  }} 
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label={t('Occupation')} value={selectStudentData?.occupationname || ''}   
                  fullWidth 
                  InputProps={{ readOnly: true, style: { color: 'black' }  }}
                  InputLabelProps={{
                    style: { color: selectStudentData?.occupationname ? 'blue' : 'red'  },
                    shrink: true
                  }} 
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label={t('Graduation')} value={selectStudentData?.edulevelname || ''}  
                  fullWidth 
                  InputProps={{ readOnly: true, style: { color: 'black' }  }}
                  InputLabelProps={{
                    style: { color: selectStudentData?.edulevelname ? 'blue' : 'red'  },
                    shrink: true
                  }} 
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField label={t('FinancialCode')} 
                //value={selectStudentData?.resourcecode == 'P' ? 'Private' : 'Job Center' || ''}
                value={selectStudentData?.resourcecode === 'P' ? 'Private' : selectStudentData?.resourcecode === 'J' ? 'Job Center' : ''} 
                  fullWidth 
                  InputProps={{ readOnly: true, style: { color: 'black' }  }}
                  InputLabelProps={{
                    style: { color: selectStudentData?.resourcecode ? 'blue' : 'red'  },
                    shrink: true
                  }} 
                />
              </Grid>
              <Grid item xs={12}>
                <TextField label={t('Intern Info')} value={selectStudentData?.interninfo || ''} 
                  fullWidth 
                  InputProps={{ readOnly: true, style: { color: 'black' }  }}
                  InputLabelProps={{
                    style: { color: selectStudentData?.interninfo ? 'blue' : 'red'  },
                    shrink: true
                  }} 
                />
              </Grid>
              <Grid item xs={12}>
                <TextField label={t('Description')} value={selectStudentData?.description || ''}
                  fullWidth
                  multiline
                  maxRows={10}
                  InputProps={{ readOnly: true, style: { color: 'black' }  }}
                  InputLabelProps={{
                    style: { color: selectStudentData?.description ? 'blue' : 'red'  },
                    shrink: true
                  }} 
                  //onChange={handleDescriptionTextChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField label={t('Created At')} value={selectStudentData?.atcreated.split("T")[0] + " " + selectStudentData?.atcreated.split("T")[1].split("Z")[0] || ''} 
                  fullWidth 
                  InputProps={{ readOnly: true, style: { color: 'black' }  }}
                  InputLabelProps={{
                    style: { color: selectStudentData?.atcreated ? 'blue' : 'red'  },
                    shrink: true
                  }} 
                />               
              </Grid>
              <Grid item xs={12}>
                <TextField label={t('Created By')} value={selectStudentData?.follower_namesurname || ''} 
                  fullWidth 
                  InputProps={{ readOnly: true, style: { color: 'black' }  }}
                  InputLabelProps={{
                    style: { color: selectStudentData?.follower_namesurname ? 'blue' : 'red'  },
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
          </Paper>        
          <Accordion variant="outlined" expanded={expanded === 'contact'} onChange={handleAccordionChange('contact')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="contact-content" id="contact-header">
              <Typography variant="h6">{t('Contact')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField label={t('City')} value={ selectStudentData?.contact.city || ''} 
                    fullWidth 
                    InputProps={{ readOnly: true, style: { color: 'black' }  }}
                    InputLabelProps={{
                      style: { color: selectStudentData?.contact.city ? 'blue' : 'red'  },
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label={t('Country')} 
                    value={selectStudentData?.contact.country || ''} 
                    fullWidth 
                    InputProps={{ readOnly: true, style: { color: 'black' }  }}
                    InputLabelProps={{
                      style: { color: selectStudentData?.contact.country ? 'blue' : 'red'  },
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField label={t('Address')} value={selectStudentData?.contact.address || ''} 
                    fullWidth 
                    InputProps={{ readOnly: true, style: { color: 'black' }  }}
                    InputLabelProps={{
                      style: { color: selectStudentData?.contact.address ? 'blue' : 'red'  },
                      shrink: true
                    }} 
                  />
                </Grid>                               
                <Grid item xs={12} sm={6}>
                  <TextField label={t('Email Address')} value={selectStudentData?.contact.mail.mailaddress1 || ''} 
                    fullWidth 
                    InputProps={{ readOnly: true, style: { color: 'black' }  }}
                    InputLabelProps={{
                      style: { color: selectStudentData?.contact.mail.mailaddress1 ? 'blue' : 'red'  },
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label={t('Email Address-2')} value={selectStudentData?.contact.mail.mailaddress2 || ''} 
                    fullWidth 
                    InputProps={{ readOnly: true, style: { color: 'black' }  }}
                    InputLabelProps={{
                      style: { color: selectStudentData?.contact.mail.mailaddress2 ? 'blue' : 'red'  },
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label={t('Phone')} value={selectStudentData?.contact.phone.cellphone1 || ''} 
                    fullWidth 
                    InputProps={{ readOnly: true, style: { color: 'black' }  }}
                    InputLabelProps={{
                      style: { color: selectStudentData?.contact.phone.cellphone1 ? 'blue' : 'red'  },
                      shrink: true
                    }} 
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label={t('Whatsapp')} value={selectStudentData?.contact.phone.whatsapp || ''} 
                    fullWidth 
                    InputProps={{ readOnly: true, style: { color: 'black' }  }}
                    InputLabelProps={{
                      style: { color: selectStudentData?.contact.phone.whatsapp ? 'blue' : 'red'  },
                      shrink: true
                    }} 
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion sx = {{mt: 2}} variant="outlined" expanded={expanded === 'memberships'} onChange={handleAccordionChange('memberships')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="memberships-content" id="memberships-header">
              <Typography variant="h6">{t('Memberships')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                        <Checkbox 
                            checked={selectStudentData?.memberships?.Quizizz || false} 
                            name="Quizizz"
                        />
                    }
                    label={t('Quizizz')}
                    sx={{ color: selectStudentData?.memberships?.Quizizz ? 'blue' : 'red' }}
                  />                  
                </Grid>
                 <Grid item xs={12}>                  
                  <FormControlLabel
                    control={
                        <Checkbox 
                            checked={selectStudentData?.memberships?.TryHackMe || false} 
                            name="TryHackMe"
                        />
                    }
                    label={t('TryHackMe')}
                    sx={{ color: selectStudentData?.memberships?.TryHackMe ? 'blue' : 'red' }}
                  />                  
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                        <Checkbox 
                            checked={selectStudentData?.memberships?.LMS || false} 
                            name="LMS"
                            sx={{ color: 'red' }} // Checkbox rengini ayarlar
                        />
                    }
                    label={t('Digital Campus (LMS)')}
                    sx={{ color: selectStudentData?.memberships?.LMS ? 'blue' : 'red' }} // Etiket rengini ayarlar
                  /> 
                </Grid>
                <Grid item xs={12}>
                  <TextField label={t('Discord Username')} value={selectStudentData?.memberships?.DiscordUserName} fullWidth 
                    InputProps={{ readOnly: true, style: { color: 'black' }  }} // Okunabilir durumdaki metnin rengini belirleyin
                    InputLabelProps={{
                      style: { color: selectStudentData?.memberships?.DiscordUserName ? 'blue' : 'red'  }, // Etiketin rengini belirleyin
                      shrink: true, // Etiketin her zaman yukarıda kalmasını sağlar
                    }} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default StudentDetailsDialog;
