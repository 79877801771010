import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getViewStudent = async (studentid) => {
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/students/getviewstudent`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      params: {
        studentid: studentid
      }
    });
    return response.data;
  } catch (error) {  
      console.error("Report error: ", error);
      return [];
  }
};

export default getViewStudent;