import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
// import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar'
// sections
import { StudentListHead, StudentListToolbar } from '../../sections/@dashboard/student';
// google drive
import { getStudent } from '../../_data/student/getStudent'; //'../../_data/student/getStudent';
//import { ClassNames } from '@emotion/react';

// -------------------------COLUMN TITLES--------------------------------

const TABLE_HEAD = [
  { id: 'coursename', label: 'CourseName', alignRight: false },
  { id: 'namesurname', label: 'Name Surname', alignRight: false },
  // { id: 'surname', label: 'Surname', alignRight: false },
  //{ id: 'contact', label: 'Contact', alignRight: false },
  { id: 'memberships', label: 'Memberships', alignRight: false },
   { id: 'source', label: 'Source', alignRight: false },
  // { id: 'info', label: 'Info', alignRight: false },
  // { id: 'isVerified', label: 'Verified', alignRight: false },
  // { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];
//idstudents,coursename, name, surname, contact, memberships, source, info
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (!Array.isArray(array)) {
    return [];
  }
  

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_student) => _student.id.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('id');

  const [filterMeetId, setfilterMeetId] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [studentDatas, setStudentDatas] = useState([getStudent]);
  
  const [recordsNumber, setrecordsNumber] = useState([0]);

  useEffect(() => {
    const getstudentDatas = async () => {
      try {
          const data = await getStudent();
        //  console.log("data:", data );
          setrecordsNumber(data.length);
        //  console.log("data.length: ",data.length);
          
          
          setStudentDatas(data);        
      } catch (error) {
          console.error(error);
      setStudentDatas([]);
      }
    };
    getstudentDatas();
  }, []);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = studentDatas.map((n) => n.id);
    //  console.log("newSelecteds: ", newSelecteds);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setfilterMeetId(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - studentDatas.length) : 0;

  const filteredStudents = applySortFilter(studentDatas, getComparator(order, orderBy), filterMeetId);

  const isNotFound = !filteredStudents.length && !!filterMeetId;
  return (
    <>    
      <Helmet>
        <title> Oak Students| Oak Academy </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Oak Students List (Record Of Number: {recordsNumber})
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Folder
          </Button>
        </Stack>

        <Card>
          <StudentListToolbar numSelected={selected.length} filterMeetId={filterMeetId} onfilterMeetId={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <StudentListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={recordsNumber}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {/* {filteredStudents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {    */}                                
                  {
                  studentDatas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {   
                  //                
                    const { id, coursename, name, surname, source, avatarUrl, /*contact, */  /*info, status,  isVerified */} = row; 
                    //console.log(coursename)
                    //const { Quizizz, TryHackMe, DigitalCampus, DiscordUserName } = memberships;
                    // const MemberShips  = JSON.parse(memberships);
                    // console.log("MemberShips: ",MemberShips);

                    // const MemberShips = {
                    //   `<p> {Quizizz} </p>`
                      
                    //   {} 
                    //   {TryHackMe} 
                    //   {DigitalCampus} 
                    //   {DiscordUserName}
                    // } 
                    // const member = memberships.map = ((items) =>{
                    //   const { Quizizz, TryHackMe, DigitalCampus, DiscordUserName } = items; 
                    //   console.log("New Memberships: ", items);
                    //   return(
                    //     console.log("Quizizz: ", Quizizz)
                    //   )
                    // })
                    
                    const selectedStudent = selected.indexOf(id) !== -1;                    
                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedStudent}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedStudent} onChange={(event) => handleClick(event, id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                             <Avatar alt={id} src={avatarUrl} /> 
                            <Typography variant="subtitle2" noWrap>
                              {coursename}
                            </Typography>
                          </Stack>
                        </TableCell>                               
                        <TableCell align="left">{name} {''} {surname}</TableCell>
                        <TableCell align="left">{coursename}</TableCell>
                        {/* <TableCell align="left">{contact}</TableCell> */}
                        {/* {
                          memberships.map(member => {const { DigitalCampus, DiscordUserName, Quizizz, TryHackMe} = memberships;  
                            console.log("membership: ", memberships);                                                
                          <TableCell align="left"> 
                            <TableCell align="left">{DigitalCampus}</TableCell> 
                            <TableCell align="left">{DiscordUserName}</TableCell> 
                            <TableCell align="left">{Quizizz}</TableCell> 
                            <TableCell align="left">{TryHackMe}</TableCell> 
                          </TableCell>
                          })
                        } */}                                      
                        {/* <TableCell align="left">{  
                        
                          memberships.map((item) => {
                            const { DigitalCampus, DiscordUserName, Quizizz, TryHackMe} = item;  
                            console.log("membership: ", item);  
                            return (
                              <Typography>
                                {DigitalCampus}
                                <br />
                                {DiscordUserName}
                              </Typography>
                            );                                                                                                    
                          })
                        }</TableCell> */}

                        {/* <TableCell align="left">{'Discord: '}{memberships.Quizizz}</TableCell> */}
                        <TableCell align="left">{source}</TableCell>
                        {/* <TableCell align="left">{info}</TableCell>
                        <TableCell align="left">{status}</TableCell> */}
                        {/* <TableCell align="left">{verified ? 'Yes' : 'No'}</TableCell> */}

                        {/* <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell> */}

                        {/* <TableCell align="left">
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell> */}

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterMeetId}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, { value: -1, label: 'All' }]}
            component="div"
            count={recordsNumber}            
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>

        <MenuItem sx={{ color: 'secondary.main' }}>
          <Iconify icon={'eva:more-vertical-fill'} sx={{ mr: 2 }} />   
          {/* more-vertical-fill        eva:plus-fill*/}
          Details
        </MenuItem>
      </Popover>
    </>
  );
}