import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getInterviewTransaction= async (studentid) => {
  try {
    const token = await getToken();
    //console.log("[getInterviewTransaction] studentid: ", studentid)
    const response = await axios.get(`${BaseUrl}/students/getinterviewtransaction`, {    
      headers: {
        'Authorization': `Bearer ${token}`
      },     
      params: {
        idstudent: studentid,
      },
    });
    return response.data;
  } catch (error) {  
      console.error("Report error: ", error);
      return [];
  }
};

export default getInterviewTransaction;