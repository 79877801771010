import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const setVoucherFile = async (id, file) => {
  try {
    const token = await getToken();
    // FormData kullanarak dosyayı ve diğer parametreleri ekleyelim
    const formData = new FormData();
    formData.append('vouchersubmission', file);
    formData.append('studentid', id);

    const response = await axios.post(`${BaseUrl}/student/onboard/initialization/setvouchersubmission`, formData, {    
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error("Report error: ", error);
    return [];
  }
};

export default setVoucherFile;