import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../utils/contexts/AlertContext'; // useAlert hook'unun doğru yolu

const ProtectedPage = ({ children, canAccess, redirectTo }) => {
  const navigate = useNavigate();
  const { showAlert } = useAlert(); // useAlert hook'unu kullan
  useEffect(() => {
    let timer;
    if (!canAccess) {
      showAlert('error'); // alertType olarak 'error' kullan
      timer = setTimeout(() => {
        navigate(redirectTo);
      }, 100); // 5 saniye sonra yönlendir
    }
    return () => clearTimeout(timer); // Cleanup fonksiyonu
  }, [canAccess, navigate, redirectTo, showAlert]);

  if (!canAccess) {
    // Erişim izni yoksa, yalnızca null döndür (Snackbar mesajı DashboardLayout'ta gösterilecek)
    return null;
  }

  // Erişim izni varsa, children'ı render et
  return children;
};
export default ProtectedPage;
