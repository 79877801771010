import React, { useState, useRef, useEffect } from 'react';
import { useLoading } from '../../utils/contexts/LoadingContext';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, 
InputLabel, FormControl, Alert, Snackbar, FormControlLabel, RadioGroup, Radio, Box,Typography,
FormGroup, Checkbox,Accordion, AccordionSummary, AccordionDetails, Paper, Grid, FormLabel, 
Autocomplete, DialogContentText, TableContainer, Table, TableRow, Tooltip  } from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Divider from '@mui/material/Divider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { fDate, fDateX } from '../../utils/formatTime';

import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; 
import Iconify from '../iconify';
import InputAdornment from '@mui/material/InputAdornment';
import creatstudent from '../../_data/student/createstudent';
import { useTranslation } from 'react-i18next'; //Multi Language
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneInput from '../student/PhoneInput';
import getSession from '../../utils/getSession';
import style from '../../theme/blink.css';
import { getContactChannels } from '../../_data/contactchannels/getContactChannels';
import { getAssessmentLevels } from '../../_data/assesmentlevels/getAssessmentLevels';
import createFirstInterviewTask from '../../_data/student/createFirstInterviewTask';
import convertDateTime from '../../utils/convertDateTime';
import getAllCourseCoordinators from '../../_data/user/getAllCourseCoordination';
import setStudentFinancialDatas from '../../_data/finance/setStudentFinancialDatas';
import { getPaymentMethods } from '../../_data/finance/getPaymentMethods';

// utils
import formatDateTime from '../../utils/dateTimeFormatter';

export default function AssignStudentDialog({ onTransactionAdded, studentNameSurname, studentId, email, paymentDetails, contractId, openDialog, onClose }) {
console.log("contractId: ----> ", contractId);
  const [registrationfee, setregistrationfee] = useState(paymentDetails?.registrationfee ? paymentDetails.registrationfee : 0);  
  const { showLoading, hideLoading } = useLoading();
  const account = getSession();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState(convertDateTime(formatDateTime()));
  const [howmanycall, setHowManyCall] = useState(null);
  // const [nextCall, setNextCall] = useState(dayjs()); //Today
  const [allcoordinators, setAllCoordinators] = useState([]);
  const [coordinatorName, setCoordinatorName] = useState([]);
  const autocompleteCoordinatorRef = useRef(null);
  const [showNewTask, setShowNewTask] = useState(false);
  const [sendEmailConfirm, setSendEmailConfirm] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openSelectedTaskConfirmation, setOpenSelectedTaskConfirmation] = useState(false);
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [selectedPaymentDate, setSelectedPaymentDate] = useState(null);
  const [selectedPaymentOverdueDate, setselectedPaymentOverdueDate] = useState(null);
  const [paymentDescription, setPaymentDescription] = useState("");
  const [paymentDetailDescription, setPaymentDetailDescription] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState(['']);
  const [selectedPaymentMethodName, setSelectedPaymentMethodName] = useState(['']);
  const [transactionInfo, setTransactionInfo] = useState('');
  const [cardLastFour, setTCardLastFour] = useState('');

  let formattedDate = new Date();

  let year = formattedDate.getFullYear();
  let month = String(formattedDate.getMonth() + 1).padStart(2, "0");
  let day = String(formattedDate.getDate()).padStart(2, "0");
  let today = `${year}-${month}-${day}`;
  const convertDateFormat = (dateStr) => {
    if (!dateStr) {
      return 'Invalid Date';
    }
    // DD.MM.YYYY HH:MM:SS formatına uygun regex
    const parts = dateStr.match(/(\d{2})\.(\d{2})\.(\d{4}) (\d{2}):(\d{2}):(\d{2})/);
    if (!parts || parts.length < 7) {
        return 'Invalid Date';
    }
    const day = parts[1];
    const month = parts[2];
    const year = parts[3];
    const hours = parts[4];
    const minutes = parts[5];
    const seconds = parts[6];
    // YYYY-MM-DD HH:MM:SS formatında string oluştur
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

 

  const handleDateChange = (newValue) => {
    //console.log('newValue: ', newValue);
    setSelectedPaymentDate(newValue);
  };

  const handleDueDateChange = (newValue) => {
    //console.log('newValue: ', newValue);
    setselectedPaymentOverdueDate(newValue);
  };

  // const createFinancialDatas = async () =>{
  //   const result = await setStudentFinancialDatas(studentId, paymentDetails);
  // } 

  // const convertDateFormat = (dateStr) => {
  //   const parts = dateStr.match(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}:\d{2}:\d{2})/);
  //   return `${parts[3]}-${parts[1]}-${parts[2]} ${parts[4]}`;
  // }

  const [formData, setFormData] = useState({
    studentid: studentId,
    contactdatetime: convertDateFormat(currentTime),   
    descriptionabout: '',    
    contactchannel: -1,
    contactstate: -1,
    assessmentlevel: -1,
    howmanycall: 0,  //For First Record, this field fixed 0(Zero) bacause first record is first task.
    //nextcalldatetime: '',
    transactionby: account.user[0].iduser,
    relatedtaskid: 0, //For First Record, this field fixed 0(Zero)
    toMail: email, 
    sendmail: sendEmailConfirm
  }); 

  

  // const handleMembershipCheckboxChange = (event) => {
  //   setFormData({
  //     ...formData,
  //     memberships: {
  //       ...formData.memberships,
  //       [event.target.name]: event.target.checked
  //     }
  //   });
  // };
 

  

  useEffect(() => {
    loadPaymentMethods();
    clearForm();
    const timer = setInterval(() => {
     setCurrentTime(formatDateTime());      
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const contactDate = convertDateFormat(currentTime);
    setFormData({ ...formData, contactdatetime: contactDate});    
  }, [currentTime]);

  const clearForm = () => {    
    setFormErrors({});
  };

  const loadPaymentMethods = async () => {
    try {
       const response = await getPaymentMethods(); //coming all Payment Methods
       setPaymentMethods(response);
    } catch (error) {
        console.error('Error loading payment methods:', error);
    }
  };

  const handleChangePaymentMethods = (event) => {
    // setSelectedPaymentMethodName(value);
    // const { name, value } = event.target;
    // setSelectedPaymentMethods(value);
    const selectedMethodId = event.target.value;
    const selectedMethod = paymentMethods.find((method) => method.idpayment_methods === selectedMethodId);
    setSelectedPaymentMethodName(selectedMethod ? selectedMethod.methodName : '');
    setSelectedPaymentMethods(selectedMethodId);
  };

  const handleTransactionInfo = (event) => {
    const value = event.target.value;
    setTransactionInfo(value);
  };

  const [accountNumber, setAccountNumber] = useState('');
  const [providerEmail, setProviderEmail] = useState('');
  const [recevignAccountNumber, setRecevignAccountNumber] = useState('');

  const handleAccountNumber = (event) => {
    const value = event.target.value;
    setAccountNumber(value);
  };

  const handleProviderEmail = (event) => {
    const value = event.target.value;
    setProviderEmail(value);
  };

  const handleReceivingAccountNumber = (event) => {
    const value = event.target.value;
    setRecevignAccountNumber(value);
  };

  // const fetchFollower = async () => {
  //   // const data = await getAllCourseCoordinators();
  //   // setCourseCoordinators(data);
  // };

  // const fetchContactChannel = async () => {
  //   const data = await getContactChannels();
  //   setContactChannel(data);
  // };

  // const fetchAssessmentLevel = async () => {
  //   const data = await getAssessmentLevels();
  //   setAssessmentLevel(data);
  // };

  // dayjs.extend(utc);
  // const handleNextCallChange = (newValue) => {
  //   if (dayjs.isDayjs(newValue)) {
  //     let utcDate = newValue.utc().format();
  //     let mysqlDatetime = dayjs(utcDate).utc().format('YYYY-MM-DD HH:mm:ss');
  //     setNextCall(newValue);
  //     setFormData({ ...formData, nextcalldatetime: mysqlDatetime});
  //   } else {
  //       console.log('newValue is not a Dayjs object:', newValue);
  //   }
  // };

  // const handleNextCallChange = (newValue) => {
  //  // console.log('Selected Action Date: ', newValue);
  //   if (dayjs.isDayjs(newValue)) {
  //     let localDatetime = newValue.format('YYYY-MM-DD HH:mm:ss');
  //   //  console.log('Selected Action Formatted Date: ', localDatetime);
  //     setNextCall(newValue);
  //     setFormData({ ...formData, nextcalldatetime: localDatetime });
  //   } else {
  //   //  console.log('newValue is not a Dayjs object:', newValue);
  //   }
  // };

  // const copyToClipboard = async (text) => {
  //   try {
  //     if (navigator.clipboard && window.isSecureContext) {
  //       await navigator.clipboard.writeText(text);
  //       showMessage('Create e-Mail User & Password copied.');
  //     } else {
  //       // Eski tarayıcılar için alternatif yöntem
  //       let textArea = document.createElement("textarea");
  //       textArea.value = text;
  //       document.body.appendChild(textArea);
  //       textArea.focus();
  //       textArea.select();
  //       document.execCommand('copy');
  //       document.body.removeChild(textArea);
  //       showMessage('Create e-Mail User & Password copied.');
  //     }
  //   } catch (err) {
  //     console.error('Could not copy password to clipboard!', err);
  //   }
  // };

  // const loadCoordinators = async () => {
  //   try {
  //      const response = await getAllCourseCoordinators();
  //      setAllCoordinators(response);
  //   } catch (error) {
  //       console.error('Error loading loadCoordinators:', error);
  //   }
  // };

  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success'); // or 'error'
  const [alertMessages, setAlertMessages] = useState([]); // Burada alertMessage yerine alertMessages kullanıyorum ve başlangıç değeri olarak boş dizi veriyorum
  const showMessage = (message, severityType = 'warning', duration = 3000) => {
    const id = new Date().getTime();  // Unique ID olarak zaman damgasını kullanabiliriz.
    const newMessage = { id, message, severityType };
    setAlertMessages(prevMessages => [...prevMessages, newMessage]);
    setSeverity(severityType);
    setMsgOpen(true);
    setTimeout(() => {
      setAlertMessages(prevMessages => prevMessages.filter(msg => msg.id !== id));
    }, duration);
  };

  const handleCloseStudentMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  // const handleContactChannels = (event) => {
  //   const { name, value } = event.target;
  //   setFormData({ ...formData, contactchannel: value });
  // };
  
  // const handleChangeHowManyCall = (event) => {
  //   const { name, value } = event.target;
  //   setFormData({ ...formData, howmanycall: value});
  // };

  // const handleChangeFollower = (event) => {
  //   const { name, value } = event.target;
  //   setFormData({ ...formData, follower: value });
  // };

  // const handleChange = (event) => {
  //   try {
  //     const { name, value } = event.target;
  //     if(name === 'contact.mail.mailaddress1'){
  //       setFormData({ ...formData, email: value });
  //     }
  //     const nameParts = name.split('.'); // Nokta ile ayrılmış yolu parçalara ayır
  //     setFormData((prevFormData) => {
  //       let updatedValue = { ...prevFormData }; // İlk kopyayı oluştur
  //       nameParts.reduce((acc, key, index) => {
  //         if (index === nameParts.length - 1) {
  //           // Eğer son parçadaysak değeri güncelle
  //           acc[key] = value;
  //         } else {
  //           // Eğer son parça değilse, bir sonraki parçaya geç
  //           acc[key] = acc[key] || {}; // Eğer bir sonraki anahtar yoksa, yeni bir nesne oluştur
  //         }
  //         return acc[key];
  //       }, updatedValue);
  //       return updatedValue; 
  //     });
  //   } catch (error) {
  //     console.log("Error: ", error);
  //   }
  // };

  const maxChars = 900;
  const [charCount, setCharCount] = useState(0);
  const [charDetailCount, setCharDetailCount] = useState(0);

  const handleChangeDescription = (event) => {
    setPaymentDescription(event.target.value);
    setCharCount(event.target.value.length); // Karakter sayısını günceller
  };

  const handleChangeDetailDescription = (event) => {
    setPaymentDetailDescription(event.target.value);
    setCharDetailCount(event.target.value.length); // Karakter sayısını günceller
  };

  // const handleChangeAssessmentLevel = (event) => {
  //   setFormData({ ...formData, assessmentlevel: event.target.value });
  // };

  // const handleIncrement = () => {
  //   setHowManyCall(prevCount => prevCount + 1);
  // };

  // const handleDecrement = () => {
  //   setHowManyCall(prevCount => prevCount - 1);
  // };

  //const contactstateRef = useRef(null);
  const paymentmethodsRef = useRef(null);
  const paymentoverduedateRef = useRef(null);
  const paymentdateRef = useRef(null);
  const [formErrors, setFormErrors] = useState({});
  const [firstErrorField, setFirstErrorField] = useState(null);

  const validateForm = () => {
    let errors = {};
    let firstError = null;    
    if(showNewTask){
      // TODO: burada ayrica register fee kontrolu saglanabilir
      
      if (!selectedPaymentDate) {
        errors.selectedPaymentDate = true;
        firstError = firstError || 'selectedPaymentDate';
      }
      else{
        const datetime = dayjs(selectedPaymentDate);
        if (datetime.isAfter(dayjs(), 'day')) {
          errors.selectedPaymentDate = true;
          firstError = firstError || 'selectedPaymentDate';
          showMessage(`The selected date (${firstError}) field should not be after today!`, 'warning', 3000);
        }
      }      
    }
    if (!selectedPaymentMethods) {
      errors.paymentmethods = true;
      firstError = firstError || 'paymentmethods';
    }

    if (!selectedPaymentOverdueDate) {
      errors.follower = true;
      firstError = firstError || 'selectedPaymentOverdueDate';
    }
    setFormErrors(errors);
    setFirstErrorField(firstError);

    if (firstError) {
      const refs = {
        // contactstate: contactstateRef,
        contactchannel: paymentmethodsRef,
        selectedPaymentOverdueDate: paymentoverduedateRef,
        selectedPaymentDate: paymentdateRef
      };
      refs[firstError].current?.focus();
      showMessage(`Fill in (${firstError}) the required fields!`, 'warning');
    }

    return Object.keys(errors).length === 0;
};

// const updatedFormData = {
// ...formData,
// };

let resolveConfirmation;

// const showNewTaskConfirmation = () => {
//  // console.log('showNewTaskConfigmation');
//   return new Promise((resolve) => {
//     resolveConfirmation = resolve;
//   //  console.log('resolveConfirmation: ', resolveConfirmation);
//     setOpenConfirmation(true);
//   });

// };
  
// const replaceDateTime = (dateString) => {
//   console.log("dateString: ", dateString);
//   if (!dateString || typeof dateString !== 'string') {
//     return '';
//   };
//   // Cut T ve Z char
//   const formattedDate = dateString.replace('T', ' ').replace('Z', '').slice(0, 16);
//   console.log("formattedDate: ", formattedDate);
//   return formattedDate;
// };

const handleSubmit = async () => {
    const isValid = validateForm();
    if (!isValid) return;    
    try {
      showLoading();
      const formatedDate = dayjs(selectedPaymentDate).format('YYYY-MM-DD');
      const formatedOverdueDate = dayjs(selectedPaymentOverdueDate).format('YYYY-MM-DD');
      const result = await setStudentFinancialDatas(studentId, paymentDetails, formatedDate, formatedOverdueDate, paymentDescription, contractId,
        selectedPaymentMethodName, transactionInfo, accountNumber, providerEmail, paymentDetailDescription, recevignAccountNumber );
      console.log('result.message: ', result.message);
      if (result.affectedRows === 1 && result.message === "Finance installment record process is successful.") {
        showMessage(t('NewFinanceTracingCreateSuccess'), 'success');
        onTransactionAdded(result.message);        
      }else if (result.affectedRows === 1 && result.message === "Finance first record ok but installment process can not create successfully. Because 'Installment Amount' is empty!"){
        showMessage(result.message, 'warning');
        onTransactionAdded(result.message);
      } 
      else {
        showMessage(result.message, 'error');
      }
    } catch (error) {
        console.error('Error during createFirstInterviewTransaction:', error); 
        showMessage('An error occurred while creating the transaction.', 'error');
    }finally{
      hideLoading();
      handleClose();
    }
  };
  
  // const handleSendMailConfirmation = () => {
  //   if (resolveConfirmation) {
  //       resolveConfirmation(true);
  //   }
  //   sendEmail(email);
  //   setOpenConfirmation(false);
  //   clearForm();
  // };
  
  const handleCloseConfirmation = () => {
  //  console.log('Close butonuna bastim.')
  //  console.log('resolveConfirmation: ', resolveConfirmation);
    if (resolveConfirmation) resolveConfirmation(false);
    setIsSendEmail(false);
    setOpenConfirmation(false);
    clearForm();
  };

  const sendEmail = (email) => {
    setIsSendEmail(true);
  //  console.log(`Email sent to ${email} with the next call date and time: ${nextCall}`);
  };
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj) || '';
  };

  const handleCloseSelectedTask = () => {
    setOpenSelectedTaskConfirmation(false);
    clearForm();
  };

  const handleRelatedSelectedTask = () => {
    const idtransaction = 1;
    relatedTask(idtransaction);
    setOpenSelectedTaskConfirmation(false);
    clearForm();
  };

  const relatedTask = (idtransaction) => {
  //  console.log('idtransaction: ', idtransaction);
  };

  // const [contentEmailError, setContentEmailError] = useState(false);
  // const [emailHelperText, setEmailHelperText] = useState('');
  // const [contentFullnameError, setContentFullnameError] = useState(false); 
  // const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  // const handleBlur = (e) => {
  //   const isValid = emailRegex.test(e.target.value);
  //   if (!isValid) {
  //     // Set error message if not valid.
  //     setEmailHelperText("Please enter a valid email address.");
  //     setContentEmailError(true);
  //   } else {
  //     setEmailHelperText("");
  //     setContentEmailError(false);
  //   }
  // };

  // const handleRadioChange1 = (event) => {
  //   setFormData({ ...formData, radioGroup1: event.target.value });
  // };

  // const handleRadioChange2 = (event) => {
  //   setFormData({ ...formData, radioGroup2: event.target.value });
  // };

  // const handleCoordinatorName = (newValue) => {
  //   if (!newValue) {
  //     setCoordinatorName('');
  //     return;
  //   }
  //   //console.log('setCoordinatorName(newValue.id); :', newValue.id);
  //   setCoordinatorName(newValue.id);
  // };

  // const handleCheckboxChange = (event) => {
  //   setShowNewTask(event.target.checked);
  // };

  const handleCheckboxChange = (event) => {
    setShowNewTask(event.target.checked);
    if(event.target.checked === false){
      setSendEmailConfirm(false);
      //setNextCall(null);
      setFormData({ ...formData, 
        newaction: ''
       });

    }
  };

  const handleSendMailCheckboxChange = (event) => {
   // console.log('sendEmailConfirm: ', event.target.checked);           
    setSendEmailConfirm(event.target.checked);    
  };

  // useEffect (() => {    
  //   setFormData({ ...formData, sendmail: sendEmailConfirm});    
  // },[sendEmailConfirm]);
  // const isRegistrationFeeValid = registrationfee !== undefined && registrationfee !== null;
  const isRegistrationFeeValid = registrationfee === "" || registrationfee === 0  ? 0 : registrationfee;
  return (
    <Grid sx = {{ml: 8}}>
      <Dialog open={openDialog} onClose={onClose}>
        <DialogTitle>
            <Typography component="span" sx={{ color: "red" }}>
                {t('Title.ATRAS')}&nbsp; 
            </Typography>
            <Typography component="span" sx={{ color: "blue", fontWeight: 'bold'}}>
                ({studentNameSurname.Name})
            </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2" noWrap sx={{ fontWeight: 'bold', color: 'mycolor.main', fontSize: 14, pb:2, pt:1, mr: 2 }}>
            {currentTime} 
          </Typography>
          <TextField
            margin="dense"
            name="surname"
            label={t('TransactionBy')}
            fullWidth            
            variant="outlined"
            value={account.user[0].name_surname}
            InputProps={{
              readOnly: true,
            }}
          />
          

          {/* <Box>
            <FormControlLabel
              control={<Checkbox checked={showNewTask} onChange={handleCheckboxChange} disabled={registrationfee === 0}/>}
              //label= {`charged (registration fee)?  €${registrationfee}`} //Eger registrated fee 0 (sifir) degil ise bu checkbox actif olsun.
              label={`charged (registration fee)? € ${isRegistrationFeeValid ? registrationfee : 0}`}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontWeight: showNewTask ? 'bold' : 'normal',
                  color: showNewTask ? 'mycolor.main' : 'inherit',
                },
              }}
            />            
          </Box> */}

          <Box>
            {/* <FormControlLabel
              control={<Checkbox checked={showNewTask} onChange={handleCheckboxChange} />}
              label="Create New Task"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontWeight: showNewTask ? 'bold' : 'normal',
                  color: showNewTask ? 'mycolor.main' : 'inherit',
                },
              }}
            /> */}
            <FormControlLabel
              control={<Checkbox checked={showNewTask} onChange={handleCheckboxChange} disabled={registrationfee === 0}/>}
              //label= {`charged (registration fee)?  €${registrationfee}`} //Eger registrated fee 0 (sifir) degil ise bu checkbox actif olsun.
              label={`charged (registration fee)? € ${isRegistrationFeeValid ? registrationfee : 0}`}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontWeight: showNewTask ? 'bold' : 'normal',
                  color: showNewTask ? 'mycolor.main' : 'inherit',
                },
              }}
            />   
            
            
            {showNewTask && (
              <Box sx={{ border: '1px solid gray', padding: 2, marginTop: 0, marginBottom: 1, borderRadius: 2  }}>
                {/* <Typography component="span" sx={{ 
                  color: registrationfee === 0 ? "gray" : "blue",
                  fontWeight: 'bormal' }}>
                  Registration Fee Payment Date:
                  <br/>
                </Typography>           */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker sx={{mt: 1, color: isRegistrationFeeValid === 0 || showNewTask === true ? "blue" : "gray", }}
                    label="Registration Fee Payment Date"
                    value={selectedPaymentDate}
                    onChange={handleDateChange}                
                    renderInput={(params) => <TextField {...params} />}                
                    disabled={registrationfee === 0}                
                  />
                </LocalizationProvider>
                <Tooltip title="Enter the bank account number where the payment was received." arrow>
                  <TextField
                    margin="dense"                    
                    name="receiving_account_number"
                    label="Receiving Account Number"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={recevignAccountNumber}
                    onChange={handleReceivingAccountNumber}
                    className={formErrors.receiving_account_number ? "blinking" : ""}
                    multiline
                    rows={1}
                    sx={{ mb: 1 }}
                  />
                </Tooltip>
                <Tooltip title="Enter a transaction number or receipt number that can be associated with the received payment (45 characters)." arrow>
                  <TextField
                    margin="dense"
                    required
                    name="transactioninfo"
                    label="Transaction Info"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={transactionInfo}
                    onChange={handleTransactionInfo}
                    className={formErrors.transactioninfo ? "blinking" : ""}
                    multiline
                    rows={1}
                    sx={{ mb: 1 }}
                  />
                </Tooltip>
                <FormControl fullWidth margin="dense">
                  <InputLabel id="paymentmethods-select-label">{t('PaymentMethods')}</InputLabel>
                  <Select
                    inputRef={paymentmethodsRef}
                    labelId="paymentmethods-select-label"                    
                    name="Payment Methods"
                    label={t('PaymentMethods')}
                    value={selectedPaymentMethods || ''}
                    onChange={handleChangePaymentMethods}
                    className={formErrors.paymentmethods ? "blinking" : ""}
                  >
                    {paymentMethods && paymentMethods.map((methods) => (                                
                      <MenuItem key={methods.idpayment_methods} value={methods.idpayment_methods}>
                        {methods.methodName}        
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Tooltip title="If there is an account number which is sending account , please enter it here." arrow>
                  <TextField
                    margin="dense"                  
                    name="accountNumber"
                    label="Account Number"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={accountNumber}
                    onChange={handleAccountNumber}
                    multiline
                    rows={1}
                    sx={{ mb: 1 }}
                  />
                </Tooltip>
                <Tooltip title="Email address sending the fee." arrow>                
                  <TextField
                    margin="dense"                  
                    name="providerEmail"
                    label="Provider Email"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={providerEmail}
                    onChange={handleProviderEmail}
                    multiline
                    rows={1}
                    sx={{ mb: 1 }}
                  />            
                </Tooltip>          
                <Box display="flex" justifyContent="flex-end">
                  <Typography variant="caption" fontWeight={'bold'} mt={1} color={ charDetailCount < maxChars ? 'green' : 'red'}>
                    {`${maxChars - charDetailCount} characters remaining`}
                  </Typography>
                </Box>    
                <Tooltip title="You can enter a description related to the payment collected." arrow>
                  <TextField
                    margin="dense"
                    name="description"
                    label={t('Description')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={paymentDetailDescription}
                    onChange={handleChangeDetailDescription}
                    multiline
                  />
                </Tooltip>  
          {(paymentDetails.paymentfrequency === "Installment" || paymentDetails.paymentfrequency === "INSTALLMENT") && (
            <>
            <Divider sx={{mt: 2, mb: 2, backgroundColor: 'grey', height: '2px' }}/> 
              <Typography component="span" sx={{ 
                color: registrationfee === 0 ? "gray" : "blue",
                fontWeight: 'normal', width: '15px'
              }}>
                First Monthly Payment Overdue Date:
                <br/>
              </Typography>          
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ mt: 2, mb: 2, color: registrationfee === 0 ? "gray" : "blue" }}
                  label="Select Overdue Date"
                  value={selectedPaymentOverdueDate}
                  onChange={handleDueDateChange}                
                  renderInput={(params) => <TextField {...params} />}
                  //disabled={registrationfee === 0} // Bu, ilk açılışta disable olacak
                />
              </LocalizationProvider>
              {/* <Divider sx={{ mt: 2, mb: 2 }} /> */}
            </>
          )}
          </Box>
              
            )}
          </Box>          
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="caption" fontWeight={'bold'} mt={3} color={ charCount < maxChars ? 'green' : 'red'}>
              {`${maxChars - charCount} characters remaining`}
            </Typography>
          </Box>
          <Tooltip title="The description entered in this field is a general note and will appear under the finance table." arrow>
            <TextField
              margin="dense"
              name="description"
              label={t('Description')}
              type="text"
              fullWidth
              variant="outlined"
              value={paymentDescription}
              onChange={handleChangeDescription}
              multiline
            />
          </Tooltip>         
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button onClick={handleSubmit} autoFocus
            disabled={isRegistrationFeeValid === 0 || showNewTask === true ? false : true}            
            style={{
              backgroundColor: isRegistrationFeeValid === 0 || showNewTask === true ? 'blue' : 'gray',
              color: '#FFFFFF',
            }}
          >
              {t('Create')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
        PaperProps={{
          sx: {
            padding: 2,
            borderRadius: 3,
            boxShadow: 5,
            maxWidth: '500px',
            margin: 'auto',
          },
        }}
      >
        <DialogTitle id="confirmation-dialog-title" sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.5rem' }}>
          Confirmation
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="confirmation-dialog-description" sx={{ textAlign: 'center', marginBottom: 2 }}>
            <Typography variant="body1" component="span">
              An email will be sent to <strong>{email}</strong> with the next call date and time:
            </Typography>
            <Typography variant="h6" sx={{ marginTop: 1 }} component="span">
              {nextCall ? nextCall.format('MMMM D, YYYY h:mm A') : ''}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 2 }} component="span">
              Do you confirm?
            </Typography>
          </DialogContentText>
        </DialogContent> */}
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={ handleCloseConfirmation} color="secondary" variant="outlined">
            Cancel
          </Button>
          {/* <Button onClick={handleSendMailConfirmation} color="primary" variant="contained" sx={{ marginLeft: 2 }}>
            OK
          </Button> */}
        </DialogActions>
      </Dialog>
      {alertMessages.map((msg, index) => (
        <Snackbar
          key={msg.id} 
          open={msgOpen} 
          autoHideDuration={6000} 
          onClose={() => {
            setAlertMessages(prevMessages => prevMessages.filter(message => message.id !== msg.id));
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx = {{
            position: 'fixed', // Snackbar'ı sabitlemek için position fixed kullanılıyor.
            bottom: `${index * 120}px`, // Her mesaj için alttan yukarıya doğru 60px artan bir yer kaplar.
            left: 0, // Ekranın sol tarafından başlar.
            right: 0, // Ekranın sağ tarafına kadar uzanır.
            width: 'auto' // Genişlik otomatik ayarlanır.
          }}
        >
          <Alert severity={msg.severityType}>
            {msg.message}
          </Alert>
        </Snackbar>
      ))}
    </Grid>
  );
};