import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getAllCountries = async () => {
    try {     
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/students/getallcountries`, {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      });
      const info = response.data.result || []; 
      if (!Array.isArray(info)) {
        // throw new Error('API response is not an array');
    //    console.log('API response is not an array');
      }

      const countries = info.map((item, index) => {
        return {
            idcountries: item.idcountries || '',
            countriesname: item.countriesname || '',
        };
      });
     // console.error('getAllCountries: ', countries);
      return countries;
    } catch (error) {
      console.error('Error fetching getAllCountries: ', error);
      return [];
    }
};

export default getAllCountries;