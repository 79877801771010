import React, { createContext, useState, useEffect, useContext } from 'react';

// Dil context'i oluşturuluyor
export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  // useState ile dil durumunu yönetmek için hook
  const [language, setLanguage] = useState('en'); // Varsayılan dil olarak İngilizce

  useEffect(() => {
    // localStorage'dan dil bilgisini yükleme
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  const changeLanguage = (lang) => {
    setLanguage(lang); // Dil durumunu güncelle
    localStorage.setItem('language', lang); // Yeni dili localStorage'a kaydet
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Custom hook: Dili ve dil değiştirme fonksiyonunu döndürür
export const useLanguage = () => useContext(LanguageContext);
