import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getOnboardLecturesList = async () => {
    try {
        const token = await getToken();
        //console.log('getOnboardLecturesList.');
        const response = await axios.get(`${BaseUrl}/student/onboard/exams/getlectures`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
       // console.log('getOnboardLecturesList: ', response.data);
        const info = response.data || [];
        const lecturesInfo = info.map((item, index) => {
            return {
                idsyllabus_lecture: item.idsyllabus_lecture || '',
                lecturetopic: item.lecturetopic || '',                
                index: index
            };
        });
       // console.log("getLecturesList: ", lecturesInfo);
        return lecturesInfo;
    } catch (error) {
        console.log('error: ', error);
        return [];
    }
};

export { getOnboardLecturesList };

/*
SELECT idsyllabus_lecture, lecturetopic FROM syllabus_lectures where moduleid in (SELECT idsyllabus_modules FROM syllabus_modules Where insidesyllabus = 0)
*/

/* SELECT idquestions, 
questionstext, 
questionsimage, 
imagepath,
idcorrectanswer, 
idquestionslecture, 
questionpoint, 
significanceofquestion, 
questionsource, 
atcreated, 
atupdated FROM questionsoftest;
 
*/