import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getAllCourseCoordinators = async () => {
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/users/getallcoursecoordinators`,{
      headers: {
          'Authorization': `Bearer ${token}`
      }
    });
    const info = response.data || [];
    const coordinatorInfo = info.map((item, index) => {
      return {
          id: item.iduser || '',
          name_surname: item.name_surname || '',
          username: item.username || '',               
          roles: item.roles || '',
          jobs: item.jobs || '',     
          contacts: item.contacts || '',
          is_active: item.is_active || '',                
          idstatus: item.idstatus || '',
          index: index
      };
    });
    return coordinatorInfo;
  } catch (error) {  
    return [];
  }
};

export default getAllCourseCoordinators;