import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, InputLabel, FormControl, Alert, Snackbar } from '@mui/material';
import Iconify from '../iconify';
import EmailAutocomplete from './emailautocomplated';
import PasswordField from './passwordfield';
import InputAdornment from '@mui/material/InputAdornment';
import createmailuser from '../../_data/mail/createmailuser';

export default function CreateEmailDialog() {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    domain_name: 'oakacademy.uk',
    email: '' ,
    password: '',
    fullname: '',
    department: '',
  });

  const departments = ['Management', 'Oak MEDIA', 'Human Resources', 'Finance', 'Marketing', 'Education', 'Infrastructure']; // TODO: Dynamic olarak databaseden alınacak.

  const copyToClipboard = async (text) => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        // Güvenli bir bağlamda ve modern bir tarayıcıda navigator.clipboard API'sini kullan
        await navigator.clipboard.writeText(text);
        showMessage('Create e-Mail User & Password copied.');
        //console.log('The text has been copied to the clipboard.');
      } else {
        // Eski tarayıcılar için alternatif yöntem
        let textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        showMessage('Create e-Mail User & Password copied.');
        //console.log('Text copied to clipboard');
      }
    } catch (err) {
      console.error('Could not copy password to clipboard!', err);
    }
  };

  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success'); // or 'error'
  const [alertMessage, setAlertMessage] = useState('');
  const showMessage = (message, severityType = 'success') => {
    setAlertMessage(message);
    setSeverity(severityType);
    setMsgOpen(true);
  };
  const handleClosePasswMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const cleanedValue = value.replace(/@/g, '');
    setFormData({
      ...formData,
      [name]: cleanedValue,
    });
  };

  const updatedFormData = {
    ...formData,
    email: `${formData.email}@${formData.domain_name}`,
  };
  
  // Form verilerini güncellemek için bir state güncelleme fonksiyonu kullanın (örneğin, setFormData)
  
  const handleSubmit = () => {
    // Burada form verilerini işleyebilirsiniz.
    // Örneğin, bir API çağrısı yaparak verileri sunucuya gönderebilirsiniz.
    setFormData(updatedFormData);
    formData.email = `${formData.email}@${formData.domain_name}`;
   // console.log(formData);
    // Kullanım örneği
    createmailuser(formData);
    copyToClipboard(formData.password);
    handleClose(); // Form gönderildikten sonra diyalogu kapat
  };

  const handlePasswordChange = (newPassword) => {
    setFormData({ ...formData, password: newPassword });
  };

  return (
    <div>
      <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen} >
      {/* variant="outlined" */}
        New Email
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New Email</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="domain_name"
            label="Domain Name"
            type="text"
            fullWidth
            disabled
            variant="outlined"
            value={formData.domain_name}
          />
          {/* <PasswordField /> */}
          <PasswordField onPasswordChange={handlePasswordChange} />
          {/* <TextField
            margin="dense"
            name="password"
            label="Password"
            type="password"
            fullWidth
            variant="outlined"
            value={formData.password}
            onChange={handleChange}
          /> */}
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            value={formData.email}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  @{formData.domain_name}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="dense"
            name="fullname"
            label="Full Name"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.fullname}
            onChange={handleChange}
          />          
          <FormControl fullWidth margin="dense">
            <InputLabel id="department-select-label">Department</InputLabel>
            <Select
              labelId="department-select-label"
              name="department"
              label="Department"
              value={formData.department}
              onChange={handleChange}
            >
              {departments.map((department, index) => (
                <MenuItem key={index} value={department}>
                  {department}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} autoFocus
            style={{
              backgroundColor:'blue',
              color: '#FFFFFF',
            }}>Create</Button>
        </DialogActions>
      </Dialog>
      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={5000} 
        onClose={handleClosePasswMessage}
      >
        <Alert severity={severity} onClose={handleClosePasswMessage} sx={{ width: 450, height: 70, fontSize: 20, fontWeight: 'bold', fontStyle: 'normal'}} >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
