import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getActiveBatches= async () => {
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/courses/getactivebatches`,{
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const info = response.data || [];
    const batchList = info.map((item, index) => {
      return { 
        batchname: item.batchname || '',
        idbatches: item.idbatches || '',
      };
      // console.log("getActiveBatches-batchList: ", batchList);

    });
    return batchList;
  } catch (error) {
    if (error.response && error.response.status === 501) {
      return('Network connection failed.');
    } else {
      return('An unexpected error occurred.');
    }
  }
};

export { getActiveBatches };