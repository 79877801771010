import React from 'react';

function ImageViewer({ fileData, onClose }) {
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%', margin: 'auto' }}>
      <button onClick={onClose} style={{ position: 'absolute', top: 10, right: 10 }}>Close</button>
      <img
        src={fileData}
        alt="Preview"
        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        onError={() => console.log('Image failed to load')}
      />
    </div>
  );
}

export default ImageViewer;
