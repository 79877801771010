import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getUser = async (userId) => {
    try {
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/users/getusers`, {
        params: {
          iduser: userId
        },
        headers: {
            'Authorization': `Bearer ${token}`
        }
      });
        const info = response.data || [];
        //console.log("getUser-Info: ", info);
        const userInfo = info.map((item, index) => {       
            return {
                id: item.iduser || '',
                name_surname: item.name_surname || '',
                username: item.username || '',
                passwd: item.passwd || '',
                roles: item.roles || '',
                jobs: item.jobs || '',     
                contacts: item.contacts || '',
                pass_hash: item.pass_hash || '',
                is_active: item.is_active || '',
                photourl: item.photourl || '',
                status: item.status || '',
                index: index
            };
        });
        //console.log("userInfo: ", userInfo);
        return userInfo;
    } catch (error) {  
        return [];
    }
};

export default getUser;