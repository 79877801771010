const config = {
  BaseUrl: "https://oakapi.oakacademy.de/api",
  PUBLIC_URL: "https://oak.oakacademy.de",
  //BaseUrl: "http://localhost:2003/api",
  //PUBLIC_URL: "http://localhost:4000",
  //BaseUrl: "https://api.oakacademy.de/api",
  //PUBLIC_URL: "https://edu.oakacademy.de",
  AppCode: "N!q#fMY7QgW*B9Sv?Ua$3R1Ao"  // Main App code for Auth
};

export default config;
