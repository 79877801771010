import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getFirstStudentRecords = async () => {
    try {
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/students/firststudentrecords`, {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      });
      const firstStudentsRecord = response.data || [];
     // console.log('getFirstStudentRecords-firstStudentsRecord: ', firstStudentsRecord);
      return firstStudentsRecord;
    } catch (error) {
      console.error('Error fetching First Students Records: ', error);
      return [];
    }
};
export default getFirstStudentRecords;