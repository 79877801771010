import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getAllOccupations = async () => {
    try {     
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/students/getalloccupations`, {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      });
      const info = response.data.result || []; 
      if (!Array.isArray(info)) {
        throw new Error('API response is not an array');
      }

      const occupations = info.map((item, index) => {
        return {
          idoccupations: item.idoccupations || '',
          occupationname: item.occupationname || '',
          description: item.description || '',
        };
      });
      return occupations;
    } catch (error) {
      console.error('Error fetching occupations: ', error);
      return [];
    }
};

export default getAllOccupations;