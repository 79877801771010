// useTokenRefresh.js
import { useEffect } from 'react';
import getTokenExpiryTime from './getTokenExpiryTime';

const useTokenRefresh = (refreshTokenCallback, checkInterval = 300000, refreshThreshold = 600000) => {
    useEffect(() => {
        const checkTokenExpiry = () => {
            const tokenExpiryTime = getTokenExpiryTime(); // Token'ın bitiş süresini alın
            const currentTime = Date.now();
            const timeToExpiry = tokenExpiryTime - currentTime;

            if (timeToExpiry < refreshThreshold) { // Örneğin 10 dakika
                refreshTokenCallback();
            }
        };

        const intervalId = setInterval(checkTokenExpiry, checkInterval);

        return () => clearInterval(intervalId);
    }, [refreshTokenCallback, checkInterval, refreshThreshold]);

    return null;
};

export default useTokenRefresh;