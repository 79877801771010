import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getNextTransactions = async () => {
    try {
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/students/getnexttransactions`, {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      });
      const info = response.data || []; 
      //console.log('getNextTransactions-info: ', info);
      if (!Array.isArray(info)) {
    //    console.log('API NextTransactions response is not an array')
      }
      const nextTransactions = info.filter(item => item !== '' && item !== undefined && item !== null); // Bu kod ise bos olmayan verileri Array olarak dondurur.
      //console.log('getNextTransactions-nexttransactions: ', nextTransactions);      
      return nextTransactions;
    } catch (error) {
      console.error('Error fetching next transactions: ', error);
      return [];
    }
};
export default getNextTransactions;