import React, { forwardRef, useImperativeHandle, useState, useRef, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, 
InputLabel, FormControl, Alert, Snackbar, FormControlLabel, RadioGroup, Radio, Box,Typography,
FormGroup, Checkbox,Accordion, AccordionSummary, AccordionDetails, Paper, Grid } from '@mui/material';
import Iconify from '../iconify';
import InputAdornment from '@mui/material/InputAdornment';
import updatestudent from '../../_data/student/updatestudent';
import getAllGraduations from '../../_data/student/getAllGraduations';
import getAllLangLevel from '../../_data/student/getAllLangLevel';
import { useTranslation } from 'react-i18next'; //Multi Language
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneInput from './PhoneInput';
import getSession from '../../utils/getSession';
import style from '../../theme/blink.css';
import { getActiveBatches } from '../../_data/batchs/getActiveBatches';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';

// const UpdateStudentDialog = forwardRef((props, ref) => {
const UpdateStudentDialog = forwardRef(({ initialData, onStudentUpdate }, ref) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const account = getSession();  
  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success'); // or 'error'
  const [alertMessage, setAlertMessage] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [firstErrorField, setFirstErrorField] = useState(null);
  const nameRef = useRef(null);
  const surnameRef = useRef(null);
  const resourcecodeRef = useRef(null);
  const statusRef = useRef(null);
  const batcheRef = useRef(null);
  const mailRef = useRef(null);
  const [newResource, setNewResource] = useState("");
  const [courseNames, setCourseNames] = useState([])
  const [graduations, setGraduations] = useState([])
  const [langLevels, setLangLevels] = useState([])
  const Status = [
    {key:4, value:'Prospective Student'}, 
    {key:1, value:'Registered Student'}, 
    {key:2, value:'Intern Student'}, 
    {key:3, value:'Timed Access Granted'}, 
    {key:5, value:'Relationship Ended'},
    {key:6, value:'LMS-Hybrid Registered'}, 
    {key:7, value:'LMS Registered'}
  ]; //TODO: Batch tablosundan dynamic almalı tablodaki isactive=1 olanlar gösterilmeli 

  const [expanded, setExpanded] = useState(false);
  const [contentEmailError, setContentEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj) || '';
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  const fetchGraduations = async () => {
    const data = await getAllGraduations();
    const graduation = Array.isArray(data) ? data : [];
    setGraduations(graduation);
  };

  const fetchLanglevel = async () => {
    const data = await getAllLangLevel();
    const langlevel = Array.isArray(data) ? data : [];
    setLangLevels(langlevel);
  };

  useEffect(() => {
    const fetchActiveBatches = async () => {
      const data = await getActiveBatches();
      const courses = Array.isArray(data) ? data : [];
      setCourseNames(courses);
    };
    fetchActiveBatches();
    fetchGraduations();
    fetchLanglevel();
  }, []);

  useEffect(() => {
    if(initialData && initialData.length > 0) {
      const firstItem = initialData[0]; // Dizi olduğu için ilk elemanı alıyoruz
      const phoneValue = firstItem.contact?.phone?.cellphone1 ?? '';
      const mailValue = firstItem.contact?.mail?.mailaddress1 ?? '';
      const dateofbirthValue = firstItem.dateofbirth ?? '';
      const graduation = firstItem.graduation ?? '';
      const langlevel = firstItem.langlevel ?? '';
      setFormData({
        ...firstItem, // İlk elemanı baz alıyoruz
        memberships: firstItem.memberships || { Quizizz: false, TryHackMe: false, DigitalCampus: false, DiscordUserName: "" },
        contact: {
          ...firstItem.contact,
          phone: {
            ...firstItem.contact?.phone,
            cellphone1: phoneValue
          }
        },
        bycreated: account.user[0].iduser,
        email: mailValue,
        dateofbirth: dateofbirthValue,
        graduation: graduation,
        langlevel: langlevel,
      });
    }  
  }, [initialData]);
  
  const [formData, setFormData] = useState({
    idstudents: '',
    name: '',
    surname: '',
    dateofbirth: '',
    email: '',
    graduation: '',
    langlevel: '',
    batchname: '',
    idbatch: '' ,
    contact: {city: "", mail: "", phone: "", address: "", country: ""},
    memberships: {Quizizz: false, TryHackMe: false, DigitalCampus: false, DiscordUserName: ""},
    resourcecode: '',
    interninfo: null,
    bycreated: '',
    status: '',
    description: ''
  });

  const updatedFormData = {
    ...formData,
  };

  const showMessage = (message, severityType = 'success') => {
    setAlertMessage(message);
    setSeverity(severityType);
    setMsgOpen(true);
  };

  const handleCloseStudentMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };
  
  const handleClose = () => {
    setOpen(false);
   };
  const handleSubmit = async() => {    
    const isValid = validateForm();
    if (updatedFormData.description === null || updatedFormData.description === undefined) {
      setFormData({ ...formData, description: '' });
    }
    if (updatedFormData.memberships.LMS === null || updatedFormData.memberships.LMS === undefined) {
      setFormData({
        ...formData,
        memberships: {
          ...formData.memberships.LMS = false
        }
      });
    }
    setFormData(updatedFormData);
    if (!isValid) return;
    const result = await updatestudent(formData);
    if(result.affectedRows === 1 && result.message === "Student update successfully."){
      showMessage(t('StudentUpdateSuccess'), 'success');
      handleClose();
      onStudentUpdate(); // Callback fonksiyonu çağır
    } else {
      showMessage(result.message, 'error');
    }    
  };

  const handleChangeDateOfBirth = (date) => {
    try {
      const formattedDate = date ? format(date, 'MM/dd/yyyy') : null;
      setFormData({ ...formData, dateofbirth: formattedDate});
    } catch (error) {
      console.error('Date formatting error: ', error);
    }
  };
  
  const handleChangeStatus = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, status: value });
  };

  const handleChangeGraduations = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, graduation: value });
  };

  const handleChangeLangLevels = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, langlevel: value });
  };

  const handleChangeCourseName = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, idbatches: value, batchname: name });
  };

  const handleChange = (event) => {
    try {
      const { name, value } = event.target;
      if(name === 'contact.mail.mailaddress1'){
        setFormData({ ...formData, email: value });
      }
      const nameParts = name.split('.'); // Nokta ile ayrılmış yolu parçalara ayır
      
      setFormData((prevFormData) => {
        let updatedValue = { ...prevFormData }; // İlk kopyayı oluştur
        nameParts.reduce((acc, key, index) => {
          if (index === nameParts.length - 1) {
            // Eğer son parçadaysak değeri güncelle
            acc[key] = value;
          } else {
            // Eğer son parça değilse, bir sonraki parçaya geç
            acc[key] = acc[key] || {}; // Eğer bir sonraki anahtar yoksa, yeni bir nesne oluştur
          }
          return acc[key];
        }, updatedValue);
        return updatedValue; // Güncellenmiş değeri döndür
      });
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleMembershipCheckboxChange = (event) => {
    setFormData({
      ...formData,
      memberships: {
        ...formData.memberships,
        [event.target.name]: event.target.checked
      }
    });
  };

  const handleMembershipTextChange = (event) => {
    setFormData({
      ...formData,
      memberships: {
        ...formData.memberships,
        [event.target.name]: event.target.value
      }
    });
  };

  const handleChangeResource = (event) => {
    setFormData({ ...formData, resourcecode: event.target.value });
  };
  
  const handleBlur = (e) => {
    const isValid = emailRegex.test(e.target.value);
    if (!isValid) {
      // Geçerli değilse hata mesajını set et
      setEmailHelperText("Please enter a valid email address.");
      setContentEmailError(true);
    } else {
      setEmailHelperText("");
      setContentEmailError(false);
    }
  };

  // Dışarıdan erişilebilir metodları tanımla
  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
    handleClose() {
      setOpen(false);
    },
  }));

  const validateForm = () => {
    
     let errors = {};
     let firstError = null;

    if (!formData.name) {
      errors.name = true;
      firstError = firstError || 'name';
      showMessage("Fill in (name) the required fields! ", 'warning');
    }
    if (!formData.surname) {
      errors.surname = true;
      firstError = firstError || 'surname';
      showMessage("Fill in (surname) the required fields! ", 'warning');
    }
    if (!formData.resourcecode && formData.status !== 4 ) {
      errors.resourcecode = true;
      firstError = firstError || 'resourcecode';
      showMessage("Fill in (recourse) the required fields! ", 'warning');
    }
    if (!formData.status) {
      errors.status = true;
      firstError = firstError || 'status';
      showMessage("Fill in (status) the required fields! ", 'warning');
    }
    if (!formData.idbatch && formData.status !== 4 ) {
      errors.idbatches = true;
      firstError = firstError || 'coursename';
      showMessage("Fill in (course name) the required fields! ", 'warning');
    }
    if (!formData.email) {
      errors.email = true;
      firstError = firstError || 'email';
      showMessage("Fill in (contac->mail) the required fields! ", 'warning');
    }
    setTimeout(() => setFormErrors(false), 3000);
    setFormErrors(errors);
    setFirstErrorField(firstError);
    
    if(firstError) {
      // İlk hata alanına odaklan
      const refs = {name: nameRef, surname: surnameRef, resourcecode: resourcecodeRef, status: statusRef, coursename: batcheRef, email: mailRef};
      refs[firstError].current?.focus();
    }
    return Object.keys(errors).length === 0;

  };

  return (
    <div>
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('Title.USS')}</DialogTitle>
      <DialogContent>
        <TextField
          inputRef={nameRef}
          margin="dense"
          name="name"
          label={t('Name')}
          type="text"
          fullWidth            
          variant="outlined"
          value={formData.name}
          onChange={handleChange}            
          className={formErrors.name ? 'blinking' : ''}
        />
        <TextField
          inputRef={surnameRef}
          margin="dense"
          name="surname"
          label={t('Surname')}
          type="text"
          fullWidth            
          variant="outlined"
          value={formData.surname}
          onChange={handleChange}
          className={formErrors.surname ? "blinking" : ""}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
            <DatePicker
              label="Date Of Birth"
              //value={formData.dateofbirth}
              value={formData.dateofbirth ? new Date(formData.dateofbirth) : null}
              onChange={handleChangeDateOfBirth}
              // (newValue) => {
              //   setDateOfBirthValue(newValue);
              // }}
              renderInput={(params) => <TextField {...params} />}
              sx={{mt:2}}
            />
          </LocalizationProvider>
        <Paper 
            sx={{
              mt:1,
              pl:2,
              border: 1,
              borderColor: 'divider'
            }}
        >
          <FormControl sx={{ ml: 0 }} fullWidth margin="dense">
            <InputLabel sx={{ pl: 0 }} id="resource-select-label" shrink>{t('ResourceCode')}</InputLabel>
            <RadioGroup 
              row aria-label="resourcecode" 
              name="row-radio-buttons-group" 
              value={formData.resourcecode}  
              onChange={handleChangeResource} 
              sx={{ mt: 1 }}
              className={formErrors.resourcecode ? "blinking" : ""}
            >
              <FormControlLabel value="P" control={<Radio />} label={t('Private')} sx={{ mr: 8 }}/>
              <FormControlLabel value="J" control={<Radio />} label={t('JobCenter')} />
            </RadioGroup>
          </FormControl>
        </Paper>

        <FormControl fullWidth margin="dense">
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              inputRef={statusRef}
              labelId="status-select-label"
              name="status"
              label="Status"
              value={formData.status}
              onChange={handleChangeStatus}                
              className={formErrors.status ? "blinking" : ""}
            >
              {Status.map((status, index) => (
                <MenuItem key={index} value={status.key}>
                  {status.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="dense">
            <InputLabel id="graduations-select-label">Graduations</InputLabel>
            <Select
              //inputRef={sRef}
              labelId="graduations-select-label"
              name="graduations"
              label="graduations"
              value={formData.graduation}
              onChange={handleChangeGraduations}                
              className={formErrors.graduations ? "blinking" : ""}
            >              
              {graduations.map((graduate) => (
                <MenuItem key={graduate.idgraduations} value={graduate.idgraduations}>
                  {graduate.edulevelname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="dense">
            <InputLabel id="langlevels-select-label">Lang. Levels</InputLabel>
            <Select
              //inputRef={sRef}
              labelId="langlevels-select-label"
              name="langlevels"
              label="langlevels"
              value={formData.langlevel}
              onChange={handleChangeLangLevels}                
              // className={formErrors.graduations ? "blinking" : ""}
            >
              {langLevels.map((langlevel) => (
                <MenuItem key={langlevel.idlanglevel} value={langlevel.idlanglevel}>
                  {langlevel.langname} {langlevel.levelname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        <FormControl fullWidth margin="dense">
          <InputLabel id="coursename-select-label">{t('CourseName')}</InputLabel>
          <Select
            labelId="coursename-select-label"
            name="batchename"
            label={t('CourseName')}
            value={formData.idbatch || ''}
            onChange={handleChangeCourseName}
            className={formErrors.idbatch ? "blinking" : ""}
          >
            {courseNames.length > 0 && courseNames.map((courseName) => (                                
              <MenuItem key={courseName.idbatches} value={courseName.idbatches}>
                {courseName.batchname}                 
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        
        <Paper 
            sx={{
              mt:1,
              border: 1,
              borderColor: 'divider'
            }}
        >
          <Accordion expanded={expanded === 'contact'} onChange={handleAccordionChange('contact')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="contactbh-content"
              id="contactbh-header"
            >
              <Typography variant="h6">{t('Contact')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField            
                margin="dense"
                name="contact.address"
                label="Address"
                type="text"
                fullWidth            
                variant="outlined"
                value={getNestedValue(formData, "contact.address")}
                onChange={handleChange}
              />
              <TextField            
                margin="dense"
                name="contact.city"
                label="City"
                type="text"
                fullWidth            
                variant="outlined"
                value={getNestedValue(formData, "contact.city")}
                onChange={handleChange}
              />
              <TextField            
                margin="dense"
                name="contact.country"
                label="Country"
                type="text"
                fullWidth            
                variant="outlined"
                value={getNestedValue(formData, "contact.country")}
                onChange={handleChange}
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                <TextField
                  autoFocus={contentEmailError}
                  inputRef={mailRef}
                  error={contentEmailError}
                  helperText={contentEmailError ? emailHelperText : ""}
                  type="email"
                  margin="dense"
                  name="contact.mail.mailaddress1"
                  label="Mail Address 1"
                  fullWidth
                  variant="outlined"
                  value={getNestedValue(formData, "contact.mail.mailaddress1")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={formErrors.email ? "blinking" : ""}
                />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <PhoneInput 
                    margin="dense"
                    name="contact.phone.cellphone1"
                    label="Phone CellPhone 1"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={getNestedValue(formData, "contact.phone.cellphone1")}
                    onChange={handleChange} 
                  />
                </Grid>
                <Grid item xs={6}>
                  <PhoneInput 
                      margin="dense"
                      name="contact.phone.whatsapp"
                      label="Phone WhatsApp Number"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={getNestedValue(formData, "contact.phone.whatsapp")}
                      onChange={handleChange} 
                    />
                </Grid>  
              </Grid>                   
            </AccordionDetails>
          </Accordion>
        </Paper>
        <Paper 
          sx={{
            mt:1.5,
            border: 1,
            borderColor: 'divider'
          }}
        >    
          <Accordion expanded={expanded === 'memberShips'} sx={{ order: 1, borderColor: 'divider'}} onChange={handleAccordionChange('memberShips')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="memberShipsbh-content"
              id="memberShipsbh-header"
            >
              <Typography variant="h6" gutterBottom>{t('MemberShips')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ p: 2 }}>              
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.memberships.Quizizz}
                        onChange={handleMembershipCheckboxChange}
                        name="Quizizz"
                      />
                    }
                    label="Quizizz"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.memberships.TryHackMe}
                        onChange={handleMembershipCheckboxChange}
                        name="TryHackMe"
                      />
                    }
                    label="TryHackMe"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData?.memberships?.LMS || false}
                        onChange={handleMembershipCheckboxChange}
                        name="LMS"
                      />
                    }
                    label="DigitalCampus (LMS)"
                  />
                  <TextField
                    label="Discord Username"
                    name="DiscordUserName"
                    value={formData.memberships.DiscordUserName}
                    onChange={handleMembershipTextChange}
                    margin="normal"
                    fullWidth
                  />
                </FormGroup>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Paper>
        <TextField
          margin="dense"
          name="description"
          label={t('Description')}
          type="text"
          fullWidth
          variant="outlined"
          value={formData.description}
          onChange={handleChange}
          sx={{
            mt: 2,
            '& .MuiInputLabel-root': {
              transform: 'translate(14px, -10px) scale(1)', // Label'ın başlangıç konumunu düzenler
            },
            '& .MuiInputLabel-shrink': {
              transform: 'translate(14px, -6px) scale(0.75)', // Label yukarıya çıktığında konumunu düzenler
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Cancel')}</Button>
        <Button onClick={handleSubmit} autoFocus
        style={{
          backgroundColor:'blue',
          color: '#FFFFFF',
        }}>{t('Update')}</Button>
      </DialogActions>
    </Dialog>
    <Snackbar 
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
      open={msgOpen}  
      autoHideDuration={5000} 
      onClose={handleCloseStudentMessage}
    >
      <Alert severity={severity} onClose={handleCloseStudentMessage} sx={{ width: 450, minWidth: 288, height: 75, fontSize: 14, fontStyle: 'normal'}} >
        {alertMessage}
      </Alert>
    </Snackbar>
    </div>
  );
});

export default UpdateStudentDialog;