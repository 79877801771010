
import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const setUpdateFollower = async (coordinatorId, studentId) => {
  try {
   // console.log('setUpdateFollower-formData: ', coordinatorId, studentId);
    const token = await getToken();
    const response = await axios.post(`${BaseUrl}/student/setstudentfollower`, 
      {
        coordinatorId,
        studentId
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
    return response.data;
  } catch (error) {  
      console.error("setUpdateFollower Insert error: ", error);
      return [];
  }
};

export default setUpdateFollower;