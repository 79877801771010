import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const updateModules = async (Id, newModuleName, newLectureDuration, newDescription, iduser) => {
    try {
      const token = await getToken();
      const response = await axios.patch(`${BaseUrl}/syllabus/updatemodules`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: {
          idmodules: Id,
          _modulename: newModuleName, 
          duration: newLectureDuration, 
          desc: newDescription, 
          _byupdate: iduser
        },
     });
      return response.data;
    } catch (error) {
      console.log("error-updateModules: ", error);
    }
};

export default updateModules;

/*
{
  "email": "syilmaz@oakacademy.uk",
  "name": "Teachable API Test",
  "src": "api",
  "role": "student",
  "last_sign_in_ip": null,
  "id": 104152369,
  "courses": [],
  "tags": []
}

/var/Log/OakApi/out.log last 15 lines:
created: '2024-03-14T16:31:09+00:00',
[
  {
    hook_event_id: 703736132,
    object: {
    id: 1269643621,
    is_completed: true,
    course_id: 2167687,
    lecture_id: 51778362,
    school_id: 1413080,
    percent_complete: 12,
    course: [Object],
        user: [Object],
        lecture: [Object]
    }
  }
]
*/