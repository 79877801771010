import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
// import { sentenceCase } from 'change-case';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DynamicSelect from '../../components/select/dynamicSelect';
import { useTranslation } from 'react-i18next'; //Multi Language
import  convertDateTime  from '../../utils/convertDateTime'; //Multi Language


// @mui
import {
  Card, Table, Stack, Paper, Avatar, Button, Popover, Checkbox, TableRow,
  MenuItem, TableBody, TableCell, Container, Typography, IconButton,
  TableContainer, TablePagination, InputLabel, FormControl, Select, Box, Snackbar, Alert,
  Dialog, DialogTitle, DialogContent, DialogActions, Tooltip, LinearProgress
} from '@mui/material';

import { SvgIcon } from '@mui/material';


// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import { ReactComponent as Icon_Attendance } from '../../assets/icons/attendance.svg';
import { ReactComponent as Icon_Payment } from '../../assets/icons/icon_payment.svg';
import { ReactComponent as Icon_Lecture } from '../../assets/icons/ic_lecture.svg';
import { ReactComponent as Icon_Transaction } from '../../assets/icons/transaction.svg';
import { ReactComponent as Icon_CourseProgress } from '../../assets/icons/courseprogress_hourglass.svg';

import Scrollbar from '../../components/scrollbar'
// sections
import { LmsHybridListHead, LmsHybridListToolbar } from '../../sections/@dashboard/lms';

//database
import { getLmsHybridTrainee }  from '../../_data/lms/getLmsHybridTrainee';
// import ReportStudentAttendances from '../../_data/student/getReportParticipant';
import getLmsZoomAttendance from '../../_data/student/getLmsZoomAttendance';
//import InterviewTransaction from '../../_data/student/getInterviewTransaction';
import DataDisplay from './CustomDataDisplay';
import { getLmsBatchNames } from '../../_data/batchs/getBatchNames';
import CreateStudentDialog from '../../components/student/create-student-dialog';
import UpdateStudentDialog from '../../components/lms/update-student-dialog';
import DeleteStudent from '../../_data/student/deleteStudent';
import getSession from '../../utils/getSession'; //Session Management
import getLmsCourseUserProgress from '../../_data/lms/getLmsCourseUserProgres'; //Session Management

import config from '../../config';
const { PUBLIC_URL } = config;
const TransactionIcon = `${PUBLIC_URL}/assets/icons/navbar/ic_transaction.svg`;


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, filterStatusId, filterBatchId) {
  if (!Array.isArray(array)) {
    return;
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]).filter((student) => {
    // Genel arama sorgusu için filtreleme
    const lowerCaseQuery = String(query).toLowerCase(); // query'yi string'e çevir   
    const matchQuery = query ?
      Object.values(student).some((value) => String(value).toLowerCase().includes(lowerCaseQuery)) :
      true;

    // Status ID'ye göre filtreleme
    const matchStatus = filterStatusId ? student.status === filterStatusId : true;
    // Batch ID'ye göre filtreleme
    const matchBatch = filterBatchId ? student.idbatch === filterBatchId : true;

    return matchQuery && matchStatus && matchBatch;
  });
};


export default function StudentPage() {
  const { t } = useTranslation();

  // -------------------------COLUMN TITLES-------------------------------------------------- -----

  const TABLE_HEAD = [
    { id: 'namesurname', label: t('NameSurname'), alignRight: false },
    { id: 'coursename', label: t('CourseName'), alignRight: false },
    // { id: 'status', label: t('Status'), alignRight: false },
    { id: 'resourcecode', label: t('ResourceCode'), alignRight: false },
    { id: 'contact', label: t('Contact'), alignRight: false },
    { id: 'follower', label: t('Follower'), alignRight: false },
    { id: '' },
  ];
  //idstudents,coursename, name, surname, contact, memberships, resourcecode, interninfo, status, description
  // ---------------------------------------------------------------------------------------------------------

  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('coursename');
  const [filterStudentId, setfilterStudentId] = useState('');
  const [filterStatusId, setfilterStatusId] = useState('');
  const [filterBatchId, setfilterBatchId] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [lmsHybridTraineeDatas, setLmsHybridTraineeDatas] = useState([]); 
  const [recordsNumber, setrecordsNumber] = useState(0);
  const [openAlertMsg, setOpenAlertMsg] = useState(false); //Notification Show/hide
  const [severity, setSeverity] = useState('success'); //Notification Show/hide  
  const [alertMessage, setAlertMessage] = useState('');
  const [selectData, setSelectData] = useState();
  const [selectedBatch, setSelectedBatch] = useState(""); //from
  const [msgOpen, setMsgOpen] = useState(false);
  const [titleLabel, setTitleLabel] = useState("Oak Batch Names");
  const navigate = useNavigate();  
  const MENU_OPTIONS = [
    { label1: 'Edit ', },
    { label2: 'Attendence ', },
    { label3: 'Course Progress ', }
  ];

  const handleDownRecord = async (event, ) => {
    try {     
      const label = event.currentTarget.textContent;
      selected.forEach((item) => {
      });
      //console.log("label: ", label);
      if(selected.length > 0){
        switch(label){          
          case 'Edit':
          //  console.log('Edit: ');
            //handleEditStudent();
            break;
          case 'Zoom Attendance':
            handleConfirmDialogOpen(selected);
            break;
          case 'Course Progress':
            const courseprogress = await handleUserCourseProgress(selected);            
            handleCourseUserProgressDialogOpen(courseprogress);            
            break;          
          default:
            break;
        };
    }else{          
      setSeverity('error');
      setAlertMessage('Not selected Meeting Record!');
      setMsgOpen(true);
    }
    } catch (error) {
        console.log("handleDownRecord-Error: ", error);
    }
  };

  const handleUserCourseProgress = async (selected) => {
    const data = await getLmsCourseUserProgress(selected[0].idteachable);
    return data;
  };
  
  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleCloseStudentMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };
  
  // const handleStudentAttendancePageOpen = async (email) => {
  //   const reportStudentZoomAttendances = await getLmsZoomAttendance(email);  
  //   const MeetItem = async (index) => {
  //     if (index < selected.length) {
  //       navigate(`/dashboard/studentattendances`, { state: { reportStudentZoomAttendances } });
  //       await MeetItem(index + 1);
  //     }
  //   };
  //   await MeetItem(0);
  // }

  const handleInterviewTransactionPageOpen = async (selectedId) => {
  //  console.log("handleInterviewTransactionPageOpen: ", selectedId);
    const TransactionItem = async (index) => {
      if (index < selected.length) {
         navigate(`/dashboard/interviewtransaction`, { state: { selectedId } });
        await TransactionItem(index + 1);
      }
    };
    await TransactionItem(0);
  }

  const [reportLmsZoomAttendance, setReportLmsZoomAttendance] = useState();
  const handleAllBatchAttendancePageOpen = async (select) => {
    
    const batchName = filterStudentId;
    if(filterRecords.length > 0){
      // const getEmails = filterRecords.map((selected, index) => {
      //   return selected.contact.mail['mailaddress-1'];
      // });
    //  console.log('select: ', select[0].mail);
    //  console.log('currentAction: ', currentAction);
      setReportLmsZoomAttendance(await getLmsZoomAttendance(select[0].mail));
    //  console.log('reportLmsZoomAttendance : ', reportLmsZoomAttendance);
      //navigate(`/dashboard/studentattendances`, { state: { reportAllBatchZoomAttendances, batchName } });
    }
  };  

  useEffect(() => {
    // const fetchBatchNames = async () => {
    //   const data = await getLmsBatchNames();
    //   if (!data) {
    //     console.error("Result is undefined.");
    //     setAlertMessage('An unexpected error occurred while get Batch Names. Please try again.');
    //     setSeverity('error');        
    //     setOpen(true);
    //     return;
    //   }
    //   setSelectData(data);
    // };
    // fetchBatchNames();
    getstudentDatas();
  }, []);

  const session = getSession();

  const getstudentDatas = async () => {
    try {
      // console.log("session: ", session?.user[0]?.iduser);
      const userId = session?.user[0]?.iduser;
        const data = await getLmsHybridTrainee();
       // console.log("data.idteachable: ", data[3].idteachable);
        setrecordsNumber(data.length);
        setLmsHybridTraineeDatas(data);
    } catch (error) {
        console.error(error);
        setLmsHybridTraineeDatas([]);
    }
  };  

  const [students, setStudents] = useState([]);
  const handleNewStudentAdded = async() => {
    try {      
      const data = await getLmsHybridTrainee();
      setrecordsNumber(data.length);
      setLmsHybridTraineeDatas(data);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const updateDialogRef = useRef();
  const [selectedStudentData, setSelectedStudentData] = useState(null);
  const handleEditStudent = () => {
    try { 
      setSelectedStudentData(selected);
      if (updateDialogRef.current) {
        updateDialogRef.current.handleOpen();
      }
    } catch (error) {
      console.log("error: ", error);;      
    }
  };
  
  const [filterRecords, setFilterRecords] = useState();
  useEffect(() => {
    const filteredStudents = applySortFilter(lmsHybridTraineeDatas, getComparator(order, orderBy), filterStudentId);
    const validFilteredStudent = filteredStudents || []; //TODO: filteredStatus undefined ise boş bir dizi kullan
    setrecordsNumber(validFilteredStudent.length);
    setFilterRecords(validFilteredStudent);
  }, [lmsHybridTraineeDatas, order, orderBy, filterStudentId]); // Bu useEffect, belirtilen bağımlılıklar her değiştiğinde çalışır.
  
  // --------------- Edit user Button & Modals
  const [selectedFullName, setSelectedFullName] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');
  const handleOpenMenu = (event, { idstudents, idteachable, email, name, surname, contact, follower, follower_namesurname, 
    memberships, coursename, status, resourcecode, Interninfo, description, idbatch }) => {      
    setOpen(event.currentTarget);
    setSelected([{ id: idstudents, idteachable: idteachable, mail: email, name: name, surname: surname, contact: contact, follower: follower, follower_namesurname: follower_namesurname, memberships: memberships, 
    coursename: coursename, status: status, resourcecode: resourcecode, Interninfo: Interninfo, description: description, idbatch: idbatch }]);
    setSelectedUserName(email);
    setSelectedFullName(`${name} ${surname}`);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {   
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      if (!Array.isArray(filteredStudents)) {
        return;
      }
      const newSelecteds = filteredStudents.map((n) => ({
        id: n.idstudents,
        idteachable: n.idteachable,
        mail: n.email,
        contact: n.contact,
        fullname: `${n.name} ${n.surname}`,
      }));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, student) => {
  //  console.log("handleClick: ", student);
    //studentData
    const selectedIndex = selected.findIndex((item) => item.id === student.id);
    let newSelected = [];
  
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, student);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }  
    setSelected(newSelected);
  };

  const refreshStudentData = () => {
    getstudentDatas();
  };

  const performAction = async () => {
    try {
      const ids = currentAction.map((data, index) => {
        return data.id;      
      });
      // const idsQueryString = ids.join(',');
      // const response = await DeleteStudent(idsQueryString);
      // if (response.message === 'Student deleted successfully.' && response.affectedRows > 0) {
      //   setSeverity('success');
      //   setAlertMessage('Student deleted successfully.');
      //   setMsgOpen(true);
      //   getstudentDatas();
      //   handleCloseMenu();
      // }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const performExtractAction = async () => {
    try {
      // const ids = currentAction.map((data, index) => {
      //   return data.id;      
      // });
      // const idsQueryString = ids.join(',');
      // const response = await DeleteStudent(idsQueryString);
      // if (response.message === 'Student deleted successfully.' && response.affectedRows > 0) {
      //   setSeverity('success');
      //   setAlertMessage('Student deleted successfully.');
      //   setMsgOpen(true);
      //   getstudentDatas();
      //   handleCloseMenu();
      // }
    } catch (error) {
      console.log("Error: ", error);
    }
  };
  
  const openTransactionDetails = (follower) => {
   // console.log("idstudent-transaction: ", follower);
    // window.location.href = `mailto:${emailAddress}`;
    //window.open("https://mail.google.com/mail/?tab=rm&authuser=0&ogbl", '_blank');
  };
  
  const handleConfirm = () => {    
    performAction(currentAction);
    handleConfirmDialogClose();
  };

  const handleCourseUserProgress = () => {    
    //performExtractAction(currentAction);
    handleCourseUserProgressDialogClose();
  };
  
  // const handleCourseUserProgressDialogClose = () => {
  //   setOpenConfirmDialog(false);
  // };

  const handleCourseUserProgressDialogClose = () => {
    setOpenCourseUserProgressDialog(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState([]);
  const handleConfirmDialogOpen = (select) => {
    //console.log('handleConfirmDialogOpen: ', select);
    //select.id. select.mail, select.name, select.surname
    setCurrentAction(select);
    //TODO: attandence verilerini getir
    handleAllBatchAttendancePageOpen(select);
    setOpenConfirmDialog(true);
  };

  const [openCourseUserProgressDialog, setOpenCourseUserProgressDialog] = useState(false);
  const handleCourseUserProgressDialogOpen = (courseprogress) => {
    //console.log('courseprogress[0]: ', courseprogress.lmsCoursesInfo);
    if (Array.isArray(courseprogress.lmsCoursesInfo) && courseprogress.lmsCoursesInfo.length > 0) {
      setCurrentAction(courseprogress.lmsCoursesInfo); 
    } else {
      setCurrentAction([]); // Eğer geçerli bir dizi değilse, boş bir diziye ayarlayın
    }
    setOpenCourseUserProgressDialog(true);
  };

  useEffect(() => {
    //console.log('currentAction updated:', currentAction);
  }, [currentAction]);

  const [openTransactionExtractDialog, setOpenTransactionExtractDialog] = useState(false);
  const handleTransactionExtractDialogOpen = (select) => {   
    //console.log("select: ", select); 
    handleInterviewTransactionPageOpen(select[0].id)
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setfilterStudentId(event.target.value);
  };

  const handleFilterStatusByName = (newValue) => {
    if (!newValue) {
      setPage(0);
      setfilterStatusId('');
      return;
    }
    setPage(0);
    setfilterStatusId(newValue.id);
  };

  const handleSelectionChange = (newValue) => { //from dynamicSelect.js
   // console.log('newValue: ', newValue);
    if (!newValue) {
      setPage(0);
      setfilterBatchId('');
      return;
    }
    setPage(0);
    setfilterBatchId(newValue.id);   
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - lmsHybridTraineeDatas.length) : 0;
  const filteredStudents = applySortFilter(lmsHybridTraineeDatas, getComparator(order, orderBy), filterStudentId, filterStatusId, filterBatchId);
  const isNotFound = Array.isArray(filteredStudents) && !filteredStudents.length && (!!filterStudentId || !!filterStatusId || !!filterBatchId);
  const validFilteredStudents = Array.isArray(filteredStudents) ? filteredStudents : [];
  const filteredStatuses = applySortFilter(lmsHybridTraineeDatas, getComparator(order, orderBy), filterStatusId, filterBatchId);

  return (
    <>    
      <Helmet>
        <title> {t('Page.Title')} | Oak Academy </title>
      </Helmet>     
      <Container maxWidth={false} sx={{ marginLeft: 0, maxWidth: 'none' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
              {t('StudentList')}
            </Typography>
            { t('TotalRecords')} {`: ${ recordsNumber } `}
          </div>
          {/* <div>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '16px',
                border: 1,
                borderColor: 'grey.300',
                p: 2,
                bgcolor: 'background.paper',
              }}
            >
              <DynamicSelect
                sx={{ flex: 1, height: '100%' }} 
                width={200}
                data={selectData} 
                titleLabel={titleLabel}
                onSelectionChange={handleSelectionChange}
              />
              <Button 
                variant="contained" 
                startIcon={<Iconify icon="eva:plus-fill" />}
                sx={{ ml: 2, height: '100%', marginTop: 2.8, backgroundColor: '#5141C3'}}
                onClick={handleAllBatchAttendancePageOpen}
              >
                {t('GetAllBatchAttendances')}
              </Button>
            </Box>
          </div>          */}
          <CreateStudentDialog onStudentAdded={handleNewStudentAdded} />
          <UpdateStudentDialog ref={updateDialogRef} initialData={selectedStudentData} onStudentUpdate={refreshStudentData}/>
        </Stack>
        <Card>
          <LmsHybridListToolbar 
            numSelected={selected.length}
            onBatchName={handleSelectionChange}
            filterName={filterStudentId} 
            onFilterName={handleFilterByName} 
            onStatusName={handleFilterStatusByName} 
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <LmsHybridListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={recordsNumber}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>                                              
                { //TODO: Eger veri gelmez ise kontrol yapip frontend tarafina sirayet ettirmeyelim.                  
                  (rowsPerPage === -1 ? validFilteredStudents : validFilteredStudents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))       
                    .map((row, index) => {
                      const { idstudents, idteachable, email, coursename, name, surname, resourcecode, avatarUrl, contact, follower, follower_namesurname, status, memberships, Interninfo, description, idbatch } = row;
                      const selectedStudent = selected.indexOf(idstudents) !== -1;
                      const isItemSelected = selected.some(item => item.id === idstudents);
                    return (
                      <TableRow hover key={index} tabIndex={-1} role="checkbox" selected={selectedStudent}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, { id: idstudents, idteachable: idteachable, mail: email ,contact: contact, follower: follower, follower_namesurname: follower_namesurname, membership: memberships, resourcecode: resourcecode, Interninfo: Interninfo, idbatch: idbatch, description: description, fullname: `${name} ${surname}` })}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                             <Avatar alt={String(idstudents)} src={avatarUrl} /> 
                            <Typography variant="subtitle2" noWrap>
                              {`${name} ${surname}`}
                            </Typography>
                          </Stack>
                        </TableCell>
                        {/* <TableCell align="left" sx={{pl:7}}>{resourcecode}</TableCell> */}
                        <TableCell align="left">{coursename}</TableCell>                      
                        {/* <TableCell align="left">{idteachable}</TableCell>                       */}
                        <TableCell align="left">
                          <Label 
                            color={
                              // status == 1 ? 'success' : 
                              // status == 3 ? 'warning' : 
                              // status == 4 ? 'primary' : 
                              // status == 5 ? 'error' : 
                              status == 6 ? 'warning' :
                              status == 7 ? 'info' 
                            : 'error'}
                          >
                            { //TODO: These values must be retrieved from the database.
                              // status == 1 ? t('RegisteredStudent') : 
                              // status == 2 ? t('InternStudent') :  
                              // status == 3 ? t('TimedAccessGranted') :
                              // status == 4 ? t('ProspectiveStudent') : 
                              // status == 5 ? t('RelationshipEnded') :
                              status == 6 ? t('LMS-HybridRegistered') : 
                              status == 7 ? t('LMSRegistered') : t('RelationshipEnded')
                            }
                          </Label>
                        </TableCell>
                        <TableCell align="left">
                          <DataDisplay customData = {contact}/>
                        </TableCell>
                        <TableCell align="left">{follower_namesurname}
                          <Tooltip title = {`${t('InterviewTransactions')} (Followed by ${follower_namesurname}) `}>
                          </Tooltip>
                        </TableCell> 
                        <TableCell align="right">
                          <IconButton 
                            size="large" 
                            color="inherit" 
                            onClick={(event) => {
                              handleOpenMenu(event, {idstudents, idteachable, email, name, surname, contact, follower, follower_namesurname, memberships, coursename, status, resourcecode, description, Interninfo, idbatch})
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterStudentId}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, { value: -1, label: 'All' }]}
            component="div"
            count={recordsNumber}            
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 175, //PopupMenü genişliği
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Tooltip title = {t('InterviewTransactions')}>
        </Tooltip>
        <MenuItem key={MENU_OPTIONS.label1} onClick={handleEditStudent} sx={{ color: 'primary.main' , minWidth: 300}}>
          <Iconify icon={'eva:edit-2-fill'} sx={{ mr: 2 }} />
          {t('Edit')}
        </MenuItem>
        <MenuItem 
          key={MENU_OPTIONS.label4}
          onClick={(event) => handleDownRecord(event)} 
          sx={{ color: 'secondary.text' , minWidth: 300}}
        >
          <SvgIcon component={Icon_Attendance} sx={{ mr: 2 }} />
          {t('Zoom Attendance')}
        </MenuItem>
        <MenuItem key={MENU_OPTIONS.label3} onClick={handleDownRecord} sx={{ color: 'text.main' , minWidth: 300}}>
          <SvgIcon component={Icon_CourseProgress} sx={{ mr: 2 }} /> 
          Course Progress
                   
        </MenuItem>
      </Popover>

      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={5000} 
        onClose={handleCloseStudentMessage}
      >
        <Alert severity={severity} onClose={handleCloseStudentMessage} sx={{ width: 450, minWidth: 288, height: 75, fontSize: 14, fontStyle: 'normal'}} >
          {alertMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={openConfirmDialog}
        onClose={handleConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Zoom Attendance"}</DialogTitle>
        <DialogContent>
          {selected.map((select, index) => (              
            <p key={index} style={{fontWeight: 'bold', color: "#1640D6", marginBottom: 2 }}>
              {selectedFullName }{' /  '}{selectedUserName }{'  '}
            </p>
          ))}
          Intern Zoom Registration Participation status will be displayed in this area!
          <br/> 
          {Array.isArray(currentAction) && currentAction.length > 0 && currentAction.map((select, index) => (
            <div key={index}>
              {/* <p style={{ fontWeight: 'bold', color: "red", marginBottom: 2 }}>
                {select.name} {' / '} {select.email} {' / '} {select.role}
              </p> */}
                {Array.isArray(reportLmsZoomAttendance) && reportLmsZoomAttendance.length > 0 && reportLmsZoomAttendance.map((item, idx) => (
                  <div key={idx}>
                    <Box key={index} sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '16px', marginBottom: '16px' }}>
                      <p style={{ fontWeight: 'normal', color: "black", marginBottom: 2 }}>
                        {/* {idx + 1}{'. '}{convertDateTime(item.start_time)}{' | '}{item.topic}{' | total-min:'}{parseFloat((item.total_duration/60).toFixed(2))} */}
                        <span style={{ color: 'black', fontWeight: 'bold' }}>{idx + 1}{'. '}</span>
                        {convertDateTime(item.start_time)}{' | '}{item.topic}{'  '} 
                        <span style={{ color: 'red', fontWeight: 'bold' }}>Total-Min: </span>
                        {parseFloat((item.total_duration/60).toFixed(2))}
                      </p>                      
                  </Box>
                </div>
                ))}              
            </div>
          ))}           
        </DialogContent>
        <DialogActions>
          <Button 
            sx = {{ pl: 2, pr: 2, textAlign: 'center' }}
            onClick={handleConfirmDialogClose} 
            autoFocus
            style={{
              backgroundColor: '#6C757D',
              color: '#FFFFFF',
            }}
          >
            CLOSE
          </Button>
        </DialogActions>        
      </Dialog>

      <Dialog
        open={openCourseUserProgressDialog}
        onClose={handleCourseUserProgressDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"The selected trainee's LMS Course progress is shown below."}</DialogTitle>
        <DialogContent>
          {Array.isArray(currentAction) && currentAction.length > 0 && currentAction.map((select, index) => (
            <div key={index}>
              <p style={{ fontWeight: 'bold', color: "red", marginBottom: 2 }}>
                {select.name} {' / '} {select.email} {' / '} {select.role}
              </p>
              
                {Array.isArray(select.courses) && select.courses.length > 0 && select.courses.map((item, idx) => (
                  <div key={idx}>
                    <Box key={index} sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '16px', marginBottom: '16px' }}>
                      <p style={{ fontWeight: 'normal', color: "black", marginBottom: 2 }}>
                        {idx + 1}{'. '}{item.course_name}
                      </p>
                      <Box display="flex" alignItems="center" >
                        <Box width="100%" mr={1}>
                          <LinearProgress variant="determinate" value={item.percent_complete} />
                        </Box>
                        <Box minWidth={35}>
                          <Typography variant="body2" color="textSecondary">{`${Math.round(item.percent_complete)}%`}</Typography>
                        </Box>
                      </Box>
                  </Box>
                </div>
                ))}
              
            </div>
          ))}                      
          <br/>                    
        </DialogContent>
        <DialogActions>
          <Button 
            sx = {{ pl: 2, pr: 2, textAlign: 'center' }}
            onClick={handleCourseUserProgress} 
            autoFocus
            style={{
              backgroundColor: '#6C757D',
              color: '#FFFFFF',
            }}
          >
            CLOSE
          </Button>
        </DialogActions>        
      </Dialog>
    </>
  );
};