import React, { useState, useEffect } from 'react';
import { Button, Switch, List, ListItem, Accordion, AccordionSummary, AccordionDetails, 
Typography, Box, ListItemText, Paper, Grid, FormControlLabel, Divider, IconButton, Chip, Snackbar,
Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import FaceIcon from '@mui/icons-material/Face';
import NotesIcon from '@mui/icons-material/Notes';
import InputIcon from '@mui/icons-material/Input';
import EditIcon from '@mui/icons-material/Edit';
import ShortTextIcon from '@mui/icons-material/ShortText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import AddNewRoleForm from '../../components/roles/addNewRoles';
import getRoles from '../../_data/roles/getRoles';
import addNewRoles from '../../_data/roles/addNewRoles';
import UserRolesComponent from '../../components/roles/userRoles';
import updateRoleDefinitions from '../../utils/updateJsonFormat';
import deleteRoleDefinition from '../../_data/roles/deleteRoleDefinition';
import { useConfirmDialog } from '../../utils/contexts/ConfirmDialogContext';
import { useAlert } from '../../utils/contexts/AlertContext';
import { useTranslation } from 'react-i18next'; //Multi Language

function RolesDefinitions() {
  const [roles, setRoles] = useState([]); // List of All Roles
  const [selectedRole, setSelectedRole] = useState(null); // Seçili rol
  const [successMessage, setSuccessMessage] = useState('');
  const [disabledItems, setDisabledItems] = useState({ modules: [], pages: [], fields: [] });


  useEffect(() => {      
      loadRoles();
  }, []);// İlk yükleme ve her refresh'te rolleri yükler

  // Rol tanımlarının listesini güncellemek için kullanılacak state
  const [roleDefinitions, setRoleDefinitions] = useState({});
  const loadRoles = async () => {
      try {
          const response = await getRoles(); //coming all roles        
          setRoleDefinitions(response);
          setRoles(response);
      } catch (error) {
          console.error('Error loading roles:', error);
      }
  };

  const handleNewRole = async (newRole) => {
      try {
          const response = await addNewRoles(newRole);
          loadRoles(); // Roles list load again
          setSuccessMessage('Registration has been completed successfully.');
          setTimeout(() => setSuccessMessage(''), 3000); // Delete message after 3 seconds.
      } catch (error) {
          console.error('Error adding new role:', error);
      }
  };

  // Yeni rol eklemek için kullanılacak state
  const [newRoleName, setNewRoleName] = useState('');

  const handleRoleChange = (roleId) => {
      // Rol seçildiğinde gerekli işlemler
      setSelectedRole(roleId);
      // Burada backend'den seçilen role ait tanımları çekme işlemi yapılabilir
  };

  const handleToggleEditable = (moduleId, pageId, fieldId, currentEditable) => {
      // Editable toggle işlemi
      // ...state'i güncelle
  };

  const handleSaveAllChanges = async () => {
    try {
        //await axios.post('/updateRoles', { roleDefinitions });
      //  console.log('Role definitions all updated successfully');
    } catch (error) {
        console.error('Error updating role definitions:', error);
    }
  };

  const handleDelete = async (selectedFieldName, selectedPageName, selectedModuleName) => {
    try {      
      // Check delete process
      const data = selectedRoleDefinition;
      const update = {selectedModuleName, selectedPageName, selectedFieldName};
      const action = "delete"; // TODO: Burada global tanımlı bir değişken kullanmalıyız.
      const jsonData = updateRoleDefinitions(data, update, action);
      let newRoleDefinitionData = '{"Modules": ' + JSON.stringify(jsonData) + '}';
      let header = "Role Definition Operation";
      getOpenDialog(header, selectedRoleId, newRoleDefinitionData);
      // const result = await deleteRoleDefinition(selectedRoleId, newRoleDefinitionData);
      // if(result.affectedRows >= 1){
      //   loadRoles();
      //   console.log('Role definitions delete successfully', selectedModuleName, selectedPageName, selectedFieldName, selectedRoleDefinition, selectedRoleId);
      // }
    } catch (error) {
        console.error('Error deleting role definitions:', error);
    }
  };

  const [selectedRoleDefinition, setSelectedRoleDefinition] = useState(null);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const handleRoleClick = (roleDefinition, idroles) => {
    setSelectedRoleId(idroles);
    setSelectedRoleDefinition(roleDefinition?.Modules || []);    
  };

  const handleDatabaseOperationComplete = (selectedRoleId, result) => {
    if(result.affectedRows >= 1){
     // handleRoleClick(selectedRoleDefinition, selectedRoleId);
      loadRoles();
      showMessage("Role definitions append successfully");     
    }
    //handleRoleClick(roleDefinitions, selectedRoleId);
    // Burada state güncelleme veya sayfa yenileme işlemi yapabilirsiniz.
  };

  const { openDialog } = useConfirmDialog();
  const getOpenDialog = async (header, selectedRoleId, newRoleDefinitionData) => {
    openDialog({
      title: 'Confirm Action',
      messageHeader: header,
      message: 'Are you sure you want to delete?',
      onConfirm: async () => {        
        const result = await deleteRoleDefinition(selectedRoleId, newRoleDefinitionData);
        if(result.affectedRows >= 1){
          loadRoles();
          showMessage("Role definitions delete successfully");
          //console.log('Role definitions delete successfully');      //TODO: global message verdir.
        }else{
          //işlem yapma
        }
      },
      onCancel: () => {
      //  console.log('Cancelled!');
      },
    });
  };

  const [msgOpen, setMsgOpen] = useState(false);
  const [severity, setSeverity] = useState('success'); // or 'error'
  const [alertMessage, setAlertMessage] = useState('');
  const showMessage = (message, severityType = 'success') => {
    setAlertMessage(message);
    setSeverity(severityType);
    setMsgOpen(true);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };
  
  return (
    <div>
      {successMessage && <div>{successMessage}</div>}
      <AddNewRoleForm onNewRole={handleNewRole}/>
      <Box 
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '16px', // Yuvarlak köşeler için
        border: 1, // Çerçeve kalınlığı
        borderColor: 'grey.300', // Çerçeve rengi, tema renklerine göre ayarlanmalı
        p: 2, // padding, içerik ve çerçeve arasındaki boşluk
        bgcolor: 'background.paper', // Arka plan rengi, tema renklerine göre ayarlanmalı       
      }}
      >
      <Grid container spacing={2}>
        <Grid item xs={2} >
          <Paper style={{maxWidth: 460, margin: 'auto'}}>
            <Typography variant="h5" component="h2" style={{textAlign: 'center'}}>
              Roles
            </Typography>
            <List>
              {roles && roles.map((role, index) => (
                <ListItem button key={role.idroles} onClick={() => handleRoleClick(role.roledefinitions, role.idroles)}>
                  {role.roletitle !== 'SystemAdmin' && (                        
                    <ListItemText 
                    style={{ backgroundColor: selectedRoleId === role.idroles ? '#D4E7C5' : 'transparent' }}                
                    primary={`${role.idroles}. ${role.roletitle}`} />
                  )}
                </ListItem>
              ))}
            </List>
             {/* {<span>{selectedRoleId}</span>} */}
          </Paper>
        </Grid>
       
        <Grid sx={{borderLeft: 1, borderColor: 'primary.main'}} item xs={8}>
          <Paper elevation={0} style={{ padding: '10px', width: '600px'}}>
            {selectedRoleDefinition ? (                                 
              <React.Fragment>
                {selectedRoleDefinition.length === 0 
                ? 
                <Typography color="error" variant="h5">No Role Definition has been created yet.</Typography>
                :
                <Typography variant="h6">Role Definitions</Typography>   
                }                                 
                {selectedRoleDefinition && selectedRoleDefinition.map((module, moduleIndex) => (   
                  
                  <Accordion key={moduleIndex}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>                      
                      <Typography><span><strong style={ {color:'red'}}>Module Name:</strong> &nbsp;&nbsp;&nbsp;{module.ModuleName}</span></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List dense>
                        {module && module.Pages.map((page, pageIndex) => (
                          <React.Fragment key={pageIndex}>
                            <ListItem >
                              <ListItemText primary={<span><strong style={ {color:'navy'}}>Page Name:</strong> &nbsp;&nbsp;{page.PageName}</span>} />
                            </ListItem>
                            <Divider component="li" />
                            {page && page.FieldNames.map((field, fieldIndex) => (
                              <ListItem key={fieldIndex} secondaryAction={
                                <React.Fragment>
                                  <IconButton color="error" edge="end" aria-label="delete" onClick={() => handleDelete(field.FieldName, page.PageName, module.ModuleName)}>
                                    <DeleteIcon />
                                  </IconButton>
                                  {/* <Divider orientation="vertical" flexItem /> */}
                                </React.Fragment>
                              }>
                                <ListItemText primary={field.FieldName} />
                                <FormControlLabel
                                  control={<Switch checked={field.editable} />}
                                  label="Editable"
                                />
                              </ListItem>
                            ))}
                          </React.Fragment>
                        ))}
                      </List>                     
                    </AccordionDetails>
                  </Accordion>
                ))}
              </React.Fragment>
            ) : (              
              <Typography variant="subtitle1">Select a role to see the definitions</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
      </Box>
      <Grid sx = {{ mb: 4, pt: 1, height: 70, textAlign: 'right' }}>
        <Divider sx = {{ mb: 2 }} />
        <Button sx = {{height: 1 }} variant="contained" color="success" onClick={handleSaveAllChanges}>
          Save All Changes
        </Button>
      </Grid> 
      <UserRolesComponent selectedRoleId={selectedRoleId} selectedRoleDefinition={selectedRoleDefinition} onDatabaseOperationComplete={handleDatabaseOperationComplete} />
      <Snackbar 
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
        open={msgOpen}  
        autoHideDuration={10000} 
        onClose={handleCloseMessage}
      >        
      <Alert severity={severity} onClose={handleCloseMessage} 
        sx={{ 
          width: 450, 
          minWidth: 288,
          fontSize: 15, 
          fontStyle: 'normal' 
        }}
      >
        {alertMessage}
      </Alert>
      </Snackbar>
    </div>
    
  );
}

export default RolesDefinitions;