//SELECT meet_uuid, meetid, topic, total_duration, start_time FROM v_zoom_participant_reports  where user_email = 'eliftayfurkoc@gmail.com' order by start_time;

import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getLmsZoomAttendance= async (email) => {
  try {
    const token = await getToken();
    // console.log('getLmsZoomAttendance: ', email);
    const response = await axios.get(`${BaseUrl}/students/getlmszoomattendance`, {    
      headers: {
        'Authorization': `Bearer ${token}`
      },     
      params: {
        user_email: email,
      },
    });
    // console.log('getLmsZoomAttendance: ', response.data);
    return response.data;
  } catch (error) {  
      console.error("Report error: ", error);
      return [];
  }
};

export default getLmsZoomAttendance;