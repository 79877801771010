import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getStudentCountryData = async (countryname) => {
  try {    
      const token = await getToken();
      const response = await axios.get(`${BaseUrl}/students/getstudentcountrydata`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        params: {
            countryname: countryname
        }
      });
      const info = response.data || [];
      return response.data;
  } catch (error) {
    // if (err.response) {
    //   // Backend hatası
    //   setError(err.response.data);
    // } else {
    //   // Ağ hatası veya başka bir hata
    //   setError({ message: 'An unexpected error occurred.' });
    // }
      return [];
  }
};
export default getStudentCountryData;