import React, {useState, useRef, useEffect} from 'react';
import { TextField, Button, Container, Stack, Avatar } from '@mui/material';
import api from '../../_data/api/backendApi'
import getToken from '../../_data/api/getToken';
// import { Link } from "react-router-dom"
// import axios from 'axios';



const ModalRegisterForm = (props) => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [fullName, setFullName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [roles, setRoles] = useState('')
    const [jobs, setJobs] = useState('')
    const [address, setAddress] = useState('')
    const [email, setEmail] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [password, setPassword] = useState('')
    const [avatar, setAvatar] = useState(null);
    // const [isSuccessfull, setIsSuccessfull] = useState(false);

    // ------------------------------Api-Ninja.com password manager api----------------------------------
    // const length = '18';
    // const apiKey = 'cQj/I8S3kcz3kcO5S7jpVQ==wqj9QEhwkDGlZqd3';  // Don't shatre your API key!!!
    
    // axios.get(`https://api.api-ninjas.com/v1/passwordgenerator?length=${length}`, {
    //     headers: {
    //         'X-Api-Key': apiKey,
    //         'Content-Type': 'application/json'
    //     }
    // })
    // .then(response => {
    //     console.log(response.data);
    // })
    // .catch(error => {
    //     console.error('Hata:', error.response);
    // });
    // ------------------------------Api-Ninja.com password manager api----------------------------------

    useEffect(() => {
    }, [firstName, lastName])

    const fileInputRef = useRef(null);

    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatar(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    async function handleSubmit (event) {
        event.preventDefault();
        const formData = {
            name_surname: fullName,
            username: email,
            passwd: password,
            roles: JSON.stringify(roles),
            jobs: JSON.stringify(jobs),
            contacts: JSON.stringify({
                email: email,
                phoneNumber: phoneNumber,
                address: address
            }),
            is_active: 1
        }

        // console.log('form data:', formData);

        try {
            const token = await getToken();
            const response = await api.post('/users/setusers', formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 200 && response.data.affectedRows === 1 ) {
                props.onSuccess('Successfully registered!');
            } else {
                //console.error('Registration failed:', response.data);
                props.onError('Registration failed!');
            }
        } catch (error) {
        //    console.log('Error while registering:', error);
            console.error("API error:", error.response ? error.response.data : error.message);
        }
    }
 
    return (
        <>
            <h2>New User Registration Form</h2>
            <form onSubmit={handleSubmit}> {/*action={<Link to="/login" />}*/}
                {/* <Avatar sx={{ width: 80, height: 80, mb:2, fontSize: 80 }}>
                    
                </Avatar> */}
                <label htmlFor="avatar-upload" style={{ display: 'inline-block' }}>
                    <input
                        ref={fileInputRef}
                        type="file"
                        id="avatar-upload"                        
                        style={{ display: 'none' }}
                        onChange={handleAvatarChange}
                    />
                    <Avatar
                        src={avatar}
                        sx={{
                            width: 80,
                            height: 80,
                            mb: 2,
                            fontSize: 40,
                            cursor: 'pointer',
                            backgroundColor: !avatar && 'grey'
                        }}
                    >
                        {!avatar && '+'}
                    </Avatar>
                </label>
                <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                    <TextField
                        type="text"
                        variant='outlined'
                        color='secondary'
                        label="First Name"
                        onChange={e => setFirstName(e.target.value)}
                        value={firstName}
                        fullWidth
                        autoComplete="firstName"
                        required
                    />
                    <TextField
                        type="text"
                        variant='outlined'
                        color='secondary'
                        label="Last Name"
                        onChange={e => setLastName(e.target.value)}
                        value={lastName}
                        autoComplete="lastName"
                        fullWidth
                        required
                    />
                </Stack>
                <Stack spacing={2} direction="row" sx={{marginBottom: 2}}>
                    <TextField
                        type="tel"
                        variant='outlined'
                        color='secondary'
                        label="Phone Number"
                        onChange={e => setPhoneNumber(e.target.value)}
                        value={phoneNumber}
                        autoComplete="phoneNumber"
                        fullWidth
                        required
                        sx={{mb: 4}}
                    />
                    <TextField
                        type="text"
                        variant='outlined'
                        color='secondary'
                        label="Roles"
                        onChange={e => setRoles(e.target.value)}
                        value={roles}
                        autoComplete="roles"
                        fullWidth
                        required
                    />
                    <TextField
                        type="text"
                        variant='outlined'
                        color='secondary'
                        label="Job Titles"
                        onChange={e => setJobs(e.target.value)}
                        value={jobs}
                        autoComplete="jobs"
                        fullWidth
                        required
                    />
                </Stack>
                <TextField
                    type="text"
                    variant='outlined'
                    color='secondary'
                    label="Addresses"
                    onChange={e => setAddress(e.target.value)}
                    value={address}
                    autoComplete="address"
                    fullWidth
                    required
                    sx={{mb: 4}}
                />
                <TextField
                    type="email"
                    variant='outlined'
                    color='secondary'
                    label="Email"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    autoComplete="email"
                    fullWidth
                    required
                    sx={{mb: 4}}
                />
                <TextField
                    type="password"
                    variant='outlined'
                    color='secondary'
                    label="Password"
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                    autoComplete="password"
                    required
                    fullWidth
                    sx={{mb: 4}}
                />
                <TextField
                    type="date"
                    variant='outlined'
                    color='secondary'
                    label="Date of Birth"
                    onChange={e => setDateOfBirth(e.target.value)}
                    value={dateOfBirth}
                    autoComplete="dateOfBirth"
                    fullWidth
                    required
                    sx={{mb: 4}}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
                <Button variant="outlined" color="secondary" type="submit">Register</Button>
            </form>
     
        </>
    )
}
 
export default ModalRegisterForm;