// AccountContext.js
import React, { createContext, useState, useEffect } from 'react';

export const AccountContext = createContext(null);

export const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState(null);

  useEffect(() => {
    const loadAccount = async () => {
      // Örneğin localStorage'dan yükleme:
      const savedAccount = localStorage.getItem('session');
      if (savedAccount) {
        setAccount(JSON.parse(savedAccount));
      } else {
        // Burada API çağrısı yapılabilir ve sonucu state'e kaydedilebilir
      }
    };

    loadAccount();
  }, []);

  return (
    <AccountContext.Provider value={{ account, setAccount }}>
      {children}
    </AccountContext.Provider>
  );
};