// src/components/loading/LoadingModal.js
import React from 'react';
import { Backdrop, CircularProgress, Modal, Box, Typography } from '@mui/material';
import { useLoading } from '../../utils/contexts/LoadingContext';

const LoadingModal = () => {
  const { isLoading } = useLoading();

  return (
    <Modal
      open={isLoading}
      aria-labelledby="loading-modal-title"
      aria-describedby="loading-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        style: { zIndex: 1200, backgroundColor: 'rgba(0, 0, 0, 0.5)' }, // Arka plan siyah ama yarı saydam
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        bgcolor="rgba(0, 0, 0, 0.5)"
        padding={4}
        borderRadius={2}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <CircularProgress color="inherit" style={{ color: 'white' }} /> {/* Icon color is white */}
        <Typography variant="h6" color="white" style={{ marginTop: 20 }}>
          Processing, please wait...
        </Typography>
      </Box>
    </Modal>
  );
};

export default LoadingModal;