import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
 import './multilang';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import ConfirmDialog from './components/confirmdialog/ConfirmDialog';
import SessionExpiredDialog from './components/session/sessionExpried';

// context
import { AccountProvider } from './utils/contexts/AccountContext'; 
import { LanguageProvider } from './utils/contexts/LanguageContext';
import { AlertProvider } from './utils/contexts/AlertContext';
import { ConfirmDialogProvider } from './utils/contexts/ConfirmDialogContext';
import { LoadingProvider } from './utils/contexts/LoadingContext';
import LoadingModal from './components/loading/LoadingModal';

// hooks
//import useInactivityTimer from './useInactivityTimer';
import useTokenRefresh from './useTokenRefresh';
import useUploadImage from '../src/_data/user/uploadImage'; // Importing the useUploadImage hook

import { useState } from 'react';
import AppContent from './AppContent';

export default function App() {
  return (
    <HelmetProvider>
      <AccountProvider>
        <LanguageProvider>
          <AlertProvider>
            <ThemeProvider>
              <ConfirmDialogProvider>
                <LoadingProvider>
                  <BrowserRouter>
                    <ConfirmDialog />
                    <LoadingModal /> {/* Loader bileşenini buraya ekliyoruz */}
                    <AppContent /> {/* Tab içerikleri için ekledik */}
                  </BrowserRouter>
                  </LoadingProvider>
              </ConfirmDialogProvider>
            </ThemeProvider>
          </AlertProvider>
        </LanguageProvider>
      </AccountProvider>
    </HelmetProvider>
  );
}