import axios from 'axios';
import getToken from '../api/getToken';
import config from '../../config';
const { BaseUrl } = config;

const getLmsUsers = async (pageNum) => {
  try {
    const token = await getToken();
      const response = await axios.get(`${BaseUrl}/lms/allusers`, {
        params: {
          pageNum: pageNum,
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const meta = response.data.meta;
      const info = response.data.users || [];
      const lmsUsersInfo = info.map((item, index) => {
        return {
          id: item.id || '',
          name: item.name || '',
          email: item.email || '',
        };
      });
      return {lmsUsersInfo, meta};
    } catch (error) {
      return [];
    }
};

export default getLmsUsers;
