import React, { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';

function AddNewRoleForm({ onNewRole }) {
  const [roleName, setRoleName] = useState('');
  const [roleDefinition, setRoleDefinition] = useState('');
  const [roleDescription, setRoleDescription] = useState('');

  const handleInsert = () => {
   // console.log("handleInsert-newRoleName: ", roleName, roleDescription);
    onNewRole({ name: roleName, description:  roleDescription});
    setRoleName(''); // İnputu sıfırlar
    setRoleDescription(''); // İnputu sıfırlar
  };

  return (
      <Box
         // component="form"
          sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 2,
              border: '1px solid #c4c4c4', // Gri çerçeve
              borderRadius: '8px', // Yuvarlatılmış köşeler
              bgcolor: 'white', // Beyaz arka plan
              boxShadow: 1, // Hafif gölge
              '&:hover': {
                  borderColor: 'primary.main', // Hover durumunda çerçeve rengi
              },
              width: 900,
              maxWidth: 1000, // Maksimum genişlik
              //margin: 'auto', // Otomatik merkezleme
              marginBottom: 2
          }}
          noValidate
          autoComplete="off"
         // onSubmit={handleSubmit}
      >
          <TextField
              label="Role Name"
              variant="outlined"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
              placeholder="Role Name"
              sx={{ mr: 2, flex: 1, width: 1 }} // Sağa doğru boşluk ve esneklik
          />
          <TextField
              label="Role Description"
              variant="outlined"
              value={roleDescription}
              onChange={(e) => setRoleDescription(e.target.value)}
              placeholder="Role Description"
              sx={{ mr: 2, flex: 1, width: 40 }} // Sağa doğru boşluk ve esneklik
              fullWidth
          />
          <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ py: '10px', px: '20px' }} // Düğme yüksekliği ve genişliği
              onClick={handleInsert}
          >
              Insert in Roles
          </Button>
      </Box>
  );
}

export default AddNewRoleForm;
