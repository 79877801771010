import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const uploadImage = async (formData) => {
  try {
    const token = await getToken();   
    // FormData içeriğini kontrol edin
    for (let [key, value] of formData.entries()) {
    //  console.log("(handleFileUpload) FormData: ", key, value);
    }
    const response = await axios.post(`${BaseUrl}/users/upload-avatar`,formData, {        
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          },
      });
     // console.log("return: ", response);
      return response.data;
  } catch (error) {
      console.error("Update error: ", error);
      return [];
  }
};

export default uploadImage;