// TODO: Bu kod tarayicinin yerel tarih/saat ozelliklerini almakta tarayicinin zaman diliminden veri cekmekte!!!! 
// function formatDateTime() {
//   const now = new Date();
//   const options = {
//     year: 'numeric', month: 'long', day: 'numeric',
//     hour: 'numeric', minute: 'numeric', second: 'numeric',
//     hour12: true,
//     fractionalSecondDigits: 3 // Milisaniyeyi dahil etmek için.
//   };
//   const formatter = new Intl.DateTimeFormat('en-US', options);
//   const dateString = formatter.format(now);
//   const dateObject = new Date(dateString.replace(' at ', ' ')); // convert date format
//   const dateTimeNow = dateObject.toISOString().slice(0, 19).replace('T', ' ') + '.' + Math.floor(dateObject.getMilliseconds()/100); //convert to 'YYYY-MM-DD HH:MM:SS.sss' format

//   return dateTimeNow;
// }

// module.exports = formatDateTime;

// dateTimeFormatter.js
// function formatDateTime() {
//   // Almanya'daki (CET/CEST) zaman dilimini belirlemek için UTC saatini al ve 2 saat ekle
//   const dateTimeString = new Date().toISOString();
//   //const now = new Date();
//   const utcNow = new Date(now.getTime() + (now.getTimezoneOffset() * 60000)); // UTC'ye dönüştür
//   const germanyOffset = 2; // Almanya yaz saati (CEST) için UTC+2
//   const germanyNow = new Date(utcNow.getTime() + (germanyOffset * 3600000)); // Almanya saatine dönüştür
//   const options = {
//     year: 'numeric', month: 'long', day: 'numeric',
//     hour: 'numeric', minute: 'numeric', second: 'numeric',
//     hour12: true,
//     fractionalSecondDigits: 3 // Milisaniyeyi dahil etmek için.
//   };
//   const formatter = new Intl.DateTimeFormat('en-US', options);
//   const dateString = formatter.format(germanyNow);
//   const dateObject = new Date(dateString.replace(' at ', ' ')); // convert date format
//   const dateTimeNow = dateObject.toISOString().slice(0, 19).replace('T', ' ') + '.' + Math.floor(dateObject.getMilliseconds()/100); //convert to 'YYYY-MM-DD HH:MM:SS.sss' format
//   return dateTimeNow;
// }

// module.exports = formatDateTime;

function formatDateTime() {
  const now = new Date();
  //const germanyTime = now.toLocaleString('de-DE', { timeZone: 'Europe/Berlin' });

  // const options = {
  //   year: 'numeric',
  //   month: 'long',
  //   day: 'numeric',
  //   hour: 'numeric',
  //   minute: 'numeric',
  //   second: 'numeric',
  //   hour12: false,
  // };
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'Europe/Berlin' // Almanya (Berlin) zaman dilimini kullan
  };

  const formatter = new Intl.DateTimeFormat('de-DE', options);
  // const dateTimeNow = formatter.format(new Date(now)).replace(',', '');
  const dateTimeNow = formatter.format(now).replace(',', '');
  return dateTimeNow;
}

module.exports = formatDateTime;
