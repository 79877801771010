import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const zooms = async () => {
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/listmeeting`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });
    // console.log("response.data.meetings :", response.data.meetings);
    const meetings = response.data.meetings || []; // meetings dizisi boş ise varsayılan olarak boş dizi ataması yapılır.
    // console.log("meetings :", meetings);    
    const zoomDatas = meetings.map((meeting) => ({
      id: meeting.uuid || '',
      meetid: meeting.id || '',
      start_time: meeting.start_time || '',
      duration: meeting.duration || '',
      topic: meeting.topic || '',
      created_at: meeting.created_at || '',      
    }));
    // console.log("zoomDatas :", zoomDatas);
    return zoomDatas;
  } catch (error) {
    console.error(error);    
    return [];
  }
};

export default zooms;
