import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControlLabel, Checkbox, Typography, Box } from '@mui/material';

const PreferencesDialog = ({ open, onClose, fullname, preferences = "[]" }) => {
  const [normalizedPreferences, setNormalizedPreferences] = useState([]);

  // useEffect(() => {
  //   try {
  //     const parsedPreferences = JSON.parse(preferences);
  //     if (Array.isArray(parsedPreferences)) {
  //       setNormalizedPreferences(parsedPreferences);
  //     } else {
  //       console.error("Parsed preferences should be an array");
  //     }
  //   } catch (error) {
  //     console.error("Failed to parse preferences: ", error);
  //   }
  // }, [preferences]);
  useEffect(() => {
    try {
      // Check if preferences is a string and parse it if necessary
      const parsedPreferences = typeof preferences === 'string' ? JSON.parse(preferences) : preferences;
      
      if (Array.isArray(parsedPreferences)) {
        setNormalizedPreferences(parsedPreferences);
      } else {
        console.error("Parsed preferences should be an array");
      }
    } catch (error) {
      console.error("Failed to parse preferences: ", error);
    }
  }, [preferences]);

  //console.log('PreferencesDialog: ', normalizedPreferences);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Participant Preferences / <span style={{ color: 'red' }}>{fullname}</span>
      </DialogTitle>
      <DialogContent dividers>
        {normalizedPreferences.map((category) => (
          <Box key={category.name} sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>{category.name}</Typography>
            {Object.keys(category["sub-items"]).map((item) => (
              <FormControlLabel
                key={item}
                // control={<Checkbox defaultChecked={category["sub-items"][item]} />}
                control={<Checkbox defaultChecked={Boolean(category["sub-items"][item])} />}
                label={item}
              />
            ))}
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ backgroundColor: 'red', color: 'white' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreferencesDialog;




// import { useState, useEffect } from 'react';
// import React from 'react';
// import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControlLabel, Checkbox, Typography, Box } from '@mui/material';

// const PreferencesDialog = ({ open, onClose, fullname, preferences = [] }) => {
//   const [normalizedPreferences, setNormalizedPreferences] = useState([]);
//   useEffect(() => {
//     // Eğer preferences bir dizi değilse, onu diziye çevir
//     if (!Array.isArray(preferences)) {
//       setNormalizedPreferences([preferences]);
//     } else {
//       setNormalizedPreferences(preferences);
//     }
//   }, [preferences]);

//   console.log('PreferencesDialog: ', preferences);
//   // if (!Array.isArray(preferences)) {
//   //   console.error("Preferences should be an array");
//   //   return null;
//   // }
//   console.log('PreferencesDialog: ', normalizedPreferences);
//   return (
//       <Dialog open={open} onClose={onClose}>
//           <DialogTitle>
//               Participant Preferences / <span style={{ color: 'red' }}>{fullname}</span>
//           </DialogTitle>
//           <DialogContent dividers>
//               {normalizedPreferences.map((category) => (
//                   <Box key={category.name} sx={{ mb: 2 }}>
//                       <Typography variant="h6" sx={{ mb: 1 }}>{category.name}</Typography>
//                       {Object.keys(category["sub-items"]).map((item) => (
//                           <FormControlLabel
//                               key={item}
//                               control={<Checkbox defaultChecked={category["sub-items"][item]} />}
//                               label={item}
//                           />
//                       ))}
//                   </Box>
//               ))}
//           </DialogContent>
//           <DialogActions>
//               <Button onClick={onClose} sx={{ backgroundColor: 'red', color: 'white' }}>
//                   Close
//               </Button>
//           </DialogActions>
//       </Dialog>
//   );
// };

// export default PreferencesDialog;