export default function convertDateTime(dateTimeString) {
  try {
    //console.log('convertDateTime-dateTimeString-1: ', dateTimeString);
    if (!dateTimeString || isNaN(Date.parse(dateTimeString))) {
      return 'Invalid Date'; // veya başka bir hata mesajı / varsayılan değer simdilik bu
    }
    // '28.07.2024 17:39:36' formatındaki bir tarihi yeni bir formatta işleme
    const parts = dateTimeString.match(/(\d{2})\.(\d{2})\.(\d{4}) (\d{2}):(\d{2}):(\d{2})/);
    if (!parts) {
      return 'Invalid Date';
    }

   // let date = new Date(dateTimeString);
    // let formatter = new Intl.DateTimeFormat('en-US', {
    // let formatter = new Intl.DateTimeFormat('de-DE', {
    //   year: 'numeric',
    //   month: '2-digit',
    //   day: '2-digit',
    //   hour: '2-digit',
    //   minute: '2-digit',
    //   second: '2-digit',
    //   hour12: false, // 24 saat formatı kullan
    //   //timeZone: 'UTC' // UTC zaman dilimini kullan
    //   timeZone: 'Europe/Berlin' // Almanya (Berlin) zaman dilimini kullan
    // });
    // let formattedDate = formatter.format(date).replace(',', '');
    // return formattedDate;
    const formattedDate = `${parts[3]}-${parts[2]}-${parts[1]} ${parts[4]}:${parts[5]}:${parts[6]}`;
   // console.log('convertDateTime-dateTimeString-2: ', formattedDate);
    return formattedDate;
  } catch (error) {
    console.log("Error[convertDateTime]: ", error);
  }
}
