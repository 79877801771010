import axios from 'axios';
import config from '../../config';
import getToken from '../api/getToken';
const { BaseUrl } = config;

const getLectures = async () => {
  try {
    const token = await getToken();
    const response = await axios.get(`${BaseUrl}/syllabus/getlectures`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    //console.log("getLectures-response: ",response);
    const info = response.data || [];
    const lecturesInfo = info.map((item, index) => {
        return {
          idsyllabus_lecture: item.idsyllabus_lecture || '',
          lecturetopic: item.lecturetopic || '',
          lectureduration: item.lectureduration || '',
          lecturedescription: item.description || '',
          moduleid: item.moduleid || ''
        };
    });
    //console.log("lecturesInfo: ",lecturesInfo);
    return lecturesInfo;
  } catch (error) {
    console.log("error-getLectures: ", error);
      return [];
  }
};

export default getLectures;